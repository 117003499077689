import * as yup from 'yup';

import { Language } from '../../../common/models/enums/language';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { NotificationBrand } from '../../ApplicationUsers/models/notification-config';

export const useSendTestNotificationValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    language: yup
      .string()
      .oneOf([...Object.values(Language)])
      .default(Language.En),
    applicationUser: yup.object().required(translations('validation_required')),
    messageType: yup.object().required(translations('validation_required')),
    notificationType: yup
      .string()
      .oneOf([NotificationBrand.EMAIL, NotificationBrand.PUSH])
      .default(NotificationBrand.EMAIL),
  });
};
