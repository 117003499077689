import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { pdf } from '@react-pdf/renderer';

import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../../../../common/constants/common';
import { useDownloadZip } from '../../../../../common/hooks/use-download-zip.hook';
import { Paginated } from '../../../../../common/models';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useKeyAccountGroupReturnCodes } from '../../../../../services/key-account-groups';
import { RestaurantReturnCodeResponse } from '../../../../Restaurants/RestaurantReturnCodes/models/restaurant-return-code-response';
import PdfDocument from '../../../../Restaurants/ReturnPosters/PdfDocument';
import { getReturnCodePosterFileName } from '../../../../Restaurants/utils/return-codes.helper';
import { RETURN_POSTERS_ZIP_FILE_NAME } from '../../../KeyAccountGroupRestaurants/components/RestaurantsReturnCodes/utils/restaurant-return-codes.const';
import { useReturnCodesFilterContext } from '../../context/HistoryFilterContext/hooks/use-return-codes-filter-context';
import { KeyAccountGroupReturnCodesQueryRequest } from '../../models/key-account-group-return-codes-query-request';

type DownloadReturnQrPostersProps = {
  keyAccountGroupId: string;
};

export const DownloadReturnQrPosters = ({ keyAccountGroupId }: DownloadReturnQrPostersProps) => {
  const translations = useTranslations();
  const { downloadZip } = useDownloadZip();

  const { selectedReturnCodesNumber, selectedReturnCodesIds } = useReturnCodesFilterContext();
  const [enabled, setEnabled] = useState(false);

  const { offset, limit } = ALL_PAGINATED_LIST_ITEMS_PARAMS;
  const queryRequest: KeyAccountGroupReturnCodesQueryRequest = {
    offset,
    limit,
    returnCodeIds: selectedReturnCodesIds ?? undefined,
  };

  const triggerDownload = () => setEnabled(true);

  const handleDownloadReturnPosters = async (data: Paginated<RestaurantReturnCodeResponse>) => {
    const files = await Promise.all(
      data.items.map(async (returnCode) => {
        const blob = await pdf(
          <PdfDocument
            returnCode={returnCode}
            restaurant={{
              restaurantName: returnCode.restaurant.restaurantName || '',
              metaCategories: returnCode.restaurant.metaCategories || [],
            }}
          />,
        ).toBlob();
        return {
          name: getReturnCodePosterFileName(returnCode.name),
          blob,
        };
      }),
    );
    await downloadZip(files, RETURN_POSTERS_ZIP_FILE_NAME).finally(() => setEnabled(false));
  };

  const { isFetching: isFetchingReturnCodes } = useKeyAccountGroupReturnCodes(
    keyAccountGroupId,
    queryRequest,
    enabled,
    handleDownloadReturnPosters,
  );

  return (
    <Button
      isLoading={enabled && isFetchingReturnCodes}
      isDisabled={!selectedReturnCodesNumber}
      onClick={triggerDownload}
    >
      {translations('return_posters_download', {
        '{{count}}': selectedReturnCodesNumber.toString(),
      })}
    </Button>
  );
};
