import React, { JSX } from 'react';
import { CellProps } from 'react-table';
import { Button, Flex } from '@chakra-ui/react';
import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { Language, useExecuteWithConfirmation } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';

import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { useDeleteTag, useTagsList } from '../../services/tags';
import { TagResponse } from './models/tag-response';

const Tags = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);

  const {
    data: paginatedTags,
    isFetching,
    error,
  } = useTagsList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const { mutate: deleteTag, isLoading, error: deleteError } = useDeleteTag();

  const { executeWithConfirmation: onDeleteTag } = useExecuteWithConfirmation(
    translations('tags_delete_confirmation'),
    deleteTag,
  );

  const columns = React.useMemo(
    () => [
      {
        id: '1',
        Header: translations('tags_table_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('tags_en_table_name'),
        accessor: 'translations' as const,
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell }: CellProps<TagResponse>) => cell.value[Language.En] ?? null,
      },
      {
        id: '3',
        Header: translations('tags_de_table_name'),
        accessor: 'translations' as const,
        Cell: ({ cell }: CellProps<TagResponse>) => cell.value[Language.De] ?? null,
      },
      {
        id: 'actions',
        Header: translations('tags_table_actions'),
        Cell: ({ row: { original } }: CellProps<TagResponse>) => (
          <Flex>
            <AddEditItemLink to={`${Routes.TAGS_PATH}/${original.id}`} m={1} isEdit />
            <Button m={1} onClick={() => onDeleteTag(original.id)}>
              {translations('delete')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, onDeleteTag],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('tags_header')}</PageHeading>
      <ErrorMessages errors={[error, deleteError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_TAG_PATH}>{translations('tags_add')}</AddEditItemLink>
      </Flex>
      <Table<TagResponse>
        isLoading={isFetching || isLoading}
        data={paginatedTags?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedTags?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('tags_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default Tags;
