import React, { JSX } from 'react';
import { Box } from '@chakra-ui/react';

type MainPageContentProp = {
  children: React.ReactNode | React.ReactNode[];
};

export const MainPageContent = ({ children }: MainPageContentProp): JSX.Element => (
  <Box textAlign="center" mb={1}>
    {children}
  </Box>
);
