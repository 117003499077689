import React, { JSX } from 'react';
import { generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button, Flex, useTheme } from '@chakra-ui/react';
import { BooleanValueIcon, ErrorMessages, LinkButton, MainPageContent, PageHeading, Table } from 'common/components';
import { useExecuteWithConfirmation } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useAnonymizeApplicationUser, useApplicationUsersList } from 'services/application-users';

import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { ApplicationUser } from './models/application-user';
import { UserType } from './models/enums/user-type.enum';
import AnonymizedUserFallbackValue from './AnonymizedUserFallbackValue';

const ApplicationUsersPage = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const [page, setPage] = React.useState(1);
  const { colors } = useTheme();

  const {
    data,
    isFetching,
    error: applicationUsersError,
  } = useApplicationUsersList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const { mutate: sendAnonymizeUserRequest, isLoading: isAnonymizingInProgress } = useAnonymizeApplicationUser();

  const { executeWithConfirmation: triggerUserAnonymization } = useExecuteWithConfirmation(
    translations('users_anonymize_confirmation'),
    sendAnonymizeUserRequest,
  );

  const columns = React.useMemo(
    () => [
      {
        id: '1',
        Header: translations('users_table_corporate_unique_id'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ApplicationUser>) => <AnonymizedUserFallbackValue value={original.corporateUniqueId} />,
      },
      {
        id: '2',
        Header: translations('users_table_user_id'),
        accessor: 'userId' as const,
      },
      {
        id: '3',
        Header: translations('users_table_first_name'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ApplicationUser>) => <AnonymizedUserFallbackValue value={original.firstName} />,
      },
      {
        id: '4',
        Header: translations('users_table_last_name'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ApplicationUser>) => <AnonymizedUserFallbackValue value={original.lastName} />,
      },
      {
        id: '5',
        Header: translations('users_table_email'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ApplicationUser>) => <AnonymizedUserFallbackValue value={original.email} />,
      },
      {
        id: '6',
        Header: translations('users_table_language'),
        accessor: 'language' as const,
      },
      {
        id: '7',
        Header: translations('users_table_subscribed'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ApplicationUser>) => <BooleanValueIcon value={original.isSubscribed} />,
      },
      {
        id: '8',
        Header: translations('users_table_skip_payments'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ApplicationUser>) => <BooleanValueIcon value={original.skipPayments} />,
      },
      {
        id: 'actions',
        Header: translations('users_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ApplicationUser>) => (
          <Flex wrap="wrap">
            <LinkButton
              m={1}
              to={generatePath(Routes.USERS_ASSIGNED_ITEMS_PATH, { userId: original.id })}
              color={colors.orange[500]}
            >
              {translations('users_items_button')}
            </LinkButton>
            {original.userType === UserType.app ? (
              <LinkButton
                m={1}
                to={generatePath(Routes.USERS_PROFILE_SETTINGS_PATH, { userId: original.id })}
                color={colors.orange[500]}
              >
                {translations('profile_settings_button')}
              </LinkButton>
            ) : null}
            <Button m={1} onClick={() => triggerUserAnonymization(original.id)}>
              {translations('users_anonymize_button')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, triggerUserAnonymization, colors],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('users_header')}</PageHeading>
      <ErrorMessages errors={applicationUsersError} />
      <Table<ApplicationUser>
        isLoading={isFetching || isAnonymizingInProgress}
        data={data?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('users_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default ApplicationUsersPage;
