import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { createWarehouse, getWarehouse, getWarehouses, updateWarehouse } from 'api/warehouses';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { RestaurantRequest } from 'modules/Restaurants/models/restaurant-request.model';
import { RestaurantDetailsResponse, RestaurantResponse } from 'modules/Restaurants/models/restaurant-response.model';

import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';

export const CK_WAREHOUSES = 'warehouses';

export const useWarehouseList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<RestaurantResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_WAREHOUSES, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getWarehouses(params),
    {
      keepPreviousData: true,
    },
  );
};

export const useWarehouse = (id?: string): QueryObserverResult<RestaurantDetailsResponse, AxiosError<HttpException>> =>
  useQuery([CK_WAREHOUSES, id], () => getWarehouse(id || ''), { enabled: !!id });

export const useCreateWarehouse = (): UseMutationResult<
  RestaurantDetailsResponse,
  AxiosError<HttpException>,
  RestaurantRequest
> => {
  const queryClient = useQueryClient();
  return useMutation(createWarehouse, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_WAREHOUSES);
    },
  });
};

export const useUpdateWarehouse = (): UseMutationResult<
  RestaurantDetailsResponse,
  AxiosError<HttpException>,
  { id: string; updateWarehouseRequest: RestaurantRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, updateWarehouseRequest }: { id: string; updateWarehouseRequest: RestaurantRequest }) =>
      updateWarehouse(id, updateWarehouseRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_WAREHOUSES);
      },
    },
  );
};
