import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ErrorMessages, FullPageLoader } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { useCreateUpdateRestaurantUser, useRestaurantUser } from '../../services/restaurants';
import UpdateRestaurantUserForm from './UpdateRestaurantUserForm';

const RestaurantUserPage = (): JSX.Element => {
  const { id: userId } = useParams<{ id: string }>();
  invariant(userId, 'id is not set within the route');
  const translations = useTranslations();
  const navigate = useNavigate();
  const {
    mutate: createUpdateRestaurantUser,
    isLoading,
    error: modifyRestaurantUserError,
  } = useCreateUpdateRestaurantUser();
  const { data: restaurantUser, isFetching, error: loadError } = useRestaurantUser(userId);

  if (isFetching || isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{translations('restaurant_user_update_restaurant_user')}</ChildPageHeading>
      <ErrorMessages errors={[loadError, modifyRestaurantUserError]} />
      <UpdateRestaurantUserForm
        isLoading={isLoading}
        onSubmit={(values) =>
          createUpdateRestaurantUser(
            { values },
            {
              onSuccess: () => navigate(Routes.RESTAURANT_USERS_PATH),
            },
          )
        }
        data={restaurantUser}
      />
    </>
  );
};

export default RestaurantUserPage;
