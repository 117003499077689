import React, { JSX, useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { readString } from 'react-papaparse';
import { generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button, Center, Flex, Text, useTheme } from '@chakra-ui/react';
import {
  AddEditItemLink,
  ErrorMessages,
  FileInput,
  FormGroup,
  LinkButton,
  MainPageContent,
  Modal,
  ModalType,
  PageHeading,
  Table,
  useToast,
} from 'common/components';
import { config } from 'config/config';
import { useTranslations } from 'contexts/LocalizationContext';
import { Role, useSessionContext } from 'contexts/SessionContext';
import { useCreateRestaurantUsers, useDeleteRestaurantUser, useRestaurantUsersList } from 'services/restaurants';

import { CSV_DELIMITERS } from '../../common/constants/csvConvert.consts';
import { useDebounce } from '../../common/hooks/useDebounce';
import { readBlob } from '../../common/utils/readBlob.helper';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { Routes } from '../../config/routes';
import { useModal } from '../../contexts/ModalContext';
import { ModalVariant } from '../../contexts/ModalContext/hook';
import { KeyAccountGroupsResponse } from '../KeyAccountGroups/models/key-account-groups-response';
import { PartnerRole } from '../RelevoUsers/types';
import { RestaurantResponse } from '../Restaurants/models';
import { CreateRestaurantUsersResponse } from './models/create-restaurant-users-response.model';
import { credentialsCsvAsTextToJSON, getSummaryOfAssignedUsers } from './utils/createUsers.helper';
import { RestaurantUserResponse } from './models';

const RestaurantUsers = (): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const { role } = useSessionContext();
  const { displayToast } = useToast();
  const { modalState, handleToggleModal } = useModal();
  const [createUsersResponse, setCreateUsersResponse] = useState<CreateRestaurantUsersResponse>({
    assignedRestaurantUsers: [],
    notAssignedRestaurantUsers: [],
  });

  const {
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({});

  const csvFile = watch('csvFile');

  const {
    data: paginatedRestaurantUsers,
    isFetching: isFetchingRestaurantUsers,
    error: loadCredentialsListError,
  } = useRestaurantUsersList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const handleSummaryModalOpen = handleToggleModal({ modalType: ModalVariant.DEFAULT });

  const {
    mutate: createRestaurantUsers,
    isLoading: isLoadingCreateRestaurantUsers,
    error: createRestaurantUsersError,
  } = useCreateRestaurantUsers((data) => {
    setCreateUsersResponse(data);
    if (!data.notAssignedRestaurantUsers.length) {
      displayToast('success', translations('restaurant_user_import_csv_success'));
    } else {
      handleSummaryModalOpen();
    }
  });

  const {
    mutate: deleteRestaurantCredential,
    isLoading: isLoadingDeleteRestaurantUser,
    error: deleteError,
  } = useDeleteRestaurantUser();

  const onDeleteClicked = React.useCallback(
    (id: string, email: string) => {
      const confirmed = window.confirm(
        translations('restaurant_credentials_delete_confirmation', { '{{user-email}}': email }),
      );
      if (confirmed) {
        deleteRestaurantCredential({ id });
      }
    },
    [translations, deleteRestaurantCredential],
  );

  const scrollBarStyles = {
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.orange['500'],
      borderRadius: '24px',
    },
    scrollbarColor: `${colors.orange['500']} white`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    scrollbarWidth: 'thin' as any,
  };

  useEffect(() => {
    const readCsv = async () => {
      if (csvFile?.length) {
        const csvFileAsText = (await readBlob(csvFile![0])) || '';
        readString(csvFileAsText, {
          worker: true,
          skipEmptyLines: true,
          delimitersToGuess: CSV_DELIMITERS,
          complete: (results) => {
            createRestaurantUsers({ restaurantUsers: credentialsCsvAsTextToJSON(results.data as string[]) });
          },
        });
      }
    };
    readCsv();
    reset(csvFile);
  }, [csvFile, reset, createRestaurantUsers]);

  const columns = React.useMemo(
    () => [
      {
        id: '1',
        Header: translations('restaurant_credentials_email'),
        accessor: 'email' as const,
      },
      {
        id: '2',
        Header: translations('restaurant_credentials_first_name'),
        accessor: 'firstName' as const,
      },
      {
        id: '3',
        Header: translations('restaurant_credentials_last_name'),
        accessor: 'lastName' as const,
      },
      {
        id: '4',
        Header: translations('restaurant_credentials_restaurants'),
        accessor: 'locations' as const,
        Cell: ({ cell: { value } }: CellProps<RestaurantUserResponse>) =>
          // eslint-disable-next-line react/prop-types
          value.restaurantIds.map((r: RestaurantResponse) => r.restaurantName).join(', ') || null,
      },
      {
        id: '5',
        Header: translations('restaurant_credentials_key_account_groups'),
        accessor: 'locations' as const,
        Cell: ({ cell: { value } }: CellProps<RestaurantUserResponse>) =>
          // eslint-disable-next-line react/prop-types
          value.keyAccountsGroupIds.map((k: KeyAccountGroupsResponse) => k.keyAccount).join(', ') || null,
      },
      {
        id: '6',
        Header: translations('restaurant_credentials_role'),
        accessor: 'role' as const,
      },
      {
        id: 'actions',
        Header: translations('restaurant_credentials_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantUserResponse>) => (
          <Flex>
            <AddEditItemLink
              to={generatePath(Routes.UPDATE_RESTAURANT_USERS_PATH, {
                id: original.id,
              })}
              m={1}
              isEdit
            />
            {original.role === PartnerRole.MANAGER ? (
              <LinkButton
                color="orange.500"
                m={1}
                to={generatePath(Routes.RESTAURANT_USERS_NOTIFICATION_SETTINGS_PATH, {
                  id: original.id,
                })}
              >
                {translations('restaurant_credentials_notification_settings_button')}
              </LinkButton>
            ) : null}
            {role !== Role.LIMITED_USER && (
              <Button m={1} onClick={() => onDeleteClicked(original.id, original.email)}>
                {translations('delete')}
              </Button>
            )}
          </Flex>
        ),
      },
    ],
    [translations, onDeleteClicked, role],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('restaurant_credentials_header')}</PageHeading>
      <ErrorMessages errors={[loadCredentialsListError, deleteError, createRestaurantUsersError]} />
      <form>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="space-between">
            <FormGroup
              label={translations('restaurant_user_import_csv')}
              labelTooltipText={translations('restaurant_user_import_csv_tooltip')}
              inputId="csvFile"
              validationError={(errors.csvFile as FieldError)?.message}
            >
              <FileInput accept="text/csv" register={register('csvFile')} aria-label="csvFile" />
            </FormGroup>
          </Flex>
          <AddEditItemLink to={Routes.CREATE_RESTAURANT_USER_PATH}>
            {translations('restaurant_credentials_add')}
          </AddEditItemLink>
        </Flex>
      </form>
      <Table<RestaurantUserResponse>
        isLoading={isFetchingRestaurantUsers || isLoadingDeleteRestaurantUser || isLoadingCreateRestaurantUsers}
        data={paginatedRestaurantUsers?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedRestaurantUsers?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('restaurant_credentials_searchbox')}
        searchEnabled
        paginationEnabled
      />
      <Modal
        type={ModalType.INFO}
        isModalVisible={modalState?.modalType === ModalVariant.DEFAULT}
        onModalClose={handleToggleModal(null)}
        modalHeader={translations('restaurant_user_import_csv_summary')}
        customModalFooter={
          <Flex justifyContent="flex-end">
            <Button m={2} onClick={handleToggleModal(null)}>
              {translations('ok')}
            </Button>
          </Flex>
        }
      >
        <Flex direction="column">
          <Flex direction="row">
            <Text fontWeight="bold" color={colors.green[500]} mr={2}>
              {translations('restaurant_user_import_csv_summary_assigned_users')}
            </Text>
            <Text>{getSummaryOfAssignedUsers(createUsersResponse)}</Text>
          </Flex>
          <Text fontWeight="bold" color={colors.red[500]} my={2}>
            {translations('restaurant_user_import_csv_summary_not_assigned_users')}
          </Text>
          <Flex direction="column" overflowY="scroll" css={scrollBarStyles} maxH="200px">
            {createUsersResponse.notAssignedRestaurantUsers.map((email) => (
              <Center>
                <Text>{email}</Text>
              </Center>
            ))}
          </Flex>
        </Flex>
      </Modal>
    </MainPageContent>
  );
};

export default RestaurantUsers;
