import { mapValues } from 'lodash';
import * as yup from 'yup';
import { object } from 'yup';

import { REQUIRED_TRANSLATIONS } from '../../common/models/enums/language';
import { useTranslations } from '../../contexts/LocalizationContext';
import { CategoryGroup } from './model/Category.model';
import { MAX_EXTENSION_FEE, MAX_NOT_RETURN_FEE } from './category.consts';

export type CategoryGroupSelect = {
  label: CategoryGroup | string;
  value: CategoryGroup | string;
};

export const selectOptions: CategoryGroupSelect[] = [
  { value: '', label: 'No category' },
  { value: CategoryGroup.BOWLS, label: CategoryGroup.BOWLS },
  { value: CategoryGroup.CUPS, label: CategoryGroup.CUPS },
];

const mapRules = (map: any, rule: any) => {
  return Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});
};

export const useCategoriesFormValidationSchema = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    translations: yup.lazy((translatedValues) =>
      object(
        mapValues(translatedValues, (value, key) => {
          if (REQUIRED_TRANSLATIONS.includes(key)) {
            return yup
              .string()
              .required(translations('validation_required'))
              .max(191, translations('validation_too_long'));
          } else {
            return yup.string().max(191, translations('validation_too_long'));
          }
        }),
      ),
    ),
    categoryGroup: yup.string().nullable(),
    notReturnFee: yup.lazy((map) =>
      object(
        mapRules(
          map,
          yup
            .number()
            .typeError(translations('validation_number_type_error'))
            .nullable()
            .max(
              MAX_NOT_RETURN_FEE,
              translations('validation_max_value', { '{{max_value}}': MAX_NOT_RETURN_FEE.toString() }),
            ),
        ),
      ),
    ),
    extensionFee: yup.lazy((map) =>
      object(
        mapRules(
          map,
          yup
            .number()
            .typeError(translations('validation_number_type_error'))
            .nullable()
            .max(
              MAX_EXTENSION_FEE,
              translations('validation_max_value', { '{{max_value}}': MAX_EXTENSION_FEE.toString() }),
            ),
        ),
      ),
    ),
    borrowingPeriod: yup.number().typeError(translations('validation_number_type_error')).nullable(),
    extensionPeriod: yup.number().typeError(translations('validation_number_type_error')).nullable(),
  });
};
