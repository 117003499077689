import Select from 'react-select';
import { Box, Flex } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { RestaurantDraftStatus } from './models/restaurant-draft-status.enum';
import { RestaurantDraftsFilters } from './models/restaurant-drafts-filters.model';

type RestaurantDraftsTableFiltersProps = {
  filters: RestaurantDraftsFilters;
  onChange: (value: RestaurantDraftsFilters) => void;
};

const STATUS_OPTIONS = [...Object.values(RestaurantDraftStatus), 'ALL' as const].map((status) => ({
  status,
}));

export const RestaurantDraftsTableFilters = ({ filters, onChange }: RestaurantDraftsTableFiltersProps): JSX.Element => {
  const translations = useTranslations();

  return (
    <Flex>
      <Box width="300px">
        <Select<{ status: RestaurantDraftsFilters['status'] }>
          name="status"
          placeholder={translations('restaurant_drafts_table_filters_status_placeholder')}
          getOptionLabel={({ status }) => translations(`restaurant_drafts_table_status_${status.toLowerCase()}`)}
          getOptionValue={({ status }) => status}
          onChange={(value) => {
            if (value) {
              onChange({ ...filters, status: value.status });
            }
          }}
          options={STATUS_OPTIONS}
          defaultValue={{ status: filters.status }}
        />
      </Box>
    </Flex>
  );
};
