import { RestaurantUserRequest } from '../models/create-restaurant-users-request.model';
import { CreateRestaurantUsersResponse } from '../models/create-restaurant-users-response.model';

export const getSummaryOfAssignedUsers = (createUsersResponse: CreateRestaurantUsersResponse): string => {
  return `
    ${createUsersResponse.assignedRestaurantUsers.length.toString()}/${
      createUsersResponse.assignedRestaurantUsers.length + createUsersResponse.notAssignedRestaurantUsers.length
    }`;
};

export const credentialsCsvAsTextToJSON = (lines: string[]): RestaurantUserRequest[] => {
  const restaurantUsersRequest: RestaurantUserRequest[] = [];
  const fieldNames = lines[0];
  for (let i = 1; i < lines.length; i++) {
    const fieldValues = lines[i];
    if (lines[i].length === 1 && lines[i][0] === '') {
      continue;
    }
    const restaurantUser = {} as RestaurantUserRequest;
    for (let j = 0; j < fieldValues.length; j++) {
      if (fieldNames[j]?.toLowerCase().includes('id')) {
        restaurantUser.restaurantId = fieldValues[j].trim();
      } else if (fieldNames[j]?.toLowerCase().includes('email')) {
        restaurantUser.email = fieldValues[j].trim();
      }
    }
    restaurantUsersRequest.push(restaurantUser);
  }
  return restaurantUsersRequest;
};
