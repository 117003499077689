import { OrderType } from '../../../../common/components/Table/TableHeaderWithOrder/order-type.enum';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../../../common/constants/common';
import { Paginated } from '../../../../common/models';
import { useCity } from '../../../../services/cities';
import { useClosingSource } from '../../../../services/closing-sources';
import { useKeyAccountGroup } from '../../../../services/key-account-groups';
import { useKeyAccountGroupRestaurantList, useRestaurantsByIds } from '../../../../services/restaurants';
import { City } from '../../../City/models/City';
import { ClosingSource } from '../../../ClosingSources/model/ClosingSource';
import { KeyAccountGroupRestaurantsOrderField } from '../../../KeyAccountGroups/models/enums/key-account-group-restaurants-order-field.enum';
import { KeyAccountGroupsResponse } from '../../../KeyAccountGroups/models/key-account-groups-response';
import { RestaurantResponse } from '../../models';
import { UpdateRestaurantsRequest } from '../model/update-restaurants.request.model';

export const useUpdateMultipleRestaurantsCharacteristics = (
  updateRestaurantsRequest: UpdateRestaurantsRequest,
  restaurantIds: string[],
  keyAccountId?: string,
): {
  isLoadingCity: boolean;
  isLoadingKeyAccount: boolean;
  isLoadingClosingSource: boolean;
  isLoadingRestaurants: boolean;
  city?: City;
  keyAccount?: KeyAccountGroupsResponse;
  closingSource?: ClosingSource;
  restaurants?: Paginated<RestaurantResponse>;
} => {
  const { data: city, isLoading: isLoadingCity } = useCity(
    updateRestaurantsRequest.cityId || '',
    !!updateRestaurantsRequest.cityId,
  );
  const { data: keyAccount, isLoading: isLoadingKeyAccount } = useKeyAccountGroup(
    updateRestaurantsRequest.keyAccountId || '',
    !!updateRestaurantsRequest.keyAccountId,
  );
  const { data: closingSource, isLoading: isLoadingClosingSource } = useClosingSource(
    updateRestaurantsRequest.closingSourceId || '',
    !!updateRestaurantsRequest.closingSourceId,
  );
  const { data: restaurants, isLoading: isLoadingRestaurants } = useRestaurantsByIds({
    restaurantIds,
    ...ALL_PAGINATED_LIST_ITEMS_PARAMS,
  });
  const { data: keyAccountRestaurants, isLoading: isLoadingKeyAccountRestaurants } = useKeyAccountGroupRestaurantList({
    keyAccountId: keyAccountId!,
    ...ALL_PAGINATED_LIST_ITEMS_PARAMS,
    orderField: KeyAccountGroupRestaurantsOrderField.ID,
    orderType: OrderType.ASC,
    isActive: !!keyAccountId && !restaurantIds.length,
  });

  return {
    city,
    isLoadingCity,
    keyAccount,
    isLoadingKeyAccount,
    closingSource,
    isLoadingClosingSource,
    restaurants: restaurantIds.length ? restaurants : keyAccountRestaurants,
    isLoadingRestaurants: restaurantIds.length ? isLoadingRestaurants : isLoadingKeyAccountRestaurants,
  };
};
