import { useMemo } from 'react';

import { ReuseCardUserResponse } from '../../models/reuse-card-user-response';

export const useUpdateReuseCard = (model?: ReuseCardUserResponse) => {
  const formDefaultValues = useMemo(() => {
    return {
      isActive: model?.isActive,
      monetaryValue: model?.value?.monetaryValue,
      currency: model?.value?.currency,
      borrowingsLimit: model?.borrowingsLimit,
      activationRestaurants: model?.activationLocations?.restaurants,
      activationFnbGroups: model?.activationLocations?.fnbGroups,
    };
  }, [model]);

  return {
    formDefaultValues,
  };
};
