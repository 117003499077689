import React from 'react';
import { CellProps } from 'react-table';
import { Box } from '@chakra-ui/react';

import { BooleanValueIcon, Table } from '../../../../../common/components';
import { formatDateTime } from '../../../../../common/utils';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { RestaurantDepositSettingsHistory } from '../../models/restaurant-deposit-settings-response';

type DepositSettingsHistoryProps = {
  history: RestaurantDepositSettingsHistory[];
};

export const DepositSettingsHistory = ({ history }: DepositSettingsHistoryProps) => {
  const translations = useTranslations();
  const columns = [
    {
      id: '1',
      Header: translations('deposit_settings_history_table_enabled'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<RestaurantDepositSettingsHistory>) => <BooleanValueIcon value={original.enabled} />,
    },
    {
      id: '2',
      Header: translations('deposit_settings_history_table_bowls'),
      accessor: 'bowls' as const,
    },
    {
      id: '3',
      Header: translations('deposit_settings_history_table_cups'),
      accessor: 'cups' as const,
    },
    {
      id: '4',
      Header: translations('deposit_settings_history_table_currency'),
      accessor: 'currency' as const,
    },
    {
      id: '5',
      Header: translations('deposit_settings_history_table_updated_at'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<RestaurantDepositSettingsHistory>) => formatDateTime(new Date(original.updatedAt)),
    },
  ];
  return (
    <Box pb={4}>
      <Table<RestaurantDepositSettingsHistory> data={history} columns={columns} isLoading={false} />
    </Box>
  );
};
