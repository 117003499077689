import * as yup from 'yup';
import { object } from 'yup';

import { AccountPriority } from '../../../../common/models/enums/accountPriority';
import { isValidStringUrl } from '../../../../common/utils/url.helper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import {
  MAX_CATEGORY_GROUP_PRICE_VALUE,
  MAX_COMMON_LENGTH,
  MAX_RESTAURANT_ADDRESS_LENGTH,
  MAX_RESTAURANT_ANDROID_LINK_LENGTH,
  MAX_RESTAURANT_DETAIL_LENGTH,
  MAX_RESTAURANT_NAME_LENGTH,
  MAX_RESTAURANT_PHONE_LENGTH,
  MAX_RESTAURANT_STREET_NAME_LENGTH,
  MAX_RESTAURANT_WHOLESALE_CUSTOMER_ID_LENGTH,
  MIN_CATEGORY_GROUP_PRICE_VALUE,
} from '../../restaurant.consts';
import { RestaurantStatus, StarterSetSize } from '../../RestaurantForm/RestaurantForm.form';

export const useRestaurantsUpdateFormValidationSchema = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  const selectOptionSchema = {
    id: yup.string(),
    translations: yup.object().shape({
      en: yup.string(),
      de: yup.string(),
      fr: yup.string().nullable(),
    }),
  };

  const mapRules = (map: any, rule: any) =>
    map && Object.keys(map)?.reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});

  const validateUrlProperty = (maxLength?: number) => {
    return yup
      .string()
      .nullable()
      .max(maxLength ?? MAX_COMMON_LENGTH, translations('validation_too_long'))
      .test('test url', translations('validation_incorrect_url_format'), (text) => {
        if (text?.trim().length === 0 || !text) {
          return true;
        }
        return text.trim().length > 0 && isValidStringUrl(text.trim());
      });
  };

  return yup.object().shape({
    restaurantName: yup.string().nullable().max(MAX_RESTAURANT_NAME_LENGTH, translations('validation_too_long')),
    restaurantDetail: yup.string().nullable().max(MAX_RESTAURANT_DETAIL_LENGTH, translations('validation_too_long')),
    restaurantAddress: yup.string().nullable().max(MAX_RESTAURANT_ADDRESS_LENGTH, translations('validation_too_long')),
    streetName: yup.string().nullable().max(MAX_RESTAURANT_STREET_NAME_LENGTH, translations('validation_too_long')),
    addressLinkAndroid: validateUrlProperty(MAX_RESTAURANT_ANDROID_LINK_LENGTH),
    phoneNumber: yup.string().nullable().max(MAX_RESTAURANT_PHONE_LENGTH, translations('validation_too_long')),
    websiteLink: validateUrlProperty(),
    status: yup.mixed().nullable().oneOf(Object.values(RestaurantStatus), translations('validation_incorrect_value')),
    starterSetStartDate: yup.date().nullable(),
    starterSetSize: yup
      .mixed()
      .nullable()
      .oneOf(Object.values(StarterSetSize), translations('validation_incorrect_value')),
    wholesaleCustomerId: yup
      .string()
      .nullable()
      .max(MAX_RESTAURANT_WHOLESALE_CUSTOMER_ID_LENGTH, translations('validation_too_long')),
    deliverers: yup.object().shape({
      uberEats: yup.object().shape({
        url: validateUrlProperty(),
      }),
      lieferando: yup.object().shape({
        url: validateUrlProperty(),
      }),
      wolt: yup.object().shape({
        url: validateUrlProperty(),
      }),
      customDelivery: yup.object().shape({
        url: validateUrlProperty(),
      }),
    }),
    categoryGroupPrice: yup.lazy((map) =>
      object(
        mapRules(
          map,
          yup
            .number()
            .typeError(translations('import_restaurants_category_group_price_error_message'))
            .max(MAX_CATEGORY_GROUP_PRICE_VALUE, translations('validation_too_big'))
            .min(MIN_CATEGORY_GROUP_PRICE_VALUE, translations('validation_too_small'))
            .nullable()
            .transform((value?: string | number, originalValue?: string | number) =>
              originalValue?.toString().trim() === '' ? undefined : value,
            ),
        ),
      ),
    ),
    accountPriority: yup
      .mixed()
      .nullable()
      .oneOf(Object.values(AccountPriority), translations('validation_incorrect_value')),
    city: yup.object().nullable().shape(selectOptionSchema),
    keyAccountId: yup.string().nullable(),
    closingSourceId: yup.string().nullable(),
    latitude: yup.string().nullable(),
    longitude: yup.string().nullable(),
  });
};
