import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ErrorMessages, FullPageLoader } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useTag, useUpdateTag } from 'services/tags';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { TagRequest } from './models/tag-request';
import TagForm from './TagForm';

const UpdateTag = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const translations = useTranslations();

  const { data: tag, isLoading: isLoadingTag, error: loadError } = useTag(id);
  const { mutate: updateTag, isLoading: isSavingTag, error } = useUpdateTag();

  const onSubmit = (updateTagRequest: TagRequest) =>
    updateTag(
      { id, updateTagRequest },
      {
        onSuccess: async () => navigate(Routes.TAGS_PATH),
      },
    );

  if (isLoadingTag) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{translations('tags_update_tag')}</ChildPageHeading>
      <ErrorMessages errors={[error, loadError]} />
      <TagForm isLoading={isSavingTag} onSubmit={onSubmit} model={tag} />
    </>
  );
};

export default UpdateTag;
