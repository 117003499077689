import React, { JSX, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import { FormGroup } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { useDebounce } from '../../common/hooks/useDebounce';
import { config } from '../../config/config';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { useKeyAccountGroupsList } from '../../services/key-account-groups';
import { useRestaurantList } from '../../services/restaurants';
import { KeyAccountGroupsResponse } from '../KeyAccountGroups/models/key-account-groups-response';
import { RestaurantResponse } from '../Restaurants/models';
import { RestaurantFilterMode } from '../Restaurants/models/enums/restaurant-filter-mode.enum';
import { INDIVIDUAL_KEY_ACCOUNT_ID } from '../Restaurants/restaurant.consts';

const LocationsForm = ({ control }: { control: Control<any> }): JSX.Element => {
  const translations = useTranslations();
  const [restaurantSearchText, setRestaurantSearchText] = useState('');
  const debouncedSearch = useDebounce(restaurantSearchText, DEBOUNCE_SEARCH_TIME);
  const [keyAccountGroupsSearchText, setKeyAccountGroupsSearchText] = useState('');

  const { data: restaurants, isFetching: isFetchingRestaurants } = useRestaurantList({
    isActive: true,
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: 1,
    filterMode: RestaurantFilterMode.DROP_DOWN_SELECT,
  });

  const { data: keyAccountGroups, isFetching: isFetchingKayAccounts } = useKeyAccountGroupsList({
    searchText: keyAccountGroupsSearchText,
    limit: config.paginationSize,
    offset: 1,
  });

  return (
    <>
      <FormGroup label={translations('restaurant_users_form_restaurants')} inputId="restaurants">
        <Controller
          control={control}
          name="locations.restaurantIds"
          render={({ field: { value, onChange, name } }) => (
            <Select
              inputId="restaurants"
              placeholder={translations('restaurant_users_form_restaurants_placeholder')}
              name={name}
              isMulti
              getOptionLabel={(option: RestaurantResponse) => option.restaurantName}
              getOptionValue={(option: RestaurantResponse) => option.id}
              value={value}
              onChange={onChange}
              onInputChange={setRestaurantSearchText}
              options={restaurants?.items}
              isLoading={isFetchingRestaurants}
              filterOption={null}
            />
          )}
        />
      </FormGroup>

      <FormGroup label={translations('restaurant_users_form_key_account_groups')} inputId="KeyAccountGroups">
        <Controller
          control={control}
          name="locations.keyAccountsGroupIds"
          render={({ field: { value, onChange, name } }) => (
            <Select
              inputId="KeyAccountGroups"
              placeholder={translations('restaurant_users_form_key_account_groups_placeholder')}
              name={name}
              isMulti
              getOptionLabel={(option: KeyAccountGroupsResponse) => option.keyAccount}
              getOptionValue={(option: KeyAccountGroupsResponse) => option.id}
              value={value}
              onChange={onChange}
              onInputChange={setKeyAccountGroupsSearchText}
              options={keyAccountGroups?.items.filter((k) => k.id !== INDIVIDUAL_KEY_ACCOUNT_ID)}
              isLoading={isFetchingKayAccounts}
            />
          )}
        />
      </FormGroup>
    </>
  );
};

export default LocationsForm;
