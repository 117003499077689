import { isEmpty } from '../../../../common/utils/helpers';
import { CategoryGroup } from '../../../Categories/model/Category.model';
import { City } from '../../../City/models/City';
import { ClosingSourceResponse, ClosingSourceStatus } from '../../../ClosingSources/model/ClosingSource';
import { KeyAccountGroupsResponse } from '../../../KeyAccountGroups/models/key-account-groups-response';
import { MetaCategory } from '../../../MetaCategories/model/MetaCategory';
import { Promotion } from '../../../Promotions/models/Promotion';
import { TagResponse } from '../../../Tags/models/tag-response';
import { RestaurantDetailsResponse } from '../../models';
import { Deliverers } from '../../models/restaurant-deliverers.model';
import { CategoryGroupPrice, defaultRestaurantStatus, StarterSetSize } from '../../RestaurantForm/RestaurantForm.form';
import { ImportRestaurantCsvRequest } from '../model/import-restaurant-csv.request.model';

export const camelize = (text: string): string => {
  const result = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
  return result.substring(0, 1).toLowerCase() + result.substring(1);
};

export const getRestaurantNonEmptyColumns = (restaurants: RestaurantDetailsResponse[]): string[] => {
  let fieldNames = Object.keys(restaurants[0]);
  const fieldNamesToRemove: string[] = [];
  for (const fieldName of fieldNames) {
    let isColumnEmpty = true;
    for (const restaurant of restaurants) {
      const fieldValue = restaurant[fieldName as keyof RestaurantDetailsResponse];
      if (!isEmpty(fieldValue)) {
        isColumnEmpty = false;
      }
    }
    if (isColumnEmpty) {
      fieldNamesToRemove.push(fieldName);
    }
  }
  fieldNames = fieldNames.filter((el) => !fieldNamesToRemove.includes(el));

  return fieldNames;
};

export const getCsvNonEmptyColumns = (lines: string[][]): string[] => {
  const fieldNames = lines[0].map((name) => camelize(name));
  let nonEmptyColumns = fieldNames;
  const headersToRemove: string[] = [];

  for (let i = 0; i < fieldNames.length; i++) {
    let isColumnEmpty = true;
    for (let j = 1; j < lines.length; j++) {
      const row = lines[j];
      const fieldValue = row[i]?.trim();
      if (fieldValue && fieldValue !== '') {
        isColumnEmpty = false;
      }
    }
    if (isColumnEmpty) {
      headersToRemove.push(fieldNames[i]);
    }
  }
  nonEmptyColumns = nonEmptyColumns.filter((el) => !headersToRemove.includes(el));

  return nonEmptyColumns;
};

export const getCamelCasedCsvHeaders = (headers: string[]): string[] => headers.map((name) => camelize(name));

export const restaurantsCsvAsTextToJSON = (lines: string[][]): ImportRestaurantCsvRequest[] => {
  const restaurants = [];
  const fieldNames = lines[0].map((name) => camelize(name));
  for (let i = 1; i < lines.length; i++) {
    const restaurant: any = {};
    const fieldValues = lines[i];
    if (lines[i].length === 1 && lines[i][0] === '') {
      continue;
    }
    for (let j = 0; j < fieldValues.length; j++) {
      if (fieldValues[j]?.length) {
        restaurant[fieldNames[j]?.trim()] = fieldValues[j]?.trim();
      }
    }
    restaurant.id = i - 1;
    restaurants.push(restaurant);
  }
  return restaurants;
};

export const buildRestaurantTableRecord = (
  restaurant: ImportRestaurantCsvRequest,
  cities?: City[],
  tags?: TagResponse[],
  promotions?: Promotion[],
  metaCategories?: MetaCategory[],
  keyAccounts?: KeyAccountGroupsResponse[],
  closingSources?: ClosingSourceResponse[],
): RestaurantDetailsResponse => {
  const { city, woltUrl, lieferandoUrl, uberEatsUrl, cupsPrice, bowlsPrice, ...data } = restaurant;

  const matchedCity = cities?.find(
    (item) =>
      city &&
      Object.values(item.translations)
        .map((el) => el.toLowerCase())
        .includes(city.toLowerCase()),
  );

  const matchedTags = restaurant.tags
    ?.replace(/\s*,\s*/g, ',')
    .split(',')
    .reduce<TagResponse[]>((accumulator, currentItem) => {
      const currentTag = tags?.filter((item) =>
        Object.values(item.translations)
          .map((el) => el.toLowerCase())
          .includes(currentItem.toLowerCase()),
      );

      return [
        ...accumulator,
        ...(currentTag?.length
          ? [...currentTag]
          : currentItem?.length
          ? [
              {
                id: '',
                translations: { en: currentItem, de: currentItem, fr: currentItem },
              },
            ]
          : []),
      ];
    }, []);

  const matchedPromotions = restaurant.promotions?.split(',').reduce<Promotion[]>((accumulator, currentItem) => {
    const currentPromotion = promotions?.filter((item) => {
      const translations = Object.values(item.translations).map((el) => el.toLowerCase());
      return translations.includes(currentItem.toLowerCase());
    });

    return [
      ...accumulator,
      ...(currentPromotion?.length
        ? [...currentPromotion]
        : currentItem.length
        ? [{ id: '', translations: { en: currentItem, de: currentItem, fr: currentItem } }]
        : []),
    ];
  }, []);

  const matchedMetaCategories = restaurant.metaCategories
    ?.replace(/\s*,\s*/g, ',')
    ?.split(',')
    .reduce<MetaCategory[]>((accumulator, currentItem) => {
      const currentMetaCategory = metaCategories?.filter((item) =>
        Object.values(item.translations)
          .map((el) => el.toLowerCase())
          .includes(currentItem.toLowerCase()),
      );

      return [
        ...accumulator,
        ...(currentMetaCategory?.length
          ? [...currentMetaCategory]
          : currentItem.length
          ? [
              {
                id: '',
                translations: { en: currentItem, de: currentItem, fr: currentItem },
                color: null,
                image: null,
                type: null,
              },
            ]
          : []),
      ];
    }, []);

  const matchedKeyAccount = keyAccounts?.find(
    (item) => item.keyAccount?.toLowerCase() === restaurant.keyAccount?.toLowerCase(),
  );

  const matchedClosingSources = closingSources?.find(
    (item) => item.name.toLowerCase() === restaurant.closingSource?.toLowerCase(),
  );

  return {
    ...data,
    restaurantName: restaurant.restaurantName || '',
    deliverers: {
      wolt: {
        url: restaurant.woltUrl || null,
      },
      lieferando: {
        url: restaurant.lieferandoUrl || null,
      },
      uberEats: {
        url: restaurant.uberEatsUrl || null,
      },
    },
    status: defaultRestaurantStatus,
    tags: matchedTags,
    promotions: matchedPromotions,
    metaCategories: matchedMetaCategories || [],
    starterSetSize: restaurant.starterSetSize
      ? StarterSetSize[restaurant.starterSetSize.toUpperCase() as keyof typeof StarterSetSize] ||
        restaurant.starterSetSize
      : undefined,
    categoryGroupPrice: {
      [CategoryGroup.CUPS]: restaurant.cupsPrice?.length ? restaurant.cupsPrice : undefined,
      [CategoryGroup.BOWLS]: restaurant.bowlsPrice?.length ? restaurant.bowlsPrice : undefined,
    },
    city: matchedCity
      ? matchedCity
      : {
          id: '',
          translations: { en: restaurant.city ?? '', de: restaurant.city ?? '', fr: restaurant.city ?? '' },
          country: { id: '', countryCode: '', translations: { en: '', de: '', fr: '' } },
        },
    closingSource: matchedClosingSources || {
      id: '',
      name: restaurant.closingSource ?? '',
      status: ClosingSourceStatus.ACTIVE,
      googleDriveLink: '',
    },
    // FIXME: causes errors if in the csv file is keyAccountId column
    keyAccountId: matchedKeyAccount ? matchedKeyAccount?.id : restaurant?.keyAccount,
  };
};

export const getCategoryGroupPriceTableValue = (value?: CategoryGroupPrice): string | undefined =>
  value
    ? JSON.stringify(value).replaceAll('}', '').replaceAll('{', '').replaceAll('"', '').replaceAll(',', ', ')
    : undefined;

export const getDeliverersTableValue = (value?: Deliverers): string =>
  [value?.wolt?.url || '', value?.lieferando?.url || '', value?.uberEats?.url || '']
    .filter((element) => element !== '')
    .join(', ');

export const getCityById = (city: City, cities?: City[]): City | undefined =>
  cities?.find((item) => item.id === city?.id);

export const getTagByTranslation = (tag: TagResponse, tags?: TagResponse[]): TagResponse | undefined =>
  tags?.find((item) => item.translations.en === tag.translations.en || item.translations.de === tag.translations.de);

export const getTagById = (tag: TagResponse, tags?: TagResponse[]): TagResponse | undefined =>
  tags?.find((item) => item.id === tag.id);

export const getMetaCategoryByTranslation = (
  metaCategory: MetaCategory,
  metaCategories?: MetaCategory[],
): MetaCategory | undefined =>
  metaCategories?.find(
    (item) =>
      item.translations.en === metaCategory.translations.en || item.translations.de === metaCategory.translations.de,
  );

export const getMetaCategoryById = (
  metaCategory: MetaCategory,
  metaCategories?: MetaCategory[],
): MetaCategory | undefined => metaCategories?.find((item) => item.id === metaCategory.id);

export const getPromotionByTranslation = (promotion: Promotion, promotions?: Promotion[]): Promotion | undefined =>
  promotions?.find(
    (item) => item.translations.en === promotion.translations.en || item.translations.de === promotion.translations.de,
  );

export const getPromotionById = (promotion: Promotion, promotions?: Promotion[]): Promotion | undefined =>
  promotions?.find((item) => item.id === promotion.id);

export const getKeyAccountGroupById = (
  id: string,
  keyAccountGroups?: KeyAccountGroupsResponse[],
): KeyAccountGroupsResponse | undefined => keyAccountGroups?.find((item) => item.id === id);
