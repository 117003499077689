import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { Routes } from 'config/routes';
import { AssignedItems } from 'modules/AssignedItems/models/assigned-items';

import { extendAssignedItems, getAssignedItemsForUserList, removeAssignedItem } from '../api/assigned-items';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';
import { AssignedItemsExtendRequest } from '../modules/AssignedItems/models/assigned-items-extend-request';

export const CK_ASSIGNED_ITEMS_FOR_USERS = 'assignedItemsForUsers';

export const useAssignedItemsForUserList = (
  userId: string,
  isReturn: boolean,
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<AssignedItems>, AxiosError<HttpException>> => {
  return useQuery(
    [
      CK_ASSIGNED_ITEMS_FOR_USERS,
      { text: params.searchText, page: params.offset, limit: params.limit, userId, isReturn },
    ],
    () => getAssignedItemsForUserList(userId, isReturn, params),
    {
      keepPreviousData: true,
    },
  );
};

export const useExtendAssignedItems = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  { userId: string; assignedItemsId: string; assignedItemsExtendRequest: AssignedItemsExtendRequest }
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(
    ({
      userId,
      assignedItemsId,
      assignedItemsExtendRequest,
    }: {
      userId: string;
      assignedItemsId: string;
      assignedItemsExtendRequest: AssignedItemsExtendRequest;
    }) => extendAssignedItems(userId, assignedItemsId, assignedItemsExtendRequest),
    {
      onSuccess: async (data, variable) => {
        await queryClient.invalidateQueries(CK_ASSIGNED_ITEMS_FOR_USERS);
        navigate(generatePath(Routes.USERS_ASSIGNED_ITEMS_PATH, { userId: variable.userId }));
      },
    },
  );
};

export const useRemoveAssignedItems = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  { userId: string; assignedItemsId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, assignedItemsId }: { userId: string; assignedItemsId: string }) =>
      removeAssignedItem({ userId, assignedItemsId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_ASSIGNED_ITEMS_FOR_USERS);
      },
    },
  );
};
