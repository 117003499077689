import React, { JSX } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Flex, Switch } from '@chakra-ui/react';
import { FormGroup, Select } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../../common/components/FormInput/FormInput';
import WarningText from '../../../common/components/WarningText/WarningText';
import { ApplicationUserProfileSettingsResponse } from '../models/application-user-notification-settings.response.model';
import { NewsletterSubscription, NotificationBrand } from '../models/notification-config';

type NotificationSettingsSelect = {
  label: NotificationBrand;
  value: NotificationBrand;
};

type NewsletterSubscriptionSelect = {
  label: NewsletterSubscription;
  value: boolean;
};

export const returnNotificationSelectOptions: NotificationSettingsSelect[] = [
  { label: NotificationBrand.PUSH, value: NotificationBrand.PUSH },
  { label: NotificationBrand.EMAIL, value: NotificationBrand.EMAIL },
];

export const otherNotificationSelectOptions: NotificationSettingsSelect[] = [
  { label: NotificationBrand.PUSH, value: NotificationBrand.PUSH },
  { label: NotificationBrand.EMAIL, value: NotificationBrand.EMAIL },
  { label: NotificationBrand.NONE, value: NotificationBrand.NONE },
];

export const newsletterSubscriptionSelectOptions: NewsletterSubscriptionSelect[] = [
  {
    label: NewsletterSubscription.SUBSCRIBED,
    value: true,
  },
  { label: NewsletterSubscription.UNSUBSCRIBED, value: false },
];

type ProfileSettingsFormProps = {
  model?: ApplicationUserProfileSettingsResponse;
};

const ApplicationUserProfileSettingsForm = ({ model }: ProfileSettingsFormProps): JSX.Element => {
  const translations = useTranslations();
  const { control, watch, register, setValue } = useFormContext();

  const watchSkipPayments = watch('skipPayments');

  return (
    <>
      <FormInput
        id={'email'}
        label={translations('application_user_email')}
        useFormRegisterReturn={register('email')}
        setValue={setValue}
      />
      <FormGroup label={translations('return_notification_setting')} inputId="notificationSetting.return">
        <Controller
          control={control}
          name="notificationSetting.return"
          render={({ field: { value, onChange, name } }) => (
            <Select<NotificationSettingsSelect>
              name={name}
              value={returnNotificationSelectOptions.find((option) => option.value === value)}
              getOptionLabel={(option) => (option as NotificationSettingsSelect).label}
              getOptionValue={(option) => (option as NotificationSettingsSelect).value}
              onChange={onChange}
              valueMapper={(v) => v?.value}
              options={returnNotificationSelectOptions}
            />
          )}
        />
      </FormGroup>
      <FormGroup label={translations('other_notification_setting')} inputId="notificationSetting.other">
        <Controller
          control={control}
          name="notificationSetting.other"
          render={({ field: { value, onChange, name } }) => (
            <Select<NotificationSettingsSelect>
              name={name}
              value={otherNotificationSelectOptions.find((option) => option.value === value)}
              getOptionLabel={(option) => (option as NotificationSettingsSelect).label}
              getOptionValue={(option) => (option as NotificationSettingsSelect).value}
              onChange={onChange}
              valueMapper={(v) => v?.value}
              options={otherNotificationSelectOptions}
            />
          )}
        />
      </FormGroup>
      <FormGroup label={translations('newsletter_setting')} inputId="isNewsletterSubscribed">
        <Controller
          control={control}
          name="isNewsletterSubscribed"
          render={({ field: { value, onChange, name } }) => (
            <Select<NewsletterSubscriptionSelect>
              name={name}
              value={newsletterSubscriptionSelectOptions.find((option) => option.value === value)}
              getOptionLabel={(option) => (option as NewsletterSubscriptionSelect).label}
              getOptionValue={(option) => (option as NewsletterSubscriptionSelect).label}
              onChange={onChange}
              valueMapper={(v) => v?.value}
              options={newsletterSubscriptionSelectOptions}
            />
          )}
        />
      </FormGroup>
      <FormGroup label={translations('skip_payments_setting')} inputId="skipPayments">
        <Controller
          control={control}
          name="skipPayments"
          render={({ field: { value, onChange, ref } }) => (
            <>
              <Box>
                <Switch colorScheme="orange" id="skipPayments" isChecked={value} onChange={onChange} ref={ref} />
              </Box>
              <Flex h={6} mt={2}>
                {!model?.skipPayments && watchSkipPayments ? (
                  <WarningText text={translations('skip_payments_setting_warning')} />
                ) : null}
              </Flex>
            </>
          )}
        />
      </FormGroup>
    </>
  );
};

export default ApplicationUserProfileSettingsForm;
