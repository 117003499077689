import React, { JSX } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormControl, FormLabel, Image, Select } from '@chakra-ui/react';
import { FileInput, FormFooter, FormGroup } from 'common/components';
import { Language } from 'common/utils';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../common/components/FormInput/FormInput';
import {
  ImagePreviewValidation,
  isFileTooLarge,
} from '../../common/components/ImagePreviewValidation/ImagePreviewValidation';
import { AboutUsRequest } from './models/about-us-request';
import { AboutUsResponse } from './models/about-us-response';

type AboutUsFormProps = {
  model?: AboutUsResponse | undefined;
  isLoading: boolean;
  onSubmit(data: AboutUsRequest): void;
};

type FormModel = AboutUsResponse & { temporaryImage?: FileList };

const AboutUsForm = ({ isLoading, onSubmit, model }: AboutUsFormProps): JSX.Element => {
  const translations = useTranslations();
  const { register, handleSubmit, reset, getValues, watch, setValue, control } = useForm<FormModel>({
    defaultValues: { language: Language.En },
  });

  const imageNamePreview: string | undefined = watch('temporaryImage')?.[0]?.name;
  const imageSizePreview: number | undefined = watch('temporaryImage')?.[0]?.size;

  React.useEffect(() => {
    reset(model);
  }, [reset, model]);

  const onFormSubmit = handleSubmit(({ temporaryImage, ...form }: FormModel) => {
    onSubmit({
      ...form,
      image: temporaryImage?.[0],
    });
  });

  const handleDeleteUploadedImage = () => setValue('temporaryImage', undefined);

  return (
    <form onSubmit={onFormSubmit}>
      <FormInput
        id={'fullName'}
        label={translations('about_form_fullname')}
        useFormRegisterReturn={register('fullName')}
        setValue={setValue}
      />
      <FormInput
        id={'description'}
        label={translations('about_form_description')}
        useFormRegisterReturn={register('description')}
        setValue={setValue}
      />
      <FormGroup label={translations('about_form_language')} inputId="language">
        <Controller
          control={control}
          name="language"
          render={({ field: { value, onChange } }) => (
            <Select id="language" placeholder="" value={value} onChange={onChange}>
              {Object.values(Language).map((lang) => (
                <option value={lang} key={lang}>
                  {lang}
                </option>
              ))}
            </Select>
          )}
        />
      </FormGroup>
      <FormInput
        id={'detailsPageUrl'}
        label={translations('about_form_detailsPageUrl')}
        useFormRegisterReturn={register('detailsPageUrl')}
        setValue={setValue}
      />
      <FormControl>
        <FormLabel htmlFor="image">{translations('about_form_image')}</FormLabel>
        <Image id="image" src={getValues('image')} />
        <FileInput accept="image/*" register={register('temporaryImage')} aria-label="temporaryImage" />
        {imageNamePreview && imageSizePreview ? (
          <ImagePreviewValidation
            imageNamePreview={imageNamePreview}
            imageSizePreview={imageSizePreview}
            handleDeleteUploadedImage={handleDeleteUploadedImage}
          />
        ) : null}
      </FormControl>
      <FormFooter
        isLoadingSubmitResult={isLoading}
        isSubmitDisabled={imageSizePreview ? isFileTooLarge(imageSizePreview) : false}
      />
    </form>
  );
};

export default AboutUsForm;
