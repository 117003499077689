import { createContext, ReactNode, useReducer } from 'react';
import { MultiValue, SingleValue } from 'react-select';

import { SelectOption } from '../../../../../common/components/Select/models/select-option';
import { useSelectRows } from '../../../../../common/components/Table/Pagination/hooks/use-select-rows.hook';
import { OrderType } from '../../../../../common/components/Table/TableHeaderWithOrder/order-type.enum';
import { RestaurantResponse } from '../../../../Restaurants/models';
import { ReturnCodeOrderFields } from '../../../../Restaurants/RestaurantReturnCodes/models/enums/return-code-order-fields.enum';
import { ReturnCodeStatus } from '../../../../Restaurants/RestaurantReturnCodes/models/enums/return-code-status.enum';
import { RestaurantReturnCodeResponse } from '../../../../Restaurants/RestaurantReturnCodes/models/restaurant-return-code-response';
import { ReturnCodesFilter } from '../../models/return-codes-filter';
import { ReturnCodesFilterContextActionType } from './model/enums/return-codes-filter-context-action-type.enum';
import { ReturnCodesFilterContextInterface } from './model/return-codes-filter-context';
import { ReturnCodesFilterContextAction } from './model/return-codes-filter-context-action';

type ReturnCodesFilterContextProps = {
  keyAccountGroupRestaurants: RestaurantResponse[];
  children: ReactNode;
};

export const ReturnCodesFilterContext = createContext<ReturnCodesFilterContextInterface | null>(null);

const reducer = (state: ReturnCodesFilter, action: ReturnCodesFilterContextAction): ReturnCodesFilter => {
  switch (action.type) {
    case ReturnCodesFilterContextActionType.ORDER: {
      if (action.payload.field === state.orderField) {
        return {
          ...state,
          orderField: action.payload.field,
          orderType: state.orderType === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
        };
      } else {
        return {
          ...state,
          orderField: action.payload.field,
          orderType: OrderType.ASC,
        };
      }
    }
    case ReturnCodesFilterContextActionType.RESTAURANTS: {
      return {
        ...state,
        restaurantIds: action.payload.ids,
      };
    }
    case ReturnCodesFilterContextActionType.STATUS: {
      if (
        action.payload.status &&
        Object.values(ReturnCodeStatus).includes(action.payload.status as ReturnCodeStatus)
      ) {
        return {
          ...state,
          status: action.payload.status as ReturnCodeStatus,
        };
      } else {
        return {
          ...state,
          status: undefined,
        };
      }
    }
    case ReturnCodesFilterContextActionType.DATE: {
      if (action.payload.date) {
        return {
          ...state,
          date: action.payload.date,
        };
      } else {
        return {
          ...state,
          date: undefined,
        };
      }
    }
    default:
      throw new TypeError('Not supported action type');
  }
};

export const ReturnCodesFilterContextProvider = ({
  keyAccountGroupRestaurants,
  children,
}: ReturnCodesFilterContextProps) => {
  const [returnCodesFilterState, dispatch] = useReducer(reducer, {
    restaurantIds: keyAccountGroupRestaurants.map((restaurant) => restaurant.id),
    orderField: ReturnCodeOrderFields.RESTAURANT_ID,
    orderType: OrderType.ASC,
    date: undefined,
    status: undefined,
  });

  const handleOrderBy = (field: ReturnCodeOrderFields) => {
    dispatch({ type: ReturnCodesFilterContextActionType.ORDER, payload: { field } });
  };

  const handleSelectRestaurants = (selectedRestaurants: MultiValue<SelectOption>) => {
    const ids = selectedRestaurants.map((selectedRestaurant) => selectedRestaurant.value);
    dispatch({ type: ReturnCodesFilterContextActionType.RESTAURANTS, payload: { ids } });
  };

  const handleSelectStatus = (selectedReturnCodeStatus: SingleValue<SelectOption>) => {
    const status = selectedReturnCodeStatus?.value;
    dispatch({ type: ReturnCodesFilterContextActionType.STATUS, payload: { status } });
  };

  const handleSelectCreationDate = (date: Date | null) => {
    dispatch({
      type: ReturnCodesFilterContextActionType.DATE,
      payload: {
        date,
      },
    });
  };

  const {
    selectAllRows: selectAllReturnCodes,
    setSelectAllRows: setSelectAllReturnCodes,
    selectedRowsIds: selectedReturnCodesIds,
    handleClearSelection,
    getIsChecked,
    getIsSelectPageChecked,
    handleSelectCheckbox,
    handleSelectPageCheckbox,
    shouldShowSelectAllRows: shouldShowSelectAllReturnCodes,
    selectedRowsNumber: selectedReturnCodesNumber,
    setPaginatedItems,
    isEachRowSelected: isEachReturnCodeSelected,
    paginatedItems,
  } = useSelectRows<RestaurantReturnCodeResponse>();

  return (
    <ReturnCodesFilterContext.Provider
      value={{
        returnCodesFilterState,
        handleOrderBy,
        handleSelectRestaurants,
        handleSelectStatus,
        handleSelectCreationDate,
        selectAllReturnCodes,
        setSelectAllReturnCodes,
        selectedReturnCodesIds,
        handleClearSelection,
        getIsChecked,
        getIsSelectPageChecked,
        handleSelectCheckbox,
        handleSelectPageCheckbox,
        shouldShowSelectAllReturnCodes,
        setPaginatedItems,
        selectedReturnCodesNumber,
        isEachReturnCodeSelected,
        paginatedItems,
      }}
    >
      {children}
    </ReturnCodesFilterContext.Provider>
  );
};
