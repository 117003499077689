import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { FormGroup, Select } from '../../../../../../../common/components';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { ReturnCodeTypeSelect, returnCodeTypeSelectOptions } from '../../restaurant-return-code.form';

type ReturnCodeFormTypeProps = {
  isDisabled?: boolean;
};

export const FormReturnCodeType = ({ isDisabled }: ReturnCodeFormTypeProps) => {
  const translations = useTranslations();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormGroup
      label={translations('restaurants_return_codes_form_type')}
      inputId="type"
      validationError={(errors.type as FieldError)?.message}
    >
      <Controller
        control={control}
        name="type"
        render={({ field: { value, onChange, name } }) => (
          <Select<ReturnCodeTypeSelect>
            name={name}
            getOptionLabel={(option) => (option as ReturnCodeTypeSelect).label}
            getOptionValue={(option) => (option as ReturnCodeTypeSelect).value}
            value={returnCodeTypeSelectOptions.find((option) => option.value === value)}
            valueMapper={(v: ReturnCodeTypeSelect | null) => v?.value}
            onChange={onChange}
            options={returnCodeTypeSelectOptions}
            isDisabled={isDisabled}
          />
        )}
      />
    </FormGroup>
  );
};
