import { JSX } from 'react';

import { MainPageContent, PageHeading } from '../../../common/components';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { CustomersTable } from './CustomersTable';

const Customers = (): JSX.Element => {
  const translations = useTranslations();

  return (
    <MainPageContent>
      <PageHeading>{translations('customers_header')}</PageHeading>
      <CustomersTable />
    </MainPageContent>
  );
};

export default Customers;
