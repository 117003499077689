import Alert from './Alert';
import Button from './Button';
import Heading from './Heading';
import Icon from './Icon';
import Radio from './Radio';
import { switchTheme } from './Switch';
import Table from './Table';
import Tooltip from './Tooltip';

export default {
  Button,
  Heading,
  Table,
  Tooltip,
  Icon,
  Alert,
  Switch: switchTheme,
  Radio,
};
