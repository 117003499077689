import { JSX, useState } from 'react';
import Select from 'react-select';
import { CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Box, HStack, Text, Tooltip, useTheme } from '@chakra-ui/react';

import { selectStyle } from '../../../../../common/components/Select/select-styles';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useUpdateRestaurantReturnCode } from '../../../../../services/return-codes';
import { ReturnCodeStatus } from '../../models/enums/return-code-status.enum';
import {
  ReturnCodeStatusNames,
  returnCodeStatusSelectOptions,
} from '../RestaurantReturnCodeForm/restaurant-return-code.form';

interface ReturnCodeStatusUpdateProps {
  id: string;
  restaurantId: string;
  status: ReturnCodeStatus;
}

export const ReturnCodeStatusUpdate = ({ id, restaurantId, status }: ReturnCodeStatusUpdateProps): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const [isEditMode, setIsEditMode] = useState(false);

  const { mutate: updateRestaurantReturnCodeStatus } = useUpdateRestaurantReturnCode();
  const handleEditMode = () => setIsEditMode(!isEditMode);

  const handleUpdateStatus = (selectedStatus: any) => {
    updateRestaurantReturnCodeStatus({
      id,
      restaurantId,
      updateRestaurantReturnCodeRequest: {
        status: selectedStatus.value,
      },
    });
    handleEditMode();
  };

  const statusValueMapper = {
    [ReturnCodeStatus.ACTIVE]: ReturnCodeStatusNames.ACTIVE,
    [ReturnCodeStatus.PASSIVE]: ReturnCodeStatusNames.PASSIVE,
  };

  return (
    <>
      {isEditMode ? (
        <Box width="150px">
          <Box display="none">
            <label htmlFor="status">{translations('restaurant_return_codes_table_status')}</label>
          </Box>
          <HStack>
            <Select
              name="status"
              inputId="status"
              menuPortalTarget={document.body}
              styles={selectStyle}
              defaultValue={returnCodeStatusSelectOptions.find((option) => option.value === status)}
              getOptionLabel={(option) => option.label}
              onChange={handleUpdateStatus}
              options={returnCodeStatusSelectOptions}
            />
            <Tooltip label={translations('cancel')}>
              <CloseIcon cursor="pointer" color={colors.red[500]} onClick={handleEditMode} ml={2} w={3} />
            </Tooltip>
          </HStack>
        </Box>
      ) : (
        <HStack justifyContent="center">
          <Text>{status ? statusValueMapper[status] : null}</Text>
          <Tooltip label={translations('edit')}>
            <EditIcon data-testid="edit-icon" cursor="pointer" onClick={handleEditMode} ml={2} />
          </Tooltip>
        </HStack>
      )}
    </>
  );
};
