import { Language } from 'common/utils';

import { ApplicationUser } from '../ApplicationUsers/models/application-user';

export enum NotificationReceiversType {
  ALL_USERS = 'all_users',
  SELECTED_USERS = 'selected_users',
}

export enum EmailInputType {
  SELECT = 'select',
  TEXT = 'text',
}

export enum NotificationType {
  DEFAULT = 'DEFAULT',
  IN_APP = 'IN_APP',
}

export enum OtherNotificationIgnoreSettingType {
  ALL_USERS = 'ALL_USERS',
  ACTIVATED_USERS = 'ACTIVATED_USERS',
}

export type NotificationsForm = {
  notificationReceiversType: NotificationReceiversType;
  notificationType: NotificationType;
  title_en: string;
  title_de: string;
  title_fr: string;
  body_en: string;
  body_de: string;
  body_fr: string;
  emailInputType: EmailInputType;
  inputMailList: string;
  selectedUsersList: ApplicationUser[];
  link_en: string;
  link_de: string;
  link_fr: string;
  buttonText_en: string;
  buttonText_de: string;
  buttonText_fr: string;
  image: string;
  temporaryImage?: FileList;
  otherNotificationIgnoreSettingType: OtherNotificationIgnoreSettingType;
};

export type InternationalisedContent = {
  [key in Language]: string;
};

export type SendNotificationsRequest = {
  title: InternationalisedContent;
  body: InternationalisedContent;
  buttonText: InternationalisedContent;
  link?: InternationalisedContent;
  userEmails?: string[];
  type: NotificationType;
  image?: File;
};
