import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card, CardBody, CardFooter, Flex, Heading, Spinner, Text } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { Routes } from '../../../../config/routes';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import colors from '../../../../styles/colors';
import { RestaurantDraftStatus } from '../models/restaurant-draft-status.enum';
import { DRAFT_ATTRIBUTES } from './consts';
import { useDraft } from './useDraft';

export const RestaurantDraft = () => {
  const navigate = useNavigate();
  const translations = useTranslations();
  const { draftId, restaurantId } = useParams<{ restaurantId: string; draftId: string }>();
  invariant(draftId, 'id is not set within the route');
  invariant(restaurantId, 'id is not set within the route');
  const { acceptDraft, rejectDraft, draft, restaurant, isLoading, isEditable } = useDraft({
    draftId,
    restaurantId,
  });
  const isDraftActive = draft?.status === RestaurantDraftStatus.ACTIVE;

  if (isLoading) {
    return <Spinner />;
  }

  if (!draft || !restaurant) {
    if (!isLoading) {
      navigate(Routes.RESTAURANT_DRAFTS_PATH);
    }
    return <></>;
  }

  const handleAcceptDraft = () => {
    acceptDraft({ draftId, restaurantId });
  };

  const handleRejectDraft = () => {
    rejectDraft({ draftId, restaurantId });
  };

  const handleBackToDrafts = () => {
    navigate(Routes.RESTAURANT_DRAFTS_PATH);
  };

  return (
    <Flex
      width="100%"
      maxHeight="calc(100vh - 2 * var(--chakra-space-4))"
      overflow="hidden"
      direction="column"
      alignItems="center"
    >
      <Heading as="h1" size="lg">
        {restaurant.restaurantName}
      </Heading>
      <Card width="100%" flexGrow="1" overflow="hidden" display="flex" flexDirection="column" mt="4">
        <CardBody flexGrow="1" overflow="scroll">
          {DRAFT_ATTRIBUTES.map(({ render, isChanged, labelTranslationKey, key }, index) => {
            const attributeChanged = isChanged({ restaurant, draft });
            return (
              <Box
                opacity={isDraftActive && !attributeChanged ? 0.2 : 1}
                width="100%"
                textAlign="center"
                key={key}
                mt={index === 0 ? 0 : 8}
              >
                <Heading as="h3" size="sm">
                  {translations(labelTranslationKey)}
                </Heading>
                {render({ restaurant, draft, isChanged: isChanged({ restaurant, draft }) })}
              </Box>
            );
          })}
        </CardBody>
        <CardFooter>
          <Box width="100%">
            <Flex width="100%" justifyContent="center" mt={4}>
              <Button isDisabled={!isEditable} onClick={handleRejectDraft} size="lg" mr={4}>
                {translations('restaurant_drafts_reject_draft')}
              </Button>
              <Button isDisabled={!isEditable} onClick={handleAcceptDraft} size="lg" colorScheme="orange">
                {translations('restaurant_drafts_accept_draft')}
              </Button>
            </Flex>
            {!isEditable && (
              <Text textAlign="center" mt={4} fontStyle="italic" color={colors.grey[100]}>
                {translations('restaurant_drafts_not_editable')}
              </Text>
            )}
          </Box>
        </CardFooter>
      </Card>
      <Box textAlign="center" mt={2}>
        <Text color="blue.500" cursor="pointer" onClick={handleBackToDrafts}>
          {translations('restaurant_drafts_back_to_drafts')}
        </Text>
      </Box>
    </Flex>
  );
};
