import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

type FormFooterProps =
  | {
      hasCustomContent: true;
      children: React.ReactNode | React.ReactNode[];
    }
  | {
      hasCustomContent?: false;
      isLoadingSubmitResult?: boolean;
      isSubmitDisabled?: boolean;
    };

export const FormFooter = (props: FormFooterProps): JSX.Element => {
  const translations = useTranslations();
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Flex justifyContent="flex-start" mt={4}>
      {props?.hasCustomContent ? (
        props.children
      ) : (
        <>
          <Button mr={4} isLoading={props.isLoadingSubmitResult} type="submit" isDisabled={!!props.isSubmitDisabled}>
            {translations('save')}
          </Button>
          <Button onClick={handleCancel}>{translations('cancel')}</Button>
        </>
      )}
    </Flex>
  );
};
