import { useTranslations } from 'contexts/LocalizationContext';
import * as yup from 'yup';

import { ProductResponse } from '../../Products/Product.model';
import { RestaurantResponse } from '../../Restaurants/models';

export type ReassignBetweenRestaurantsForm = {
  sourceRestaurant: RestaurantResponse;
  shouldTransferAllProducts: boolean;
  selectedRestaurantProducts: ProductResponse[];
  destinationRestaurant: RestaurantResponse;
};

export const useReassignBetweenRestaurantsFormSchema = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    sourceRestaurant: yup.mixed().required(translations('validation_required')),
    shouldTransferAllProducts: yup.boolean(),
    selectedRestaurantProducts: yup
      .array()
      .nullable()
      .of(yup.object())
      .when('shouldTransferAllProducts', {
        is: (value?: boolean) => !value,
        then: (schema) =>
          schema.test('array-length', translations('validation_required'), (value) =>
            Boolean(value && value?.length >= 1),
          ),
      }),
    destinationRestaurant: yup
      .mixed()
      .required(translations('validation_required'))
      .when('sourceRestaurant', ([sourceRestaurant], schema) =>
        schema.test({
          name: 'desitnation-restaurant',
          test: (destinationRestaurant) => (destinationRestaurant as RestaurantResponse)?.id !== sourceRestaurant?.id,
          message: translations('products_assignment_the_same_restaurants_validation'),
        }),
      ),
  });
};
