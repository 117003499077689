import apiPaths from '../config/apiPaths';
import { ReportsStatisticsResponse } from '../modules/Reports/model/ReportsStatisticsResponse';
import axiosInstance from './axios';

export const getReportsStatistics = (
  restaurantIds: string[],
  keyAccountGroupIds: string[],
  categoryIds: string[],
  startDate: string,
  endDate: string,
): Promise<ReportsStatisticsResponse[]> => {
  const urlSearchParams = new URLSearchParams();
  restaurantIds.forEach((restaurantId) => urlSearchParams.append('restaurantIds[]', restaurantId));
  keyAccountGroupIds.forEach((keyAccountGroupId) => urlSearchParams.append('keyAccountGroupIds[]', keyAccountGroupId));
  categoryIds.forEach((categoryId) => urlSearchParams.append('categoryIds[]', categoryId));
  urlSearchParams.append('startDate', startDate);
  urlSearchParams.append('endDate', endDate);

  return axiosInstance
    .get<ReportsStatisticsResponse[]>(apiPaths.REPORTS_PATH, { params: urlSearchParams })
    .then(({ data }) => data);
};
