import React, { JSX, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { Flex } from '@chakra-ui/react';
import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { config } from 'config/config';
import { Routes } from 'config/routes';

import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useMetaCategoryList } from '../../services/meta-categories';
import { MetaCategory } from './model/MetaCategory';

const MetaCategories = (): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const [page, setPage] = useState(1);
  const {
    data,
    isFetching,
    error: loadCategoriesError,
  } = useMetaCategoryList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const columns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('meta_categories_table_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('meta_categories_table_name'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<MetaCategory>) => original.translations[language],
      },
      {
        id: '3',
        Header: translations('meta_categories_table_type'),
        accessor: 'type' as const,
      },
      {
        id: 'actions',
        Header: translations('meta_categories_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<MetaCategory>) => (
          <Flex>
            <AddEditItemLink to={`${Routes.META_CATEGORIES_PATH}/${original.id}`} m={1} isEdit />
          </Flex>
        ),
      },
    ],
    [translations, language],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('meta_categories_header')}</PageHeading>
      <ErrorMessages errors={[loadCategoriesError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_META_CATEGORY_PATH}>{translations('meta_categories_add')}</AddEditItemLink>
      </Flex>
      <Table<MetaCategory>
        data={data?.items}
        columns={columns}
        isLoading={isFetching}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('meta_categories_searchbox_placeholder')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default MetaCategories;
