import React from 'react';
import { generatePath } from 'react-router-dom';

import { AddEditItemLink } from '../../../../../common/components';
import { Routes } from '../../../../../config/routes';
import { useTranslations } from '../../../../../contexts/LocalizationContext';

type AddRestaurantProps = {
  keyAccountGroupId: string;
};

export const AddRestaurant = ({ keyAccountGroupId }: AddRestaurantProps) => {
  const translations = useTranslations();

  return (
    <AddEditItemLink
      mt={2}
      to={generatePath(Routes.ADD_RESTAURANT_KEY_ACCOUNT_GROUP_PATH, { keyAccountId: keyAccountGroupId })}
    >
      {translations('restaurants_add')}
    </AddEditItemLink>
  );
};
