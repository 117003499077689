import React, { JSX } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button, Flex, useTheme } from '@chakra-ui/react';
import { BooleanValueIcon, ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { formatDateTime, useExecuteWithConfirmation, useSearchCancelQuery } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import invariant from 'tiny-invariant';

import { Role, useSessionContext } from '../../contexts/SessionContext';
import {
  CK_ASSIGNED_ITEMS_FOR_USERS,
  useAssignedItemsForUserList,
  useRemoveAssignedItems,
} from '../../services/assigned-items';
import { AssignedItems, AssignedItemsProduct } from './models/assigned-items';

const UsersAssignedItemsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  invariant(userId, 'userId is not set within the route');
  const translations = useTranslations();
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const { role } = useSessionContext();
  const { colors } = useTheme();

  const {
    data,
    isFetching: isFetchingAssignedItemsList,
    error: assignedItemsListError,
  } = useAssignedItemsForUserList(userId, false, {
    searchText,
    limit: config.paginationSize,
    offset: page,
  });
  useSearchCancelQuery(searchText, CK_ASSIGNED_ITEMS_FOR_USERS);
  const onExtendClicked = React.useCallback(
    (assignedItemsId: string) => {
      navigate(generatePath(Routes.ASSIGNED_ITEMS_EXTEND_PATH, { userId, assignedItemsId }));
    },
    [userId, navigate],
  );

  const { mutate: deleteAssignedItem, isLoading: isDeleting, error: deleteError } = useRemoveAssignedItems();

  const { executeWithConfirmation: onDeleteAssignedItem } = useExecuteWithConfirmation(
    translations('assigned_items__delete_confirmation'),
    deleteAssignedItem,
  );

  const columns = React.useMemo(
    () => [
      {
        id: '1',
        Header: translations('assigned_items_table_id'),
        accessor: 'product' as const,
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }: CellProps<AssignedItemsProduct>) => value?.id || null,
      },
      {
        id: '2',
        Header: translations('assigned_items_table_expire_time_stamp'),
        accessor: 'expiryTimeStamp' as const,
        Cell: ({ cell: { value } }: CellProps<AssignedItems>) => formatDateTime(new Date(+value)),
      },
      {
        id: '3',
        Header: translations('assigned_items_table_is_extend'),
        accessor: 'isExtended' as const,
        Cell: ({ cell: { value } }: CellProps<AssignedItems>) => <BooleanValueIcon value={!!value} />,
      },
      {
        id: '4',
        Header: translations('assigned_items_table_product_name'),
        accessor: 'product' as const,
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }: CellProps<AssignedItems>) => value?.productName || null,
      },
      {
        id: 'actions',
        Header: translations('assigned_items_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<AssignedItems>) => (
          <Flex>
            <Button m={1} color={colors.orange[500]} onClick={() => onExtendClicked(original.id)}>
              {translations('assigned_items_extend_button')}
            </Button>
            {!original.product && (role === Role.ADMINISTRATOR || role === Role.STANDARD_USER) ? (
              <Button m={1} onClick={() => onDeleteAssignedItem({ userId, assignedItemsId: original.id })}>
                {translations('assigned_items_delete_button')}
              </Button>
            ) : null}
          </Flex>
        ),
      },
    ],
    [translations, onExtendClicked, onDeleteAssignedItem, userId, role, colors],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('users_assigned_items_header')}</PageHeading>
      <ErrorMessages errors={[assignedItemsListError, deleteError]} />
      <Table<AssignedItems>
        isLoading={isFetchingAssignedItemsList || isDeleting}
        data={data?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('users_assigned_items_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default UsersAssignedItemsPage;
