import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { FormGroup } from '../../../../../common/components';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { NotificationSettingsEnabled } from '../../models/enums/notification-settings-enabled.enum';

export const NotificationEnabled = () => {
  const translations = useTranslations();
  const { control } = useFormContext();

  return (
    <FormGroup label={translations('notification_settings_enabled_label')} inputId="enabled">
      <Controller
        control={control}
        name="enabled"
        render={({ field: { value, onChange } }) => (
          <RadioGroup onChange={onChange} value={value} id="enabled">
            <Stack direction="row">
              <Radio value={NotificationSettingsEnabled.ON}>{translations('notification_settings_enabled_on')}</Radio>
              <Radio value={NotificationSettingsEnabled.OFF}>{translations('notification_settings_enabled_off')}</Radio>
            </Stack>
          </RadioGroup>
        )}
      />
    </FormGroup>
  );
};
