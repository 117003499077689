import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Button, Flex } from '@chakra-ui/react';

import { ErrorMessages, useToast } from '../../common/components';
import { Drag } from '../../common/components/DragAndDrop/Drag';
import { DragItem } from '../../common/components/DragAndDrop/DragItem';
import { Drop } from '../../common/components/DragAndDrop/Drop';
import { useExecuteWithConfirmation } from '../../common/utils';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useDeleteFaqSection } from '../../services/frequently-asked-questions';
import { FrequentlyAskedQuestion } from './models/frequently-asked-questions-response.model';
import { FaqDragAndDropQuestion } from './FaqDragAndDropQuestion';

type FaqDragAndDropSectionProps = {
  faq: FrequentlyAskedQuestion;
  index: number;
};

export const FaqDragAndDropSection = ({ faq, index }: FaqDragAndDropSectionProps) => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const { language } = useLocalizationContext();
  const { mutate: deleteFaqSection, error: deleteSectionError } = useDeleteFaqSection();
  const handleSectionDelete = (values: { sectionId: string }) => {
    deleteFaqSection(values, {
      onSuccess: () => displayToast('success', translations('faq_section_delete_success')),
      onError: () => displayToast('error', translations('faq_section_delete_error')),
    });
  };
  const { executeWithConfirmation: onDeleteFaqSection } = useExecuteWithConfirmation(
    translations('faq_section_delete_confirmation'),
    handleSectionDelete,
  );
  const handleDeleteFaqSection = () => onDeleteFaqSection({ sectionId: faq.section.id });

  return (
    <>
      <ErrorMessages errors={deleteSectionError} />
      <Drag id={faq.section.id} index={index}>
        <Flex gap={3}>
          <DragItem w="100%">{faq.section.translations[language]}</DragItem>
          <DragItem
            as={Link}
            h="auto"
            to={generatePath(Routes.CREATE_FAQ_QUESTION_PATH, { sectionId: faq.section.id })}
          >
            <AddIcon />
          </DragItem>
          <DragItem as={Link} h="auto" to={generatePath(Routes.UPDATE_FAQ_SECTION_PATH, { id: faq.section.id })}>
            <EditIcon />
          </DragItem>
          <DragItem as={Button} h="auto" onClick={handleDeleteFaqSection}>
            <DeleteIcon />
          </DragItem>
        </Flex>
        <Drop id={faq.section.id} type="droppable-question">
          {faq.questions.map((question, questionIndex) => {
            return <FaqDragAndDropQuestion key={question.id} question={question} index={questionIndex} faq={faq} />;
          })}
        </Drop>
      </Drag>
    </>
  );
};
