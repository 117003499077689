import React, { JSX, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Button, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { ErrorMessages } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { CreateRestaurantUserMutation, useCreateRestaurantUser } from 'services/restaurants';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { RestaurantUserRequest, RestaurantUserResponse } from './models';
import RestaurantCredentialForm from './RestaurantCredentialForm';

const CreateRestaurantUser = (): JSX.Element => {
  const [isCreated, setIsCreated] = useState(false);
  const [credentials, setCredentials] = useState<RestaurantUserRequest>();
  const [restaurantUser, setRestaurantUser] = useState<RestaurantUserResponse>();
  const translations = useTranslations();
  const onSuccess = (data: RestaurantUserResponse, variable: CreateRestaurantUserMutation) => {
    setCredentials(variable.createRestaurantCredentialRequest);
    setRestaurantUser(data);
    setIsCreated(true);
  };
  const {
    mutate: createRestaurantCredential,
    isLoading,
    error: createRestaurantCredentialError,
  } = useCreateRestaurantUser(onSuccess);

  const onSubmit = (createRestaurantCredentialRequest: RestaurantUserRequest) =>
    createRestaurantCredential({ createRestaurantCredentialRequest });

  return (
    <>
      <ChildPageHeading>{translations('restaurant_credential_create_header')}</ChildPageHeading>
      <ErrorMessages errors={createRestaurantCredentialError} />
      {!isCreated ? (
        <RestaurantCredentialForm isLoading={isLoading} onSubmit={onSubmit} />
      ) : (
        <>
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Td>{translations('restaurant_user_email')}</Td>
                <Td>{credentials?.email}</Td>
              </Tr>
              <Tr>
                <Td>{translations('restaurant_user_password')}</Td>
                <Td>{credentials?.password}</Td>
              </Tr>
              <Tr>
                <Td>{translations('restaurant_user_role')}</Td>
                <Td>{credentials?.role}</Td>
              </Tr>
              <Tr>
                <Td>{translations('restaurant_user_first_name')}</Td>
                <Td>{credentials?.firstName}</Td>
              </Tr>
              <Tr>
                <Td>{translations('restaurant_user_last_name')}</Td>
                <Td>{credentials?.lastName}</Td>
              </Tr>
              <Tr>
                <Td>{translations('restaurant_user_restaurants')}</Td>
                <Td>
                  <ul>{restaurantUser?.locations?.restaurantIds.map((r) => <li key={r.id}>{r.restaurantName}</li>)}</ul>
                </Td>
              </Tr>
              <Tr>
                <Td>{translations('restaurant_user_key_account_groups')}</Td>
                <Td>
                  <ul>
                    {restaurantUser?.locations?.keyAccountsGroupIds.map((k) => <li key={k.id}>{k.keyAccount}</li>)}
                  </ul>
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Link to={generatePath(Routes.RESTAURANT_USERS_PATH)}>
            <Button mt={4} ml={4}>
              {translations('back')}
            </Button>
          </Link>
        </>
      )}
    </>
  );
};

export default CreateRestaurantUser;
