import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { useHandleTablePageCheckbox } from '../../../../hooks/useHandleTablePageCheckbox';
import { useHandleTableRowCheckbox } from '../../../../hooks/useHandleTableRowCheckbox';
import { Paginated } from '../../../../models';

interface IdType {
  id: string;
}

export const useSelectRows = <T extends IdType>() => {
  const [paginatedItems, setPaginatedItems] = useState<Paginated<T> | undefined>(undefined);
  const [selectAllRows, setSelectAllRows] = useState<boolean>(false);
  const [selectedRowsIds, setSelectedRowsIds] = useState<string[]>([]);

  const { handlePageCheckbox } = useHandleTablePageCheckbox(
    selectedRowsIds,
    setSelectedRowsIds,
    paginatedItems?.items.map((item) => item.id),
  );

  const { handleCheckbox } = useHandleTableRowCheckbox(selectedRowsIds, setSelectedRowsIds);

  const handleSelectCheckbox = useCallback(
    (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
      handleCheckbox(id, e);
      if (!e.target.checked) {
        setSelectAllRows(false);
      }
    },
    [handleCheckbox],
  );

  const handleSelectPageCheckbox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handlePageCheckbox(e);
      if (!e.target.checked) {
        setSelectAllRows(false);
      }
    },
    [handlePageCheckbox],
  );

  const getIsChecked = useCallback(
    (original: T) => selectedRowsIds.some((id) => id === original.id),
    [selectedRowsIds],
  );

  const getIsSelectPageChecked = useCallback(
    (pageItems: T[] | undefined) =>
      selectedRowsIds.length ? pageItems?.every((item) => selectedRowsIds.includes(item.id)) : false,
    [selectedRowsIds],
  );

  const shouldShowSelectAllRows =
    !selectAllRows &&
    paginatedItems?.items.map((item) => item.id).every((id) => selectedRowsIds.includes(id)) &&
    paginatedItems?.count > paginatedItems?.items.length;

  const isEachRowSelected =
    (selectAllRows && paginatedItems?.items.map((item) => item.id).every((id) => selectedRowsIds.includes(id))) ||
    !selectedRowsIds.length;

  const selectedRowsNumber = useMemo(() => {
    if (paginatedItems) {
      if (isEachRowSelected) {
        return paginatedItems?.count || 0;
      } else {
        return selectedRowsIds.length;
      }
    }
    return 0;
  }, [isEachRowSelected, paginatedItems, selectedRowsIds]);

  const handleClearSelection = () => {
    setSelectAllRows(false);
    setSelectedRowsIds([]);
  };

  return {
    selectAllRows,
    setSelectAllRows,
    selectedRowsIds,
    handleSelectCheckbox,
    handleSelectPageCheckbox,
    getIsChecked,
    getIsSelectPageChecked,
    selectedRowsNumber,
    isEachRowSelected,
    shouldShowSelectAllRows,
    handleClearSelection,
    paginatedItems,
    setPaginatedItems,
  };
};
