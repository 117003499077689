import * as yup from 'yup';

import { SelectOption } from '../../../../../common/components/Select/models/select-option';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { ReturnCodeStatus } from '../../models/enums/return-code-status.enum';
import { ReturnCodeType } from '../../models/enums/return-code-type.enum';
import {
  RETURN_CODE_GRACE_PERIOD_DEFAULT_VALUE,
  RETURN_CODE_NAME_DEFAULT_VALUE,
  RETURN_CODE_STATUS_DEFAULT_VALUE,
  RETURN_CODE_TYPE_DEFAULT_VALUE,
} from '../../utils/return-codes.consts';

export enum ReturnCodeStatusNames {
  ACTIVE = 'Active',
  PASSIVE = 'Passive',
}

export enum ReturnCodeTypeNames {
  REGULAR = 'Regular',
  OPEN_HOURS = 'Open hours',
}

export enum ReturnCodeFormMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export type StatusSelect = {
  label: ReturnCodeStatusNames;
  value: ReturnCodeStatus;
};

export type ReturnCodeTypeSelect = {
  label: ReturnCodeTypeNames;
  value: ReturnCodeType;
};

export const returnCodeStatusSelectOptions: SelectOption[] = [
  { label: ReturnCodeStatusNames.ACTIVE, value: ReturnCodeStatus.ACTIVE },
  { label: ReturnCodeStatusNames.PASSIVE, value: ReturnCodeStatus.PASSIVE },
];

export const returnCodeTypeSelectOptions: SelectOption[] = [
  { label: ReturnCodeTypeNames.REGULAR, value: ReturnCodeType.REGULAR },
  { label: ReturnCodeTypeNames.OPEN_HOURS, value: ReturnCodeType.OPEN_HOURS },
];

export type ReturnCodesFormType = {
  name: string;
  status: ReturnCodeStatus;
  type: ReturnCodeType;
  gracePeriod?: number | null;
};

export const returnCodeFormDefaultValues: ReturnCodesFormType = {
  name: RETURN_CODE_NAME_DEFAULT_VALUE,
  status: RETURN_CODE_STATUS_DEFAULT_VALUE,
  type: RETURN_CODE_TYPE_DEFAULT_VALUE,
  gracePeriod: RETURN_CODE_GRACE_PERIOD_DEFAULT_VALUE,
};

export const useReturnCodeFormValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    name: yup.string().max(255, translations('validation_too_long')).required(translations('validation_required')),
    status: yup.string().oneOf(Object.values(ReturnCodeStatus), translations('validation_incorrect_value')),
    type: yup.string().oneOf(Object.values(ReturnCodeType), translations('validation_incorrect_value')),
    gracePeriod: yup.number().nullable().typeError(translations('validation_number_type_error')),
  });
};
