import React, { JSX, useState } from 'react';
import { CellProps } from 'react-table';
import { Button, Flex } from '@chakra-ui/react';
import { FilterProperties, ProductsFilterParams } from 'api/products';
import { FallbackValue, Table, Time } from 'common/components';
import { config } from 'config/config';
import { useTranslations } from 'contexts/LocalizationContext';
import { saveAs } from 'file-saver';
import { useProductList } from 'services/products';

import qrCodeIcon from '../../assets/icons/qr-code.png';
import QRCodeDownloadModal from '../../common/components/QRCodeDownloadModal/QRCodeDownloadModal';
import { FileExtension } from '../../common/models/enums/fileExtension';
import { getQRCodeSrc } from '../../common/utils/qrCodes.helper';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useModal } from '../../contexts/ModalContext';
import { ModalVariant } from '../../contexts/ModalContext/hook';
import { ProductResponse, UnionKeys } from './Product.model';
import ProductsFilter from './ProductsFilter';

type TableFilter = {
  property: UnionKeys<ProductsFilterParams>;
  value: string | undefined;
};

type ProductsTableProps = {
  onTableFilterChange: (property: TableFilter['property'], value: TableFilter['value']) => void;
};

const ProductsTable = ({ onTableFilterChange }: ProductsTableProps): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [tableFilter, setTableFilter] = useState<TableFilter>({ property: FilterProperties.PRODUCT_ID, value: '' });
  const { modalState, handleToggleModal } = useModal();

  const {
    isLoading: isLoadingProducts,
    isFetching: isFetchingProducts,
    data: products,
  } = useProductList(
    {
      offset: page,
      limit: config.paginationSize,
    },
    tableFilter.value ? { [tableFilter.property]: tableFilter.value } : undefined,
  );

  const handleDownloadQRCode = (original: ProductResponse) => () =>
    saveAs(getQRCodeSrc(original.qrCode), `${original.uId}.${FileExtension.PNG}`);

  const columns = [
    {
      id: '1',
      accessor: 'id' as const,
      Header: translations('products_table_header_id'),
    },
    {
      id: '2',
      accessor: 'uId' as const,
      Header: translations('products_table_header_uid'),
    },
    {
      id: '3',
      accessor: 'productName' as const,
      Header: translations('products_table_header_name'),
    },
    {
      id: '4',
      Header: translations('products_table_header_category_name'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ProductResponse>) => <FallbackValue value={original?.category.translations[language]} />,
    },
    {
      id: '5',
      Header: translations('products_table_header_meta_category'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ProductResponse>) => <FallbackValue value={original?.metaCategory?.translations[language]} />,
    },
    {
      id: '6',
      Header: translations('products_table_header_creation_date'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ProductResponse>) => <Time time={original.createdAt} />,
    },
    {
      id: '7',
      Header: translations('products_table_header_qr_code'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ProductResponse>) => (
        <Flex justifyContent="center">
          <Button p={1} onClick={handleToggleModal({ modalId: original.uId, modalType: ModalVariant.PRODUCT_QR })}>
            <img src={qrCodeIcon} alt="QR code icon" width={34} />
            <QRCodeDownloadModal
              isModalVisible={modalState?.modalId === original.uId && modalState.modalType === ModalVariant.PRODUCT_QR}
              qrCode={original.qrCode}
              onDownloadQRCode={handleDownloadQRCode(original)}
              onModalClose={handleToggleModal(null)}
            />
          </Button>
        </Flex>
      ),
    },
  ];

  return (
    <Table<ProductResponse>
      data={products?.items}
      columns={columns}
      isLoading={isLoadingProducts || isFetchingProducts}
      page={page}
      onPageChanged={setPage}
      searchText={searchText}
      onSearchTextChanged={setSearchText}
      totalPages={products?.totalPages}
      customFilter={
        <ProductsFilter
          onChange={(property, value) => {
            setPage(1);
            setTableFilter({ property, value });
            onTableFilterChange(property, value);
          }}
        />
      }
      searchEnabled
      paginationEnabled
    />
  );
};

export default ProductsTable;
