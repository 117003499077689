import { accountPriorityOptions } from '../../../../common/constants/select-options/accountPriority.consts';
import { isEmpty } from '../../../../common/utils/helpers';
import { City } from '../../../City/models/City';
import { ClosingSource } from '../../../ClosingSources/model/ClosingSource';
import { KeyAccountGroupsResponse } from '../../../KeyAccountGroups/models/key-account-groups-response';
import { RestaurantRequest } from '../../models';
import { Deliverers } from '../../models/restaurant-deliverers.model';
import { CategoryGroupPrice, statusSelectOptions } from '../../RestaurantForm/RestaurantForm.form';
import { RestaurantChangesSummary } from '../model/restaurant-changes-summary.model';
import { UpdateRestaurantsRequest } from '../model/update-restaurants.request.model';

const buildDeliverers = (deliverersEntry?: Deliverers): Deliverers => {
  const deliverers: Deliverers = {};
  if (!isEmpty(deliverersEntry?.wolt)) {
    deliverers.wolt = deliverersEntry?.wolt;
  }
  if (!isEmpty(deliverersEntry?.lieferando)) {
    deliverers.lieferando = deliverersEntry?.lieferando;
  }
  if (!isEmpty(deliverersEntry?.uberEats)) {
    deliverers.uberEats = deliverersEntry?.uberEats;
  }
  if (!isEmpty(deliverersEntry?.customDelivery)) {
    deliverers.customDelivery = deliverersEntry?.customDelivery;
  }
  return deliverers;
};

const buildCategoryGroupPrice = (categoryGroupPriceEntry?: CategoryGroupPrice): CategoryGroupPrice => {
  const categoryGroupPrice: CategoryGroupPrice = {};
  if (categoryGroupPriceEntry?.BOWLS) {
    categoryGroupPrice.BOWLS = categoryGroupPriceEntry.BOWLS;
  }
  if (categoryGroupPriceEntry?.CUPS) {
    categoryGroupPrice.CUPS = categoryGroupPriceEntry.CUPS;
  }
  return categoryGroupPrice;
};

export const buildUpdateRestaurantsRequest = (formData: RestaurantRequest): UpdateRestaurantsRequest => {
  const {
    googlePlaceId,
    tags,
    promotions,
    metaCategories,
    restaurantOpeningHours,
    overrideOpeningHours,
    deliverers,
    categoryGroupPrice,
    ...remainingFields
  } = formData;
  const updateRestaurantsRequest: UpdateRestaurantsRequest = Object.fromEntries(
    Object.entries(remainingFields).filter(([, value]) => !!value && value != ''),
  );
  const deliverersRequest = buildDeliverers(deliverers);
  if (!isEmpty(deliverersRequest)) {
    updateRestaurantsRequest.deliverers = deliverersRequest;
  }
  const categoryGroupPriceRequest = buildCategoryGroupPrice(categoryGroupPrice);
  if (!isEmpty(categoryGroupPriceRequest)) {
    updateRestaurantsRequest.categoryGroupPrice = categoryGroupPriceRequest;
  }
  if (overrideOpeningHours) {
    updateRestaurantsRequest.overrideOpeningHours = overrideOpeningHours;
    updateRestaurantsRequest.restaurantOpeningHours = restaurantOpeningHours;
  }
  return updateRestaurantsRequest;
};

export const buildRestaurantChangesSummaryObject = (
  updateRestaurantsRequest: UpdateRestaurantsRequest,
  city?: City,
  keyAccount?: KeyAccountGroupsResponse,
  closingSource?: ClosingSource,
): RestaurantChangesSummary => {
  const {
    status,
    starterSetSize,
    deliverers,
    categoryGroupPrice,
    accountPriority,
    cityId,
    keyAccountId,
    closingSourceId,
    overrideOpeningHours,
    ...remainingFields
  } = updateRestaurantsRequest;
  const restaurantChangesSummary = {
    ...remainingFields,
    status: statusSelectOptions.find((option) => option.value === status)?.label,
    starterSetSize: starterSetSize?.toString(),
    accountPriority: accountPriorityOptions.find((option) => option.value === accountPriority)?.label,
    cupsPrice: categoryGroupPrice?.CUPS?.toString(),
    bowlsPrice: categoryGroupPrice?.BOWLS?.toString(),
    woltLink: deliverers?.wolt?.url,
    lieferandoLink: deliverers?.lieferando?.url,
    uberEatsLink: deliverers?.uberEats?.url,
    orderingSystemLink: deliverers?.customDelivery?.url,
    city: city?.translations?.en,
    fnbGroup: keyAccount?.keyAccount,
    closingSource: closingSource?.name,
  };
  return Object.fromEntries(Object.entries(restaurantChangesSummary).filter(([, value]) => !!value && value != ''));
};

export const toSentenceCase = (camelCase: string): string => {
  const result = camelCase.replace(/([A-Z])/g, ' $1');
  return result.toLowerCase();
};
