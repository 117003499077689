import React, { JSX } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Box, Flex, Image, useTheme } from '@chakra-ui/react';
import logo from 'assets/images/logo.png';
import { Routes } from 'config/routes';
import Session from 'supertokens-auth-react/recipe/session';

import { SIDEBAR_WIDTH } from '../AppLayout';
import { Menu } from './Menu/Menu';

export const Sidebar = (): JSX.Element => {
  const sessionContext = Session.useSessionContext();
  const { colors } = useTheme();

  return (
    <Box
      bgColor={colors.orange[500]}
      p={2}
      w={SIDEBAR_WIDTH}
      height="100%"
      position="fixed"
      sx={{ '@media print': { position: 'static' } }}
      overflowY="auto"
    >
      <Flex justifyContent="center" direction="column">
        <Link to={Routes.HOME_PATH}>
          <Image src={logo} width="150px" alt="Logo" />
        </Link>
      </Flex>
      <Badge ml="auto" mt={2} fontSize="0.8em" colorScheme="grey" variant="outline" data-testid="environment-badge">
        {process.env.REACT_APP_ENV}
      </Badge>
      <Flex justifyContent="center">
        {!sessionContext.loading && sessionContext.doesSessionExist ? <Menu activeTextColor={colors.white} /> : null}
      </Flex>
    </Box>
  );
};
