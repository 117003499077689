import React, { JSX } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ErrorMessages, FullPageLoader } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useEditKeyAccountGroup, useKeyAccountGroup } from 'services/key-account-groups';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { FormSubmitData } from './models/form-submit-data';
import KeyAccountGroupsForm from './KeyAccountGroupForm';
import { KeyAccountGroupsFormValues } from './KeyAccountGroups.model';

const KeyAccountGroupEdit = (): JSX.Element => {
  const translations = useTranslations();
  const {
    mutate: editKeyAccountGroup,
    isLoading,
    error: editKeyAccountError,
  } = useEditKeyAccountGroup(Routes.KEY_ACCOUNT_GROUPS_PATH);
  const { keyAccountId } = useParams<{ keyAccountId: string }>();
  invariant(keyAccountId, 'keyAccountId is not set within the route');
  const {
    data: keyAccountGroup,
    isLoading: isLoadingKeyAccountGroup,
    error: loadKeyAccountError,
  } = useKeyAccountGroup(keyAccountId);

  if (isLoadingKeyAccountGroup) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  const formData = {
    keyAccount: keyAccountGroup?.keyAccount,
    segment: keyAccountGroup?.segment,
    desiredStartDate: keyAccountGroup?.desiredStartDate,
    accountPriority: keyAccountGroup?.accountPriority,
    googleDriveLink: keyAccountGroup?.googleDriveLink,
    quickContactsEnabled: keyAccountGroup?.quickContactsEnabled,
  } as KeyAccountGroupsFormValues;

  return (
    <Box mb={10}>
      <ChildPageHeading>{translations('key_account_groups_edit_header')}</ChildPageHeading>
      <ErrorMessages errors={[editKeyAccountError, loadKeyAccountError]} />
      <KeyAccountGroupsForm
        isLoading={isLoading}
        formData={formData}
        onSubmit={(values: FormSubmitData) => {
          editKeyAccountGroup({ id: keyAccountId, values });
        }}
        model={keyAccountGroup}
      />
    </Box>
  );
};

export default KeyAccountGroupEdit;
