import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessages, FormHeader } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCreateWarehouse } from 'services/warehouses';

import WarehouseForm from './WarehouseForm';

const CreateRestaurant = (): JSX.Element => {
  const navigate = useNavigate();
  const translations = useTranslations();
  const { mutate: createWarehouse, isLoading: isSavingWarehouse, error: createWarehouseError } = useCreateWarehouse();

  return (
    <>
      <FormHeader>{translations('warehouses_create_header')}</FormHeader>
      <ErrorMessages errors={createWarehouseError} />
      <WarehouseForm
        isLoading={isSavingWarehouse}
        onSubmit={(values) =>
          createWarehouse(values, {
            onSuccess: async () => navigate(Routes.WAREHOUSES_PATH),
          })
        }
      />
    </>
  );
};

export default CreateRestaurant;
