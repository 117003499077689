import React, { JSX } from 'react';
import { Box, Heading } from '@chakra-ui/react';

import { PrivateFile } from '../../../../common/models/file';
import { File } from './File';

export enum CustomerFileType {
  CONTRACT,
  EXCEL,
}

interface FilesListProps {
  files: PrivateFile[] | null;
  title: string;
  fileType: CustomerFileType;
  disabled: boolean;
}

export const FilesList = ({ files, title, fileType, disabled }: FilesListProps): JSX.Element => {
  return (
    <>
      <Heading mt={10} mb={10}>
        {title}
      </Heading>
      <Box display="flex" flexWrap="wrap" mb={100} gap={5}>
        {files?.map(({ id, url }, index) => (
          <File key={id} index={index} url={url} fileId={id} fileType={fileType} disabled={disabled} />
        ))}
      </Box>
    </>
  );
};
