import React, { JSX } from 'react';
import { Modal } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { AssignItemsToRestaurantResponse } from './model/AssignItemsToRestaurant.model';
import { AssignProductsFromCsv } from './AssignProductsFromCsv';

type AssignProductFromCsvModalProps = {
  isVisible: boolean;
  onModalClose: () => void;
  isMassAssign: boolean;
  onAssign: (data: AssignItemsToRestaurantResponse) => void;
};

export const AssignProductFromCsvModal = ({
  isVisible,
  onModalClose,
  isMassAssign,
  onAssign,
}: AssignProductFromCsvModalProps): JSX.Element => {
  const translations = useTranslations();
  return (
    <>
      <Modal
        isModalVisible={isVisible}
        onModalClose={onModalClose}
        modalHeader={translations('product_assignment_from_csv_modal_header')}
        customModalFooter={null}
      >
        <AssignProductsFromCsv isMassAssign={isMassAssign} onModalClose={onModalClose} onAssign={onAssign} />
      </Modal>
    </>
  );
};
