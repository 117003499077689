import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ErrorMessages, FullPageLoader } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCreateUpdatePromotion, usePromotion } from 'services/promotions';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { CreateUpdatePromotionForm } from './CreateUpdatePromotionForm';

const PromotionFormPage = () => {
  const { id: promotionId } = useParams<{ id: string }>();
  invariant(promotionId, 'id is not set within the route');
  const translations = useTranslations();
  const navigate = useNavigate();
  const {
    mutate: onSubmitForm,
    isLoading: isUpdatingPromotion,
    error: changePromotionError,
  } = useCreateUpdatePromotion();
  const { data, isFetching: isFetchingPromotion, error: loadPromotionError } = usePromotion(promotionId);

  if (isFetchingPromotion || isUpdatingPromotion) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{!data ? translations('promotion_create') : translations('promotion_update')}</ChildPageHeading>
      <ErrorMessages errors={[loadPromotionError, changePromotionError]} />
      <CreateUpdatePromotionForm
        isLoading={isUpdatingPromotion}
        onSubmit={(values) =>
          onSubmitForm(
            { values },
            {
              onSuccess: () => navigate(Routes.PROMOTIONS_PATH),
            },
          )
        }
        data={data}
      />
    </>
  );
};

export default PromotionFormPage;
