import React, { JSX, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../common/components/FormInput/FormInput';
import { Language } from '../../common/utils';
import { FaqSection, FaqSectionFormValues, FaqSectionRequest } from './models/frequently-asked-question.model';
import { useSectionFormValidationSchema } from './SectionForm.form';

type SectionFormProps = {
  isLoading: boolean;
  onSubmit(data: FaqSectionRequest): void;
  data?: FaqSection | undefined;
};

const SectionForm = ({ isLoading, onSubmit, data }: SectionFormProps): JSX.Element => {
  const translations = useTranslations();
  const validationSchema = useSectionFormValidationSchema();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<FaqSectionFormValues>({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const handleFormSubmit = handleSubmit(({ ...form }: FaqSectionFormValues) => {
    onSubmit({ translations: form.translations });
  });

  return (
    <form onSubmit={handleFormSubmit}>
      {Object.values(Language).map((language) => (
        <Box key={language}>
          <FormInput
            id={`translations.${language}`}
            label={translations(`faq_sections_form_name_${language}`)}
            validationError={errors.translations ? errors.translations[language]?.message : undefined}
            useFormRegisterReturn={register(`translations.${language}`)}
            setValue={setValue}
          />
        </Box>
      ))}
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default SectionForm;
