import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { DeleteIcon } from '@chakra-ui/icons';
import { Flex, HStack, IconButton, Text } from '@chakra-ui/react';

import { FileInput, FormGroup } from '../../../../common/components';
import { useTranslations } from '../../../../contexts/LocalizationContext';

export const LostItemsCsvFile = () => {
  const translations = useTranslations();
  const {
    watch,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  const watchCsvFile = watch('csvFile');

  const removeCsvFile = () => setValue('csvFile', null);

  return (
    <HStack>
      <Flex mr={8}>
        <FormGroup
          label={translations('lost_items_list_label')}
          labelTooltipText={translations('lost_items_list_tooltip')}
          inputId="csvFile"
          validationError={(errors.csvFile as FieldError)?.message}
        >
          <FileInput accept="text/csv" register={register('csvFile')} aria-label="csvFile" />
        </FormGroup>
      </Flex>
      {watchCsvFile?.length ? (
        <Flex alignItems="center">
          <Text>{watchCsvFile[0].name}</Text>
          <IconButton aria-label="Remove" icon={<DeleteIcon />} onClick={removeCsvFile} size="sm" ml={2} />
        </Flex>
      ) : null}
    </HStack>
  );
};
