import { generatePath } from 'react-router-dom';

import { LinkButton } from '../../../../../common/components';
import { Routes } from '../../../../../config/routes';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useKeyAccountGroupRestaurantsContext } from '../../context/KeyAccountGroupRestaurantsContext/hooks/use-key-account-group-restaurants-context.hook';

type RestaurantsMassEditProps = {
  keyAccountGroupId: string;
};

export const MassEditRestaurants = ({ keyAccountGroupId }: RestaurantsMassEditProps) => {
  const translations = useTranslations();
  const { selectAllRestaurants, selectedRestaurantsIds, selectedRestaurantsNumber } =
    useKeyAccountGroupRestaurantsContext();

  return (
    <LinkButton
      to={generatePath(Routes.MASS_EDIT_KEY_ACCOUNT_RESTAURANTS_PATH, { keyAccountId: keyAccountGroupId })}
      state={selectAllRestaurants ? [] : selectedRestaurantsIds}
      ml={2}
      mt={2}
      isDisabled={!selectedRestaurantsNumber}
    >
      {translations('restaurants_mass_edit', {
        '{{number}}': selectedRestaurantsNumber.toString(),
      })}
    </LinkButton>
  );
};
