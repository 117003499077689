import { ReturnCodeStatus } from '../models/enums/return-code-status.enum';
import { ReturnCodeType } from '../models/enums/return-code-type.enum';

export const GRACE_PERIOD_MIN_VALUE = 0;
export const GRACE_PERIOD_MAX_VALUE = 180;
export const GRACE_PERIOD_STEP_VALUE = 10;

export const RETURN_CODE_GRACE_PERIOD_DEFAULT_VALUE = 0;
export const RETURN_CODE_STATUS_DEFAULT_VALUE = ReturnCodeStatus.ACTIVE;
export const RETURN_CODE_TYPE_DEFAULT_VALUE = ReturnCodeType.REGULAR;
export const RETURN_CODE_NAME_DEFAULT_VALUE = '';
export const GRACE_PERIOD_UNIT = 'min';
