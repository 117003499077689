import { RestaurantDetailsResponse, RestaurantOpeningHoursResponse } from '../models';

export const createDummyOpeningHours = (): RestaurantOpeningHoursResponse[] => {
  const dummyOpeningHours = [];
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    dummyOpeningHours.push({ day: dayIndex, periods: [] });
  }
  dummyOpeningHours.push(dummyOpeningHours.shift()!);
  return dummyOpeningHours;
};

export function isOpeningHours(value: unknown): value is RestaurantOpeningHoursResponse[] {
  if (!Array.isArray(value)) {
    return false;
  }
  return value.every((openingHour) => !isNaN(Number(openingHour.day)));
}

export const weekdayToString = (day: number): string => {
  const weekdays = [
    'weekday_sunday',
    'weekday_monday',
    'weekday_tuesday',
    'weekday_wednesday',
    'weekday_thursday',
    'weekday_friday',
    'weekday_saturday',
  ];
  return weekdays[day];
};

export const getTimeFromHourString = (hour: string): Date => {
  const date = new Date();
  const splitHour = hour.split(':');
  date.setHours(Number(splitHour[0]), Number(splitHour[1]), 0, 0);
  return date;
};

export const prepareOpeningHours = (model: RestaurantDetailsResponse | undefined): RestaurantOpeningHoursResponse[] => {
  let preliminaryOpeningHours = model?.restaurantOpeningHours;
  if ((preliminaryOpeningHours && preliminaryOpeningHours.length < 1) || !preliminaryOpeningHours) {
    preliminaryOpeningHours = createDummyOpeningHours();
  }
  // Due to google services starting counting week from sunday,
  // the first element is moved to the end
  if (preliminaryOpeningHours && preliminaryOpeningHours[0].day === 0) {
    preliminaryOpeningHours.push(preliminaryOpeningHours.shift()!);
  }
  return preliminaryOpeningHours;
};

export const parseOpeningHourPeriodId = (
  elementId: string,
): { day: number; periodIndex: number; type: string } | undefined => {
  // [1] - day, [2] - periodIndex, [3] - open/close time
  const periodMetadataRegex = /override-input-(\d*)-(\d*)-(open|close|add|remove|empty)/;
  const periodMetadata = periodMetadataRegex.exec(elementId);
  if (periodMetadata && periodMetadata[1] && periodMetadata[2] && periodMetadata[3]) {
    return {
      day: Number(periodMetadata[1]),
      periodIndex: Number(periodMetadata[2]),
      type: periodMetadata[3],
    };
  }
  return;
};
