import React, { JSX, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { LinkButton } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import invariant from 'tiny-invariant';

import FormInput from '../../../../../common/components/FormInput/FormInput';
import { ReturnCodeType } from '../../models/enums/return-code-type.enum';
import { FormReturnCodeGracePeriod } from './components/FormReturnCodeGracePeriod/FormReturnCodeGracePeriod';
import { FormReturnCodeStatus } from './components/FormReturnCodeStatus/FormReturnCodeStatus';
import { FormReturnCodeType } from './components/FormreturnCodeType/FormReturnCodeType';
import {
  returnCodeFormDefaultValues,
  ReturnCodeFormMode,
  ReturnCodesFormType,
  useReturnCodeFormValidation,
} from './restaurant-return-code.form';

type RestaurantReturnCodeFormProps = {
  isLoading: boolean;
  onSubmit: (request: ReturnCodesFormType) => void;
  mode: ReturnCodeFormMode;
  model?: ReturnCodesFormType;
};

const RestaurantReturnCodeForm = ({ isLoading, onSubmit, model, mode }: RestaurantReturnCodeFormProps): JSX.Element => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  invariant(restaurantId, 'restaurantId is not set within the route');
  const translations = useTranslations();
  const schema = useReturnCodeFormValidation();

  const formMethods = useForm<ReturnCodesFormType>({
    defaultValues: returnCodeFormDefaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (model) {
      formMethods.reset(model);
    }
  }, [formMethods, model]);

  const onFormSubmit = formMethods.handleSubmit((form) => {
    onSubmit(form);
  });

  const watchType = formMethods.watch('type');

  return (
    <FormProvider {...formMethods}>
      <form data-testid="form" onSubmit={onFormSubmit} autoComplete="off">
        <FormInput
          id={'name'}
          label={translations('restaurants_return_codes_form_name')}
          useFormRegisterReturn={formMethods.register('name')}
          validationError={formMethods.formState.errors.name?.message}
          setValue={formMethods.setValue}
        />
        <FormReturnCodeStatus />
        <FormReturnCodeType isDisabled={mode === ReturnCodeFormMode.UPDATE} />
        {watchType === ReturnCodeType.OPEN_HOURS ? <FormReturnCodeGracePeriod /> : null}
        <Button mt={4} isLoading={isLoading} type="submit">
          {translations('save')}
        </Button>
        <LinkButton to={generatePath(Routes.RESTAURANT_RETURN_CODES_PATH, { restaurantId })} mt={4} ml={4}>
          {translations('cancel')}
        </LinkButton>
      </form>
    </FormProvider>
  );
};

export default RestaurantReturnCodeForm;
