import apiPaths from '../config/apiPaths';
import {
  SendTestNotificationRequest,
  TestMessageTypeResponse,
} from '../modules/Notifications/TestNotifications/model/TestNotification.model';
import axiosInstance from './axios';

export const getTestMessageTypeList = (): Promise<TestMessageTypeResponse[]> =>
  axiosInstance.get<TestMessageTypeResponse[]>(`${apiPaths.TEST_NOTIFICATIONS}/message-types`).then(({ data }) => data);

export const sendTestNotification = (data: SendTestNotificationRequest): Promise<void> =>
  axiosInstance.post(apiPaths.TEST_NOTIFICATIONS, data);
