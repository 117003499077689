import { createContext, ReactNode } from 'react';

import { useSelectRows } from '../../../../../common/components/Table/Pagination/hooks/use-select-rows.hook';
import { RestaurantResponse } from '../../../../Restaurants/models';
import { KeyAccountGroupRestaurantsContextInterface } from './models/key-account-group-restaurants-context';

type KeyAccountGroupRestaurantsContextProps = {
  children: ReactNode;
};

export const KeyAccountGroupRestaurantsContext = createContext<KeyAccountGroupRestaurantsContextInterface | null>(null);

export const KeyAccountGroupRestaurantsContextProvider = ({ children }: KeyAccountGroupRestaurantsContextProps) => {
  const {
    selectAllRows: selectAllRestaurants,
    setSelectAllRows: setSelectAllRestaurants,
    selectedRowsIds: selectedRestaurantsIds,
    handleClearSelection,
    getIsChecked,
    getIsSelectPageChecked,
    handleSelectCheckbox,
    handleSelectPageCheckbox,
    selectedRowsNumber: selectedRestaurantsNumber,
    isEachRowSelected: isEachRestaurantSelected,
    shouldShowSelectAllRows: shouldShowSelectAllRestaurants,
    paginatedItems,
    setPaginatedItems,
  } = useSelectRows<RestaurantResponse>();

  return (
    <KeyAccountGroupRestaurantsContext.Provider
      value={{
        selectAllRestaurants,
        setSelectAllRestaurants,
        selectedRestaurantsIds,
        handleClearSelection,
        getIsChecked,
        getIsSelectPageChecked,
        handleSelectCheckbox,
        handleSelectPageCheckbox,
        selectedRestaurantsNumber,
        isEachRestaurantSelected,
        shouldShowSelectAllRestaurants,
        paginatedItems,
        setPaginatedItems,
      }}
    >
      {children}
    </KeyAccountGroupRestaurantsContext.Provider>
  );
};
