import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCreateClosingSource } from '../../services/closing-sources';
import { ClosingSourceRequest } from './model/ClosingSource';
import ClosingSourcesForm from './ClosingSourcesForm';

const CreateClosingSource = (): JSX.Element => {
  const { mutate: createClosingSource, isLoading } = useCreateClosingSource();
  const navigate = useNavigate();
  const translations = useTranslations();
  return (
    <>
      <ChildPageHeading>{translations('closing_sources_add_header')}</ChildPageHeading>
      <ClosingSourcesForm
        isLoading={isLoading}
        onSubmit={(values: ClosingSourceRequest) => {
          createClosingSource(
            { values },
            {
              onSuccess: async () => navigate(Routes.CLOSING_SOURCES_PATH),
            },
          );
        }}
      />
    </>
  );
};

export default CreateClosingSource;
