import React, { JSX } from 'react';
import { FormControl, Input, Text } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../common/components/FormInput/FormInput';
import { generatePassword } from '../../common/utils/helpers';
import { RelevoUserFormModel } from './models';

export type RelevoUserCredentialsFormModel = RelevoUserFormModel & { password: string };

// eslint-disable-next-line
const RelevoUserCredentialsForm = ({ register, errors, setValue }: any): JSX.Element => {
  const translations = useTranslations();

  return (
    <>
      <FormControl>
        <FormInput
          id={'email'}
          label={translations('restaurants_credential_form_email')}
          useFormRegisterReturn={register('email')}
          setValue={setValue}
        />
        <Text color="red" textAlign="left">
          {errors.email?.message}
        </Text>
      </FormControl>
      <FormControl>
        <Input hidden id="password" {...register('password')} value={generatePassword()} />
      </FormControl>
    </>
  );
};

export default RelevoUserCredentialsForm;
