import React from 'react';
import ReactDomServer from 'react-dom/server';
import { Text } from '@chakra-ui/react';

import ItemsSummaryModal from '../../../../common/components/ItemsSummaryModal/ItemsSummaryModal';
import { useHtmlTranslations } from '../../../../contexts/LocalizationContext/hooks';
import { LostItemsFormActionType } from '../../model/enums/lost-items-form-action-type.enum';
import { LostItemsResult } from '../../model/lost-items-result';

type LostSummaryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  action: LostItemsFormActionType;
  items?: LostItemsResult;
};

export const LostSummaryModal = ({ isOpen, onClose, action, items }: LostSummaryModalProps) => {
  const translations = useHtmlTranslations();

  const modalHeader =
    action === LostItemsFormActionType.MARK_AS_LOST
      ? translations('lost_items_summary_modal_header')
      : translations('lost_items_mark_as_active_summary_modal_header');

  const failureText =
    action === LostItemsFormActionType.MARK_AS_LOST
      ? translations('lost_items_summary_modal_failure_content')
      : translations('lost_items_mark_as_active_summary_modal_failure_content');

  const successTextArgs = {
    '{{success-items-number}}': ReactDomServer.renderToString(
      <Text as="b">{(items?.successItems.length || 0).toString()}</Text>,
    ),
    '{{items-number}}': ReactDomServer.renderToString(
      <Text as="b">{((items?.successItems.length || 0) + (items?.failureItems.length || 0)).toString()}</Text>,
    ),
  };

  const successText =
    action === LostItemsFormActionType.MARK_AS_LOST
      ? translations('lost_items_summary_modal_success_content', successTextArgs)
      : translations('lost_items_mark_as_active_summary_modal_success_content', successTextArgs);

  return (
    <ItemsSummaryModal
      isVisible={isOpen}
      onCancelClicked={onClose}
      modalHeader={modalHeader}
      successText={successText}
      failureText={failureText}
      failureItems={items?.failureItems}
      successItems={items?.successItems}
    />
  );
};
