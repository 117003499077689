import React, { JSX, useEffect, useState } from 'react';
import { Box, Button, useDisclosure, useTheme } from '@chakra-ui/react';
import { DropResult } from '@hello-pangea/dnd';

import { ErrorMessages, useToast } from '../../common/components';
import { DragAndDrop } from '../../common/components/DragAndDrop';
import { Drop } from '../../common/components/DragAndDrop/Drop';
import { reorder } from '../../common/components/DragAndDrop/helpers';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useFaqList, useUpdateOrderFaqList } from '../../services/frequently-asked-questions';
import { FrequentlyAskedQuestionsReorderRequest } from './models/frequently-asked-questions-reorder-request.model';
import { FrequentlyAskedQuestionsResponse } from './models/frequently-asked-questions-response.model';
import { FaqDragAndDropSection } from './FaqDragAndDropSection';

const FrequentlyAskedQuestionsList = (): JSX.Element => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const { data: faqListData, error: loadFaqListError } = useFaqList();
  const { mutate: updateFaqList, error: updateError, isLoading: isFaqUpdating } = useUpdateOrderFaqList();

  const [faqList, setFaqList] = useState<FrequentlyAskedQuestionsResponse>(faqListData || []);
  const { isOpen: isSaveSectionOpen, onOpen: onSaveSectionOpen, onClose: onSaveSectionClose } = useDisclosure();
  const { colors } = useTheme();

  useEffect(() => {
    setFaqList(faqListData || []);
  }, [faqListData]);

  const handleCancel = () => {
    setFaqList(faqListData || []);
    onSaveSectionClose();
  };

  const handleFaqSave = () => {
    const faqReorderRequestDate: FrequentlyAskedQuestionsReorderRequest = {
      sectionsQuestionsSorted: faqList.map((faq) => ({
        sectionId: faq.section.id,
        order: faq.order,
        questionsIds: faq.questions.map((question) => question.id),
      })),
    };
    updateFaqList(
      { values: faqReorderRequestDate },
      {
        onSuccess: () => {
          onSaveSectionClose();
          displayToast('success', translations('faq_list_save_success'));
        },
      },
    );
  };

  const handleDragEnd = (result: DropResult) => {
    const { type, source, destination } = result;
    if (!destination) return;

    const sourceCategoryId = source.droppableId;
    const destinationCategoryId = destination.droppableId;

    // Reordering questions
    if (type === 'droppable-question') {
      // If drag and dropping within the same category
      if (sourceCategoryId === destinationCategoryId) {
        const updatedOrder = reorder(
          faqList.find((category) => category.section.id === sourceCategoryId)?.questions || [],
          source.index,
          destination.index,
        );
        const updatedFaqList = faqList.map((faq) =>
          faq.section.id !== sourceCategoryId ? faq : { ...faq, questions: updatedOrder },
        );
        setFaqList(updatedFaqList);
      } else {
        const sourceOrder = faqList.find((category) => category.section.id === sourceCategoryId)?.questions || [];
        const destinationOrder =
          faqList.find((category) => category.section.id === destinationCategoryId)?.questions || [];

        const [removed] = sourceOrder.splice(source.index, 1);
        destinationOrder.splice(destination.index, 0, removed);

        const updatedFaqList = faqList.map((faq) =>
          faq.section.id === sourceCategoryId
            ? { ...faq, questions: sourceOrder }
            : faq.section.id === destinationCategoryId
            ? { ...faq, questions: destinationOrder }
            : faq,
        );
        setFaqList(updatedFaqList);
      }
    }

    // Reordering sections
    if (type === 'droppable-sections') {
      const updatedFaqList = reorder(faqList, source.index, destination.index);
      setFaqList(updatedFaqList);
    }
    onSaveSectionOpen();
  };

  return (
    <>
      <ErrorMessages errors={[loadFaqListError, updateError]} />
      <DragAndDrop onDragEnd={handleDragEnd}>
        <Drop id="droppable" type="droppable-sections">
          {faqList.map((faq, categoryIndex) => {
            return <FaqDragAndDropSection key={faq.section.id} faq={faq} index={categoryIndex} />;
          })}
        </Drop>
      </DragAndDrop>
      {isSaveSectionOpen ? (
        <Box bgColor={colors.grey[100]} position="fixed" bottom={0} p={5} mx={-4} width="100%" textAlign="left">
          <Button onClick={handleFaqSave} mr={8} width="20vw" isDisabled={isFaqUpdating} isLoading={isFaqUpdating}>
            {translations('save')}
          </Button>
          <Button isDisabled={isFaqUpdating} onClick={handleCancel}>
            {translations('reset')}
          </Button>
        </Box>
      ) : null}
    </>
  );
};

export default FrequentlyAskedQuestionsList;
