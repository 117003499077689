import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button, Flex } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { ErrorMessages, MainPageContent, PageHeading, Table } from '../../../../common/components';
import { DEFAULT_PAGINATED_TABLE_LIMIT } from '../../../../common/constants/common';
import { formatDateTime } from '../../../../common/utils';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReuseCardUserAssignedItems } from '../../../../services/reuse-cards';
import { ItemStatus } from '../../../AssignedItems/models/enums/item-status';
import { ReuseCardUserAssignedItemResponse } from '../../models/reuse-card-user-assigned-item-response';
import { REUSE_CARD_USERS_TABLE_EMPTY_CELL_VALUE } from '../../utils/reuse-cards.consts';

export const ReuseCardItems = () => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const translations = useTranslations();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const params = {
    offset: page,
    limit: DEFAULT_PAGINATED_TABLE_LIMIT,
    status: ItemStatus.NOT_RETURNED,
  };

  const {
    data: paginatedAssignedItems,
    isFetching: isFetchingAssignedItems,
    error: reuseCardUserItemsError,
  } = useReuseCardUserAssignedItems(id, params);

  const handleCancel = () => {
    navigate(-1);
  };

  const columns = useMemo(
    () => [
      {
        id: 'productName',
        Header: translations('reuse_cards_assigned_items_product_header'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReuseCardUserAssignedItemResponse>) => original.product?.productName,
      },
      {
        id: 'categoryGroup',
        Header: translations('reuse_cards_assigned_items_category_group_header'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReuseCardUserAssignedItemResponse>) => original.product?.categoryGroup,
      },
      {
        id: 'categoryName',
        Header: translations('reuse_cards_assigned_items_category_header'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReuseCardUserAssignedItemResponse>) => original.product?.categoryTranslations.en,
      },
      {
        id: 'restaurantName',
        Header: translations('reuse_cards_assigned_items_restaurant_header'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReuseCardUserAssignedItemResponse>) =>
          original.restaurant?.restaurantName || REUSE_CARD_USERS_TABLE_EMPTY_CELL_VALUE,
      },
      {
        Header: translations('reuse_cards_assigned_items_borrowed_at_header'),
        accessor: 'borrowedAt' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReuseCardUserAssignedItemResponse>) => (
          <>{original.borrowedAt ? formatDateTime(new Date(original.borrowedAt)) : null}</>
        ),
      },
    ],
    [translations],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('reuse_cards_items_header')}</PageHeading>
      <ErrorMessages errors={reuseCardUserItemsError} />
      <Table<ReuseCardUserAssignedItemResponse>
        data={paginatedAssignedItems?.items}
        columns={columns}
        isLoading={isFetchingAssignedItems}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedAssignedItems?.totalPages}
        paginationEnabled
      />
      <Flex justifyContent="flex-start" mt={4}>
        <Button onClick={handleCancel}>{translations('reuse_cards_back_button')}</Button>
      </Flex>
    </MainPageContent>
  );
};
