import * as React from 'react';
import { ComponentProps, JSX } from 'react';
import { ArrowUpDownIcon } from '@chakra-ui/icons';
import { Box, Flex, useTheme } from '@chakra-ui/react';
import { Draggable } from '@hello-pangea/dnd';

export const Drag = ({
  id,
  index,
  children,
  ...props
}: {
  id: string;
  index: number;
} & ComponentProps<typeof Box>): JSX.Element => {
  const { colors } = useTheme();
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => {
        return (
          <Flex ref={provided.innerRef} {...provided.draggableProps} {...props} m={5} alignItems="flex-start" gap={3}>
            <Box {...provided.dragHandleProps} bgColor={colors.orange[500]} borderRadius="lg" p={3}>
              <ArrowUpDownIcon />
            </Box>
            <Box w="100%">{children}</Box>
          </Flex>
        );
      }}
    </Draggable>
  );
};
