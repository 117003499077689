import React, { ComponentProps, JSX } from 'react';
import { Box } from '@chakra-ui/react';
import { Banner, InlineLoader } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import colors from '../../../styles/colors';

type ProductsManagementPanelStatusProps = {
  hasError: boolean;
  hasData: boolean;
  isLoading: boolean;
  onBannerClose?: () => void;
};

export const ProductsManagementPanelStatus = ({
  hasError,
  hasData,
  isLoading,
  onBannerClose,
  ...styleProps
}: ProductsManagementPanelStatusProps & ComponentProps<typeof Box>): JSX.Element => {
  const translations = useTranslations();

  return (
    <>
      {(hasError || hasData || isLoading) && (
        <Box {...styleProps}>
          <Banner
            status={hasError ? 'error' : isLoading ? 'info' : 'success'}
            isClosable={hasError || hasData}
            customIcon={
              isLoading && (
                <Box mr={2}>
                  <InlineLoader thickness="2px" color={colors.grey[900]} size="sm" />
                </Box>
              )
            }
          >
            <>
              {hasError ? (
                <>{translations('error')}</>
              ) : (
                <>{translations(isLoading ? 'loading' : 'products_generation_multiple_generation_success')}</>
              )}
            </>
          </Banner>
        </Box>
      )}
    </>
  );
};
