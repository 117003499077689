import React, { JSX } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ErrorMessages, FormHeader, FullPageLoader } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import invariant from 'tiny-invariant';

import { useRestaurantReturnCode, useUpdateRestaurantReturnCode } from '../../../../../services/return-codes';
import { mapReturnCodeFormTypeToCreateRequest } from '../../utils/return-codes.helper';
import { ReturnCodeFormMode } from '../RestaurantReturnCodeForm/restaurant-return-code.form';
import RestaurantReturnCodeForm from '../RestaurantReturnCodeForm/RestaurantReturnCodeForm';

const UpdateRestaurantReturnCode = (): JSX.Element => {
  const { id, restaurantId } = useParams<{ id: string; restaurantId: string }>();
  invariant(id, 'id is not set within the route');
  invariant(restaurantId, 'restaurantId is not set within the route');
  const translations = useTranslations();

  const {
    data: returnCode,
    isLoading: isLoadingReturnCode,
    error: loadError,
  } = useRestaurantReturnCode(id, restaurantId);

  const {
    mutate: updateReturnCode,
    isLoading: isSavingReturnCode,
    error: updateReturnCodeError,
  } = useUpdateRestaurantReturnCode(generatePath(Routes.RESTAURANT_RETURN_CODES_PATH, { restaurantId }));

  if (isLoadingReturnCode) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <FormHeader>{translations('restaurant_return_codes_update_header')}</FormHeader>
      <ErrorMessages errors={[updateReturnCodeError, loadError]} />
      <RestaurantReturnCodeForm
        isLoading={isSavingReturnCode}
        onSubmit={(formValues) =>
          updateReturnCode({
            id,
            restaurantId,
            updateRestaurantReturnCodeRequest: mapReturnCodeFormTypeToCreateRequest(formValues),
          })
        }
        mode={ReturnCodeFormMode.UPDATE}
        model={returnCode}
      />
    </>
  );
};

export default UpdateRestaurantReturnCode;
