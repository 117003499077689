import React, { ComponentProps, JSX } from 'react';
import { Message, UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { Input } from '@chakra-ui/react';

import { FormGroup } from '../FormGroup';

type FormInputProps = {
  id: string;
  label: string;
  useFormRegisterReturn: UseFormRegisterReturn;
  setValue: UseFormSetValue<any>;
  validationError?: string | Message | undefined;
} & ComponentProps<typeof Input>;

const FormInput = ({
  id,
  label,
  useFormRegisterReturn,
  setValue,
  validationError,
  ...inputAttributes
}: FormInputProps): JSX.Element => {
  const trimValue = (event: React.FocusEvent<HTMLInputElement>) => setValue(id, event.target.value.trim());
  return (
    <FormGroup label={label} inputId={id} validationError={validationError}>
      <Input id={id} {...useFormRegisterReturn} onBlur={trimValue} {...inputAttributes} />
    </FormGroup>
  );
};

export default FormInput;
