import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import {
  anonymizeApplicationUser,
  getApplicationUserList,
  getUserProfileSettings,
  updateUserProfileSettings,
} from 'api/application-users';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { ApplicationUser } from 'modules/ApplicationUsers/models/application-user';

import { useToast } from '../common/components';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { ApplicationUserProfileSettingsRequest } from '../modules/ApplicationUsers/models/application-user-notification-settings.request.model';
import { ApplicationUserProfileSettingsResponse } from '../modules/ApplicationUsers/models/application-user-notification-settings.response.model';

export const CK_APPLICATION_USERS = 'applicationUsers';

export const useApplicationUsersList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<ApplicationUser>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_APPLICATION_USERS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getApplicationUserList(params),
    {
      keepPreviousData: true,
    },
  );
};

export const useAnonymizeApplicationUser = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation((id) => anonymizeApplicationUser(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_APPLICATION_USERS);
      displayToast('success', translations('users_anonymize_success'));
    },
    onError: (error) => {
      const toastMessage =
        error?.response?.status === 403
          ? translations('users_anonymize_forbidden_error')
          : translations('users_anonymize_error');
      displayToast('error', toastMessage);
    },
  });
};

export const useUserProfileSettings = (
  id: string,
): QueryObserverResult<ApplicationUserProfileSettingsResponse, AxiosError<HttpException>> =>
  useQuery([CK_APPLICATION_USERS, id], () => getUserProfileSettings(id));

export const useUpdateUserProfileSettings = (): UseMutationResult<
  ApplicationUserProfileSettingsResponse,
  AxiosError<HttpException>,
  { id: string; settingsRequest: ApplicationUserProfileSettingsRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, settingsRequest }: { id: string; settingsRequest: ApplicationUserProfileSettingsRequest }) =>
      updateUserProfileSettings(id, settingsRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_APPLICATION_USERS);
      },
    },
  );
};
