import React, { JSX } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { CustomerStatus } from '../../../api/customers';
import { ErrorMessages, FullPageLoader } from '../../../common/components';
import { ChildPageHeading } from '../../../common/components/ChildPageHeading';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useGetCustomer } from '../../../services/customers';
import { CustomerForm } from './CustomerForm/CustomerForm';
import { CustomerFileType, FilesList } from './FilesList/FilesList';

const UpdateCustomer = (): JSX.Element => {
  const translations = useTranslations();

  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const { data: customer, isLoading: isCustomerLoading, error: customerLoadError } = useGetCustomer(id);

  if (isCustomerLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{translations('customers_update_header')}</ChildPageHeading>
      <ErrorMessages errors={[customerLoadError]} />
      <CustomerForm isLoading={isCustomerLoading} model={customer} />
      {customer?.contractFile ? (
        <FilesList
          files={[customer.contractFile]}
          title={translations('customer_contract_files_heading')}
          fileType={CustomerFileType.CONTRACT}
          disabled={customer.status === CustomerStatus.READY}
        />
      ) : null}
      {customer?.relevoCalculatorFilesUrls ? (
        <FilesList
          files={customer.relevoCalculatorFilesUrls}
          title={translations('customer_excel_files_heading')}
          fileType={CustomerFileType.EXCEL}
          disabled={customer.status === CustomerStatus.READY}
        />
      ) : null}
    </>
  );
};

export default UpdateCustomer;
