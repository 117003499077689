import React, { JSX, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Box, Input, Switch } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter, FormGroup, FullPageLoader } from 'common/components';
import { config } from 'config/config';
import { useTranslations } from 'contexts/LocalizationContext';
import { useRestaurantList } from 'services/restaurants';
import * as yup from 'yup';

import FormInput from '../../common/components/FormInput/FormInput';
import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { RestaurantResponse } from '../Restaurants/models';
import { RestaurantFilterMode } from '../Restaurants/models/enums/restaurant-filter-mode.enum';
import { PartnerFormValues } from './partner.model';

type PartnersFormProps = {
  formData?: PartnerFormValues;
  isEditing?: boolean;
  isLoading?: boolean;
  onSubmit: (values: PartnerFormValues) => void;
};

const PartnersForm = ({ formData, isEditing = false, isLoading, onSubmit }: PartnersFormProps): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const validationSchema = yup.object().shape({
    partnerName: yup
      .string()
      .required(translations('validation_required'))
      .max(191, translations('validation_too_long')),
    apiKey: yup.string().max(191, translations('validation_too_long')),
    isEnabled: yup.bool().required(),
    restaurants: yup.array().of(
      yup.object().shape({
        id: yup.string().required(),
        restaurantName: yup.string().required(),
      }),
    ),
  });

  const { data: restaurants, isFetching } = useRestaurantList({
    isActive: true,
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: 1,
    filterMode: RestaurantFilterMode.DROP_DOWN_SELECT,
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  if (isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <FormInput
        id={'partnerName'}
        label={translations('partners_form_partner_name')}
        validationError={errors.partnerName?.message}
        useFormRegisterReturn={register('partnerName')}
        setValue={setValue}
      />

      {isEditing && (
        <FormGroup
          label={translations('partners_form_api_key')}
          inputId="apiKey"
          validationError={errors.apiKey?.message}
        >
          <Input {...register('apiKey')} />
        </FormGroup>
      )}
      <FormGroup label={translations('partners_form_enabled')} inputId="isEnabled">
        <Switch id="isEnabled" {...register('isEnabled')} />
      </FormGroup>
      <FormGroup
        label={translations('partners_form_restaurants')}
        inputId="restaurants"
        validationError={errors.restaurants?.message}
      >
        <Controller
          control={control}
          name="restaurants"
          render={({ field: { value, onChange, name } }) => (
            <Select
              inputId="restaurants"
              placeholder={translations('partners_form_restaurants_placeholder')}
              name={name}
              isMulti
              getOptionLabel={(option: RestaurantResponse) => option.restaurantName}
              getOptionValue={(option: RestaurantResponse) => option.id}
              value={value as RestaurantResponse[]}
              onChange={onChange}
              onInputChange={setSearchText}
              options={restaurants?.items}
              isLoading={isFetching}
              filterOption={null}
            />
          )}
        />
      </FormGroup>
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default PartnersForm;
