import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorMessages } from '../../common/components';
import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCreateMetaCategory } from '../../services/meta-categories';
import { MetaCategoryFormValues } from './model/MetaCategory';
import { getParsedData } from './utils/metaCategory.helper';
import MetaCategoriesForm from './MetaCategoriesForm';

const CreateMetaCategory = (): JSX.Element => {
  const { mutate: createMetaCategory, isLoading, error: createMetaCategoryError } = useCreateMetaCategory();
  const navigate = useNavigate();
  const translations = useTranslations();

  return (
    <>
      <ChildPageHeading>{translations('meta_categories_add_header')}</ChildPageHeading>
      <ErrorMessages errors={createMetaCategoryError} />
      <MetaCategoriesForm
        isLoading={isLoading}
        onSubmit={(values: MetaCategoryFormValues) => {
          createMetaCategory(
            { values: getParsedData(values) },
            {
              onSuccess: async () => navigate(Routes.META_CATEGORIES_PATH),
            },
          );
        }}
      />
    </>
  );
};

export default CreateMetaCategory;
