import { JSX, useState } from 'react';
import Select from 'react-select';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Box, HStack, Text, Tooltip, useTheme } from '@chakra-ui/react';

import { useExecuteWithConfirmation } from '../../common/utils';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useUpdateFeatureFlag } from '../../services/feature-flags';

export enum FeatureFlagsIsEnabled {
  ENABLED = 1,
  DISABLED = 0,
}

export enum FeatureFlagsIsEnabledNames {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export type IsEnabledSelect = {
  label: FeatureFlagsIsEnabledNames;
  value: FeatureFlagsIsEnabled;
};

interface FeatureFlagsIsEnabledUpdateProps {
  id: string;
  isEnabled: boolean;
}

export const isEnabledSelectOptions: IsEnabledSelect[] = [
  { label: FeatureFlagsIsEnabledNames.ENABLED, value: FeatureFlagsIsEnabled.ENABLED },
  { label: FeatureFlagsIsEnabledNames.DISABLED, value: FeatureFlagsIsEnabled.DISABLED },
];

// TODO: maybe create generic module for this?
export const FeatureFlagsIsEnabledUpdate = ({ id, isEnabled }: FeatureFlagsIsEnabledUpdateProps): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedIsEnabled, setSelectedIsEnabled] = useState<IsEnabledSelect | null>(null);

  const { mutate: updateFeatureFlagIsEnabled } = useUpdateFeatureFlag();
  const handleEditMode = () => setIsEditMode(!isEditMode);

  const handleUpdateIsEnabled = () => {
    if (selectedIsEnabled) {
      updateFeatureFlagIsEnabled({
        id,
        updateFeatureFlagRequest: {
          isEnabled: !!selectedIsEnabled?.value,
        },
      });
    }
    handleEditMode();
  };

  const { executeWithConfirmation: onChangeFeatureFlagIsEnabledConfirm } = useExecuteWithConfirmation(
    translations('feature_flags_update_is_enabled_confirmation'),
    handleUpdateIsEnabled,
  );

  const isEnabledValueMapper = {
    [FeatureFlagsIsEnabled.ENABLED]: FeatureFlagsIsEnabledNames.ENABLED,
    [FeatureFlagsIsEnabled.DISABLED]: FeatureFlagsIsEnabledNames.DISABLED,
  };

  return (
    <>
      {isEditMode ? (
        <HStack>
          <Box width="150px">
            <Select
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
              defaultValue={isEnabledSelectOptions.find(
                (option) =>
                  option.value === (isEnabled ? FeatureFlagsIsEnabled.ENABLED : FeatureFlagsIsEnabled.DISABLED),
              )}
              getOptionLabel={(option: IsEnabledSelect) => option.label}
              onChange={setSelectedIsEnabled}
              options={isEnabledSelectOptions}
            />
          </Box>
          <Tooltip label={translations('save')}>
            <CheckIcon
              cursor="pointer"
              color={colors.green[500]}
              onClick={selectedIsEnabled ? onChangeFeatureFlagIsEnabledConfirm : handleEditMode}
              ml={5}
            />
          </Tooltip>
          <Tooltip label={translations('cancel')}>
            <CloseIcon cursor="pointer" color={colors.red[500]} onClick={handleEditMode} ml={5} w={3} />
          </Tooltip>
        </HStack>
      ) : (
        <HStack justifyContent="center">
          <Text>
            {isEnabledValueMapper[isEnabled ? FeatureFlagsIsEnabled.ENABLED : FeatureFlagsIsEnabled.DISABLED]}
          </Text>
          <Tooltip label={translations('edit')}>
            <EditIcon cursor="pointer" onClick={handleEditMode} ml={5} />
          </Tooltip>
        </HStack>
      )}
    </>
  );
};
