import { Currency } from '../../../common/models/enums/currency.enum';
import { LanguagesConfig, MetaCategory } from '../../MetaCategories/model/MetaCategory';

export enum CategoryGroup {
  CUPS = 'CUPS',
  BOWLS = 'BOWLS',
}

type BrandedType<T, BrandId> = T & { __type: BrandId };
export type Money = BrandedType<string, 'Money'>;

export type Amount = {
  [key in Currency]: Money;
};

export interface Category {
  id: string;
  translations: LanguagesConfig;
  notReturnFee: Amount;
  extensionFee: Amount;
  categoryGroup: CategoryGroup | null;
  borrowingPeriod: number;
  extensionPeriod: number;
  metaCategory: MetaCategory | null;
  image?: string;
}

export interface CategoryRequest {
  translations: LanguagesConfig;
  notReturnFee: Amount;
  extensionFee: Amount;
  categoryGroup: CategoryGroup | null;
  borrowingPeriod: number;
  extensionPeriod: number;
  metaCategoryId: string;
  image?: File;
}
