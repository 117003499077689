import { Currency } from '../../../common/models/enums/currency.enum';
import { KeyAccountGroupsResponse } from '../../KeyAccountGroups/models/key-account-groups-response';
import { RestaurantResponse } from '../../Restaurants/models';
import {
  REUSE_CARD_DEFAULT_BORROWINGS_LIMIT,
  REUSE_CARD_DEFAULT_CURRENCY,
  REUSE_CARD_DEFAULT_MONETARY_VALUE,
} from '../utils/reuse-cards.consts';

export interface CreateReuseCardUsersFormModel {
  batchSize: number;
  monetaryValue: number;
  currency: Currency;
  borrowingsLimit: number;
  activationRestaurants: RestaurantResponse[];
  activationFnbGroups: KeyAccountGroupsResponse[];
}

export const createReuseCardUsersFormDefaultValues: CreateReuseCardUsersFormModel = {
  batchSize: 1,
  monetaryValue: REUSE_CARD_DEFAULT_MONETARY_VALUE,
  currency: REUSE_CARD_DEFAULT_CURRENCY,
  borrowingsLimit: REUSE_CARD_DEFAULT_BORROWINGS_LIMIT,
  activationRestaurants: [],
  activationFnbGroups: [],
};
