import React, { ComponentProps, JSX, useState } from 'react';
import OptionTypeBase from 'react-select';

import { Select } from '../../common/components/Select/Select';
import { useDebounce } from '../../common/hooks/useDebounce';
import { config } from '../../config/config';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useLocationList } from '../../services/locations';
import { RestaurantResponse } from '../Restaurants/models';

type RestaurantSelectProps = Omit<
  ComponentProps<typeof Select>,
  'options' | 'isLoading' | 'getOptionLabel' | 'getOptionValue' | 'placeholder' | 'onChange'
> & {
  onChange: (v: unknown | OptionTypeBase) => void;
};

export const RestaurantSelect = ({ name, value, onChange, isDisabled }: RestaurantSelectProps): JSX.Element => {
  const [restaurantSearchText, setRestaurantSearchText] = useState('');
  const debouncedSearch = useDebounce(restaurantSearchText, DEBOUNCE_SEARCH_TIME);
  const { data: searchedRestaurants, isLoading: isLoadingRestaurants } = useLocationList({
    limit: config.paginationSize,
    offset: 1,
    searchText: debouncedSearch,
  });
  const translations = useTranslations();
  return (
    <Select<RestaurantResponse>
      name={name}
      placeholder={translations('product_assignment_search_restaurant_placeholder')}
      getOptionLabel={(option) =>
        `${(option as RestaurantResponse).id} - ${(option as RestaurantResponse).restaurantName}`
      }
      getOptionValue={(option) => (option as RestaurantResponse).id}
      value={value}
      onChange={onChange}
      onInputChange={(searchText) => setRestaurantSearchText(searchText)}
      options={searchedRestaurants?.items}
      isLoading={isLoadingRestaurants}
      isDisabled={isDisabled}
    />
  );
};
