import { AxiosError } from 'axios';

import { useToast } from '../../../../common/components';
import { HttpException } from '../../../../common/models/httpException';
import { downloadFile } from '../../../../common/utils/files.helper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { CSV_REUSE_CARD_USERS_FILENAME } from '../reuse-cards.consts';

export const useCreateReuseCardsResult = () => {
  const translations = useTranslations();
  const { displayToast } = useToast();

  const handleError = (error: AxiosError<HttpException>) => {
    displayToast('error', error.response?.data.message || translations('something_went_wrong'));
  };
  const handleSuccess = (data: string, batchSize: number) => {
    downloadFile(new Blob([data]), CSV_REUSE_CARD_USERS_FILENAME);
    const csvRows = data.split('\n');
    const createdUsersNumber = csvRows.length - 1;
    if (createdUsersNumber === Number(batchSize)) {
      displayToast(
        'success',
        translations('reuse_cards_activation_success', { '{{users-number}}': createdUsersNumber.toString() }),
      );
    } else {
      displayToast(
        'warning',
        translations('reuse_cards_activation_warning', {
          '{{users-number}}': createdUsersNumber.toString(),
          '{{batch-size}}': Number(batchSize).toString(),
        }),
      );
    }
  };

  return {
    handleSuccess,
    handleError,
  };
};
