import React, { ChangeEvent, JSX, useRef, useState } from 'react';
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import { Box, Flex, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { CustomersFilterProperties } from '../../../api/customers';
import { getFormattedDate } from '../../../common/utils';
import { DATE_PICKER_LOCALIZATION_CONFIG } from '../../../config/configConsts';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { AvailableOrderFields } from './CustomersTable';

interface CustomersFilterProps {
  onChange: (propertyName: AvailableOrderFields, propertyValue?: string) => void;
  onDatePickerChange: (propertyName: 'startDate' | 'endDate', value: string) => void;
}

interface StatusListProps {
  label: CustomerFilterStatus;
  value: CustomerFilterStatus;
}

export enum CustomerFilterStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  READY = 'READY',
  ALL = 'ALL',
}

const statusList: StatusListProps[] = [
  {
    label: CustomerFilterStatus.NEW,
    value: CustomerFilterStatus.NEW,
  },
  {
    label: CustomerFilterStatus.APPROVED,
    value: CustomerFilterStatus.APPROVED,
  },
  {
    label: CustomerFilterStatus.READY,
    value: CustomerFilterStatus.READY,
  },
  {
    label: CustomerFilterStatus.ALL,
    value: CustomerFilterStatus.ALL,
  },
];

export const CustomersFilter = ({ onChange, onDatePickerChange }: CustomersFilterProps): JSX.Element => {
  const translations = useTranslations();

  const radioConfig: { value: CustomersFilterProperties; label: string }[] = [
    { value: CustomersFilterProperties.STATUS, label: translations('customers_status') },
    { value: CustomersFilterProperties.SALES_ORGANISATION, label: translations('customers_sales_organization') },
    { value: CustomersFilterProperties.SALES_PERSON_NAME, label: translations('customers_sales_person_name') },
    { value: CustomersFilterProperties.CUSTOMER_NAME, label: translations('customers_customer_name') },
    { value: CustomersFilterProperties.BILLING_ADDRESS_CITY, label: translations('customers_city') },
    { value: CustomersFilterProperties.DESIRED_START_DATE, label: translations('customers_desired_start_date') },
  ];
  const [radioValue, setRadioValue] = useState<CustomersFilterProperties>(radioConfig[0].value);
  const [startDateRange, setStartDateRange] = useState(null);
  const [endDateRange, setEndDateRange] = useState(null);

  const textInputRef = useRef<HTMLInputElement>(null);

  const handleRadioGroup = (value: CustomersFilterProperties) => {
    setRadioValue(value);
    onChange(value, '');
    if (textInputRef?.current) {
      textInputRef.current.value = '';
    }
    setStartDateRange(null);
    setEndDateRange(null);
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => onChange(radioValue, e.target.value);

  const handleSelect = (value: StatusListProps | null) => onChange(radioValue, value?.label);

  const handleStartDatePicker = (v: any) => {
    setStartDateRange(v);
    if (!v) {
      onDatePickerChange('startDate', v);
      return;
    }
    onDatePickerChange('startDate', getFormattedDate(v?.toISOString()));
  };

  const handleEndDatePicker = (v: any) => {
    setEndDateRange(v);
    if (!v) {
      onDatePickerChange('endDate', v);
      return;
    }
    onDatePickerChange('endDate', getFormattedDate(v?.toISOString()));
  };

  const radioValueMapper: Record<CustomersFilterProperties, JSX.Element> = {
    [CustomersFilterProperties.STATUS]: (
      <Box width="300px">
        <Select
          defaultValue={statusList.find((item) => item.value === CustomerFilterStatus.NEW)}
          id="status-name-select"
          placeholder={translations('customers_filter_status_placeholder')}
          getOptionLabel={(option: StatusListProps) => option.label}
          getOptionValue={(option: StatusListProps) => option.value}
          onChange={handleSelect}
          options={statusList}
        />
      </Box>
    ),
    [CustomersFilterProperties.SALES_ORGANISATION]: (
      <Input
        ref={textInputRef}
        onChange={handleInput}
        placeholder={translations('customers_filter_customer_sales_organisation_placeholder')}
      />
    ),
    [CustomersFilterProperties.SALES_PERSON_NAME]: (
      <Input
        ref={textInputRef}
        onChange={handleInput}
        placeholder={translations('customers_filter_customer_sales_person_name_placeholder')}
      />
    ),
    [CustomersFilterProperties.CUSTOMER_NAME]: (
      <Input
        ref={textInputRef}
        onChange={handleInput}
        placeholder={translations('customers_filter_customer_name_placeholder')}
      />
    ),
    [CustomersFilterProperties.BILLING_ADDRESS_CITY]: (
      <Input
        ref={textInputRef}
        onChange={handleInput}
        placeholder={translations('customers_filter_city_placeholder')}
      />
    ),
    [CustomersFilterProperties.DESIRED_START_DATE]: (
      <>
        <DatePicker
          locale={DATE_PICKER_LOCALIZATION_CONFIG.locale}
          format={DATE_PICKER_LOCALIZATION_CONFIG.dateFormat}
          value={startDateRange}
          onChange={handleStartDatePicker}
        />
        <DatePicker
          locale={DATE_PICKER_LOCALIZATION_CONFIG.locale}
          format={DATE_PICKER_LOCALIZATION_CONFIG.dateFormat}
          value={endDateRange}
          onChange={handleEndDatePicker}
        />
      </>
    ),
  };

  return (
    <>
      <Flex direction="column" w="50%" minWidth="1000px">
        <Box mb={2}>
          <RadioGroup onChange={handleRadioGroup} value={radioValue}>
            <Stack direction="row">
              {radioConfig.map(({ label, value }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Box>
        <Box p={1} h={12} w="50%" minWidth="500px" display="flex">
          {radioValueMapper[radioValue]}
        </Box>
      </Flex>
    </>
  );
};
