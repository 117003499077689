import React, { JSX, useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, Button, Center, Flex, Image, Spinner, useTheme } from '@chakra-ui/react';

import download_icon from '../../../assets/icons/download.svg';
import { useToast } from '../../../common/components';
import { useLocationsSetState } from '../../../common/hooks/use-locations-set-state.hook';
import { config } from '../../../config/config';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useKeyAccountGroupsList } from '../../../services/key-account-groups';
import { useReportsStatistics } from '../../../services/reports';
import { useRestaurantList } from '../../../services/restaurants';
import { Category } from '../../Categories/model/Category.model';
import { KeyAccountGroupsResponse } from '../../KeyAccountGroups/models/key-account-groups-response';
import { RestaurantResponse } from '../../Restaurants/models';
import { RestaurantFilterMode } from '../../Restaurants/models/enums/restaurant-filter-mode.enum';
import { INDIVIDUAL_KEY_ACCOUNT_ID } from '../../Restaurants/restaurant.consts';
import ListView from '../ListView/ListView';
import { ReportsStatisticsResponse } from '../model/ReportsStatisticsResponse';
import { KEY_ACCOUNT_GROUP_SELECT_LIMIT, RESTAURANT_SELECT_LIMIT } from '../report.consts';
import { useFilterSetState } from './hooks/use-filter-set-state.hook';
import DateRange from './DateRange';
import ProductCategories from './ProductCategories';
import ReportsLabel from './ReportsLabel';

type FiltersProps = {
  productCategories: Category[];
};

const FilteredResults = ({ productCategories }: FiltersProps): JSX.Element => {
  const translations = useTranslations();

  const [restaurantsSelected, setRestaurantsSelected] = useState<RestaurantResponse[]>([]);
  const [keyAccountGroupsSelected, setKeyAccountGroupsSelected] = useState<KeyAccountGroupsResponse[]>([]);

  const [timeframeInvalid, setTimeFrameInvalid] = useState(false);
  const { mutate: triggerResultSetStatistics, isLoading: isResultSetLoading } = useReportsStatistics();
  const [resultSet, setResultSet] = useState([] as ReportsStatisticsResponse[]);
  const { displayToast } = useToast();
  const { colors } = useTheme();

  const validateTimeframe = (startDate: string, endDate: string) => {
    if (startDate > endDate) {
      setTimeFrameInvalid(startDate > endDate);
      displayToast('error', translations('reports_timeframe_invalid_error'));
    }
  };

  const {
    setRestaurantSearchText,
    debouncedRestaurantSearch,
    setKeyAccountGroupSearchText,
    debouncedKeyAccountGroupSearch,
  } = useLocationsSetState();

  const { data: restaurants, isFetching: isFetchingRestaurants } = useRestaurantList({
    isActive: true,
    searchText: debouncedRestaurantSearch,
    limit: config.paginationSize,
    offset: 1,
    filterMode: RestaurantFilterMode.DROP_DOWN_SELECT,
  });

  const { data: keyAccountGroups, isFetching: isFetchingKeyAccountGroups } = useKeyAccountGroupsList({
    limit: config.paginationSize,
    searchText: debouncedKeyAccountGroupSearch,
    offset: 1,
  });

  const filteredKeyAccountGroups = keyAccountGroups?.items.filter(
    (keyAccountGroup) => keyAccountGroup.id !== INDIVIDUAL_KEY_ACCOUNT_ID,
  );

  const {
    startDate: resultSetStartDate,
    setStartDate: setResultSetStartDate,
    endDate: resultSetEndDate,
    setEndDate: setResultSetEndDate,
    categoryIds: resultSetCategoryIds,
    setCategoryIds: setResultSetCategoryIds,
    handleCategoryIdsChange: handleResultSetCategoryIdsChange,
  } = useFilterSetState(productCategories);

  useEffect(() => {
    setTimeFrameInvalid(false);
    if (resultSetStartDate && resultSetEndDate) {
      validateTimeframe(resultSetStartDate, resultSetEndDate);
    }
    // eslint-disable-next-line
  }, [resultSetStartDate, resultSetEndDate]);

  const handleRestaurantsSelectedChange = (option: readonly RestaurantResponse[]) => {
    if (option.length > RESTAURANT_SELECT_LIMIT) {
      displayToast(
        'error',
        translations('reports_restaurant_limit_error', { '{{limit}}': RESTAURANT_SELECT_LIMIT.toString() }),
      );
      return;
    }
    const newOptions = [...option];
    setRestaurantsSelected(newOptions);
  };

  const handleKeyAccountGroupsSelectedChange = (option: readonly KeyAccountGroupsResponse[]) => {
    if (option.length > KEY_ACCOUNT_GROUP_SELECT_LIMIT) {
      displayToast(
        'error',
        translations('reports_key_account_group_limit_error', {
          '{{limit}}': KEY_ACCOUNT_GROUP_SELECT_LIMIT.toString(),
        }),
      );
      return;
    }
    const newOptions = [...option];
    setKeyAccountGroupsSelected(newOptions);
  };

  const clearFilters = () => {
    setRestaurantsSelected([]);
    setKeyAccountGroupsSelected([]);
    setResultSetStartDate('');
    setResultSetEndDate('');
    setResultSetCategoryIds(productCategories.map((category) => category.id));
  };

  const handleCompareClick = async () => {
    triggerResultSetStatistics(
      {
        restaurantIds: restaurantsSelected.map((restaurant) => restaurant.id),
        keyAccountGroupIds: keyAccountGroupsSelected.map((keyAccountGroup) => keyAccountGroup.id),
        categoryIds: resultSetCategoryIds,
        startDate: resultSetStartDate,
        endDate: resultSetEndDate,
      },
      {
        onSuccess: (data) => {
          setResultSet(data);
        },
      },
    );
  };

  const isCompareDisabled =
    timeframeInvalid ||
    (!restaurantsSelected.length && !keyAccountGroupsSelected.length) ||
    !resultSetStartDate ||
    !resultSetEndDate;

  return (
    <>
      <Flex justifyContent="flex-end">
        <Button width="20%" sx={{ '@media print': { display: 'none' } }} onClick={clearFilters}>
          {translations('reports_clear_filters')}
        </Button>
      </Flex>
      <ReportsLabel label={translations('reports_restaurants_label')} />
      <Select
        inputId="restaurants"
        placeholder={translations('restaurants_placeholder')}
        isMulti
        getOptionLabel={(option: RestaurantResponse) => option.restaurantName}
        getOptionValue={(option: RestaurantResponse) => option.id}
        value={restaurantsSelected}
        onChange={handleRestaurantsSelectedChange}
        onInputChange={setRestaurantSearchText}
        options={restaurants?.items}
        isLoading={isFetchingRestaurants}
        filterOption={null}
      />
      <Select
        inputId="keyAccountGroups"
        placeholder={translations('key_account_groups_placeholder')}
        isMulti
        getOptionLabel={(option: KeyAccountGroupsResponse) => option.keyAccount}
        getOptionValue={(option: KeyAccountGroupsResponse) => option.id}
        value={keyAccountGroupsSelected}
        onChange={handleKeyAccountGroupsSelectedChange}
        onInputChange={setKeyAccountGroupSearchText}
        options={filteredKeyAccountGroups}
        isLoading={isFetchingKeyAccountGroups}
        filterOption={null}
      />
      <span style={{ fontSize: 12, marginTop: 2, textAlign: 'left', color: colors.grey[200] }}>
        {translations('reports_locations_limit_hint')}
      </span>
      <DateRange
        startDate={resultSetStartDate}
        endDate={resultSetEndDate}
        onStartDateChange={setResultSetStartDate}
        onEndDateChange={setResultSetEndDate}
      />
      <ReportsLabel label={translations('reports_product_categories_label')} />
      <ProductCategories
        categories={productCategories}
        onChange={handleResultSetCategoryIdsChange}
        categoryIds={resultSetCategoryIds}
      />
      <Flex justifyContent="flex-end" pt={4}>
        <Button
          bg={colors.orange[500]}
          width="20%"
          sx={{ '@media print': { display: 'none' } }}
          isDisabled={isCompareDisabled}
          onClick={handleCompareClick}
        >
          {translations('reports_clear_compare')}
        </Button>
      </Flex>

      {isResultSetLoading && (
        <Center>
          <Spinner />
        </Center>
      )}

      {resultSet.length > 0 && !isResultSetLoading && (
        <>
          <Box pt={4} sx={{ '@media print': { display: 'none' } }}>
            <Flex pt={4}>
              <Box width="50%">
                <h4 style={{ fontSize: '20px', textAlign: 'left' }}>{translations('reports_result_heading')}</h4>
              </Box>
              <Box width="50%" textAlign="right">
                <Button backgroundColor="transparent" color={colors.orange[500]} onClick={() => window.print()}>
                  <Image width="auto" height="auto" src={download_icon} mr="2.5" />
                  {translations('download_report')}
                </Button>
              </Box>
            </Flex>
          </Box>
          <ListView data={resultSet} />
        </>
      )}
    </>
  );
};

export default FilteredResults;
