import React, { useCallback, useMemo, useState } from 'react';
import ReactDomServer from 'react-dom/server';
import { generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Box, Button, Center, Checkbox, Flex, Image, Text, Tooltip, useTheme } from '@chakra-ui/react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import qrCodeIcon from '../../../../../assets/icons/qr-code.png';
import { LinkButton, Table } from '../../../../../common/components';
import TableHeaderWithOrder from '../../../../../common/components/Table/TableHeaderWithOrder/TableHeaderWithOrder';
import { formatDate } from '../../../../../common/utils';
import { config } from '../../../../../config/config';
import { Routes } from '../../../../../config/routes';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useHtmlTranslations } from '../../../../../contexts/LocalizationContext/hooks';
import { useKeyAccountGroupReturnCodes } from '../../../../../services/key-account-groups';
import { ReturnCodeStatusUpdate } from '../../../../Restaurants/RestaurantReturnCodes/components/ReturnCodeStatusUpdate/ReturnCodeStatusUpdate';
import { ReturnCodeOrderFields } from '../../../../Restaurants/RestaurantReturnCodes/models/enums/return-code-order-fields.enum';
import { RestaurantReturnCodeResponse } from '../../../../Restaurants/RestaurantReturnCodes/models/restaurant-return-code-response';
import { getReturnCodeTypeLabel } from '../../../../Restaurants/RestaurantReturnCodes/utils/return-codes.helper';
import PdfDocument from '../../../../Restaurants/ReturnPosters/PdfDocument';
import { getReturnCodePosterFileName } from '../../../../Restaurants/utils/return-codes.helper';
import { useReturnCodesFilterContext } from '../../context/HistoryFilterContext/hooks/use-return-codes-filter-context';
import { KeyAccountGroupReturnCodesQueryRequest } from '../../models/key-account-group-return-codes-query-request';

type KeyAccountGroupReturnCodesTableProps = {
  keyAccountGroupId: string;
};

export const KeyAccountGroupReturnCodesTable = ({ keyAccountGroupId }: KeyAccountGroupReturnCodesTableProps) => {
  const { colors } = useTheme();
  const translations = useTranslations();
  const htmlTranslations = useHtmlTranslations();
  const {
    returnCodesFilterState,
    handleOrderBy,
    selectAllReturnCodes,
    setSelectAllReturnCodes,
    selectedReturnCodesIds,
    handleClearSelection,
    getIsChecked,
    getIsSelectPageChecked,
    handleSelectCheckbox,
    handleSelectPageCheckbox,
    shouldShowSelectAllReturnCodes,
    setPaginatedItems,
  } = useReturnCodesFilterContext();
  const [page, setPage] = useState(1);

  const queryRequest: KeyAccountGroupReturnCodesQueryRequest = {
    offset: page,
    limit: config.paginationSize,
    orderField: returnCodesFilterState.orderField,
    orderType: returnCodesFilterState.orderType,
    status: returnCodesFilterState.status,
    restaurantIds: returnCodesFilterState.restaurantIds,
    createdAt: returnCodesFilterState.date,
  };

  const enabled = true;

  const {
    data: paginatedReturnCodes,
    isFetching: isFetchingReturnCodes,
    error: returnCodesError,
  } = useKeyAccountGroupReturnCodes(keyAccountGroupId, queryRequest, enabled, setPaginatedItems);

  const handleOrderReturnCodesBy = useCallback(
    (field: ReturnCodeOrderFields) => () => handleOrderBy(field),
    [handleOrderBy],
  );

  const handleDownloadReturnCodePoster = useCallback((original: RestaurantReturnCodeResponse) => {
    return async () => {
      const blob = await pdf(
        <PdfDocument
          returnCode={original}
          restaurant={{
            restaurantName: original.restaurant.restaurantName || '',
            metaCategories: original.restaurant.metaCategories ?? [],
          }}
        />,
      ).toBlob();
      saveAs(blob, getReturnCodePosterFileName(original.name));
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        id: '1',
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantReturnCodeResponse>) => (
          <Checkbox
            name={original.id}
            key={original.id}
            size="sm"
            colorScheme="orange"
            isChecked={getIsChecked(original)}
            onChange={handleSelectCheckbox(original.id)}
          />
        ),
        Header: (
          <Tooltip
            label={
              !getIsSelectPageChecked(paginatedReturnCodes?.items)
                ? translations('table_select_page')
                : translations('table_unselect_all')
            }
          >
            <Box>
              <Checkbox
                isDisabled={!paginatedReturnCodes?.items.length}
                name="page"
                key="page"
                size="sm"
                colorScheme="orange"
                isChecked={getIsSelectPageChecked(paginatedReturnCodes?.items)}
                onChange={handleSelectPageCheckbox}
              />
            </Box>
          </Tooltip>
        ),
      },
      {
        id: 'restaurantId',
        Header: (
          <TableHeaderWithOrder
            headerText={translations('restaurant_return_codes_table_restaurant_id')}
            showOrder={returnCodesFilterState.orderField === ReturnCodeOrderFields.RESTAURANT_ID}
            orderType={returnCodesFilterState.orderType}
            onClick={handleOrderReturnCodesBy(ReturnCodeOrderFields.RESTAURANT_ID)}
          />
        ),
        Cell: ({
          cell: {
            row: { original },
          }, // eslint-disable-next-line react/prop-types
        }: CellProps<RestaurantReturnCodeResponse>) => original.restaurant.id,
      },
      {
        id: 'restaurantName',
        Header: (
          <TableHeaderWithOrder
            headerText={translations('restaurant_return_codes_table_restaurant_name')}
            showOrder={returnCodesFilterState.orderField === ReturnCodeOrderFields.RESTAURANT_NAME}
            orderType={returnCodesFilterState.orderType}
            onClick={handleOrderReturnCodesBy(ReturnCodeOrderFields.RESTAURANT_NAME)}
          />
        ),
        Cell: ({
          cell: {
            row: { original },
          }, // eslint-disable-next-line react/prop-types
        }: CellProps<RestaurantReturnCodeResponse>) => original.restaurant.restaurantName,
      },
      {
        Header: (
          <TableHeaderWithOrder
            headerText={translations('restaurant_return_codes_table_name')}
            showOrder={returnCodesFilterState.orderField === ReturnCodeOrderFields.RETURN_CODE_NAME}
            orderType={returnCodesFilterState.orderType}
            onClick={handleOrderReturnCodesBy(ReturnCodeOrderFields.RETURN_CODE_NAME)}
          />
        ),
        accessor: 'name' as const,
      },
      {
        Header: (
          <TableHeaderWithOrder
            headerText={translations('restaurant_return_codes_table_status')}
            showOrder={returnCodesFilterState.orderField === ReturnCodeOrderFields.RETURN_CODE_STATUS}
            orderType={returnCodesFilterState.orderType}
            onClick={handleOrderReturnCodesBy(ReturnCodeOrderFields.RETURN_CODE_STATUS)}
          />
        ),
        accessor: 'status' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantReturnCodeResponse>) => (
          <ReturnCodeStatusUpdate status={original.status} id={original.id} restaurantId={original.restaurant.id} />
        ),
      },
      {
        Header: translations('restaurant_return_codes_table_type'),
        accessor: 'type' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantReturnCodeResponse>) => <Text>{getReturnCodeTypeLabel(original)}</Text>,
      },
      {
        Header: (
          <TableHeaderWithOrder
            headerText={translations('restaurant_return_codes_table_creation_date')}
            showOrder={returnCodesFilterState.orderField === ReturnCodeOrderFields.RETURN_CODE_CREATION_DATE}
            orderType={returnCodesFilterState.orderType}
            onClick={handleOrderReturnCodesBy(ReturnCodeOrderFields.RETURN_CODE_CREATION_DATE)}
          />
        ),
        accessor: 'recordCreatedAt' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantReturnCodeResponse>) => <>{formatDate(new Date(original.recordCreatedAt))}</>,
      },
      {
        Header: translations('restaurant_return_codes_table_qr_code'),
        accessor: 'qrCode' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantReturnCodeResponse>) => (
          <Flex justifyContent="center">
            <LinkButton
              data-testid="qr-code"
              p={1}
              to={generatePath(Routes.DOWNLOAD_RESTAURANT_RETURN_CODES_PATH, {
                restaurantId: original.restaurant.id,
                id: original.id,
              })}
            >
              <Image src={qrCodeIcon} alt="QR code icon" width={34} />
            </LinkButton>
            <Button ml={2} color={colors.orange[500]} onClick={handleDownloadReturnCodePoster(original)}>
              {translations('return_codes_download_poster')}
            </Button>
          </Flex>
        ),
      },
    ],
    [
      getIsSelectPageChecked,
      paginatedReturnCodes?.items,
      translations,
      handleSelectPageCheckbox,
      returnCodesFilterState.orderField,
      returnCodesFilterState.orderType,
      handleOrderReturnCodesBy,
      getIsChecked,
      handleSelectCheckbox,
      colors.orange,
      handleDownloadReturnCodePoster,
    ],
  );

  const handleSelectAllReturnCodes = () => setSelectAllReturnCodes(true);

  return (
    <Table<RestaurantReturnCodeResponse>
      data={paginatedReturnCodes?.items}
      columns={columns}
      page={page}
      onPageChanged={setPage}
      totalPages={paginatedReturnCodes?.totalPages}
      isLoading={isFetchingReturnCodes}
      searchEnabled={false}
      paginationEnabled
      errors={returnCodesError}
    >
      <Flex justifyContent="flex-end">
        {shouldShowSelectAllReturnCodes ? (
          <Center>
            <Text>
              {htmlTranslations('table_selected_return_codes', {
                '{{count}}': ReactDomServer.renderToString(<Text as="b">{paginatedReturnCodes?.items.length}</Text>),
              })}
            </Text>
            <Button ml={2} onClick={handleSelectAllReturnCodes}>
              {translations('table_select_all_return_codes')}
            </Button>
          </Center>
        ) : null}
        {selectAllReturnCodes &&
        paginatedReturnCodes?.items.map((item) => item.id).every((id) => selectedReturnCodesIds.includes(id)) ? (
          <Center>
            <Text>
              {htmlTranslations('table_all_return_codes_selected', {
                '{{count}}': ReactDomServer.renderToString(<Text as="b">{paginatedReturnCodes?.count}</Text>),
              })}
            </Text>
            <Button ml={2} onClick={handleClearSelection}>
              {translations('table_clear_selection')}
            </Button>
          </Center>
        ) : null}
      </Flex>
    </Table>
  );
};
