import { JSX, useState } from 'react';
import Select from 'react-select';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Box, HStack, Text, Tooltip, useTheme } from '@chakra-ui/react';

import { Role } from '../../../common/types/auth';
import { useExecuteWithConfirmation } from '../../../common/utils';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useSessionContext } from '../../../contexts/SessionContext';
import { useGetRestaurantProducts } from '../../../services/product-assignment';
import { useUpdateRestaurant } from '../../../services/restaurants';
import {
  RestaurantStatus,
  RestaurantStatusNames,
  StatusSelect,
  statusSelectOptions,
} from '../RestaurantForm/RestaurantForm.form';

interface RestaurantStatusUpdateProps {
  id: string;
  status?: RestaurantStatus;
}

export const RestaurantStatusUpdate = ({ id, status }: RestaurantStatusUpdateProps): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const { role } = useSessionContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<StatusSelect | null>(null);
  const { data: restaurantProducts } = useGetRestaurantProducts(
    selectedStatus?.value === RestaurantStatus.DELETED ? id : null,
    {
      limit: 0,
      offset: 1,
    },
  );
  const { mutate: updateRestaurantStatus } = useUpdateRestaurant();
  const handleEditMode = () => setIsEditMode(!isEditMode);

  const handleUpdateStatus = () => {
    if (selectedStatus) {
      updateRestaurantStatus({
        id,
        updateRestaurantRequest: {
          status: selectedStatus?.value,
        },
      });
    }
    handleEditMode();
  };

  const { executeWithConfirmation: onChangeRestaurantStatusConfirm } = useExecuteWithConfirmation(
    translations(
      restaurantProducts?.items.length
        ? 'restaurant_update_status_assign_items_confirmation'
        : 'restaurant_update_status_confirmation',
      {
        '{{items-number}}': restaurantProducts?.items.length.toString() || '',
      },
    ),
    handleUpdateStatus,
  );

  const restaurantStatusSelectOptions = (): StatusSelect[] =>
    role !== Role.ADMINISTRATOR
      ? statusSelectOptions.filter((option) => option.value !== RestaurantStatus.DELETED)
      : statusSelectOptions;

  const statusValueMapper = {
    [RestaurantStatus.LIVE_INVISIBLE]: RestaurantStatusNames.LIVE_INVISIBLE,
    [RestaurantStatus.LIVE_VISIBLE]: RestaurantStatusNames.LIVE_VISIBLE,
    [RestaurantStatus.COMING_SOON_INVISIBLE]: RestaurantStatusNames.COMING_SOON_INVISIBLE,
    [RestaurantStatus.COMING_SOON_VISIBLE]: RestaurantStatusNames.COMING_SOON_VISIBLE,
    [RestaurantStatus.LOST]: RestaurantStatusNames.LOST,
    [RestaurantStatus.DELETED]: RestaurantStatusNames.DELETED,
  };

  return (
    <>
      {isEditMode ? (
        <HStack>
          <Box width="150px">
            <Select
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
              defaultValue={restaurantStatusSelectOptions().find((option) => option.value === status)}
              getOptionLabel={(option: StatusSelect) => option.label}
              onChange={setSelectedStatus}
              options={restaurantStatusSelectOptions()}
            />
          </Box>
          <Tooltip label={translations('save')}>
            <CheckIcon
              cursor="pointer"
              color={colors.green[500]}
              onClick={selectedStatus ? onChangeRestaurantStatusConfirm : handleEditMode}
              ml={2}
            />
          </Tooltip>
          <Tooltip label={translations('cancel')}>
            <CloseIcon cursor="pointer" color={colors.red[500]} onClick={handleEditMode} ml={2} w={3} />
          </Tooltip>
        </HStack>
      ) : (
        <HStack justifyContent="center">
          <Text>{status ? statusValueMapper[status] : null}</Text>
          <Tooltip label={translations('edit')}>
            <EditIcon cursor="pointer" onClick={handleEditMode} ml={2} />
          </Tooltip>
        </HStack>
      )}
    </>
  );
};
