import React, { JSX, useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { CloseIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { Table } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { ProductResponse } from '../Products/Product.model';

type ProductAssignmentTableProps = {
  data: ProductResponse[];
  isLoading: boolean;
  onProductDelete: (uId: string | null) => void;
};
const ProductAssignmentTable = ({ data, isLoading, onProductDelete }: ProductAssignmentTableProps): JSX.Element => {
  const translations = useTranslations();

  const columns: Column<ProductResponse>[] = useMemo(
    () => [
      {
        id: '1',
        Header: translations('product_assignment_items_table_product_id_header'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ProductResponse>) => original.id,
      },
      {
        id: '2',
        Header: translations('product_assignment_items_table_product_name_header'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ProductResponse>) => original.productName,
      },
      {
        id: 'actions',
        Header: translations('product_assignment_items_table_actions_header'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ProductResponse>) => (
          <Button m={1} onClick={() => onProductDelete(original.uId)} data-testid="delete-button">
            <CloseIcon color="red.500" />
          </Button>
        ),
      },
    ],
    [translations, onProductDelete],
  );

  return <Table data={data} columns={columns} isLoading={isLoading} />;
};

export default ProductAssignmentTable;
