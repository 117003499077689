import React, { JSX, useState } from 'react';
import Select from 'react-select';
import { EditIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { useUpdateCustomer } from '../../../services/customers';
import { statusList, StatusListProps } from './CustomerForm/CustomerForm';

interface CustomStatusTableFieldProps {
  status: string;
  id: string;
}
export const CustomStatusTableField = ({ status, id }: CustomStatusTableFieldProps): JSX.Element => {
  const translations = useTranslations();
  const [isEditingMode, setIsEditingMode] = useState(false);

  const { mutate: updateCustomer } = useUpdateCustomer();
  const handleSelect = (value: StatusListProps | null) => {
    updateCustomer({
      id,
      updateCustomerRequest: {
        status: value?.label,
      },
    });
  };

  const handleEditingMode = () => () => setIsEditingMode(!isEditingMode);

  return (
    <>
      {isEditingMode ? (
        <>
          <Select
            defaultValue={statusList.find((item) => item.value === status)}
            id="status-name-select"
            getOptionLabel={(option: StatusListProps) => option.label}
            getOptionValue={(option: StatusListProps) => option.label}
            onChange={handleSelect}
            options={statusList}
          />
          <Box onClick={handleEditingMode()} fontSize="12px" cursor="pointer">
            {translations('customer_table_close_button')}
          </Box>
        </>
      ) : (
        <Box display="flex" justifyContent="center">
          <span>{status}</span>
          <EditIcon onClick={handleEditingMode()} ml={2} />
        </Box>
      )}
    </>
  );
};
