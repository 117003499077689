import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../../../../common/constants/common';
import { BaseQueryParams } from '../../../../common/models';
import { useCitiesList } from '../../../../services/cities';
import { useClosingSourceList } from '../../../../services/closing-sources';
import { useKeyAccountGroupsList } from '../../../../services/key-account-groups';
import { useMetaCategoryList } from '../../../../services/meta-categories';
import { usePromotionsList } from '../../../../services/promotions';
import { useTagsList } from '../../../../services/tags';
import { City } from '../../../City/models/City';
import { ClosingSourceResponse } from '../../../ClosingSources/model/ClosingSource';
import { KeyAccountGroupsResponse } from '../../../KeyAccountGroups/models/key-account-groups-response';
import { MetaCategory } from '../../../MetaCategories/model/MetaCategory';
import { Promotion } from '../../../Promotions/models/Promotion';
import { TagResponse } from '../../../Tags/models/tag-response';

export const useRestaurantCharacteristicsLists = (
  queryParams?: BaseQueryParams,
): {
  cities?: City[];
  isCityListLoading?: boolean;
  keyAccounts?: KeyAccountGroupsResponse[];
  isKeyAccountGroupListLoading?: boolean;
  tags?: TagResponse[];
  isTagListLoading?: boolean;
  metaCategories?: MetaCategory[];
  isMetaCategoryListLoading?: boolean;
  closingSources?: ClosingSourceResponse[];
  isClosingSourceListLoading?: boolean;
  promotions?: Promotion[];
  isPromotionListLoading?: boolean;
} => {
  const { data: cities, isLoading: isCityListLoading } = useCitiesList(
    queryParams ?? DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
  );
  const { data: keyAccounts, isLoading: isKeyAccountGroupListLoading } = useKeyAccountGroupsList(
    queryParams ?? DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
  );
  const { data: tags, isLoading: isTagListLoading } = useTagsList(queryParams ?? DEFAULT_PAGINATED_LIST_ITEMS_PARAMS);
  const { data: metaCategories, isLoading: isMetaCategoryListLoading } = useMetaCategoryList(
    queryParams ?? DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
  );
  const { data: closingSources, isLoading: isClosingSourceListLoading } = useClosingSourceList(
    queryParams ?? DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
  );
  const { data: promotions, isLoading: isPromotionListLoading } = usePromotionsList(
    queryParams ?? DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
  );
  return {
    cities: cities?.items,
    isCityListLoading,
    keyAccounts: keyAccounts?.items,
    isKeyAccountGroupListLoading,
    tags: tags?.items,
    isTagListLoading,
    metaCategories: metaCategories?.items,
    isMetaCategoryListLoading,
    closingSources: closingSources?.items,
    isClosingSourceListLoading: isClosingSourceListLoading,
    promotions: promotions?.items,
    isPromotionListLoading,
  };
};
