import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios/index';

import {
  getRestaurantTwoFactorReturnSettings,
  updateRestaurantTwoFactorReturnSettings,
} from '../api/two-factor-return';
import { useToast } from '../common/components';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { RestaurantTwoFactorReturnSettingsResponse } from '../modules/Restaurants/TwoFactorReturn/models/restaurant-two-factor-return-settings-response';
import { UpdateRestaurantTwoFactorReturnSettingsRequest } from '../modules/Restaurants/TwoFactorReturn/models/update-restaurant-two-factor-return-settings-request';

const RESTAURANT_TWO_FACTOR_RETURN_QUERY_KEY = 'restaurantTwoFactorReturn';

const getRestaurantTwoFactorReturnSettingsQueryKey = (restaurantId: string) => [
  RESTAURANT_TWO_FACTOR_RETURN_QUERY_KEY,
  restaurantId,
];

export const useRestaurantTwoFactorReturnSettings = (
  restaurantId: string,
): QueryObserverResult<RestaurantTwoFactorReturnSettingsResponse, AxiosError<HttpException>> => {
  return useQuery(getRestaurantTwoFactorReturnSettingsQueryKey(restaurantId), () =>
    getRestaurantTwoFactorReturnSettings(restaurantId),
  );
};

export const useUpdateRestaurantTwoFactorReturnSettings = (): UseMutationResult<
  RestaurantTwoFactorReturnSettingsResponse,
  AxiosError<HttpException>,
  { id: string; updateRestaurantTwoFactorReturnSettingsRequest: UpdateRestaurantTwoFactorReturnSettingsRequest }
> => {
  const queryClient = useQueryClient();
  const translations = useTranslations();
  const { displayToast } = useToast();

  return useMutation(
    ({
      id,
      updateRestaurantTwoFactorReturnSettingsRequest,
    }: {
      id: string;
      updateRestaurantTwoFactorReturnSettingsRequest: UpdateRestaurantTwoFactorReturnSettingsRequest;
    }) => updateRestaurantTwoFactorReturnSettings(id, updateRestaurantTwoFactorReturnSettingsRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(RESTAURANT_TWO_FACTOR_RETURN_QUERY_KEY);
      },
      onError: () => displayToast('error', translations('something_went_wrong')),
    },
  );
};
