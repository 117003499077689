import React, { JSX } from 'react';
import { useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import { Modal, useToast } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { ImageForm } from '../../../common/components/Images/ImageForm';
import { Routes } from '../../../config/routes';
import { useModal } from '../../../contexts/ModalContext';
import { ModalVariant } from '../../../contexts/ModalContext/hook';
import { CK_KEY_ACCOUNT_GROUP_IMAGES, useAddKeyAccountGroupImage } from '../../../services/key-account-groups';

type AddKeyAccountGroupImageModalProps = {
  keyAccountId: string;
};

export const AddKeyAccountGroupImage = ({ keyAccountId }: AddKeyAccountGroupImageModalProps): JSX.Element => {
  const translations = useTranslations();
  const { modalState, handleToggleModal } = useModal();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { displayToast } = useToast();

  const { mutate: addKeyAccountGroupImage, isLoading } = useAddKeyAccountGroupImage({
    onSuccessfulAssign: async (data) => {
      if (data) {
        displayToast('success', translations('image_modal_success'), false, 5000);
        await queryClient.invalidateQueries(CK_KEY_ACCOUNT_GROUP_IMAGES);
        navigate(generatePath(Routes.KEY_ACCOUNT_GROUP_IMAGES_PATH, { keyAccountId }));
      }
    },
  });

  const handleFormSubmit = async (data: any) => {
    const keyAccountGroupImageRequest = { file: data.file[0] };
    addKeyAccountGroupImage({ keyAccountId, addKeyAccountGroupImageRequest: keyAccountGroupImageRequest });
  };

  return (
    <>
      <Modal
        isModalVisible={modalState?.modalType === ModalVariant.DEFAULT}
        onModalClose={handleToggleModal(null)}
        modalHeader={translations('images_modal_header')}
        customModalFooter={null}
      >
        <ImageForm isLoading={isLoading} handleFormSubmit={handleFormSubmit} onModalClose={handleToggleModal(null)} />
      </Modal>
    </>
  );
};
