import * as yup from 'yup';

import { useTranslations } from '../../../../../contexts/LocalizationContext';
import {
  TWO_FACTOR_RETURN_COMPLETION_DAYS_AMOUNT_MAX_VALUE,
  TWO_FACTOR_RETURN_COMPLETION_DAYS_AMOUNT_MIN_VALUE,
} from '../../utils/two-factor-return.consts';

export const useTwoFactorReturnSettingsFormValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    twoFactorReturnCompletionDaysAmount: yup
      .number()
      .min(
        TWO_FACTOR_RETURN_COMPLETION_DAYS_AMOUNT_MIN_VALUE,
        translations('validation_min_value', {
          '{{min_value}}': TWO_FACTOR_RETURN_COMPLETION_DAYS_AMOUNT_MIN_VALUE.toString(),
        }),
      )
      .max(
        TWO_FACTOR_RETURN_COMPLETION_DAYS_AMOUNT_MAX_VALUE,
        translations('validation_max_value', {
          '{{max_value}}': TWO_FACTOR_RETURN_COMPLETION_DAYS_AMOUNT_MAX_VALUE.toString(),
        }),
      )
      .typeError(translations('validation_number_type_error'))
      .required(),
  });
};
