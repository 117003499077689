import { Fragment } from 'react';
import { Box, Text } from '@chakra-ui/react';

import { useLocalizationContext } from '../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useMetaCategoryList } from '../../../../../services/meta-categories';
import colors from '../../../../../styles/colors';
import { Diff } from './Diff';
import { DiffProps } from './types';

export const MetaCategoriesDiff = ({ before, after, showDiff, isChanged }: DiffProps<string[]>) => {
  const { language } = useLocalizationContext();
  const { data: metaCategories } = useMetaCategoryList();

  if (!metaCategories) {
    return <></>;
  }

  return (
    <Diff
      isChanged={isChanged}
      showDiff={showDiff}
      before={
        <Box float="right" textAlign="right">
          {(before ?? [])
            .map((id) => metaCategories.items.find((category) => category.id === id))
            .map((metaCategory, index) => {
              const hasBeenRemoved = !(after ?? []).includes(metaCategory?.id ?? '');
              return (
                <Fragment key={metaCategory?.id}>
                  <Text
                    as="span"
                    display="inline"
                    opacity={hasBeenRemoved ? 0.3 : 1}
                    textDecorationLine={hasBeenRemoved ? 'line-through' : 'none'}
                  >
                    {metaCategory?.translations[language]}
                  </Text>
                  <Text as="span" display="inline">
                    {index < (before ?? []).length - 1 ? ', ' : ''}
                  </Text>
                </Fragment>
              );
            })}
        </Box>
      }
      after={
        <Box float="left" textAlign="left">
          {(after ?? [])
            .map((id) => metaCategories.items.find((category) => category.id === id))
            .map((metaCategory, index) => {
              const hasBeenAdded = !(before ?? []).includes(metaCategory?.id ?? '');
              return (
                <Fragment key={metaCategory?.id}>
                  <Text as="span" display="inline" background={hasBeenAdded && showDiff ? colors.green[100] : 'none'}>
                    {metaCategory?.translations[language]}
                  </Text>
                  <Text as="span" display="inline">
                    {index < (after ?? []).length - 1 ? ', ' : ''}
                  </Text>
                </Fragment>
              );
            })}
        </Box>
      }
    />
  );
};
