import React, { JSX } from 'react';

import { RestaurantResponse } from '../Restaurants/models';
import {
  PartnerFormValues,
  PartnerRequest,
  PartnersCreateBatchRequest,
  PartnersCreateBatchValues,
} from './partner.model';

export const mappedRestaurantsColumnElement = (restaurants: Omit<RestaurantResponse, 'id'>[]): JSX.Element[] =>
  restaurants.map(({ restaurantName, websiteLink }) => (
    <React.Fragment key={`${restaurantName}-${websiteLink}`}>
      {restaurantName && <span>{restaurantName}</span>}
      {restaurantName && websiteLink && ' - '}
      {websiteLink && (
        <a href={websiteLink} target="_blank" rel="noreferrer">
          {websiteLink}
        </a>
      )}
      <br />
    </React.Fragment>
  ));

export const mapFormPartnerToRequest = (values: PartnerFormValues): PartnerRequest => {
  const { restaurants, ...otherProperties } = values;

  return {
    ...otherProperties,
    restaurantsIds: restaurants ? restaurants?.map((restaurant) => restaurant.id) : [],
  };
};

export const mapFormPartnerBatchToRequest = (values: PartnersCreateBatchValues): PartnersCreateBatchRequest => {
  const { keyAccountGroups, ...otherProperties } = values;

  return {
    ...otherProperties,
    keyAccountGroupsIds: keyAccountGroups ? keyAccountGroups?.map((kag) => kag.id) : [],
  };
};
