import React, { JSX } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormFooter } from 'common/components';

import { RelevoUserFormModel, RelevoUserRequest } from './models';
import RelevoUserRolesForm from './RelevoUserRolesForm';

type Props = {
  data?: RelevoUserFormModel;
  isLoading: boolean;
  onSubmit(data: RelevoUserRequest): void;
};

const UpdateRelevoUserForm = ({ isLoading, onSubmit, data }: Props): JSX.Element => {
  const formMethods = useForm<RelevoUserFormModel>();
  const { reset, handleSubmit, control } = formMethods;

  React.useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onFormSubmit = handleSubmit(({ closingSource, ...form }: RelevoUserFormModel) => {
    onSubmit({
      ...form,
      closingSourceId: closingSource?.id,
      locations: {
        restaurantIds: form.locations?.restaurantIds?.map((r) => r.id),
        keyAccountsGroupIds: form.locations?.keyAccountsGroupIds?.map((k) => k.id),
      },
    });
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onFormSubmit}>
        {data?.email ? `User: ${data?.email}` : null}
        <RelevoUserRolesForm control={control} />
        {/* <RelevoUserMetadataForm control={control} /> */}
        <FormFooter isLoadingSubmitResult={isLoading} />
      </form>
    </FormProvider>
  );
};

export default UpdateRelevoUserForm;
