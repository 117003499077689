import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Flex, Switch } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormGroup, NumberInput, useToast } from '../../../../../common/components';
import CurrencySelect from '../../../../../common/components/CurrencySelect/CurrencySelect';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useUpdateRestaurantDepositSettings } from '../../../../../services/deposit';
import { RestaurantDepositSettingsResponse } from '../../models/restaurant-deposit-settings-response';
import { UpdateRestaurantDepositSettingsRequest } from '../../models/update-restaurant-deposit-settings-request';
import { DEPOSIT_AMOUNT_MAX_VALUE, DEPOSIT_AMOUNT_MIN_VALUE } from '../../utils/deposit.consts';
import { useDepositSettingsFormValidation } from './update-deposit-schema';

type UpdateDepositSettingsProps = {
  restaurantId: string;
  isEditMode: boolean;
  onEditModeChange: (isEditMode: boolean) => void;
  model?: RestaurantDepositSettingsResponse;
};

export const UpdateDepositSettings = ({
  restaurantId,
  model,
  isEditMode,
  onEditModeChange,
}: UpdateDepositSettingsProps) => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const handleDisableEditMode = () => onEditModeChange(false);
  const validationSchema = useDepositSettingsFormValidation();
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<RestaurantDepositSettingsResponse>({
    resolver: yupResolver(validationSchema),
  });

  const { mutate: updateRestaurantDepositSettings, isLoading: isSavingDepositSettings } =
    useUpdateRestaurantDepositSettings();

  const isFormUpdated = (form: RestaurantDepositSettingsResponse) =>
    form.isDepositEnabled !== model?.isDepositEnabled ||
    form.bowlsAmount !== model?.bowlsAmount ||
    form.cupsAmount !== model?.cupsAmount ||
    form.currency !== model?.currency;

  const handleFormSubmit = handleSubmit(({ ...form }: RestaurantDepositSettingsResponse) => {
    if (!isFormUpdated(form)) {
      displayToast('info', translations('form_no_changes_info'), false, 1500);
      handleDisableEditMode();
    } else {
      const request: UpdateRestaurantDepositSettingsRequest = {
        isDepositEnabled: form.isDepositEnabled,
        bowlsAmount: form.bowlsAmount,
        cupsAmount: form.cupsAmount,
        currency: form.currency,
      };
      updateRestaurantDepositSettings(
        {
          id: restaurantId,
          updateRestaurantDepositSettingsRequest: request,
        },
        {
          onSuccess: () => {
            displayToast('success', translations('deposit_update_success'), false, 4000);
            handleDisableEditMode();
          },
        },
      );
    }
  });

  useEffect(() => reset(model), [reset, model]);

  const handleCancel = () => {
    reset(model);
    handleDisableEditMode();
  };

  const isCurrencyEditable = isEditMode && !model?.history.some((historyRecord) => historyRecord.enabled);

  return (
    <form onSubmit={handleFormSubmit}>
      <Flex textAlign="start" wrap="wrap" width="100%">
        <Box width={['50%', '50%', '25%']} p={2}>
          <FormGroup label={translations('deposit_enabled_label')} inputId="isDepositEnabled">
            <Controller
              control={control}
              name="isDepositEnabled"
              render={({ field: { value, onChange, ref } }) => (
                <Box>
                  <Switch
                    colorScheme="orange"
                    id="isDepositEnabled"
                    isChecked={value}
                    onChange={onChange}
                    ref={ref}
                    isDisabled={!isEditMode}
                  />
                </Box>
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['50%', '50%', '25%']} p={2}>
          <FormGroup
            label={translations('deposit_bowls_amount_label')}
            inputId="bowlsAmount"
            validationError={errors.bowlsAmount?.message}
          >
            <Controller
              control={control}
              name="bowlsAmount"
              render={({ field: { value, onChange } }) => (
                <NumberInput
                  name="bowlsAmount"
                  register={register('bowlsAmount')}
                  min={DEPOSIT_AMOUNT_MIN_VALUE}
                  max={DEPOSIT_AMOUNT_MAX_VALUE}
                  isDisabled={!isEditMode}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['50%', '50%', '25%']} p={2}>
          <FormGroup
            label={translations('deposit_cups_amount_label')}
            inputId="cupsAmount"
            validationError={errors.cupsAmount?.message}
          >
            <Controller
              control={control}
              name="cupsAmount"
              render={({ field: { value, onChange } }) => (
                <NumberInput
                  name="cupsAmount"
                  register={register('cupsAmount')}
                  min={DEPOSIT_AMOUNT_MIN_VALUE}
                  max={DEPOSIT_AMOUNT_MAX_VALUE}
                  isDisabled={!isEditMode}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['50%', '50%', '25%']} p={2}>
          <FormGroup
            label={translations('deposit_currency_label')}
            labelTooltipText={translations('deposit_settings_currency_tooltip_text')}
            inputId={'currency'}
          >
            <Controller
              control={control}
              name="currency"
              render={({ field: { value, name, onChange } }) => (
                <CurrencySelect
                  selectedCurrency={value}
                  name={name}
                  onSelect={onChange}
                  isDisabled={!isCurrencyEditable}
                  inputId={'currency'}
                />
              )}
            />
          </FormGroup>
        </Box>
      </Flex>
      <Flex justifyContent="flex-start">
        {isEditMode ? (
          <>
            <Button mr={2} type="submit" isLoading={isSavingDepositSettings}>
              {translations('save')}
            </Button>
            <Button onClick={handleCancel}>{translations('cancel')}</Button>
          </>
        ) : null}
      </Flex>
    </form>
  );
};
