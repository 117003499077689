import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FormFooter, useToast } from '../../../../../common/components';
import { Routes } from '../../../../../config/routes';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useUpdateRestaurantUserNotificationSettings } from '../../../../../services/restaurant-user-notification-settings';
import { NotificationSettingsLocations } from '../../models/enums/notification-settings-locations.enum';
import { NotificationSettingsFormData } from '../../models/notification-settings-form-data';
import { UpdateNotificationSettingsRequest } from '../../models/update-notification-settings-request';
import { UserNotificationSettingsResponse } from '../../models/user-notification-settings-response';
import {
  buildNotificationSettingsFormData,
  buildNotificationSettingsUpdateRequest,
} from '../../utils/notification-settings-form.helper';
import { NotificationEnabled } from '../NotificationEnabled/NotificationEnabled';
import { NotificationFrequency } from '../NotificationFrequency/NotificationFrequency';
import { NotificationLocations } from '../NotificationLocations/NotificationLocations';

type NotificationSettingsFormProps = {
  userId: string;
  userNotificationSettings: UserNotificationSettingsResponse;
};

export const NotificationSettingsForm = ({ userId, userNotificationSettings }: NotificationSettingsFormProps) => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const navigate = useNavigate();
  const { mutate: updateNotificationSettings, isLoading: isUpdatingNotificationSettings } =
    useUpdateRestaurantUserNotificationSettings();
  const methods = useForm<NotificationSettingsFormData>({
    defaultValues: buildNotificationSettingsFormData(userNotificationSettings),
  });

  const handleFormSubmit = methods.handleSubmit((form) => {
    const { locationsSetting, selectedLocations } = form;
    if (locationsSetting === NotificationSettingsLocations.SELECTED && !selectedLocations?.length) {
      displayToast('warning', translations('notification_settings_selected_locations_warning'));
    } else {
      const updateRequest: UpdateNotificationSettingsRequest = buildNotificationSettingsUpdateRequest(form);
      updateNotificationSettings(
        { id: userId, request: updateRequest },
        {
          onSuccess: () => {
            displayToast('success', translations('notification_settings_successfully_saved'));
            navigate(Routes.RESTAURANT_USERS_PATH);
          },
          onError: () => displayToast('error', translations('something_went_wrong')),
        },
      );
    }
  });

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleFormSubmit}>
          <NotificationEnabled />
          <NotificationFrequency />
          <NotificationLocations />
          <FormFooter isLoadingSubmitResult={isUpdatingNotificationSettings} />
        </form>
      </FormProvider>
    </>
  );
};
