import React from 'react';
import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';

import theme from '../../../styles/theme';

// TODO: consider changing the implementation so that displayToast in deps does not cause re-rendering or putting it in deps is not necessary
// check https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
export const useToast = (): {
  displayToast: (
    status: UseToastOptions['status'],
    toastBody: React.ReactNode,
    shouldStack?: boolean,
    duration?: number,
  ) => void;
  closeAll: () => void;
} => {
  const { toast } = createStandaloneToast({ theme });

  const displayToast = (
    status: UseToastOptions['status'],
    description: React.ReactNode,
    shouldStack = false,
    duration = 6000,
  ) => {
    if (shouldStack || !toast.isActive('id')) {
      toast({
        id: 'id',
        position: 'top',
        duration,
        status,
        description,
      });
    }
  };

  return {
    displayToast,
    closeAll: toast.closeAll,
  };
};
