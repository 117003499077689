import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import { FormGroup } from '../../../../../common/components';
import { useLocationsSetState } from '../../../../../common/hooks/use-locations-set-state.hook';
import { config } from '../../../../../config/config';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useRestaurantList } from '../../../../../services/restaurants';
import { RestaurantResponse } from '../../../../Restaurants/models';
import { RestaurantFilterMode } from '../../../../Restaurants/models/enums/restaurant-filter-mode.enum';

type FormActivationLocationsProps = {
  isDisabled?: boolean;
  tooltip?: string;
};

export const FormActivationLocations = ({ isDisabled, tooltip }: FormActivationLocationsProps) => {
  const translations = useTranslations();
  const { control } = useFormContext();
  const { setRestaurantSearchText, debouncedRestaurantSearch } = useLocationsSetState();

  const { data: restaurants, isFetching: isFetchingRestaurants } = useRestaurantList({
    isActive: true,
    searchText: debouncedRestaurantSearch,
    limit: config.paginationSize,
    offset: 1,
    filterMode: RestaurantFilterMode.DROP_DOWN_SELECT,
  });

  return (
    <FormGroup
      label={translations('reuse_cards_activation_restaurants_label')}
      labelTooltipText={tooltip}
      inputId={'activationRestaurants'}
    >
      <Controller
        control={control}
        name="activationRestaurants"
        render={({ field: { value, name, onChange } }) => (
          <Select
            inputId="activationRestaurants"
            name={name}
            placeholder={translations('restaurants_placeholder')}
            isMulti
            getOptionLabel={(option: RestaurantResponse) => option.restaurantName}
            getOptionValue={(option: RestaurantResponse) => option.id}
            value={value}
            onChange={onChange}
            onInputChange={setRestaurantSearchText}
            options={restaurants?.items}
            isLoading={isFetchingRestaurants}
            filterOption={null}
            menuPlacement="auto"
            isDisabled={isDisabled}
          />
        )}
      />
    </FormGroup>
  );
};
