import React, { JSX } from 'react';
import { Modal } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { AssignItemsToRestaurantResponse } from '../AssignProductFromCsv/model/AssignItemsToRestaurant.model';
import { ReassignBetweenRestaurants } from './ReassignBetweenRestaurants';

type ReassignBetweenRestaurantsModalProps = {
  isVisible: boolean;
  onModalClose: () => void;
  onAssign: (data: AssignItemsToRestaurantResponse) => void;
};

export const ReassignBetweenRestaurantsModal = ({
  isVisible,
  onModalClose,
  onAssign,
}: ReassignBetweenRestaurantsModalProps): JSX.Element => {
  const translations = useTranslations();
  return (
    <>
      <Modal
        isModalVisible={isVisible}
        onModalClose={onModalClose}
        modalHeader={translations('product_assignment_between_restaurants_modal_header')}
        customModalFooter={null}
      >
        <ReassignBetweenRestaurants onModalClose={onModalClose} onAssign={onAssign} />
      </Modal>
    </>
  );
};
