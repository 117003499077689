import React, { JSX, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import { Controller, useForm } from 'react-hook-form';
import { Box, Switch } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter, FormGroup, FullPageLoader, Select } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import * as yup from 'yup';

import FormInput from '../../common/components/FormInput/FormInput';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../common/constants/common';
import {
  accountPriorityOptions,
  AccountPrioritySelect,
} from '../../common/constants/select-options/accountPriority.consts';
import { AccountPriority } from '../../common/models/enums/accountPriority';
import { SelectType } from '../../common/models/enums/selectType';
import { getFormattedDate } from '../../common/utils';
import { DATE_PICKER_LOCALIZATION_CONFIG } from '../../config/configConsts';
import { useSegmentList } from '../../services/segments';
import { INDIVIDUAL_KEY_ACCOUNT_ID } from '../Restaurants/restaurant.consts';
import { SegmentResponse } from '../Segments/model/Segment';
import { FormSubmitData } from './models/form-submit-data';
import { KeyAccountGroupsResponse } from './models/key-account-groups-response';
import { KeyAccountGroupsFormValues } from './KeyAccountGroups.model';

type KeyAccountGroupsFormProps = {
  onSubmit: (values: FormSubmitData) => void;
  isLoading: boolean;
  model?: KeyAccountGroupsResponse;
  formData?: KeyAccountGroupsFormValues;
};

const KeyAccountGroupForm = ({ onSubmit, isLoading, model, formData }: KeyAccountGroupsFormProps): JSX.Element => {
  const translations = useTranslations();
  const validationSchema = yup.object().shape({
    keyAccount: yup
      .string()
      .required(translations('validation_required'))
      .max(200, translations('validation_too_long')),
    segment: yup
      .object()
      .required()
      .shape({
        id: yup.string().required(),
        name: yup.string().required(),
      })
      .test('test segment', translations('validation_required'), (segment) => !!segment.id),
    accountPriority: yup
      .string()
      .nullable()
      .oneOf([...Object.values(AccountPriority)]),
    desiredStartDate: yup.string().nullable(),
    // googleDriveLink: yup
    //   .string()
    //   .test('test url', translations('validation_incorrect_url_format'), (text) => {
    //     if (text?.trim().length === 0 || !text) {
    //       return true;
    //     }
    //     return text.trim().length > 0 && isValidStringUrl(text.trim());
    //   })
    //   .max(MAX_COMMON_LENGTH, translations('validation_too_long')),
  });

  const { data: paginatedSegments } = useSegmentList(ALL_PAGINATED_LIST_ITEMS_PARAMS);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm<KeyAccountGroupsFormValues>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  const selectValueMapper = {
    [SelectType.ACCOUNT_PRIORITIES]: (option: AccountPrioritySelect | null) => option?.value,
  };

  if (isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  const onFormSubmit = handleSubmit(({ ...form }: KeyAccountGroupsFormValues) => {
    onSubmit({
      ...form,
      accountPriority: form.accountPriority ?? undefined,
      segmentId: form.segment.id,
      desiredStartDate: getFormattedDate(form.desiredStartDate),
      quickContactsEnabled: form.quickContactsEnabled || false,
    });
  });

  return (
    <form data-testid="form" onSubmit={onFormSubmit} autoComplete="off">
      <FormInput
        id={'keyAccount'}
        label={translations('key_account_group')}
        validationError={errors.keyAccount?.message}
        useFormRegisterReturn={register('keyAccount')}
        setValue={setValue}
      />
      <FormGroup label={translations('segment')} inputId="segment" validationError={errors.segment?.message}>
        <Controller
          control={control}
          name="segment"
          render={({ field: { value, onChange, name } }) => (
            <Select<SegmentResponse>
              inputId="segment"
              name={name}
              getOptionLabel={(option) => (option as SegmentResponse).name}
              getOptionValue={(option) => (option as SegmentResponse).id}
              defaultValue={!model?.segment ?? null}
              value={paginatedSegments?.items.find((option) => option.name === (value as SegmentResponse)?.name)}
              onChange={onChange}
              options={paginatedSegments?.items}
            />
          )}
        />
      </FormGroup>
      {/* <FormInput */}
      {/*   id={'googleDriveLink'} */}
      {/*   label={translations('google_drive_link')} */}
      {/*   validationError={errors.googleDriveLink?.message} */}
      {/*   useFormRegisterReturn={register('googleDriveLink')} */}
      {/*   setValue={setValue} */}
      {/* /> */}
      <FormGroup
        label={translations('key_account_group_account_priority')}
        inputId="accountPriority"
        validationError={errors.accountPriority?.message}
      >
        <Controller
          control={control}
          name="accountPriority"
          render={({ field: { value, onChange, name } }) => (
            <Select<AccountPrioritySelect>
              name={name}
              getOptionLabel={(option) => (option as AccountPrioritySelect).label}
              getOptionValue={(option) => (option as AccountPrioritySelect).value}
              value={accountPriorityOptions.find((option) => option.value === value)}
              valueMapper={selectValueMapper[SelectType.ACCOUNT_PRIORITIES]}
              onChange={onChange}
              options={accountPriorityOptions}
            />
          )}
        />
      </FormGroup>
      <FormGroup label={translations('key_account_group_desired_start_date')} inputId="desiredStartDate">
        <Controller
          control={control}
          name="desiredStartDate"
          render={({ field: { value, onChange } }) => (
            <DatePicker
              locale={DATE_PICKER_LOCALIZATION_CONFIG.locale}
              format={DATE_PICKER_LOCALIZATION_CONFIG.dateFormat}
              value={value ? new Date(value) : null}
              onChange={(v) => {
                onChange(v);
              }}
            />
          )}
        />
      </FormGroup>
      {model?.id !== INDIVIDUAL_KEY_ACCOUNT_ID ? (
        <FormGroup label={translations('key_account_group_quick_contacts_enabled')} inputId="quickContactsEnabled">
          <Controller
            control={control}
            name="quickContactsEnabled"
            render={({ field: { value, onChange, ref } }) => {
              return (
                <>
                  <Box>
                    <Switch
                      colorScheme="orange"
                      id="quickContactsEnabled"
                      isChecked={value}
                      onChange={onChange}
                      ref={ref}
                    />
                  </Box>
                </>
              );
            }}
          />
        </FormGroup>
      ) : null}
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default KeyAccountGroupForm;
