import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { getRestaurantDraftById, getRestaurantDrafts, updateRestaurantDraftStatus } from '../api/drafts';
import { useToast } from '../common/components';
import { Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { RestaurantDraftResponse } from '../modules/Restaurants/RestaurantDrafts/models/restaurant-draft-response.model';
import { RestaurantDraftStatus } from '../modules/Restaurants/RestaurantDrafts/models/restaurant-draft-status.enum';
import { RestaurantDraftListParams } from './restaurants';

export const CK_RESTAURANT_DRAFTS = 'restaurantDrafts';
export const CK_RESTAURANT_DRAFT = 'restaurantDraft';
export const useRestaurantDraftList = (
  params: RestaurantDraftListParams,
): QueryObserverResult<Paginated<RestaurantDraftResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_RESTAURANT_DRAFTS, { offset: params.offset, limit: params.limit, status: params.status }],
    () => getRestaurantDrafts(params),
    {
      keepPreviousData: true,
    },
  );
};
export const useRestaurantDraft = ({
  draftId,
  restaurantId,
}: {
  restaurantId: string;
  draftId: string;
}): QueryObserverResult<RestaurantDraftResponse, AxiosError<HttpException>> => {
  return useQuery([CK_RESTAURANT_DRAFT, restaurantId, draftId], () =>
    getRestaurantDraftById({
      draftId,
      restaurantId,
    }),
  );
};
export const useAcceptRestaurantDraft = ({
  onSuccess,
}: {
  onSuccess: () => void;
}): UseMutationResult<
  { status: RestaurantDraftStatus },
  AxiosError<HttpException>,
  { draftId: string; restaurantId: string }
> => {
  const queryClient = useQueryClient();
  const translations = useTranslations();
  const { displayToast } = useToast();

  return useMutation(
    ({ draftId, restaurantId }) =>
      updateRestaurantDraftStatus({
        restaurantId,
        draftId,
        status: RestaurantDraftStatus.ACCEPTED,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_RESTAURANT_DRAFTS);
        displayToast('success', translations('restaurant_drafts_accept_draft_success_message'));
        onSuccess();
      },
      onError: (error) => displayToast('error', error.response?.data.message || translations('something_went_wrong')),
    },
  );
};
export const useRejectRestaurantDraft = ({
  onSuccess,
}: {
  onSuccess: () => void;
}): UseMutationResult<
  { status: RestaurantDraftStatus },
  AxiosError<HttpException>,
  {
    draftId: string;
    restaurantId: string;
  }
> => {
  const queryClient = useQueryClient();
  const translations = useTranslations();
  const { displayToast } = useToast();

  return useMutation(
    ({ draftId, restaurantId }) =>
      updateRestaurantDraftStatus({
        restaurantId,
        draftId,
        status: RestaurantDraftStatus.REJECTED,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_RESTAURANT_DRAFTS);
        displayToast('success', translations('restaurant_drafts_reject_draft_success_message'));
        onSuccess();
      },
      onError: (error) => displayToast('error', error.response?.data.message || translations('something_went_wrong')),
    },
  );
};
