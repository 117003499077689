import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormGroup, NumberInput } from '../../../../../common/components';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { REUSE_CARD_MONETARY_MAX_VALUE, REUSE_CARD_MONETARY_MIN_VALUE } from '../../../utils/reuse-cards.consts';

type FormMonetaryValueProps = {
  isDisabled?: boolean;
  tooltip?: string;
};

export const FormMonetaryValue = ({ isDisabled, tooltip }: FormMonetaryValueProps) => {
  const translations = useTranslations();
  const { control, register } = useFormContext();

  return (
    <FormGroup
      label={translations('reuse_cards_monetary_value_label')}
      labelTooltipText={tooltip}
      inputId="monetaryValue"
    >
      <Controller
        control={control}
        name="monetaryValue"
        render={({ field: { value, onChange } }) => (
          <NumberInput
            name="monetaryValue"
            register={register('monetaryValue')}
            min={REUSE_CARD_MONETARY_MIN_VALUE}
            max={REUSE_CARD_MONETARY_MAX_VALUE}
            value={value}
            onChange={onChange}
            isDisabled={isDisabled}
          />
        )}
      />
    </FormGroup>
  );
};
