import { JSX } from 'react';

import { MainPageContent, PageHeading } from '../../../common/components';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { RestaurantDraftsTable } from './RestaurantDraftsTable';

const RestaurantDrafts = (): JSX.Element => {
  const translations = useTranslations();

  return (
    <MainPageContent>
      <PageHeading>{translations('restaurant_drafts_header')}</PageHeading>
      <RestaurantDraftsTable />
    </MainPageContent>
  );
};

export default RestaurantDrafts;
