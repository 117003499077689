export enum AccountPriority {
  KEY_ACCOUNT = 'KEY_ACCOUNT',
  POTENTIAL = 'POTENTIAL',
  STANDARD = 'STANDARD',
}

export enum AccountPriorityNames {
  KEY_ACCOUNT = '1: Key Account',
  POTENTIAL = '2: Potential',
  STANDARD = '3: Standard',
}
