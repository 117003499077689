import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { RelevoUserRequest, RelevoUserResponse } from 'modules/RelevoUsers/models';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import axiosInstance from './axios';

export const getRelevoUsersList = ({
  searchText,
  offset,
  limit,
}: BaseQueryParams): Promise<Paginated<RelevoUserResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance
    .get<Paginated<RelevoUserResponse>>(`${apiPaths.RELEVO_USERS}?${searchParams}`)
    .then(({ data }) => data);
};

export const createRelevoUser = (createRelevoUserRequest: RelevoUserRequest): Promise<RelevoUserResponse> => {
  const d = {
    formFields: [
      {
        id: 'email',
        value: createRelevoUserRequest.email,
      },
      {
        id: 'password',
        value: createRelevoUserRequest.password,
      },
    ],
    partnerRole: createRelevoUserRequest.partnerRole,
    adminRole: createRelevoUserRequest.adminRole,
    locations: createRelevoUserRequest.locations,
    closingSourceId: createRelevoUserRequest.closingSourceId,
  };
  return axiosInstance.post(`${apiPaths.RELEVO_USERS}`, d).then(({ data }) => data);
};

export const getRelevoUser = (id: string): Promise<RelevoUserResponse> =>
  axiosInstance.get<RelevoUserResponse>(`${apiPaths.RELEVO_USERS}/${id}`).then(({ data }) => data);

export const updateRelevoUser = (values: RelevoUserRequest): Promise<RelevoUserResponse> => {
  return axiosInstance.put(`${apiPaths.RELEVO_USERS}/${values.id}`, values);
};

export const deleteRelevoUser = (id: string): Promise<void> => axiosInstance.delete(`${apiPaths.RELEVO_USERS}/${id}`);
