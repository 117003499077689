import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { createMetaCategory, getMetaCategory, getMetaCategoryList, updateMetaCategory } from '../api/meta-categories';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { MetaCategory, MetaCategoryRequest } from '../modules/MetaCategories/model/MetaCategory';

export const CK_META_CATEGORY = 'meta_category';

export const useMetaCategoryList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<MetaCategory>, AxiosError<HttpException>> =>
  useQuery(
    [CK_META_CATEGORY, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getMetaCategoryList(params),
    {
      keepPreviousData: true,
    },
  );

export const useMetaCategory = (id: string): QueryObserverResult<MetaCategory, AxiosError<HttpException>> =>
  useQuery([CK_META_CATEGORY, id], () => getMetaCategory(id));

export const useCreateMetaCategory = (): UseMutationResult<
  MetaCategory,
  AxiosError<HttpException>,
  { values: MetaCategoryRequest }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values }) => createMetaCategory(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_META_CATEGORY);
    },
  });
};

export const useUpdateMetaCategory = (): UseMutationResult<
  MetaCategory,
  AxiosError<HttpException>,
  { values: MetaCategoryRequest; id: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values, id }) => updateMetaCategory(values, id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_META_CATEGORY);
    },
  });
};
