import React, { JSX } from 'react';
import { useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import { Modal, useToast } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { ImageForm } from '../../../common/components/Images/ImageForm';
import { Routes } from '../../../config/routes';
import { useModal } from '../../../contexts/ModalContext';
import { ModalVariant } from '../../../contexts/ModalContext/hook';
import { CK_RESTAURANT_IMAGES, useAddRestaurantImage } from '../../../services/restaurants';

type AddRestaurantImageModalProps = {
  restaurantId: string;
};

export const AddRestaurantImage = ({ restaurantId }: AddRestaurantImageModalProps): JSX.Element => {
  const translations = useTranslations();
  const { modalState, handleToggleModal } = useModal();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { displayToast } = useToast();

  const { mutate: addRestaurantImage, isLoading } = useAddRestaurantImage({
    onSuccessfulAssign: async (data) => {
      if (data) {
        displayToast('success', translations('image_modal_success'), false, 5000);
        await queryClient.invalidateQueries(CK_RESTAURANT_IMAGES);
        navigate(generatePath(Routes.RESTAURANT_IMAGES_PATH, { restaurantId }));
      }
    },
  });

  const handleFormSubmit = async (data: any) => {
    const restaurantImageRequest = { file: data.file[0] };
    addRestaurantImage({ restaurantId, addRestaurantImageRequest: restaurantImageRequest });
  };

  return (
    <>
      <Modal
        isModalVisible={modalState?.modalType === ModalVariant.DEFAULT}
        onModalClose={handleToggleModal(null)}
        modalHeader={translations('images_modal_header')}
        customModalFooter={null}
      >
        <ImageForm isLoading={isLoading} handleFormSubmit={handleFormSubmit} onModalClose={handleToggleModal(null)} />
      </Modal>
    </>
  );
};
