export const reorder = <T extends object>(array: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(array);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  // todo: is it ok?
  result.forEach((item, index) => {
    if ('order' in item) {
      item.order = index;
    }
  });
  return result;
};
