import React, { JSX, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Button, Heading, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Routes } from 'config/routes';

import { useTranslations } from '../../contexts/LocalizationContext';
import { useCreatePartnersBatch } from '../../services/partners';
import CreatePartnersBatchForm from './CreatePartnersBatchForm';
import { PartnerResponse } from './partner.model';
import { mapFormPartnerBatchToRequest, mappedRestaurantsColumnElement } from './Partners.utils';

const CreatePartnersBatch = (): JSX.Element => {
  const translations = useTranslations();
  const [isCreated, setIsCreated] = useState(false);
  const [partners, setPartners] = useState<PartnerResponse[]>([]);
  const onSuccess = (data: PartnerResponse[]) => {
    setPartners(data);
    setIsCreated(true);
  };
  const { mutate: createPartnersBatch, isLoading } = useCreatePartnersBatch(onSuccess);

  return (
    <>
      <Heading>{translations('partners_create_partners')}</Heading>
      {!isCreated ? (
        <CreatePartnersBatchForm
          isLoading={isLoading}
          onSubmit={async (values) => {
            const mappedValues = mapFormPartnerBatchToRequest(values);
            createPartnersBatch({ values: mappedValues });
          }}
        />
      ) : (
        <>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{translations('partners_table_partner_name')}</Th>
                <Th>{translations('partners_table_api_key')}</Th>
                <Th>{translations('partners_table_enabled')}</Th>
                <Th>{translations('partners_table_restaurants')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {partners.map((p) => (
                <Tr key={p.id}>
                  <Td>{p.partnerName}</Td>
                  <Td>{p.apiKey}</Td>
                  <Td>{String(p.isEnabled)}</Td>
                  <Td>{mappedRestaurantsColumnElement(p.restaurants)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Link to={generatePath(Routes.PARTNERS_PATH)}>
            <Button mt={4} ml={4}>
              {translations('back')}
            </Button>
          </Link>
        </>
      )}
    </>
  );
};

export default CreatePartnersBatch;
