import React, { JSX } from 'react';
import { Column, useTable } from 'react-table';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Table as ChakraTable, Tbody, Td, Text, Th, Thead, Tr, useTheme } from '@chakra-ui/react';
import { AxiosError } from 'axios/index';
import { useTranslations } from 'contexts/LocalizationContext';

import { HttpException } from '../../models/httpException';
import { ErrorMessages } from '../ErrorMessages';
import { FullPageLoader } from '../Loader';
import { Pagination } from './Pagination/Pagination';
import { Searchbox } from './Searchbox/Searchbox';

type TableProps<DataType extends Record<string, unknown>> = {
  data: DataType[] | undefined;
  columns: Column<DataType>[];
  isLoading: boolean;
  onRowClicked?: (id: string) => void;
  customFilter?: React.ReactNode[] | React.ReactNode;
  children?: React.ReactNode[] | React.ReactNode;
} & (
  | { searchEnabled?: false }
  | {
      searchEnabled: true;
      searchText: string;
      onSearchTextChanged: (text: string) => void;
      searchboxPlaceholder?: string;
    }
) &
  (
    | { paginationEnabled?: false }
    | {
        paginationEnabled: true;
        page: number;
        onPageChanged: (newPage: number) => void;
        totalPages: number | undefined;
      }
  ) & { errors?: (AxiosError<HttpException> | null)[] | AxiosError<HttpException> | null };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Table = <DataType extends Record<string, any>>(props: TableProps<DataType>): JSX.Element => {
  const { columns, data, isLoading, onRowClicked } = props;
  const { headerGroups, rows, prepareRow } = useTable({ columns, data: data || [] });
  const { colors } = useTheme();
  const translations = useTranslations();

  return (
    <>
      {props.searchEnabled || props.children ? (
        <Flex justifyContent={props.searchEnabled ? 'space-between' : 'flex-end'} m={4} alignItems="center">
          {props.searchEnabled && (
            <>
              {props.customFilter || (
                <Searchbox
                  value={props.searchText}
                  onChange={props.onSearchTextChanged}
                  placeholder={props.searchboxPlaceholder ?? ''}
                />
              )}
            </>
          )}
          {props.children}
        </Flex>
      ) : null}
      <Box overflowX="auto">
        <FullPageLoader show={isLoading}>
          <ChakraTable p={1} {...(!rows.length && { border: `1px solid ${colors.gray[100]}` })}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr key={`headerGroup_${headerGroup.id}`}>
                  {headerGroup.headers.map((column) => (
                    <Th key={`header_${column.id}`}>{column.render('Header') as any}</Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {props.errors ? (
                <Tr>
                  <Td colSpan={columns.length} p={10}>
                    <ErrorMessages errors={props.errors} />
                  </Td>
                </Tr>
              ) : (
                <>
                  {rows.length ? (
                    <>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <Tr key={`row_${row.id}`}>
                            {row.cells.map((cell) => (
                              <Td onClick={() => onRowClicked?.(row.original.id)} key={`cell_${cell.column.id}`}>
                                {cell.render('Cell') as any}
                              </Td>
                            ))}
                          </Tr>
                        );
                      })}
                    </>
                  ) : (
                    <Tr>
                      <Td colSpan={columns.length} p={10}>
                        <CloseIcon margin="auto" fontSize="20px" mb={2} />
                        <Text textAlign="center" fontSize="18px">
                          {translations('no_data')}
                        </Text>
                      </Td>
                    </Tr>
                  )}
                </>
              )}
            </Tbody>
          </ChakraTable>
          {props.paginationEnabled && props?.totalPages && props.totalPages > 1 ? (
            <Flex w="100%" justifyContent="center">
              <Pagination page={props.page} totalPages={props.totalPages} onPageChanged={props.onPageChanged} />
            </Flex>
          ) : null}
        </FullPageLoader>
      </Box>
    </>
  );
};
