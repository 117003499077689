import React, { JSX, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import { Box, Button, useTheme } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useUpdateContractFile, useUpdateExcelFile } from '../../../../services/customers';
import { FileForm } from './FileForm';
import { CustomerFileType } from './FilesList';

interface FileProps {
  fileId: string;
  url: string | null;
  index: number;
  fileType: CustomerFileType;
  disabled: boolean;
}

export interface SubmitProps {
  file: FileList;
}

export const File = ({ fileId, url, index, fileType, disabled }: FileProps): JSX.Element => {
  const translations = useTranslations();
  const { id: customerId } = useParams<{ id: string }>();
  invariant(customerId, 'id is not set within the route');
  const { colors } = useTheme();

  const [isEditingMode, setIsEditingMode] = useState(false);

  const handleEditingMode = () => () => setIsEditingMode(!isEditingMode);

  const { mutate: updateExcelFile, isLoading: isUpdateExcelFileSaving } = useUpdateExcelFile();
  const { mutate: updateContractFile, isLoading: isUpdateContractFileSaving } = useUpdateContractFile();

  const handleSubmit = (data: SubmitProps) => {
    if (fileType === CustomerFileType.EXCEL) {
      updateExcelFile(
        {
          fileId,
          customerId,
          file: data.file[0],
        },
        {
          onSuccess: handleEditingMode(),
        },
      );
    }
    if (fileType === CustomerFileType.CONTRACT) {
      updateContractFile(
        {
          fileId,
          customerId,
          file: data.file[0],
        },
        {
          onSuccess: handleEditingMode(),
        },
      );
    }
  };

  const isLoading = isUpdateExcelFileSaving || isUpdateContractFileSaving;

  return (
    <Box
      width={300}
      height={200}
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      border="1px"
      borderColor={colors.orange[500]}
      position="relative"
    >
      {isEditingMode ? (
        <>
          <FileForm handleSubmit={handleSubmit} fileType={fileType} isLoading={isLoading} />
          <Box onClick={handleEditingMode()} position="absolute" top="10px" right="10px">
            <CloseIcon />
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" flexDirection="column">
            <a href={url ?? undefined} target="_blank">
              {translations('customer_file')} {index + 1} <DownloadIcon />
            </a>
            {!disabled ? (
              <Button mt={5} onClick={handleEditingMode()}>
                {translations('customer_files_edit_button')}
              </Button>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};
