import React, { JSX } from 'react';
import { Box, Divider, HStack, Stack, useTheme } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { ReportsStatisticsResponse } from '../model/ReportsStatisticsResponse';
import ListItem from './ListItem';
import { TableHeader } from './TableHelper';

type ResultListProps = {
  data: ReportsStatisticsResponse[];
};

const ListView = ({ data }: ResultListProps): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();

  const scrollBarStyles = {
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.orange['500'],
      borderRadius: '24px',
    },
    scrollbarColor: `${colors.orange['500']} white`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    scrollbarWidth: 'thin' as any,
  };

  return (
    <Box>
      <Stack
        pt={5}
        css={scrollBarStyles}
        overflowY="scroll"
        border="2px solid"
        borderColor={colors.beige[50]}
        borderRadius="6px"
      >
        <HStack pl={5} pb={1} alignItems="center">
          <TableHeader width="15%" text={translations('reports_list_view_location')} />
          <TableHeader width="7%" text={translations('reports_list_view_start_date')} />
          <TableHeader width="7%" text={translations('reports_list_view_end_date')} />
          <TableHeader width="10%" text={translations('reports_list_view_borrowings')} />
          <TableHeader width="5%" text={translations('reports_list_view_dish_borrowings')} />
          <TableHeader width="10%" text={translations('reports_list_view_dish_category')} />
          <TableHeader width="10%" text={translations('reports_list_view_dish_size')} />
          <TableHeader width="10%" text={translations('reports_list_view_users')} />
          <TableHeader width="10%" text={translations('reports_list_view_return_rate')} />
          <TableHeader width="10%" text={translations('reports_list_view_return_time')} />
        </HStack>
        <Divider color={colors.grey[200]} />

        {data.map((response) => (
          <ListItem key={response.locationId} data={response} />
        ))}
      </Stack>
    </Box>
  );
};

export default ListView;
