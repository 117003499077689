import React, { JSX, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { Button, Flex } from '@chakra-ui/react';
import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { useExecuteWithConfirmation } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';

import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useUpdateWarehouse, useWarehouseList } from '../../services/warehouses';
import { RestaurantResponse } from '../Restaurants/models';
import { RestaurantStatus } from '../Restaurants/RestaurantForm/RestaurantForm.form';

const Warehouses = (): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);

  const {
    data: paginatedRestaurants,
    isFetching: isFetchingWarehouse,
    error,
  } = useWarehouseList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const { mutate: updateWarehouse, isLoading: isSavingWarehouse, error: updateWarehouseError } = useUpdateWarehouse();

  const { executeWithConfirmation: onDeleteRestaurant } = useExecuteWithConfirmation(
    translations('warehouses_delete_confirmation'),
    updateWarehouse,
  );

  const columns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('warehouses_table_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('warehouses_table_name'),
        accessor: 'restaurantName' as const,
      },
      {
        id: '3',
        Header: translations('warehouses_table_details'),
        accessor: 'restaurantDetail' as const,
      },
      {
        id: '4',
        Header: translations('warehouses_table_city'),
        accessor: 'city' as const,
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }: CellProps<RestaurantResponse>) => value?.translations[language] || null,
      },
      {
        id: '5',
        Header: translations('warehouses_table_address'),
        accessor: 'restaurantAddress' as const,
      },
      {
        id: 'actions',
        Header: translations('warehouses_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantResponse>) => (
          <Flex>
            <AddEditItemLink to={`${Routes.WAREHOUSES_PATH}/${original.id}`} m={1} isEdit />
            <Button
              m={1}
              onClick={() =>
                onDeleteRestaurant({ id: original.id, updateWarehouseRequest: { status: RestaurantStatus.DELETED } })
              }
            >
              {translations('delete')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, onDeleteRestaurant, language],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('warehouses_header')}</PageHeading>
      <ErrorMessages errors={[error, updateWarehouseError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_WAREHOUSE_PATH}>{translations('warehouses_add')}</AddEditItemLink>
      </Flex>
      <Table<RestaurantResponse>
        isLoading={isFetchingWarehouse || isSavingWarehouse}
        data={paginatedRestaurants?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedRestaurants?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('warehouses_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default Warehouses;
