import { useContext } from 'react';

import { ReturnCodesFilterContextInterface } from '../model/return-codes-filter-context';
import { ReturnCodesFilterContext } from '../ReturnCodesFilterContext';

export const useReturnCodesFilterContext = (): ReturnCodesFilterContextInterface => {
  const ctx = useContext(ReturnCodesFilterContext);
  if (!ctx) {
    throw new Error('No context provided for KeyAccountGroupReturnCodes');
  }
  return ctx;
};
