import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'config/routes';
import invariant from 'tiny-invariant';

import { ErrorMessages } from '../../common/components';
import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCategory, useUpdateCategory } from '../../services/categories';
import { CategoryRequest } from './model/Category.model';
import { getParsedData } from './utils/category.helper';
import CategoriesForm, { CategoryFormModel } from './CategoriesForm';

const UpdateCategory = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const translations = useTranslations();
  const { isLoading: isCategoriesFetching, data: category } = useCategory(id);
  const { isLoading: isCategoriesUpdate, mutate: updateCategory, error: updateCategoryError } = useUpdateCategory();

  const formData = category ? category : undefined;

  const updateProductCategory = (parsedData: CategoryRequest) => {
    if (!category?.id) {
      return;
    }
    updateCategory(
      { values: parsedData, id: category.id },
      {
        onSuccess: () => navigate(Routes.CATEGORIES_PATH),
      },
    );
  };

  const onCategorySubmit = (values: CategoryFormModel) => {
    if (!category?.id) {
      return;
    }
    const parsedData = getParsedData(values);

    if (
      category?.notReturnFee &&
      (category.notReturnFee.EUR !== Number(values.notReturnFee.EUR).toFixed(2).toString() ||
        category.notReturnFee.CHF !== Number(values.notReturnFee.CHF).toFixed(2).toString())
    ) {
      const isConfirmed = window.confirm(translations('categories_fee_update_warning'));
      if (!isConfirmed) {
        return;
      }
    }
    if (
      category?.extensionFee &&
      (category.extensionFee.EUR !== Number(values.extensionFee.EUR).toFixed(2).toString() ||
        category.extensionFee.CHF !== Number(values.extensionFee.CHF).toFixed(2).toString())
    ) {
      const isConfirmed = window.confirm(translations('categories_extension_fee_update_warning'));
      if (!isConfirmed) {
        return;
      }
    }

    updateProductCategory(parsedData);
  };

  return (
    <>
      <ChildPageHeading>{translations('categories_update_header')}</ChildPageHeading>
      <ErrorMessages errors={updateCategoryError} />
      <CategoriesForm
        formData={formData}
        isLoading={isCategoriesFetching || isCategoriesUpdate}
        onSubmit={onCategorySubmit}
      />
    </>
  );
};

export default UpdateCategory;
