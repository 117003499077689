export enum ClosingSourceStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface ClosingSource {
  id: string;
  name: string;
  status: string;
  googleDriveLink: string | null;
}

export interface ClosingSourceResponse {
  id: string;
  name: string;
  status: ClosingSourceStatus;
  googleDriveLink: string | null;
}

export interface ClosingSourceRequest {
  name: string;
  googleDriveLink: string | null;
}

export interface ClosingSourceFormValues {
  name: string;
  googleDriveLink?: string | null;
}
