import { generatePath } from 'react-router-dom';
import apiPaths from 'config/apiPaths';
import { ProductResponse } from 'modules/Products/Product.model';

import { BaseQueryParams } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import { AssignItemsToRestaurantResponse } from '../modules/ProductAssignment/AssignProductFromCsv/model/AssignItemsToRestaurant.model';
import axiosInstance from './axios';

export type AssignData = {
  restaurantId: string;
  productIds: string[];
};

export type MassAssignData = {
  restaurantId: string;
  productIds: string[];
  categoryId: string;
};

export const assignProductsToRestaurant = (assignData: AssignData): Promise<AssignItemsToRestaurantResponse> =>
  axiosInstance
    .post(`${apiPaths.RESTAURANTS}/${assignData.restaurantId}/products`, {
      items: assignData.productIds,
    })
    .then(({ data }) => data);

export const getRestaurantProducts = (
  restaurantId: string,
  {
    searchText,
    offset,
    limit,
  }: Omit<BaseQueryParams, 'searchText'> & {
    searchText?: string;
  },
): Promise<ProductResponse[]> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance
    .get(generatePath(apiPaths.RESTAURANT_PRODUCTS, { restaurantId }), { params: searchParams })
    .then(({ data }) => data);
};

export const assignProductsByUIdsToRestaurant = (assignData: AssignData): Promise<AssignItemsToRestaurantResponse> =>
  axiosInstance
    .post(`${apiPaths.RESTAURANTS}/${assignData.restaurantId}/products/u-id`, {
      items: assignData.productIds,
    })
    .then(({ data }) => data);

export const assignProductsByUIdsToRestaurantWithCategoryRewriting = (
  assignData: MassAssignData,
): Promise<AssignItemsToRestaurantResponse> =>
  axiosInstance
    .post(`${apiPaths.RESTAURANTS}/${assignData.restaurantId}/products/u-id/${assignData.categoryId}`, {
      items: assignData.productIds,
    })
    .then(({ data }) => data);

export const transferAllProductsBetweenRestaurants = (
  fromRestaurantId: string,
  toRestaurantId: string,
): Promise<AssignItemsToRestaurantResponse> =>
  axiosInstance.post(`${apiPaths.RESTAURANTS}/${fromRestaurantId}/${toRestaurantId}/products`).then(({ data }) => data);
