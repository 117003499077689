import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/react';

import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { FormReturnCodeGracePeriod } from '../../../../../../Restaurants/RestaurantReturnCodes/components/RestaurantReturnCodeForm/components/FormReturnCodeGracePeriod/FormReturnCodeGracePeriod';
import { FormReturnCodeType } from '../../../../../../Restaurants/RestaurantReturnCodes/components/RestaurantReturnCodeForm/components/FormreturnCodeType/FormReturnCodeType';
import { ReturnCodeType } from '../../../../../../Restaurants/RestaurantReturnCodes/models/enums/return-code-type.enum';
import { FormCreateReturnCodesAction } from './components/FormCreateReturnCodesAction/FormCreateReturnCodesAction';

type CreateReturnCodesFormProps = {
  isLoading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const CreateReturnCodesForm = ({ onConfirm, onCancel, isLoading }: CreateReturnCodesFormProps) => {
  const translations = useTranslations();
  const { watch } = useFormContext();

  const watchType = watch('type');

  return (
    <form onSubmit={onConfirm}>
      <FormReturnCodeType />
      {watchType === ReturnCodeType.OPEN_HOURS ? <FormReturnCodeGracePeriod /> : null}
      <FormCreateReturnCodesAction />
      <Flex justifyContent="flex-end" pb={2}>
        <Button colorScheme="orange" mt={4} isLoading={isLoading} type="submit">
          {translations('save')}
        </Button>
        <Button mt={4} ml={4} onClick={onCancel}>
          {translations('cancel')}
        </Button>
      </Flex>
    </form>
  );
};
