import React from 'react';
import { CellProps } from 'react-table';
import { Box } from '@chakra-ui/react';

import { BooleanValueIcon, Table } from '../../../../../common/components';
import { formatDateTime } from '../../../../../common/utils';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { TwoFactorReturnEnabledHistory } from '../../models/restaurant-two-factor-return-settings-response';

type TwoFactorHistoryProps = {
  enabledHistory: TwoFactorReturnEnabledHistory[];
};

export const TwoFactorReturnHistory = ({ enabledHistory }: TwoFactorHistoryProps) => {
  const translations = useTranslations();

  const columns = [
    {
      id: '1',
      Header: translations('two_factor_return_history_table_enabled'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<TwoFactorReturnEnabledHistory>) => <BooleanValueIcon value={original.enabled} />,
    },
    {
      id: '2',
      Header: translations('two_factor_return_history_table_updated_at'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<TwoFactorReturnEnabledHistory>) => formatDateTime(new Date(original.updatedAt)),
    },
  ];
  return (
    <Box pb={4}>
      <Table<TwoFactorReturnEnabledHistory> data={enabledHistory} columns={columns} isLoading={false} />
    </Box>
  );
};
