import React, { ComponentProps, JSX } from 'react';
import { Spinner, useTheme } from '@chakra-ui/react';

export const InlineLoader = ({
  size,
  color,
  thickness,
}: Pick<ComponentProps<typeof Spinner>, 'size' | 'color' | 'thickness'>): JSX.Element => {
  const { colors } = useTheme();
  return (
    <Spinner thickness={thickness || '5px'} speed="0.4s" color={color || colors.orange[300]} size={size || 'xs'} />
  );
};
