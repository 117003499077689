import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { ErrorMessages, FullPageLoader } from '../../common/components';
import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useFaqSection, useUpdateFaqSection } from '../../services/frequently-asked-questions';
import SectionForm from './SectionForm';

const SectionUpdate = (): JSX.Element => {
  const translations = useTranslations();
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const { mutate: updateFaqSection, isLoading, error: modifyFaqSectionError } = useUpdateFaqSection();
  const { data: faqSection, isFetching, error: loadError } = useFaqSection(id);

  if (isFetching || isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{translations('faq_section_update_section')}</ChildPageHeading>
      <ErrorMessages errors={[loadError, modifyFaqSectionError]} />
      <SectionForm
        isLoading={isLoading}
        onSubmit={(values) =>
          updateFaqSection(
            { id, values },
            {
              onSuccess: () => navigate(Routes.FREQUENTLY_ASKED_QUESTIONS_PATH),
            },
          )
        }
        data={faqSection}
      />
    </>
  );
};

export default SectionUpdate;
