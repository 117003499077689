import {
  accountPriorityOptions,
  AccountPrioritySelect,
} from '../../../common/constants/select-options/accountPriority.consts';
import { KeyAccountGroupsResponse } from '../../KeyAccountGroups/models/key-account-groups-response';
import { RestaurantDetailsResponse } from '../models';
import { INDIVIDUAL_KEY_ACCOUNT_ID } from '../restaurant.consts';

export const canAccountPriorityBeChanged = (
  watchKeyAccountId?: string,
  restaurantModel?: RestaurantDetailsResponse,
): boolean =>
  (restaurantModel &&
    restaurantModel.keyAccountId === INDIVIDUAL_KEY_ACCOUNT_ID &&
    watchKeyAccountId === INDIVIDUAL_KEY_ACCOUNT_ID) ||
  watchKeyAccountId === INDIVIDUAL_KEY_ACCOUNT_ID ||
  !watchKeyAccountId;

export const getKeyAccountGroupPriority = (
  watchKeyAccountId?: string,
  keyAccounts?: KeyAccountGroupsResponse[],
): AccountPrioritySelect | null => {
  if (watchKeyAccountId !== INDIVIDUAL_KEY_ACCOUNT_ID) {
    const priority = keyAccounts?.find((keyAccountGroup) => keyAccountGroup.id === watchKeyAccountId)?.accountPriority;
    return accountPriorityOptions.find((option) => option.value === priority) || null;
  }
  return null;
};
