import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['alert', 'icon']);

const Alert = helpers.defineMultiStyleConfig({
  baseStyle: {
    icon: {
      stroke: 'none',
    },
  },
});

export default Alert;
