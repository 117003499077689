import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  createCustomerAccount,
  CustomersFilterParams,
  getCustomer,
  getCustomers,
  updateContractFile,
  updateCustomer,
  updateExcelFIle,
} from '../api/customers';
import { useToast } from '../common/components';
import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { Routes } from '../config/routes';
import { useTranslations } from '../contexts/LocalizationContext';
import { RestaurantUserResponse } from '../modules/RestaurantCredentials/models';
import { CustomerRequest } from '../modules/Restaurants/Customers/models/customer-request.model';
import { CustomerResponse } from '../modules/Restaurants/Customers/models/customer-response.model';
import { CustomersResponse } from '../modules/Restaurants/Customers/models/Customers.model';
import { FileResponse } from '../modules/Restaurants/Customers/models/file.response.model';

export const CK_CUSTOMERS = 'customers';

export const useCustomersList = (
  paginationParams: Omit<BaseQueryParams, 'searchText'> = { offset: 1, limit: 10 },
  filterParam?: CustomersFilterParams,
): QueryObserverResult<Paginated<CustomersResponse>, AxiosError<HttpException>> => {
  const { offset, limit } = paginationParams;
  return useQuery(
    [CK_CUSTOMERS, { page: offset, limit, ...filterParam }],
    () => getCustomers(paginationParams, filterParam),
    { keepPreviousData: true },
  );
};

export const useCreateCustomerAccount = (
  password: string,
): UseMutationResult<RestaurantUserResponse, AxiosError<HttpException>, string> => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { displayToast } = useToast();
  return useMutation((id) => createCustomerAccount(id, password), {
    onSuccess: async ({ id }) => {
      await queryClient.invalidateQueries(CK_CUSTOMERS);
      navigate(`${Routes.CUSTOMERS_PATH}/${id}/summary`, { state: password });

      displayToast('success', translations('customers_create_account_success'));
    },
    onError: (error) =>
      displayToast('error', error.response?.data.message || translations('customers_create_account_error')),
  });
};

export const useUpdateCustomer = (): UseMutationResult<
  CustomerResponse,
  AxiosError<HttpException>,
  { id: string; updateCustomerRequest: CustomerRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id, updateCustomerRequest }) => updateCustomer(id, updateCustomerRequest), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CUSTOMERS);
    },
  });
};

export const useGetCustomer = (id: string): QueryObserverResult<CustomerResponse, AxiosError<HttpException>> => {
  return useQuery([CK_CUSTOMERS, id], () => getCustomer(id));
};

export const useUpdateContractFile = (): UseMutationResult<
  FileResponse,
  AxiosError<HttpException>,
  { customerId: string; fileId: string; file: File }
> => {
  return useMutation(({ customerId, fileId, file }) => updateContractFile(customerId, fileId, file));
};

export const useUpdateExcelFile = (): UseMutationResult<
  FileResponse,
  AxiosError<HttpException>,
  { customerId: string; fileId: string; file: File }
> => {
  return useMutation(({ customerId, fileId, file }) => updateExcelFIle(customerId, fileId, file));
};
