import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { FormGroup, NumberInput } from '../../../../../../../common/components';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import {
  GRACE_PERIOD_MAX_VALUE,
  GRACE_PERIOD_MIN_VALUE,
  GRACE_PERIOD_STEP_VALUE,
  RETURN_CODE_GRACE_PERIOD_DEFAULT_VALUE,
} from '../../../../utils/return-codes.consts';

export const FormReturnCodeGracePeriod = () => {
  const translations = useTranslations();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormGroup
      label={translations('restaurants_return_codes_form_grace_period')}
      inputId="gracePeriod"
      validationError={(errors.gracePeriod as FieldError)?.message}
    >
      <Controller
        control={control}
        name="gracePeriod"
        render={({ field: { value, onChange } }) => (
          <NumberInput
            name="gracePeriod"
            register={register('gracePeriod')}
            min={GRACE_PERIOD_MIN_VALUE}
            max={GRACE_PERIOD_MAX_VALUE}
            step={GRACE_PERIOD_STEP_VALUE}
            value={value ?? RETURN_CODE_GRACE_PERIOD_DEFAULT_VALUE}
            onChange={onChange}
          />
        )}
      />
    </FormGroup>
  );
};
