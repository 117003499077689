import React, { useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { PageHeading, Table } from '../../../../../common/components';
import { DEFAULT_PAGINATED_TABLE_LIMIT } from '../../../../../common/constants/common';
import { formatDateTime } from '../../../../../common/utils';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useRestaurantAssignedItems } from '../../../../../services/restaurant-assigned-items';
import { ItemStatus } from '../../../../AssignedItems/models/enums/item-status';
import { RestaurantAssignedItemsResponse } from '../../models/restaurant-assigned-items-response';

type PendingReturnItemsTableProps = {
  restaurantId: string;
};

export const PendingReturnItemsTable = ({ restaurantId }: PendingReturnItemsTableProps) => {
  const translations = useTranslations();
  const [page, setPage] = useState(1);
  const params = {
    offset: page,
    limit: DEFAULT_PAGINATED_TABLE_LIMIT,
    status: ItemStatus.PENDING_RETURN,
  };

  const { data: paginatedPendingItems, isFetching: isFetchingAssignedItems } = useRestaurantAssignedItems(
    restaurantId,
    params,
  );

  const columns = useMemo(
    () => [
      {
        Header: translations('restaurant_two_factor_table_product_name'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantAssignedItemsResponse>) => original.product?.productName,
      },
      {
        Header: translations('restaurant_two_factor_table_product_u_id'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantAssignedItemsResponse>) => original.product?.uId,
      },
      {
        Header: translations('restaurant_two_factor_table_category_name'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantAssignedItemsResponse>) => original.product?.categoryName,
      },
      {
        Header: translations('restaurant_two_factor_table_assigned_to_pending_at'),
        accessor: 'assignedToRestaurantAt' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantAssignedItemsResponse>) => (
          <>{original.assignedToRestaurantAt ? formatDateTime(new Date(original.assignedToRestaurantAt)) : null}</>
        ),
      },
      {
        Header: translations('restaurant_two_factor_table_user_relevo_id'),
        accessor: 'userRelevoId' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantAssignedItemsResponse>) => original.userRelevoId,
      },
    ],
    [translations],
  );
  return (
    <>
      <PageHeading>{translations('restaurant_two_factor_return_pending_items_header')}</PageHeading>
      <Table<RestaurantAssignedItemsResponse>
        data={paginatedPendingItems?.items}
        columns={columns}
        isLoading={isFetchingAssignedItems}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedPendingItems?.totalPages}
        paginationEnabled
      />
    </>
  );
};
