import { useContext } from 'react';

import { ModalContext, ModalContextProps } from './context';

export const enum ModalVariant {
  DEFAULT = 'DEFAULT',
  PRODUCT_QR = 'PRODUCT_QR',
  RETURN_QR = 'RETURN_QR',
  RETURN_ENHANCED_QR = 'RETURN_ENHANCED_QR',
  RETURN_QR_POSTER = 'RETURN_QR_POSTER',
  RETURN_ENHANCED_QR_POSTER = 'RETURN_ENHANCED_QR_POSTER',
}

export interface ModalState {
  modalType: ModalVariant;
  modalId?: string;
}

interface UseModalProps {
  handleToggleModal: (type: ModalState | null) => () => void;
  modalState: ModalState | null;
}

export const useModal = (): UseModalProps => {
  const [modalState, setModalState] = useContext(ModalContext) as ModalContextProps;

  const handleToggleModal = (data: ModalState | null) => () => {
    setModalState?.(data);
  };

  return { handleToggleModal, modalState };
};
