import React, { JSX } from 'react';
import { useQueryClient } from 'react-query';
import { CellProps } from 'react-table';
import { Button, Flex } from '@chakra-ui/react';
import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { Language, useExecuteWithConfirmation } from 'common/utils';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { CK_PROMOTIONS, useDeletePromotion, usePromotionsList } from 'services/promotions';

import { City } from '../City/models/City';
import { Promotion } from './models/Promotion';

const PromotionsPage = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);

  const queryClient = useQueryClient();
  const {
    data,
    isFetching,
    error: loadPromotionListError,
  } = usePromotionsList({
    searchText,
    limit: 10,
    offset: page,
  });

  const { mutate: deletePromotion, isLoading, error: deleteError } = useDeletePromotion();

  React.useEffect(() => {
    const timer = setTimeout(async () => {
      await queryClient.cancelQueries();
      queryClient.removeQueries(CK_PROMOTIONS);
    }, 300);
    return () => clearTimeout(timer);
  }, [searchText, queryClient]);

  const { executeWithConfirmation: onDeletePromotion } = useExecuteWithConfirmation(
    translations('city_delete_confirmation'),
    deletePromotion,
  );

  const columns = React.useMemo(
    () => [
      {
        Header: translations('promotions_table_name'),
        id: '1',
        accessor: 'translations' as const,
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell }: CellProps<Promotion>) => cell.value[Language.En] ?? null,
      },
      {
        Header: translations('promotions_de_translations'),
        id: '2',
        accessor: 'translations' as const,
        Cell: ({ cell }: CellProps<Promotion>) => cell.value[Language.De] ?? null,
      },
      {
        id: 'actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<City>) => (
          <Flex>
            <AddEditItemLink to={`${Routes.PROMOTIONS_PATH}/${original.id}`} m={1} isEdit />
            <Button m={1} onClick={() => onDeletePromotion(original.id)}>
              {translations('delete')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, onDeletePromotion],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('promotion_header')}</PageHeading>
      <ErrorMessages errors={[loadPromotionListError, deleteError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_PROMOTION}>{translations('promotion_add')}</AddEditItemLink>
      </Flex>
      <Table<Promotion>
        isLoading={isFetching || isLoading}
        data={data?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('promotion_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default PromotionsPage;
