import * as yup from 'yup';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { LostItemsFormActionType } from '../../model/enums/lost-items-form-action-type.enum';

export type LostItemsFromCsvFormType = {
  csvFile: Blob[] | null;
  action: LostItemsFormActionType;
};

export const lostItemsFormDefaultValues: LostItemsFromCsvFormType = {
  csvFile: null,
  action: LostItemsFormActionType.MARK_AS_LOST,
};

export const useMarkLostItemsFromCsvFormValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    csvFile: yup.mixed().required(translations('validation_required')),
  });
};
