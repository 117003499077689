import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorMessages } from '../../common/components';
import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCreateCity } from '../../services/cities';
import CityForm from './CityForm';

const CreateCity = (): JSX.Element => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { mutate: createCity, isLoading, error } = useCreateCity();

  return (
    <>
      <ChildPageHeading>{translations('city_create_city')}</ChildPageHeading>
      <ErrorMessages errors={error} />
      <CityForm
        isLoading={isLoading}
        onSubmit={(createCityRequest) =>
          createCity(createCityRequest, {
            onSuccess: async () => navigate(Routes.CITIES_PATH),
          })
        }
      />
    </>
  );
};

export default CreateCity;
