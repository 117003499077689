import { mapValues } from 'lodash';
import * as yup from 'yup';
import { object } from 'yup';

import { REQUIRED_TRANSLATIONS } from '../../common/utils';
import { useTranslations } from '../../contexts/LocalizationContext';

export const useCityFormValidationSchema = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    translations: yup.lazy((translatedValues) =>
      object(
        mapValues(translatedValues, (value, key) => {
          if (REQUIRED_TRANSLATIONS.includes(key)) {
            return yup
              .string()
              .required(translations('validation_required'))
              .max(191, translations('validation_too_long'));
          } else {
            return yup.string().max(191, translations('validation_too_long'));
          }
        }),
      ),
    ),
    country: yup.object().required(translations('validation_required')),
  });
};
