import React, { JSX, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useTranslations } from 'contexts/LocalizationContext';

import { ErrorMessages } from '../../common/components';
import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { HttpException } from '../../common/models/httpException';
import RestaurantForm, { RestaurantFormMode } from './RestaurantForm/RestaurantForm';
import { useRestaurantFormValidationSchema } from './RestaurantForm/RestaurantForm.form';
import { RestaurantDetailsResponse, RestaurantRequest } from './models';

type Props = {
  onSubmit: (request: RestaurantRequest) => void;
  onCancel: () => void;
  isLoading: boolean;
  errors: (AxiosError<HttpException> | null)[] | AxiosError<HttpException> | null;
  data?: RestaurantDetailsResponse | undefined;
};

const DuplicateRestaurant = ({ data, onSubmit, onCancel, errors, isLoading }: Props): JSX.Element => {
  const translations = useTranslations();
  const [duplicatedFormValues, setDuplicatedFormValues] = useState<RestaurantDetailsResponse>();

  const schema = useRestaurantFormValidationSchema();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  useEffect(() => {
    if (data) {
      const { googlePlaceId, ...restaurant } = data;
      setDuplicatedFormValues(restaurant);
    }
  }, [data]);

  return (
    <>
      <ChildPageHeading>{translations('restaurants_duplicate_header')}</ChildPageHeading>
      <ErrorMessages errors={errors} />
      <FormProvider {...methods}>
        <RestaurantForm
          methods={methods}
          mode={RestaurantFormMode.DUPLICATE}
          isLoading={isLoading}
          onSubmit={onSubmit}
          model={duplicatedFormValues}
          hasFormFooterCustomContent={true}
          customFormFooter={
            <>
              <Button isLoading={isLoading} mr={4} type="submit">
                {translations('save')}
              </Button>
              <Button onClick={onCancel}>{translations('cancel')}</Button>
            </>
          }
        />
      </FormProvider>
    </>
  );
};

export default DuplicateRestaurant;
