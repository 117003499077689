import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getSegments } from '../api/segments';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { SegmentResponse } from '../modules/Segments/model/Segment';

export const CK_SEGMENTS = 'segments';

export const useSegmentList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<SegmentResponse>, AxiosError<HttpException>> =>
  useQuery(
    [CK_SEGMENTS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getSegments(params),
    {
      keepPreviousData: true,
    },
  );
