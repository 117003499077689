import Select, { SingleValue } from 'react-select';

import { SelectOption } from '../../../../../common/components/Select/models/select-option';
import { selectStyle } from '../../../../../common/components/Select/select-styles';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { ReturnCodeStatus } from '../../../../Restaurants/RestaurantReturnCodes/models/enums/return-code-status.enum';

type ReturnCodesStatusSelectProps = {
  onReturnCodeStatusChange: (value: SingleValue<SelectOption>) => void;
  returnCodeStatus: ReturnCodeStatus | undefined;
};

export const ReturnCodeStatusSelect = ({
  onReturnCodeStatusChange,
  returnCodeStatus,
}: ReturnCodesStatusSelectProps) => {
  const translations = useTranslations();

  const returnCodeStatusSelectOptions: SelectOption[] = [
    { label: translations('return_code_status_select_all'), value: 'all' },
    { label: translations('return_code_status_select_active'), value: ReturnCodeStatus.ACTIVE },
    { label: translations('return_code_status_select_passive'), value: ReturnCodeStatus.PASSIVE },
  ];

  return (
    <Select<SelectOption>
      value={
        returnCodeStatusSelectOptions.find((option) => option.value === returnCodeStatus) ||
        returnCodeStatusSelectOptions[0]
      }
      options={returnCodeStatusSelectOptions}
      onChange={onReturnCodeStatusChange}
      styles={selectStyle}
    />
  );
};
