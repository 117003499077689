export const modules = {
  toolbar: {
    container: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      ['link', 'image', 'video'],
      ['emoji'],
      ['clean'],
    ],
  },
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true,
};
