import { generatePath } from 'react-router-dom';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { ApplicationUser } from 'modules/ApplicationUsers/models/application-user';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import { ApplicationUserProfileSettingsRequest } from '../modules/ApplicationUsers/models/application-user-notification-settings.request.model';
import { ApplicationUserProfileSettingsResponse } from '../modules/ApplicationUsers/models/application-user-notification-settings.response.model';
import axiosInstance from './axios';

export const getApplicationUserList = ({
  searchText,
  offset,
  limit,
}: BaseQueryParams): Promise<Paginated<ApplicationUser>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get<Paginated<ApplicationUser>>(`${apiPaths.USERS}?${searchParams}`).then(({ data }) => data);
};

export const anonymizeApplicationUser = (id: string): Promise<void> => axiosInstance.delete(`${apiPaths.USERS}/${id}`);

export const getUserProfileSettings = (userId: string): Promise<ApplicationUserProfileSettingsResponse> =>
  axiosInstance
    .get<ApplicationUserProfileSettingsResponse>(generatePath(apiPaths.USERS_SETTINGS, { userId }))
    .then(({ data }) => data);

export const updateUserProfileSettings = (
  userId: string,
  settingsRequest: ApplicationUserProfileSettingsRequest,
): Promise<ApplicationUserProfileSettingsResponse> =>
  axiosInstance.put(generatePath(apiPaths.USERS_SETTINGS, { userId }), { ...settingsRequest });
