import * as yup from 'yup';

import { CustomerStatus } from '../../../../api/customers';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { StarterSetSizeSelect } from '../../RestaurantForm/RestaurantForm.form';
import { AccountTypeEnum } from '../models/customer-response.model';

export interface AccountTypesList {
  label: string;
  value: AccountTypeEnum;
}

export const accountTypesList: AccountTypesList[] = [
  {
    label: 'Individual Restaurant',
    value: AccountTypeEnum.INDIVIDUAL_RESTAURANT,
  },
  {
    label: 'New F&B Group',
    value: AccountTypeEnum.NEW_KEY_ACCOUNT_GROUP,
  },
  {
    label: 'Part Of Existing F&B Group',
    value: AccountTypeEnum.PART_OF_EXISTING_KEY_ACCOUNT_GROUP,
  },
];

export const useCustomerFormValidationSchema = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    salesOrganisation: yup
      .string()
      .required(translations('validation_required'))
      .max(255, translations('validation_too_long')),
    salesPersonName: yup
      .string()
      .required(translations('validation_required'))
      .max(255, translations('validation_too_long')),
    salesPersonEmail: yup
      .string()
      .required(translations('validation_required'))
      .max(255, translations('validation_too_long')),
    salesPersonPhone: yup
      .string()
      .required(translations('validation_required'))
      .max(20, translations('validation_too_long')),
    customerName: yup
      .string()
      .required(translations('validation_required'))
      .max(255, translations('validation_too_long')),
    contactPersonFirstName: yup.string().max(255, translations('validation_too_long')).nullable(),
    contactPersonLastName: yup.string().max(255, translations('validation_too_long')).nullable(),
    contactPersonEmail: yup
      .string()
      .required(translations('validation_required'))
      .max(255, translations('validation_too_long')),
    contactPersonPhone: yup.string().max(20, translations('validation_too_long')).nullable(),
    billingAddressStreetNumber: yup.string().max(255, translations('validation_too_long')).nullable(),
    billingAddressAddition: yup.string().max(255, translations('validation_too_long')).nullable(),
    billingAddressZipCode: yup.string().max(255, translations('validation_too_long')).nullable(),
    billingAddressCity: yup.string().max(255, translations('validation_too_long')).nullable(),
    billingAddressCountry: yup.string().max(255, translations('validation_too_long')).nullable(),
    billingPersonTitle: yup.string().max(255, translations('validation_too_long')).nullable(),
    billingName: yup.string().max(255, translations('validation_too_long')).nullable(),
    billingEmail: yup.string().max(255, translations('validation_too_long')).nullable(),
    specialRequests: yup.string().max(255, translations('validation_too_long')).nullable(),
  });
};

export interface CustomerUpdateFormData {
  status?: CustomerStatus;
  salesOrganisation?: string;
  salesPersonName?: string;
  customerName?: string;
  city?: string | null;
  desiredStartDate?: string | null;
  salesPersonEmail?: string;
  salesPersonPhone?: string;
  wholesaleCustomerId?: string | null;
  customerFirstName?: string | null;
  customerLastName?: string | null;
  customerEmail?: string;
  customerPhone?: string | null;
  addressStreetNumber?: string | null;
  addressAdditionalInformation?: string | null;
  zipCode?: string | null;
  country?: string | null;
  billingPersonTitle?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  isSepaDesired?: boolean | null;
  specialRequests?: string | null;
  contractFileUrl?: string | null;
  isSocialMediaPressPermitted?: boolean | null;
  starterSet?: StarterSetSizeSelect;
  relevoCalculatorFilesUrls?: string[] | null;
  hubspotUrl: string | null;
  keyAccountId: string | null;
}
