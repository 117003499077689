import React, { JSX, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { ImageResponse } from '../../../modules/Restaurants/models/restaurant-image-response.model';

interface ImageOrderBoxProps {
  isEditOrderMode: boolean;
  onDelete?: (id: string) => () => void;
  setBoxes?: (data?: ImageResponse[]) => void;
  boxes?: ImageResponse[];
}

export const ImageOrderBox = ({ onDelete, setBoxes, boxes, isEditOrderMode }: ImageOrderBoxProps): JSX.Element => {
  const translations = useTranslations();
  const [dragId, setDragId] = useState<string>();

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    const target = (event.target as HTMLDivElement).id;
    setDragId(target);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const target = (event.target as HTMLDivElement).id;

    const dragBox = boxes?.find((box) => box.file?.id === dragId);
    const dropBox = boxes?.find((box) => box.file?.id === target);

    if (dragBox && dropBox) {
      const dragBoxOrder = dragBox.order;
      const dropBoxOrder = dropBox.order;

      const newBoxState = boxes?.map((box) => {
        if (box.file?.id === dragId) {
          box.order = dropBoxOrder;
        }
        if (box.file?.id === target) {
          box.order = dragBoxOrder;
        }
        return box;
      });

      if (setBoxes) {
        setBoxes(newBoxState);
      }
    }
  };

  return (
    <>
      {boxes
        ?.filter((box) => !!box.file)
        .sort((a, b) => a.order - b.order)
        .map((box) => (
          <Box key={box.file?.id}>
            {box.file && (
              <Box
                cursor={isEditOrderMode ? 'pointer' : 'auto'}
                draggable={isEditOrderMode}
                id={box.file.id}
                onDragOver={(ev) => ev.preventDefault()}
                onDragStart={handleDrag}
                onDrop={handleDrop}
                backgroundImage={box.file.url}
                height="300px"
                backgroundSize="cover"
                position="relative"
              >
                {!isEditOrderMode && onDelete ? (
                  <Button position="absolute" bottom="20px" right="20px" onClick={onDelete(box.file.id)}>
                    {translations('delete_image')}
                  </Button>
                ) : null}
              </Box>
            )}
          </Box>
        ))}
    </>
  );
};
