import { useState } from 'react';

import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { useDebounce } from './useDebounce';

export const useLocationsSetState = () => {
  const [restaurantSearchText, setRestaurantSearchText] = useState('');
  const [keyAccountGroupsSearchText, setKeyAccountGroupSearchText] = useState('');
  const debouncedRestaurantSearch = useDebounce(restaurantSearchText, DEBOUNCE_SEARCH_TIME);
  const debouncedKeyAccountGroupSearch = useDebounce(keyAccountGroupsSearchText, DEBOUNCE_SEARCH_TIME);

  return {
    setRestaurantSearchText,
    setKeyAccountGroupSearchText,
    debouncedRestaurantSearch,
    debouncedKeyAccountGroupSearch,
  };
};
