import { BaseQueryParams, Paginated } from 'common/models';
import apiPaths from 'config/apiPaths';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import { RestaurantRequest } from '../modules/Restaurants/models';
import { RestaurantDetailsResponse, RestaurantResponse } from '../modules/Restaurants/models';
import axiosInstance from './axios';

export const getWarehouses = ({
  searchText,
  offset,
  limit,
}: BaseQueryParams): Promise<Paginated<RestaurantResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance
    .get<Paginated<RestaurantResponse>>(`${apiPaths.WAREHOUSES}?${searchParams}`)
    .then(({ data }) => data);
};

export const getWarehouse = (id: string): Promise<RestaurantDetailsResponse> =>
  axiosInstance.get<RestaurantDetailsResponse>(`${apiPaths.WAREHOUSES}/${id}`).then(({ data }) => data);

export const createWarehouse = (createRestaurantRequest: RestaurantRequest): Promise<RestaurantDetailsResponse> =>
  axiosInstance.post(`${apiPaths.WAREHOUSES}`, createRestaurantRequest);

export const updateWarehouse = (
  id: string,
  updateRestaurantRequest: RestaurantRequest,
): Promise<RestaurantDetailsResponse> => axiosInstance.patch(`${apiPaths.WAREHOUSES}/${id}`, updateRestaurantRequest);
