import React, { JSX } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import styled from '@emotion/styled';

type LoaderProps = {
  show: boolean;
  children: React.ReactNode;
};

const SpinnerContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: var(--chakra-colors-gray-700);
  opacity: 0.3;
  color: var(--chakra-colors-gray-200);
`;

export const FullPageLoader = ({ show, children }: LoaderProps): JSX.Element => {
  return (
    <Box position="relative">
      {show && (
        <SpinnerContainer>
          <Spinner role="status" size="xl" m={10} />
        </SpinnerContainer>
      )}
      {children}
    </Box>
  );
};
