import React, { JSX, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { Box, Input, Stack, useNumberInput } from '@chakra-ui/react';
import { FormGroup, Select } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCategoriesList } from 'services/categories';

import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { Category } from '../Categories/model/Category.model';
import { RestaurantSelect } from '../RestaurantSelect/RestaurantSelect';
import { ITEMS_VOLUME_INPUT_MAX_VALUE, ITEMS_VOLUME_INPUT_MIN_VALUE } from './utils/assign-product.consts';

type AssignProductFormProps = {
  scannedProductsLength: number;
};

const AssignProductForm = ({ scannedProductsLength }: AssignProductFormProps): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [categorySearchText, setCategorySearchText] = useState('');

  const {
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
    control,
    trigger,
  } = useFormContext();

  const { data: searchedCategories, isLoading: isLoadingCategories } = useCategoriesList({
    limit: 100,
    offset: 1,
    searchText: categorySearchText,
  });

  const { getInputProps } = useNumberInput({
    step: 1,
    defaultValue: ITEMS_VOLUME_INPUT_MIN_VALUE,
    value: getValues('itemsVolume') || '',
    min: ITEMS_VOLUME_INPUT_MIN_VALUE,
    max: ITEMS_VOLUME_INPUT_MAX_VALUE,
    precision: 0,
    onChange: (valueAsString, valueAsNumber) => {
      setValue('itemsVolume', valueAsNumber);
      void trigger('itemsVolume');
    },
  });

  return (
    <form data-testid="assign-product-form">
      <Box width={['40%', '40%', '25%']} mt={5}>
        <FormGroup
          label={translations('product_assignment_items_volume_label')}
          inputId="itemsVolume"
          validationError={(errors.itemsVolume as FieldError)?.message}
        >
          <Input {...getInputProps()} name="itemsVolume" />
        </FormGroup>
      </Box>
      <Stack direction={['column', 'column', 'row']}>
        <Box width={['100%', '100%', '50%']}>
          <FormGroup
            label={translations('product_assignment_search_restaurant_label')}
            inputId="restaurant"
            validationError={(errors.restaurant as FieldError)?.message}
          >
            <Controller
              control={control}
              name="restaurant"
              render={({ field: { value, onChange, name } }) => (
                <RestaurantSelect
                  name={name}
                  value={value}
                  onChange={(inputValue) => {
                    onChange(inputValue);
                    clearErrors('restaurant');
                  }}
                  isDisabled={!!scannedProductsLength}
                />
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['100%', '100%', '50%']}>
          <FormGroup
            label={translations('product_assignment_category_label')}
            inputId="category"
            validationError={(errors.category as FieldError)?.message}
          >
            <Controller
              control={control}
              name="category"
              render={({ field: { value, onChange, name } }) => (
                <Select<Category>
                  placeholder={translations('product_assignment_category_placeholder')}
                  name={name}
                  getOptionLabel={(option) => (option as Category).translations[language]}
                  getOptionValue={(option) => (option as Category).id}
                  value={value}
                  onChange={(inputValue) => {
                    onChange(inputValue);
                    clearErrors('category');
                  }}
                  onInputChange={(searchText) => setCategorySearchText(searchText)}
                  options={searchedCategories?.items}
                  isLoading={isLoadingCategories}
                  isDisabled={!!scannedProductsLength}
                />
              )}
            />
          </FormGroup>
        </Box>
      </Stack>
    </form>
  );
};

export default AssignProductForm;
