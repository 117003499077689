import React, { JSX, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'contexts/LocalizationContext';

import { ChildPageHeading } from '../../../common/components/ChildPageHeading';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../../common/constants/common';
import { MetaCategory } from '../../MetaCategories/model/MetaCategory';
import { RestaurantDetailsResponse, RestaurantRequest } from '../models';
import RestaurantForm, { RestaurantFormMode } from '../RestaurantForm/RestaurantForm';
import { StarterSetSize, useRestaurantFormValidationSchema } from '../RestaurantForm/RestaurantForm.form';
import {
  getKeyAccountGroupById,
  getMetaCategoryById,
  getPromotionById,
  getTagById,
} from './utils/import-restaurants.helper';
import { useRestaurantCharacteristicsLists } from './utils/use-restaurants-characteristics-lists.hook';

type Props = {
  onSubmit: (request: RestaurantRequest) => void;
  onCancel: () => void;
  data?: RestaurantDetailsResponse;
};

type FormModel = Omit<RestaurantDetailsResponse, 'metaCategories'> & {
  metaCategories: MetaCategory[];
};

const EditImportedRestaurant = ({ data, onSubmit, onCancel }: Props): JSX.Element => {
  const translations = useTranslations();
  const onUpdateRestaurantRow = (updateRestaurantRow: RestaurantRequest) => onSubmit(updateRestaurantRow);
  const schema = useRestaurantFormValidationSchema();
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  const { keyAccounts, tags, metaCategories, promotions } = useRestaurantCharacteristicsLists(
    ALL_PAGINATED_LIST_ITEMS_PARAMS,
  );

  const watchCity = methods?.watch('city');
  const watchClosingSource = methods?.watch('closingSource');
  const watchPromotions = methods?.watch('promotions');
  const watchTags = methods?.watch('tags');
  const watchMetaCategories = methods?.watch('metaCategories');
  const watchStarterSetSize = methods?.watch('starterSetSize');
  const watchKeyAccountId: string | undefined = methods?.watch('keyAccountId');

  const validateField = useCallback(
    (fieldName: keyof FormModel, condition?: boolean, fieldNameTranslation?: string) => {
      if (condition) {
        methods?.setError(fieldName, {
          message: translations('import_restaurants_validate_input_in_modal_form', {
            '{{fieldName}}': fieldNameTranslation || fieldName,
          }),
          type: 'validate',
        });
      } else {
        methods?.clearErrors(fieldName);
      }
    },
    // FYI: translations in deps cause issues `Rendered more hooks than during the previous render`
    // eslint-disable-next-line
    [methods?.setError],
  );

  useEffect(() => {
    if (data) {
      methods?.reset(data);
      methods?.trigger([
        'restaurantName',
        'restaurantDetail',
        'restaurantAddress',
        'streetName',
        'googlePlaceId',
        'phoneNumber',
        'addressLinkAndroid',
        'websiteLink',
        'deliverers.wolt.url',
        'deliverers.lieferando.url',
        'deliverers.uberEats.url',
        'categoryGroupPrice.BOWLS',
        'categoryGroupPrice.CUPS',
      ]);
    }
  }, [methods, data]);

  useEffect(() => {
    if (data) {
      const hasInvalidKeyAccount = watchKeyAccountId && !getKeyAccountGroupById(watchKeyAccountId, keyAccounts);
      const hasInvalidClosingSource = watchClosingSource?.name.length && !watchClosingSource?.id;
      const hasInvalidPromotion = watchPromotions?.some((item: any) => !getPromotionById(item, promotions));
      const hasInvalidTags = watchTags?.some((item: any) => !getTagById(item, tags));
      const hasInvalidMetaCategories = watchMetaCategories?.some(
        (item: any) => !getMetaCategoryById(item, metaCategories),
      );
      const hasInvalidStarterSetSize =
        watchStarterSetSize?.length && !Object.values(StarterSetSize).includes(watchStarterSetSize);

      validateField(
        'city',
        Boolean((watchCity?.translations.en.length || watchCity?.translations.de.length) && !watchCity?.id),
      );
      validateField('keyAccountId', Boolean(hasInvalidKeyAccount), 'F&B group');
      validateField('closingSource', Boolean(hasInvalidClosingSource));
      validateField('promotions', Boolean(hasInvalidPromotion));
      validateField('tags', Boolean(hasInvalidTags));
      validateField('metaCategories', Boolean(hasInvalidMetaCategories));
      validateField('starterSetSize', Boolean(hasInvalidStarterSetSize));
    }
  }, [
    methods,
    data,
    watchCity,
    watchKeyAccountId,
    watchClosingSource,
    watchMetaCategories,
    watchPromotions,
    watchStarterSetSize,
    watchTags,
    keyAccounts,
    promotions,
    tags,
    metaCategories,
    validateField,
  ]);

  return (
    <>
      <ChildPageHeading>{translations('restaurants_update_header')}</ChildPageHeading>

      <FormProvider {...methods}>
        <RestaurantForm
          methods={methods}
          mode={RestaurantFormMode.IMPORT}
          isLoading={false}
          onSubmit={onUpdateRestaurantRow}
          model={data}
          onCancel={onCancel}
          hasFormFooterCustomContent={true}
          customFormFooter={null}
        />
      </FormProvider>
    </>
  );
};

export default EditImportedRestaurant;
