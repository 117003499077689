import { generatePath } from 'react-router-dom';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { FormSubmitData } from 'modules/KeyAccountGroups/models/form-submit-data';
import { KeyAccountGroupsResponse } from 'modules/KeyAccountGroups/models/key-account-groups-response';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import { CreateReturnCodesRequest } from '../modules/KeyAccountGroups/KeyAccountGroupRestaurants/components/RestaurantsReturnCodes/models/create-return-codes-request';
import { KeyAccountGroupReturnCodesQueryRequest } from '../modules/KeyAccountGroups/KeyAccountGroupReturnCodes/models/key-account-group-return-codes-query-request';
import { KeyAccountGroupCreateReturnCodesResponse } from '../modules/KeyAccountGroups/models/key-account-group-create-return-codes-response';
import { KeyAccountGroupImageRequest } from '../modules/KeyAccountGroups/models/key-account-group-image-request';
import { KeyAccountGroupImageResponse } from '../modules/KeyAccountGroups/models/key-account-group-image-response';
import { KeyAccountGroupImagesOrderRequest } from '../modules/KeyAccountGroups/models/key-account-group-images-order-request';
import { ImageResponse } from '../modules/Restaurants/models/restaurant-image-response.model';
import { RestaurantReturnCodeResponse } from '../modules/Restaurants/RestaurantReturnCodes/models/restaurant-return-code-response';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export const getKeyAccountGroupsList = ({
  searchText,
  offset,
  limit,
}: BaseQueryParams): Promise<Paginated<KeyAccountGroupsResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get(apiPaths.KEY_ACCOUNT_GROUPS, { params: searchParams }).then(({ data }) => data);
};

export const createKeyAccountGroup = (keyAccountGroup: FormSubmitData): Promise<KeyAccountGroupsResponse> =>
  axiosInstance.post(apiPaths.KEY_ACCOUNT_GROUPS, keyAccountGroup);

export const deleteKeyAccountGroup = (keyAccountId: string): Promise<void> => {
  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_KEY_ACCOUNT_GROUP, { keyAccountId });
  return axiosInstance.delete(url);
};

export const deleteKeyAccountGroupRestaurant = (keyAccountId: string, restaurantId: string): Promise<void> => {
  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_RESTAURANTS_RESTAURANT, { keyAccountId, restaurantId });
  return axiosInstance.delete(url);
};

export const addRestaurantToKeyAccountGroup = (keyAccountId: string, restaurantId: string): Promise<void> => {
  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_RESTAURANTS_RESTAURANT, { keyAccountId, restaurantId });
  return axiosInstance.post(url);
};

export const editKeyAccountGroup = (
  keyAccountId: string,
  keyAccountGroupEdit: FormSubmitData,
): Promise<KeyAccountGroupsResponse> => {
  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_KEY_ACCOUNT_GROUP, { keyAccountId });
  return axiosInstance.patch(url, keyAccountGroupEdit);
};

export const getKeyAccountGroup = (keyAccountId: string): Promise<KeyAccountGroupsResponse> => {
  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_KEY_ACCOUNT_GROUP, { keyAccountId });
  return axiosInstance.get(url).then(({ data }) => data);
};

export const getKeyAccountGroupImages = (keyAccountId: string): Promise<KeyAccountGroupImageResponse[]> => {
  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_IMAGES, { keyAccountId });
  return axiosInstance.get(url).then(({ data }) => data);
};

export const deleteKeyAccountGroupImage = (keyAccountId: string, imageId: string): Promise<void> => {
  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_IMAGES_IMAGE, { keyAccountId, imageId });
  return axiosInstance.delete(url);
};

export const addKeyAccountGroupImage = (
  keyAccountId: string,
  imageRequest: KeyAccountGroupImageRequest,
): Promise<ImageResponse> => {
  const formData = createFormData(imageRequest);
  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_IMAGES, { keyAccountId });
  return axiosInstance
    .post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data);
};

export const updateKeyAccountGroupImagesOrder = (
  keyAccountId: string,
  imagesRequest: KeyAccountGroupImagesOrderRequest[],
): Promise<number> => {
  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_IMAGES, { keyAccountId });
  return axiosInstance.put(url, { images: imagesRequest }).then((res) => res.status);
};

export const createReturnCodes = (
  keyAccountId: string,
  request: CreateReturnCodesRequest,
): Promise<KeyAccountGroupCreateReturnCodesResponse> => {
  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_CREATE_RETURN_CODES, { keyAccountId });
  return axiosInstance.post(url, request).then(({ data }) => data);
};

export const getKeyAccountGroupReturnCodes = (
  keyAccountId: string,
  queryRequest: KeyAccountGroupReturnCodesQueryRequest,
): Promise<Paginated<RestaurantReturnCodeResponse>> => {
  const { offset, limit, status, orderType, restaurantIds, createdAt, orderField, returnCodeIds } = queryRequest;
  const additionalParams = new Map();
  additionalParams.set('status', status);
  additionalParams.set('createdAt', createdAt);
  additionalParams.set('orderType', orderType);
  additionalParams.set('orderField', orderField);

  const searchParams = buildBasicPaginatedSearchParams(offset, limit, undefined, additionalParams);
  restaurantIds?.forEach((restaurantId) => searchParams.append('restaurantIds[]', restaurantId));
  returnCodeIds?.forEach((returnCodeId) => searchParams.append('returnCodeIds[]', returnCodeId));

  const url = generatePath(apiPaths.KEY_ACCOUNT_GROUPS_RETURN_CODES, { keyAccountId });
  return axiosInstance.get(url, { params: searchParams }).then(({ data }) => data);
};
