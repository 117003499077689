import colors from 'styles/colors';

export default {
  variants: {
    pageHeading: {
      fontFamily: 'Covered By Your Grace',
      color: colors.orange[500],
    },
  },
};
