import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessages } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCreateTag } from 'services/tags';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import TagForm from './TagForm';

const CreateTag = (): JSX.Element => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { mutate: createTag, isLoading, error } = useCreateTag();

  return (
    <>
      <ChildPageHeading>{translations('tags_create_tag')}</ChildPageHeading>
      <ErrorMessages errors={error} />
      <TagForm
        isLoading={isLoading}
        onSubmit={(createTagRequest) =>
          createTag(createTagRequest, {
            onSuccess: async () => navigate(Routes.TAGS_PATH),
          })
        }
      />
    </>
  );
};

export default CreateTag;
