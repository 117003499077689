const urlPattern = new RegExp(
  '^(http:\\/\\/|https:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})' + // domain name
    '(\\/[-a-z\\d%_.~+]*)*', // path
  'i',
);

export const hasUrlHttpProtocol = (url: string): boolean => {
  return url.startsWith('http://') || url.startsWith('https://');
};

export const addHttpsProtocol = (url: string): string => {
  return `https://${url}`;
};

export const isValidStringUrl = (url: string): boolean => {
  try {
    return urlPattern.test(url);
  } catch (e) {
    return false;
  }
};

export const getStringUrl = (url?: string | null): string | null => {
  if (url && url.length) {
    return !hasUrlHttpProtocol(url) ? addHttpsProtocol(url) : url;
  } else {
    return null;
  }
};

export const generateUrlForGoogleFaviconApi = (url: string): string =>
  `https://www.google.com/s2/favicons?domain=${url}&sz=128`;
