import React, { JSX } from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { FormFooter, NumberInput } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { AssignedItemsExtendRequest } from './models/assigned-items-extend-request';

type FormModel = AssignedItemsExtendRequest;

type ExtendAssignedItemFormProps = {
  isLoading: boolean;
  onSubmit: (request: AssignedItemsExtendRequest) => void;
  userId: string;
};

const ExtendAssignedItemForm = ({ isLoading, onSubmit }: ExtendAssignedItemFormProps): JSX.Element => {
  const translations = useTranslations();
  const { register, handleSubmit } = useForm<FormModel>();

  const onFormSubmit = handleSubmit(({ ...form }: FormModel) => {
    onSubmit({ ...form, daysNumber: Number(form.daysNumber) });
  });

  return (
    <form data-testid="form" onSubmit={onFormSubmit}>
      <FormControl>
        <FormLabel htmlFor="daysNumber">{translations('assigned_items_extend_form_days_number')}</FormLabel>
        <NumberInput name="daysNumber" register={register('daysNumber')} />
      </FormControl>
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default ExtendAssignedItemForm;
