import React, { JSX } from 'react';
import { useTheme } from '@chakra-ui/react';

type TableHeaderProps = {
  width: string;
  text?: string;
};

export const TableHeader = ({ width, text }: TableHeaderProps): JSX.Element => {
  const { colors } = useTheme();
  return (
    <div style={{ width, textAlign: 'left' }}>
      <h2 style={{ fontSize: '11px', color: colors.grey[200] }}>{text}</h2>
    </div>
  );
};

export const TableItem = ({ width, text }: TableHeaderProps): JSX.Element => {
  const { colors } = useTheme();
  return (
    <div style={{ width, textAlign: 'left' }}>
      <h2 style={{ fontSize: '12px', color: colors.black }}>{text}</h2>
    </div>
  );
};
