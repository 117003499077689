import React, { JSX } from 'react';
import { Button, Flex, Spinner } from '@chakra-ui/react';
import { ErrorMessages, MainPageContent, PageHeading } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import { useMakeAllPayments, useUpdateAllUsersBTPaymentsInfo } from 'services/payments';

const PaymentsPage = (): JSX.Element => {
  const { mutate: makeAllPayments, isLoading, error: makeAllPaymentsError } = useMakeAllPayments();
  const {
    mutate: updateAllUsersBTPaymentsInfo,
    isLoading: isUpdateAllLoading,
    error: updateAllError,
  } = useUpdateAllUsersBTPaymentsInfo();

  const translations = useTranslations();
  const onMakePaymentsClicked = React.useCallback(() => {
    const confirmed = window.confirm(translations('payments_make_all_confirmation'));
    if (confirmed) {
      makeAllPayments({});
    }
  }, [translations, makeAllPayments]);

  const onUpdateAllUsersBTPaymentsInfo = React.useCallback(() => {
    const confirmed = window.confirm(translations('payments_update_all_BT_confirmation'));
    if (confirmed) {
      updateAllUsersBTPaymentsInfo({});
    }
  }, [translations, updateAllUsersBTPaymentsInfo]);
  return (
    <MainPageContent>
      <PageHeading>{translations('payments_header')}</PageHeading>
      <ErrorMessages errors={makeAllPaymentsError} />
      <Flex justifyContent="center">
        <Button mr={2} onClick={() => onMakePaymentsClicked()}>
          {isLoading ? <Spinner /> : translations('payments_make_all')}
        </Button>
      </Flex>
      <ErrorMessages errors={updateAllError} />
      <Flex justifyContent="center" mt={2}>
        <Button mr={2} onClick={() => onUpdateAllUsersBTPaymentsInfo()}>
          {isUpdateAllLoading ? <Spinner /> : translations('payments_update_all_BT_payments_methods')}
        </Button>
      </Flex>
    </MainPageContent>
  );
};

export default PaymentsPage;
