import React, { JSX } from 'react';
import { config } from 'config/config';
import { LOCALIZATION_CONFIG } from 'config/configConsts';

type TimeProps = {
  time: Date | string;
  mode?: 'date' | 'time' | 'full';
};
export const Time = ({ time, mode = 'date' }: TimeProps): JSX.Element => {
  if (!time) return <div>No date.</div>;

  const options = {
    ...((mode === 'date' || mode === 'full') && { year: 'numeric', month: 'numeric', day: '2-digit' }),
    ...((mode === 'time' || mode === 'full') && { hour: 'numeric', minute: 'numeric', second: 'numeric' }),
  };
  return (
    <>
      {Intl.DateTimeFormat(LOCALIZATION_CONFIG[config.language].locale, options as Intl.DateTimeFormatOptions).format(
        new Date(time),
      )}
    </>
  );
};
