import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { NewsRequest } from 'modules/News/models/news-request';
import { NewsResponse } from 'modules/News/models/news-response';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export const getNewsList = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<NewsResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get<Paginated<NewsResponse>>(`${apiPaths.NEWS}?${searchParams}`).then(({ data }) => data);
};

export const getNews = (id: string): Promise<NewsResponse> => {
  return axiosInstance.get<NewsResponse>(`${apiPaths.NEWS}/${id}`).then(({ data }) => data);
};

export const createNews = (request: NewsRequest): Promise<NewsResponse> => {
  const data = createFormData(request);
  return axiosInstance.post(`${apiPaths.NEWS}`, data);
};

export const updateNews = (id: string, request: NewsRequest): Promise<NewsResponse> => {
  const data = createFormData(request);
  return axiosInstance.put(`${apiPaths.NEWS}/${id}`, data);
};

export const deleteNews = (id: string): Promise<void> => {
  return axiosInstance.delete(`${apiPaths.NEWS}/${id}`);
};
