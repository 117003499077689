import React, { JSX } from 'react';

import { FallbackValue } from '../../common/components/FallbackValue/FallbackValue';
import { useTranslations } from '../../contexts/LocalizationContext';

const anonymizedValue = 'deleted';

const AnonymizedUserFallbackValue = ({ value }: { value: React.ReactNode }): JSX.Element => {
  const translations = useTranslations();
  return (
    <FallbackValue
      fallback={translations('user_table_anonymized_value')}
      value={value}
      isFallbackVisible={value === anonymizedValue}
    />
  );
};

export default AnonymizedUserFallbackValue;
