export const DEBOUNCE_SEARCH_TIME = 300;
export const DEBOUNCE_URL_UPDATE = 1000;

export const LOCALIZATION_CONFIG = {
  de: {
    locale: 'de-DE',
    dateFormat: 'dd/MM/yyyy',
  },
};

export const DATE_PICKER_LOCALIZATION_CONFIG = {
  locale: 'en-EN',
  dateFormat: 'dd/MM/yyyy',
};
