import React, { JSX, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter, FormGroup } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import { City } from 'modules/City/models/City';
import { RestaurantRequest } from 'modules/Restaurants/models/restaurant-request.model';
import { RestaurantDetailsResponse } from 'modules/Restaurants/models/restaurant-response.model';
import { useCitiesList } from 'services/cities';
import * as yup from 'yup';

import FormInput from '../../common/components/FormInput/FormInput';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { RestaurantStatus } from '../Restaurants/RestaurantForm/RestaurantForm.form';

type WarehouseFormProps = {
  isLoading: boolean;
  formValues?: RestaurantDetailsResponse;
  onSubmit: (request: RestaurantRequest) => void;
};

const WarehouseForm = ({ isLoading, onSubmit, formValues }: WarehouseFormProps): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [citiesSearchText, setCitiesSearchText] = useState('');
  const schema = yup.object().shape({
    restaurantName: yup
      .string()
      .max(255, translations('validation_too_long'))
      .required(translations('validation_required')),
    restaurantDetail: yup.string().max(255, translations('validation_too_long')),
    restaurantAddress: yup.string().max(255, translations('validation_too_long')),
    city: yup.object().nullable().shape({ id: yup.string().optional() }),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { data: paginatedCities } = useCitiesList({ limit: 0, offset: 1, searchText: citiesSearchText });

  useEffect(() => {
    if (formValues) {
      reset(formValues);
    }
  }, [reset, formValues]);

  const onFormSubmit = handleSubmit((data) => {
    onSubmit({
      restaurantName: data.restaurantName,
      restaurantDetail: data.restaurantDetail,
      restaurantAddress: data.restaurantAddress,
      cityId: data.city?.id,
      status: RestaurantStatus.LIVE_INVISIBLE,
      keyAccountId: '1',
    });
  });

  return (
    <form data-testid="form" onSubmit={onFormSubmit} autoComplete="off">
      <FormInput
        id={'restaurantName'}
        label={translations('warehouses_form_name')}
        validationError={errors.restaurantName?.message}
        useFormRegisterReturn={register('restaurantName')}
        setValue={setValue}
      />
      <FormInput
        id={'restaurantDetail'}
        label={translations('warehouses_form_detail')}
        validationError={errors.restaurantDetail?.message}
        useFormRegisterReturn={register('restaurantDetail')}
        setValue={setValue}
      />
      <FormGroup label={translations('warehouses_form_city')} inputId="city">
        <Controller
          control={control}
          name="city"
          render={({ field: { value, onChange } }) => (
            <Select
              inputId="city"
              placeholder=""
              getOptionLabel={(option: City) => option.translations[language]}
              getOptionValue={(option: City) => option.id}
              value={value as City}
              onChange={onChange}
              onInputChange={(searchText) => setCitiesSearchText(searchText)}
              options={paginatedCities?.items}
            />
          )}
        />
      </FormGroup>
      <Box mb={10}>
        <FormInput
          id={'restaurantAddress'}
          label={translations('warehouses_form_address')}
          validationError={errors.restaurantAddress?.message}
          useFormRegisterReturn={register('restaurantAddress')}
          setValue={setValue}
        />
      </Box>
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default WarehouseForm;
