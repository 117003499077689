import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import {
  RestaurantUserRequest,
  RestaurantUserResponse,
  RestaurantUserSettingsRequest,
} from 'modules/RestaurantCredentials/models';
import { RestaurantOpeningHoursResponse } from 'modules/Restaurants/models/restaurant-opening-hours-response.model';
import { RestaurantRequest } from 'modules/Restaurants/models/restaurant-request.model';
import { RestaurantDetailsResponse, RestaurantResponse } from 'modules/Restaurants/models/restaurant-response.model';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import { KeyAccountGroupRestaurantsQueryRequest } from '../modules/KeyAccountGroups/models/key-account-group-restaurants-query-request';
import { CreateRestaurantUsersRequest } from '../modules/RestaurantCredentials/models/create-restaurant-users-request.model';
import { CreateRestaurantUsersResponse } from '../modules/RestaurantCredentials/models/create-restaurant-users-response.model';
import { CreateRestaurantsResponse } from '../modules/Restaurants/ImportRestaurants/model/create-restaurants.response.model';
import { CreateRestaurantsRequest } from '../modules/Restaurants/ImportRestaurants/model/create-restaurants-request.model';
import { RestaurantFilterMode } from '../modules/Restaurants/models/enums/restaurant-filter-mode.enum';
import { RestaurantImageRequest } from '../modules/Restaurants/models/restaurant-image-request.model';
import { ImageResponse, RestaurantImagesResponse } from '../modules/Restaurants/models/restaurant-image-response.model';
import { RestaurantImagesOrderRequest } from '../modules/Restaurants/models/restaurant-images-order.request';
import { UpdateRestaurantsRequest } from '../modules/Restaurants/UpdateMultipleRestaurants/model/update-restaurants.request.model';
import { UpdateRestaurantsResponse } from '../modules/Restaurants/UpdateMultipleRestaurants/model/update-restaurants.response.model';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export const getRestaurantsByIds = ({
  offset,
  limit,
  restaurantIds,
}: Omit<BaseQueryParams, 'searchText'> & {
  restaurantIds?: string[];
}): Promise<Paginated<RestaurantResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit);
  return axiosInstance
    .get<Paginated<RestaurantResponse>>(`${apiPaths.RESTAURANTS}?${searchParams}`, { params: { restaurantIds } })
    .then(({ data }) => data);
};

export const getRestaurants = ({
  searchText,
  offset,
  limit,
  filterMode,
}: Omit<BaseQueryParams, 'searchText'> & {
  searchText?: string;
  filterMode?: RestaurantFilterMode;
}): Promise<Paginated<RestaurantResponse>> => {
  const additionalParams = new Map();
  additionalParams.set('filterMode', filterMode);
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText, additionalParams);
  return axiosInstance
    .get<Paginated<RestaurantResponse>>(`${apiPaths.RESTAURANTS}?${searchParams}`)
    .then(({ data }) => data);
};

export const getKeyAccountGroupRestaurants = (
  params: KeyAccountGroupRestaurantsQueryRequest,
): Promise<Paginated<RestaurantResponse>> => {
  const { offset, limit, searchText, keyAccountId, orderField, orderType } = params;
  const additionalParams = new Map();
  additionalParams.set('keyAccountId', keyAccountId);
  additionalParams.set('orderField', orderField);
  additionalParams.set('orderType', orderType);
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText, additionalParams);
  return axiosInstance
    .get<Paginated<RestaurantResponse>>(`${apiPaths.RESTAURANTS}?${searchParams}`)
    .then(({ data }) => data);
};

export const getRestaurant = (id: string): Promise<RestaurantDetailsResponse> => {
  return axiosInstance.get<RestaurantDetailsResponse>(`${apiPaths.RESTAURANTS}/${id}`).then(({ data }) => data);
};

export const getRestaurantOpeningHours = ({
  id,
}: {
  id: string;
}): Promise<Record<number, RestaurantOpeningHoursResponse>> => {
  return axiosInstance.get(`${apiPaths.RESTAURANTS}/${id}/opening-hours`).then(({ data }) => data);
};

export const createRestaurant = (createRestaurantRequest: RestaurantRequest): Promise<RestaurantDetailsResponse> => {
  return axiosInstance.post(`${apiPaths.RESTAURANTS}`, createRestaurantRequest).then(({ data }) => data);
};

export const updateRestaurant = (
  id: string,
  updateRestaurantRequest: RestaurantRequest,
): Promise<RestaurantDetailsResponse> => {
  return axiosInstance.patch(`${apiPaths.RESTAURANTS}/${id}`, updateRestaurantRequest);
};

export const getRestaurantUsers = ({
  searchText,
  offset,
  limit,
}: BaseQueryParams): Promise<Paginated<RestaurantUserResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance
    .get<Paginated<RestaurantUserResponse>>(`${apiPaths.RESTAURANT_USERS}?${searchParams}`)
    .then(({ data }) => data);
};

export const getRestaurantUser = (id: string): Promise<RestaurantUserResponse> =>
  axiosInstance.get<RestaurantUserResponse>(`${apiPaths.RESTAURANT_USERS}/${id}`).then(({ data }) => data);

export const updateRestaurantUser = (values: RestaurantUserSettingsRequest): Promise<RestaurantUserResponse> => {
  return axiosInstance.put(`${apiPaths.RESTAURANT_USERS}/${values.id}`, values);
};

export const createRestaurantCredential = (
  createRestaurantCredentialRequest: RestaurantUserRequest,
): Promise<RestaurantUserResponse> => {
  const d = {
    formFields: [
      {
        id: 'email',
        value: createRestaurantCredentialRequest.email,
      },
      {
        id: 'password',
        value: createRestaurantCredentialRequest.password,
      },
    ],
    role: createRestaurantCredentialRequest.role,
    locations: createRestaurantCredentialRequest.locations,
    firstName: createRestaurantCredentialRequest.firstName,
    lastName: createRestaurantCredentialRequest.lastName,
  };
  return axiosInstance.post(`${apiPaths.RESTAURANT_USERS}`, d).then(({ data }) => data);
};

export const createRestaurantUsers = (
  createRestaurantUsersRequest: CreateRestaurantUsersRequest,
): Promise<CreateRestaurantUsersResponse> => {
  return axiosInstance
    .post(`${apiPaths.RESTAURANT_USERS}/batch`, createRestaurantUsersRequest)
    .then(({ data }) => data);
};

export const deleteRestaurantUser = (id: string): Promise<void> =>
  axiosInstance.delete(`${apiPaths.RESTAURANT_USERS}/${id}`);

export const getRestaurantImages = (restaurantId: string): Promise<RestaurantImagesResponse> => {
  return axiosInstance
    .get<RestaurantImagesResponse>(`${apiPaths.RESTAURANTS}/${restaurantId}/images`)
    .then(({ data }) => data);
};

export const deleteRestaurantImage = (restaurantId: string, id: string): Promise<void> =>
  axiosInstance.delete(`${apiPaths.RESTAURANTS}/${restaurantId}/images/${id}`);

export const addRestaurantImage = (
  restaurantId: string,
  imageRequest: RestaurantImageRequest,
): Promise<ImageResponse> => {
  const formData = createFormData(imageRequest);
  return axiosInstance
    .post(`${apiPaths.RESTAURANTS}/${restaurantId}/images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data);
};

export const updateRestaurantImagesOrder = (
  restaurantId: string,
  imagesRequest: RestaurantImagesOrderRequest[],
): Promise<number> => {
  return axiosInstance
    .put(`${apiPaths.RESTAURANTS}/${restaurantId}/images`, { images: imagesRequest })
    .then((res) => res.status);
};

export const getRestaurantDetailsFromGoogle = (
  placeId: string,
  fields?: string[],
): Promise<Partial<RestaurantDetailsResponse>> => {
  const searchParams = new URLSearchParams({
    place_id: placeId,
  });
  fields?.forEach((field) => searchParams.append('fields[]', field));
  return axiosInstance
    .get<Partial<RestaurantDetailsResponse>>(`${apiPaths.GOOGLE_PLACE_DETAILS}?${searchParams}`)
    .then(({ data }) => data);
};

export const createRestaurants = (
  createRestaurantsRequest: CreateRestaurantsRequest,
): Promise<CreateRestaurantsResponse> => {
  return axiosInstance
    .post(`${apiPaths.RESTAURANTS}/batch`, createRestaurantsRequest)
    .then((response) => response.data);
};

export const updateRestaurants = (
  restaurantIds: string[],
  updateRestaurantsRequest: UpdateRestaurantsRequest,
): Promise<UpdateRestaurantsResponse> => {
  return axiosInstance
    .patch(`${apiPaths.RESTAURANTS}/batch`, updateRestaurantsRequest, { params: { restaurantIds } })
    .then((response) => response.data);
};

export const exportRestaurantsByIds = async (restaurantIds: string[]): Promise<string> => {
  return axiosInstance
    .get<string>(`${apiPaths.RESTAURANTS}/export/restaurant-ids`, {
      params: { restaurantIds },
      responseType: 'blob',
    })
    .then((response) => response.data);
};

export const exportRestaurantsByKeyAccountId = async (keyAccountId: string): Promise<string> => {
  return axiosInstance
    .get<string>(`${apiPaths.RESTAURANTS}/export/key-account-id`, {
      params: { keyAccountId },
      responseType: 'blob',
    })
    .then((response) => response.data);
};
