import { generatePath } from 'react-router-dom';

import apiPaths from '../config/apiPaths';
import { RestaurantTwoFactorReturnSettingsResponse } from '../modules/Restaurants/TwoFactorReturn/models/restaurant-two-factor-return-settings-response';
import { UpdateRestaurantTwoFactorReturnSettingsRequest } from '../modules/Restaurants/TwoFactorReturn/models/update-restaurant-two-factor-return-settings-request';
import axiosInstance from './axios';

export const getRestaurantTwoFactorReturnSettings = (
  restaurantId: string,
): Promise<RestaurantTwoFactorReturnSettingsResponse> => {
  return axiosInstance
    .get(generatePath(apiPaths.RESTAURANTS_TWO_FACTOR_RETURN, { restaurantId }))
    .then(({ data }) => data);
};

export const updateRestaurantTwoFactorReturnSettings = (
  restaurantId: string,
  updateRequest: UpdateRestaurantTwoFactorReturnSettingsRequest,
): Promise<RestaurantTwoFactorReturnSettingsResponse> => {
  return axiosInstance.patch(generatePath(apiPaths.RESTAURANTS_TWO_FACTOR_RETURN, { restaurantId }), updateRequest);
};
