import React, { useState } from 'react';
import { EditIcon } from '@chakra-ui/icons';
import { Button, Flex, Icon, useDisclosure } from '@chakra-ui/react';

import { ErrorMessages, FullPageLoader, Modal, PageHeading } from '../../../../../common/components';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useRestaurantDepositSettings } from '../../../../../services/deposit';
import { DepositSettingsHistory } from '../DepositSettingsHistory/DepositSettingsHistory';
import { UpdateDepositSettings } from '../UpdateDepositSettings/UpdateDepositSettings';

type DepositSettingsProps = {
  restaurantId: string;
};

export const DepositSettings = ({ restaurantId }: DepositSettingsProps) => {
  const translations = useTranslations();
  const [isEditMode, setIsEditMode] = useState(false);
  const { isOpen: showHistory, onOpen: handleOpenHistory, onClose: handleCloseHistory } = useDisclosure();

  const {
    data: restaurantDepositSettings,
    isLoading: isLoadingDepositSettings,
    error: depositSettingsError,
  } = useRestaurantDepositSettings(restaurantId);

  const handleEditMode = () => setIsEditMode(true);

  return (
    <>
      <PageHeading>{translations('restaurant_deposit_header')}</PageHeading>
      <FullPageLoader show={isLoadingDepositSettings}>
        <ErrorMessages errors={depositSettingsError} />
        <UpdateDepositSettings
          restaurantId={restaurantId}
          model={restaurantDepositSettings}
          isEditMode={isEditMode}
          onEditModeChange={setIsEditMode}
        />
        {!isEditMode ? (
          <Flex justifyContent="flex-start">
            <Button leftIcon={<Icon as={EditIcon} />} onClick={handleEditMode} mr={2}>
              {translations('edit')}
            </Button>
            <Button onClick={handleOpenHistory}>{translations('deposit_settings_show_history')}</Button>
          </Flex>
        ) : null}

        <Modal
          size="2xl"
          isModalVisible={showHistory}
          onModalClose={handleCloseHistory}
          customModalFooter={null}
          modalHeader={translations('deposit_settings_history_header')}
        >
          <DepositSettingsHistory history={restaurantDepositSettings?.history || []} />
        </Modal>
      </FullPageLoader>
    </>
  );
};
