import React, { JSX, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { ErrorMessages, FormFooter, FullPageLoader, Modal, ModalType } from '../../../common/components';
import { ChildPageHeading } from '../../../common/components/ChildPageHeading';
import { Routes } from '../../../config/routes';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useUpdateUserProfileSettings, useUserProfileSettings } from '../../../services/application-users';
import { ApplicationUserProfileSettingsResponse } from '../models/application-user-notification-settings.response.model';
import { NotificationBrand } from '../models/notification-config';
import ApplicationUserProfileSettingsForm from './ApplicationUserProfileSettingsForm';

const ApplicationUserProfileSettings = (): JSX.Element => {
  const { userId } = useParams<{ userId: string }>();
  invariant(userId, 'userId is not set within the route');
  const formMethods = useForm<ApplicationUserProfileSettingsResponse>();
  const translations = useTranslations();
  const navigate = useNavigate();
  const {
    isOpen: isWarningModalVisible,
    onOpen: handleOpenWarningModal,
    onClose: handleCloseWarningModal,
  } = useDisclosure();

  const { data: notificationSettings, isLoading: isLoadingNotificationSettings } = useUserProfileSettings(userId);

  const {
    mutate: updateNotificationSetting,
    isLoading: isSavingNotificationSetting,
    error: updateNotificationSettingError,
  } = useUpdateUserProfileSettings();

  useEffect(() => formMethods.reset(notificationSettings), [notificationSettings, formMethods]);

  const updateUserNotificationSetting = (form: ApplicationUserProfileSettingsResponse) => {
    updateNotificationSetting(
      {
        id: userId,
        settingsRequest: {
          notificationSetting: { return: form.notificationSetting.return, other: form.notificationSetting.other },
          isNewsletterSubscribed: form.isNewsletterSubscribed,
          skipPayments: form.skipPayments,
          email: form.email,
        },
      },
      { onSuccess: () => navigate(Routes.USERS_PATH) },
    );
  };

  const onFormSubmit = formMethods.handleSubmit(({ ...form }: ApplicationUserProfileSettingsResponse) => {
    if (
      (form.notificationSetting.return === NotificationBrand.PUSH &&
        notificationSettings?.notificationSetting.return !== NotificationBrand.PUSH) ||
      (form.notificationSetting.other === NotificationBrand.PUSH &&
        notificationSettings?.notificationSetting.other !== NotificationBrand.PUSH)
    ) {
      handleOpenWarningModal();
    } else {
      updateUserNotificationSetting(form);
    }
  });

  const handleConfirm = formMethods.handleSubmit(({ ...form }: ApplicationUserProfileSettingsResponse) => {
    updateUserNotificationSetting(form);
  });

  if (isLoadingNotificationSettings) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  const handleCancel = () => {
    handleCloseWarningModal();
  };

  return (
    <>
      <ChildPageHeading>{notificationSettings && translations('profile_settings_header')}</ChildPageHeading>
      <ErrorMessages errors={updateNotificationSettingError} />
      <FormProvider {...formMethods}>
        <form onSubmit={onFormSubmit}>
          <ApplicationUserProfileSettingsForm model={notificationSettings} />
          <FormFooter isLoadingSubmitResult={isSavingNotificationSetting} />
        </form>
      </FormProvider>
      <Modal
        isModalVisible={isWarningModalVisible}
        onModalClose={handleCloseWarningModal}
        type={ModalType.CONFIRMATION}
        modalHeader={translations('notification_push_disabled_modal_header')}
        customModalFooter={
          <HStack justifyContent="flex-end" mr={4} my={4}>
            <Button onClick={handleCancel}>{translations('cancel')}</Button>
            <Button type="submit" colorScheme="red" onClick={handleConfirm}>
              {translations('notification_push_disabled_modal_save')}
            </Button>
          </HStack>
        }
      >
        <Text>{translations('notification_push_disabled_modal_warning')}</Text>
      </Modal>
    </>
  );
};

export default ApplicationUserProfileSettings;
