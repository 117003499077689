import React, { JSX, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, FormControl, FormLabel, HStack, Image } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileInput, FormFooter, FormGroup, FullPageLoader, NumberInput, Select } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../common/components/FormInput/FormInput';
import {
  ImagePreviewValidation,
  isFileTooLarge,
} from '../../common/components/ImagePreviewValidation/ImagePreviewValidation';
import { Language } from '../../common/models/enums/language';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useMetaCategoryList } from '../../services/meta-categories';
import { MetaCategory } from '../MetaCategories/model/MetaCategory';
import { Category } from './model/Category.model';
import { CategoryGroupSelect, selectOptions, useCategoriesFormValidationSchema } from './CategoriesForm.form';
import {
  DEFAULT_BORROWING_PERIOD,
  DEFAULT_CLIMATE_FEE,
  DEFAULT_EXTENSION_FEE,
  DEFAULT_EXTENSION_PERIOD,
  MIN_NUMBER_INPUT_VALUE,
} from './category.consts';

type CategoriesFormProps = {
  formData?: Category;
  isLoading: boolean;
  onSubmit: (values: CategoryFormModel) => void;
};

export type CategoryFormModel = Category & { imageTemporary?: FileList };

const CategoriesForm = ({ formData, isLoading, onSubmit }: CategoriesFormProps): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const validationSchema = useCategoriesFormValidationSchema();
  const { data: paginatedMetaCategories, isLoading: metaCategoriesLoading } = useMetaCategoryList();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
  } = useForm<CategoryFormModel>({
    resolver: yupResolver(validationSchema),
  });

  const imageNamePreview: string | undefined = watch('imageTemporary')?.[0]?.name;
  const imageSizePreview: number | undefined = watch('imageTemporary')?.[0]?.size;

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  if (isLoading || metaCategoriesLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  const handleDeleteUploadedImage = () => setValue('imageTemporary', undefined);

  return (
    <form data-testid="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {Object.values(Language).map((translationLanguage) => (
        <Box key={translationLanguage}>
          <FormInput
            id={`translations.${translationLanguage}`}
            label={translations(`categories_form_name_${translationLanguage}`)}
            validationError={errors.translations ? errors.translations[translationLanguage]?.message : undefined}
            useFormRegisterReturn={register(`translations.${translationLanguage}`)}
            setValue={setValue}
          />
        </Box>
      ))}

      <FormGroup
        label={translations('categories_form_group')}
        inputId="categoryGroup"
        validationError={errors.categoryGroup?.message}
      >
        <Controller
          control={control}
          name="categoryGroup"
          render={({ field: { value, onChange, name } }) => (
            <Select<CategoryGroupSelect>
              name={name}
              defaultValue={selectOptions[0]}
              value={selectOptions.find((option) => option.value === value)}
              getOptionLabel={(option) => (option as CategoryGroupSelect).label}
              getOptionValue={(option) => (option as CategoryGroupSelect).value}
              onChange={onChange}
              valueMapper={(v) => v?.value}
              options={selectOptions}
            />
          )}
        />
      </FormGroup>
      <FormGroup
        label={translations('categories_form_meta_category')}
        inputId="metaCategory"
        validationError={errors.metaCategory?.message}
      >
        <Controller
          control={control}
          name="metaCategory"
          render={({ field: { value, onChange, name } }) => (
            <Select<MetaCategory>
              name={name}
              getOptionLabel={(option) => (option as MetaCategory).translations[language]}
              getOptionValue={(option) => (option as MetaCategory).id}
              onChange={onChange}
              defaultValue={paginatedMetaCategories?.items[0]}
              value={value}
              options={paginatedMetaCategories?.items}
            />
          )}
        />
      </FormGroup>
      <HStack>
        <FormGroup
          label={translations('categories_form_not_return_fee_eur')}
          inputId="eurNotReturnFee"
          validationError={errors.notReturnFee?.EUR?.message}
        >
          <NumberInput
            name="notReturnFee.EUR"
            register={register('notReturnFee.EUR')}
            defaultValue={formData?.notReturnFee.EUR || DEFAULT_CLIMATE_FEE.EUR}
            min={MIN_NUMBER_INPUT_VALUE}
            precision={2}
          />
        </FormGroup>
        <FormGroup
          label={translations('categories_form_not_return_fee_chf')}
          inputId="chfNotReturnFee"
          validationError={errors.notReturnFee?.CHF?.message}
        >
          <NumberInput
            name="notReturnFee.CHF"
            register={register('notReturnFee.CHF')}
            defaultValue={formData?.notReturnFee.CHF || DEFAULT_CLIMATE_FEE.CHF}
            min={MIN_NUMBER_INPUT_VALUE}
            precision={2}
          />
        </FormGroup>
      </HStack>
      <HStack>
        <FormGroup
          label={translations('categories_form_borrowing_period')}
          inputId="borrowingPeriod"
          validationError={errors.borrowingPeriod?.message}
        >
          <NumberInput
            name="borrowingPeriod"
            register={register('borrowingPeriod')}
            defaultValue={formData?.borrowingPeriod || DEFAULT_BORROWING_PERIOD}
            min={MIN_NUMBER_INPUT_VALUE}
            precision={0}
          />
        </FormGroup>
        <FormGroup
          label={translations('categories_form_extension_period')}
          inputId="extensionPeriod"
          validationError={errors.extensionPeriod?.message}
        >
          <NumberInput
            name="extensionPeriod"
            register={register('extensionPeriod')}
            defaultValue={formData?.extensionPeriod || DEFAULT_EXTENSION_PERIOD}
            min={MIN_NUMBER_INPUT_VALUE}
            precision={0}
          />
        </FormGroup>
      </HStack>
      <HStack>
        <FormGroup
          label={translations('categories_form_extension_fee_eur')}
          inputId="eurExtensionFee"
          validationError={errors.extensionFee?.EUR?.message}
        >
          <NumberInput
            name="extensionFee.EUR"
            register={register('extensionFee.EUR')}
            defaultValue={formData?.extensionFee.EUR || DEFAULT_EXTENSION_FEE.EUR}
            min={MIN_NUMBER_INPUT_VALUE}
            precision={2}
          />
        </FormGroup>
        <FormGroup
          label={translations('categories_form_extension_fee_chf')}
          inputId="chfExtensionFee"
          validationError={errors.extensionFee?.CHF?.message}
        >
          <NumberInput
            name="extensionFee.CHF"
            register={register('extensionFee.CHF')}
            defaultValue={formData?.extensionFee.CHF || DEFAULT_EXTENSION_FEE.CHF}
            min={MIN_NUMBER_INPUT_VALUE}
            precision={2}
          />
        </FormGroup>
      </HStack>
      <FormControl>
        <FormLabel htmlFor="image">{translations('categories_form_image')}</FormLabel>
        <Image id="image" src={getValues('image')} />
        <FileInput accept="image/*" register={register('imageTemporary')} aria-label="imageTemporary" />
        {imageNamePreview && imageSizePreview ? (
          <ImagePreviewValidation
            imageNamePreview={imageNamePreview}
            imageSizePreview={imageSizePreview}
            handleDeleteUploadedImage={handleDeleteUploadedImage}
          />
        ) : null}
      </FormControl>
      <FormFooter
        isLoadingSubmitResult={isLoading}
        isSubmitDisabled={imageSizePreview ? isFileTooLarge(imageSizePreview) : false}
      />
    </form>
  );
};

export default CategoriesForm;
