import { useState } from 'react';

import { getFormattedDate } from '../../../../common/utils';
import { Category } from '../../../Categories/model/Category.model';
import { KeyAccountGroupsResponse } from '../../../KeyAccountGroups/models/key-account-groups-response';
import { RestaurantResponse } from '../../../Restaurants/models';
import { handleSelectedCategoryIdsChange } from '../../utils/filter.helper';

export const useFilterSetState = (productCategories: Category[]) => {
  const [restaurantsSelected, setRestaurantsSelected] = useState<RestaurantResponse[]>([]);
  const [keyAccountGroupsSelected, setKeyAccountGroupsSelected] = useState<KeyAccountGroupsResponse[]>([]);
  const [startDate, setStartDate] = useState(getFormattedDate(new Date().toISOString()));
  const [endDate, setEndDate] = useState(getFormattedDate(new Date().toISOString()));
  const [categoryIds, setCategoryIds] = useState(productCategories.map((category) => category.id));
  const handleCategoryIdsChange = (isChecked: boolean, categoryId: string) => {
    handleSelectedCategoryIdsChange(isChecked, categoryId, categoryIds, setCategoryIds);
  };

  return {
    restaurantsSelected,
    setRestaurantsSelected,
    keyAccountGroupsSelected,
    setKeyAccountGroupsSelected,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    categoryIds,
    setCategoryIds,
    handleCategoryIdsChange,
  };
};
