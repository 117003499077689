import { config } from 'config/config';
import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/lib/build/recipe/emailpassword/recipe';
import { ComponentOverrideMap } from 'supertokens-auth-react/lib/build/recipe/emailpassword/types';
import Session from 'supertokens-auth-react/recipe/session';

export const initSuperTokens = (): void => {
  SuperTokens.init({
    appInfo: {
      appName: config.appName,
      apiDomain: config.apiUrl,
      websiteDomain: config.websiteUrl,
      apiBasePath: '/api/web/auth',
    },
    recipeList: [
      EmailPassword.init({
        useShadowDom: false,
        preAPIHook: async (context) => {
          const { url } = context;
          const { requestInit } = context;
          const { action } = context;

          if (action === 'EMAIL_PASSWORD_SIGN_IN') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            requestInit.headers['x-app-name'] = 'ADMIN';
          }

          return {
            requestInit,
            url,
          };
        },
        signInAndUpFeature: {
          signInForm: {
            style: {
              headerSubtitle: {
                display: 'none',
              },
            },
          },
        },
        override: {
          components: {
            EmailPasswordSignUp: () => {
              return null;
            },
          } as ComponentOverrideMap,
        },
      }),
      Session.init(),
    ],
  });
};
