export const buildBasicPaginatedSearchParams = (
  offset: number,
  limit: number,
  searchText?: string,
  additionalParams?: Map<string, string>,
): URLSearchParams => {
  const searchParams = new URLSearchParams({
    ...(searchText && { q: searchText }),
    offset: (offset - 1).toString(),
    limit: limit.toString(),
  });
  if (additionalParams) {
    additionalParams.forEach((value, name) => {
      if (!value) {
        return;
      }
      searchParams.append(name, value);
    });
  }
  return searchParams;
};
