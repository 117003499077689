import React, { JSX } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { PageHeading } from 'common/components';

import { useTranslations } from '../../contexts/LocalizationContext';
import { LostItemsForm } from './components/LostItemsForm/LostItemsForm';

const LostItems = (): JSX.Element => {
  const translations = useTranslations();

  return (
    <Box textAlign="center" height="100%" width="100%" margin="auto">
      <Flex direction="column" height="100%" pb={2}>
        <Box flex={1}>
          <PageHeading>{translations('lost_items_header')}</PageHeading>
          <LostItemsForm />
        </Box>
      </Flex>
    </Box>
  );
};

export default LostItems;
