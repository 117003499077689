import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Flex, Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { FormGroup } from '../../../../../common/components';
import RestaurantMultiSelect from '../../../../../common/components/RestaurantSelect/RestaurantMultiSelect';
import WarningText from '../../../../../common/components/WarningText/WarningText';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { NotificationSettingsLocations } from '../../models/enums/notification-settings-locations.enum';

export const NotificationLocations = () => {
  const translations = useTranslations();
  const { control, watch, getValues } = useFormContext();

  const watchLocationsSetting = watch('locationsSetting');
  const userLocations = getValues('userLocations');

  return (
    <FormGroup label={translations('notification_settings_locations_label')} inputId={'locationsSetting'}>
      <>
        <Stack direction="row" alignItems="center" w="100%" h="24px">
          <Controller
            control={control}
            name="locationsSetting"
            render={({ field: { value, onChange } }) => (
              <RadioGroup onChange={onChange} value={value} id="locationsSetting">
                <Stack direction="row">
                  <Radio value={NotificationSettingsLocations.ALL}>
                    {translations('notification_settings_locations_all')}
                  </Radio>
                  <Radio value={NotificationSettingsLocations.SELECTED}>
                    {translations('notification_settings_locations_selected')}
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
          {watchLocationsSetting === NotificationSettingsLocations.SELECTED ? (
            <Controller
              control={control}
              name="selectedLocations"
              render={({ field: { value, onChange } }) => (
                <Box width="25%" minWidth="300px">
                  <RestaurantMultiSelect
                    selectedRestaurantIds={value}
                    restaurants={userLocations}
                    onSelectRestaurants={(e) => onChange(e.map((v) => v.value))}
                  />
                </Box>
              )}
            />
          ) : null}
        </Stack>
        <Flex h={6} mt={3}>
          <WarningText text={translations('notification_settings_all_locations_warning')} />
        </Flex>
      </>
    </FormGroup>
  );
};
