import React from 'react';
import {
  FieldValues,
  Message,
  Path,
  PathValue,
  UseFormRegisterReturn,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import ReactQuill, { Quill } from 'react-quill';
import * as Emoji from 'quill-emoji';

import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';

import { FormGroup } from '../FormGroup';
import { modules } from './TextEditor.config';

Quill.register('modules/emoji', Emoji);

type TextEditorProps<T extends FieldValues> = {
  id: FieldPath<T>;
  setValue: UseFormSetValue<T>;
  label: React.ReactNode;
  watch: UseFormWatch<T>;
  trigger: UseFormTrigger<T>;
  useFormRegisterReturn: UseFormRegisterReturn;
  validationError?: string | Message | undefined;
  placeholder?: string;
};

export const TextEditor = <T extends FieldValues>({
  id,
  label,
  validationError,
  watch,
  setValue,
  placeholder,
  useFormRegisterReturn,
  trigger,
}: TextEditorProps<T>) => {
  const handleEditorStateChange = (editorState: PathValue<T, Path<T>>) => {
    setValue(id, editorState);
    trigger(id);
  };

  const editorContent = watch(id);
  return (
    <FormGroup label={label} inputId={id} validationError={validationError}>
      <ReactQuill
        theme="snow"
        value={editorContent}
        onChange={handleEditorStateChange}
        modules={modules}
        ref={useFormRegisterReturn.ref}
        placeholder={placeholder}
      />
    </FormGroup>
  );
};
