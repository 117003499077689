import React, { JSX, useState } from 'react';
import { Alert, AlertIcon, Button, Flex, Input } from '@chakra-ui/react';
import { MainPageContent, PageHeading } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { useExecuteWithConfirmation } from '../../common/utils';
import { useRunScript } from '../../services/scripts';

const ScriptsPage = (): JSX.Element => {
  const { mutate: runScript, isLoading, error: runScriptError, data: resultMessage } = useRunScript();
  const [scriptName, setScriptName] = useState('');
  const translations = useTranslations();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setScriptName(event.target.value);
  const { executeWithConfirmation: onRunScriptsClicked } = useExecuteWithConfirmation(
    translations('scripts_run_confirmation', {
      '{{script-name}}': scriptName,
    }),
    runScript,
  );

  const handleRunScript = React.useCallback(() => {
    onRunScriptsClicked(scriptName);
  }, [scriptName, onRunScriptsClicked]);

  return (
    <MainPageContent>
      <PageHeading>{translations('scripts_header')}</PageHeading>
      {resultMessage ? (
        <Alert status="success" mb={3}>
          <AlertIcon />
          {resultMessage}
        </Alert>
      ) : null}
      {runScriptError ? (
        <Alert status="error" mb={3}>
          <AlertIcon />
          {runScriptError.message} | {runScriptError.response?.data?.message} | {translations('scripts_status')}{' '}
          {runScriptError.response?.status}
        </Alert>
      ) : null}
      <Flex justifyContent="center">
        <Input value={scriptName} onChange={handleChange} />
        <Button mr={2} onClick={handleRunScript} isLoading={isLoading} loadingText={translations('scripts_running')}>
          {translations('scripts_run')}
        </Button>
      </Flex>
    </MainPageContent>
  );
};

export default ScriptsPage;
