import React, { JSX, ReactNode, useState } from 'react';
import { Box, Button, Flex, useTheme } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { ModalState, ModalVariant } from '../../../contexts/ModalContext/hook';
import { ImageResponse } from '../../../modules/Restaurants/models/restaurant-image-response.model';
import { FullPageLoader, ImageOrderBox } from '../index';

type ImagesProps = {
  handleSaveImages: () => void;
  handleDeleteImage: (id: string) => () => void;
  handleToggleModal: (type: ModalState | null) => () => void;
  children: ReactNode;
  isFetchingImages: boolean;
  setImages: (data?: ImageResponse[]) => void;
  images?: ImageResponse[];
};

const EditImages = ({
  handleSaveImages,
  handleDeleteImage,
  handleToggleModal,
  children,
  isFetchingImages,
  setImages,
  images,
}: ImagesProps): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const [isEditOrderMode, setIsEditOrderMode] = useState(false);

  const handleEditOrderMode = () => setIsEditOrderMode(!isEditOrderMode);
  const handleSave = () => {
    handleSaveImages();
    setIsEditOrderMode(false);
  };

  const imagesWithFile = images?.filter((image) => !!image.file);
  const editOrderEnabled = imagesWithFile && imagesWithFile.length >= 2;

  return (
    <>
      <Flex justifyContent="flex-end">
        <Button
          ml="auto"
          mb="2"
          onClick={handleToggleModal({ modalType: ModalVariant.DEFAULT })}
          isDisabled={isEditOrderMode}
        >
          {translations('images_add')}
        </Button>
        {editOrderEnabled ? (
          !isEditOrderMode ? (
            <Button ml={5} mb="2" onClick={handleEditOrderMode}>
              {translations('images_edit_order')}
            </Button>
          ) : (
            <Button ml={5} mb="2" onClick={handleSave}>
              {translations('images_save')}
            </Button>
          )
        ) : null}
        {children}
      </Flex>
      {imagesWithFile?.length ? (
        <FullPageLoader show={isFetchingImages}>
          <Box
            border="2px"
            borderColor={colors.orange[500]}
            px={4}
            py={3}
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            gap={5}
          >
            <ImageOrderBox
              boxes={images}
              setBoxes={setImages}
              isEditOrderMode={isEditOrderMode}
              onDelete={handleDeleteImage}
            />
          </Box>
        </FullPageLoader>
      ) : !isFetchingImages ? (
        <Box
          border="2px"
          borderColor={colors.orange[500]}
          px={4}
          py={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="300px"
        >
          <h1>{translations('no_images')}</h1>
        </Box>
      ) : null}
    </>
  );
};

export default EditImages;
