import { Category } from '../Categories/model/Category.model';
import { RestaurantResponse } from '../Restaurants/models';

export type ProductAssignmentForm = {
  itemsVolume: number | null;
  restaurant: RestaurantResponse | null;
  category: Category | null;
};

export enum VisibleModal {
  PRODUCTS_ASSIGNMENT_SUCCESS,
  CANCEL_PRODUCTS_ASSIGNMENT,
  ASSIGN_PRODUCTS_FROM_CSV,
  REASSIGN_BETWEEN_RESTAURANTS,
}
