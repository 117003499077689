import React, { JSX } from 'react';
import { Box } from '@chakra-ui/react';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCreateKeyAccountGroup } from 'services/key-account-groups';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { FormSubmitData } from './models/form-submit-data';
import KeyAccountGroupsForm from './KeyAccountGroupForm';

const CreateKeyAccountGroup = (): JSX.Element => {
  const translations = useTranslations();
  const { mutate: createKeyAccountGroup, isLoading } = useCreateKeyAccountGroup(Routes.KEY_ACCOUNT_GROUPS_PATH);

  return (
    <Box mb={10}>
      <ChildPageHeading>{translations('key_account_groups_add_header')}</ChildPageHeading>
      <KeyAccountGroupsForm
        isLoading={isLoading}
        onSubmit={(values: FormSubmitData) => {
          createKeyAccountGroup({ values });
        }}
      />
    </Box>
  );
};

export default CreateKeyAccountGroup;
