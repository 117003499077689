import React, { JSX, useState } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Box, Button, Flex, FormControl, FormLabel, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { MainPageContent, PageHeading, useToast } from '../../../common/components';
import WarningText from '../../../common/components/WarningText/WarningText';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { Language } from '../../../common/models/enums/language';
import { config } from '../../../config/config';
import { DEBOUNCE_SEARCH_TIME } from '../../../config/configConsts';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useApplicationUsersList } from '../../../services/application-users';
import { useGetTestMessageTypeList, useSendTestNotification } from '../../../services/test-notifications';
import colors from '../../../styles/colors';
import { ApplicationUser } from '../../ApplicationUsers/models/application-user';
import { NotificationBrand, NotificationBrandWithoutNone } from '../../ApplicationUsers/models/notification-config';
import {
  SendTestNotificationFormData,
  SendTestNotificationRequest,
  TestMessageTypeResponse,
} from './model/TestNotification.model';
import { useSendTestNotificationValidation } from './useSendTestNotificationValidation';

const SendTestNotification = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const { displayToast } = useToast();
  const validationSchema = useSendTestNotificationValidation();

  const { data: users, isFetching: isFetchingApplicationUsers } = useApplicationUsersList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: 1,
  });

  const { data: messageTypes, isFetching: isFetchingMessageTypes } = useGetTestMessageTypeList();
  const { mutate: sendDynamicTemplateEmail, isLoading: isSendingDynamicTemplateEmail } = useSendTestNotification();

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      applicationUser: undefined,
      messageType: undefined,
      language: Language.En,
      notificationType: NotificationBrand.EMAIL as NotificationBrandWithoutNone,
    },
    resolver: yupResolver(validationSchema),
  });

  const watchMessageType: TestMessageTypeResponse | undefined = watch('messageType');

  const onFormSubmit = (values: SendTestNotificationFormData) => {
    if (values.applicationUser && values.messageType) {
      const requestData: SendTestNotificationRequest = {
        userId: values.applicationUser.id,
        messageType: values.messageType.name,
        language: values.language,
        notificationType: values.notificationType,
      };
      sendDynamicTemplateEmail(
        { values: requestData },
        {
          onSuccess: async () => {
            displayToast('success', translations('send_test_notification_success'));
          },
          onError: (error) => {
            const message = error.response?.data.message;
            displayToast('error', message ?? translations('send_test_notification_error'));
          },
        },
      );
    }
  };

  return (
    <MainPageContent>
      <PageHeading>{translations('send_test_notification_header')}</PageHeading>
      <Box mt={10}>
        <form onSubmit={handleSubmit(onFormSubmit)} autoComplete="off">
          <FormControl>
            <FormLabel htmlFor="applicationUser">{translations('send_test_notification_form_user_label')}</FormLabel>
            <Controller
              control={control}
              name="applicationUser"
              render={({ field: { value, onChange, name } }) => (
                <Select
                  inputId="applicationUser"
                  placeholder={translations('send_test_notification_form_user_placeholder')}
                  name={name}
                  getOptionLabel={(option: ApplicationUser) => option.email}
                  getOptionValue={(option: ApplicationUser) => option.id}
                  value={value}
                  onChange={onChange}
                  onInputChange={setSearchText}
                  options={users?.items}
                  isLoading={isFetchingApplicationUsers}
                />
              )}
            />
            <Text color={colors.red[500]} textAlign="left" height={6}>
              {(errors as FieldErrors).applicationUser?.message as string}
            </Text>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="messageType">
              {translations('send_test_notification_form_message_type_label')}
            </FormLabel>
            <Controller
              control={control}
              name="messageType"
              render={({ field: { value, onChange, name } }) => (
                <Select
                  inputId="messageType"
                  placeholder={translations('send_test_notification_form_message_type_placeholder')}
                  name={name}
                  getOptionLabel={(option: TestMessageTypeResponse) => option.name}
                  getOptionValue={(option: TestMessageTypeResponse) => option.name}
                  value={value}
                  onChange={(messageType) => {
                    onChange(messageType);
                    if (!messageType?.isPushEnabled) {
                      setValue('notificationType', NotificationBrand.EMAIL);
                    }
                  }}
                  options={messageTypes}
                  isLoading={isFetchingMessageTypes}
                />
              )}
            />
            <Text color={colors.red[500]} textAlign="left" height={6}>
              {(errors as FieldErrors).messageType?.message as string}
            </Text>
          </FormControl>
          <FormControl>
            <FormLabel>{translations('send_test_notification_form_language_label')}</FormLabel>
            <Controller
              control={control}
              name="language"
              render={({ field: { value, onChange } }) => (
                <RadioGroup onChange={onChange} value={value}>
                  <Stack direction="row">
                    {Object.values(Language).map((language) => (
                      <Radio key={language} value={language}>
                        {language}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
            />
            <Text color="red" textAlign="left" height={6}>
              {(errors as FieldErrors).language?.message as string}
            </Text>
          </FormControl>
          <FormControl>
            <FormLabel>{translations('send_test_notification_form_notification_type_label')}</FormLabel>
            <Controller
              control={control}
              name="notificationType"
              render={({ field: { value, onChange } }) => (
                <RadioGroup
                  onChange={onChange}
                  value={value}
                  isDisabled={watchMessageType ? !(watchMessageType as TestMessageTypeResponse).isPushEnabled : false}
                >
                  <Stack direction="row">
                    {Object.values(NotificationBrand)
                      .filter((notificationBrand) => notificationBrand !== NotificationBrand.NONE)
                      .map((notificationBrand) => (
                        <Radio key={notificationBrand} value={notificationBrand}>
                          {notificationBrand}
                        </Radio>
                      ))}
                  </Stack>
                  <Flex h={6} mt={2}>
                    {value === NotificationBrand.PUSH ? (
                      <WarningText text={translations('send_test_notification_push_warning')} />
                    ) : null}
                    {watchMessageType && !(watchMessageType as TestMessageTypeResponse).isPushEnabled ? (
                      <WarningText text={translations('send_test_notification_message_type_warning')} />
                    ) : null}
                  </Flex>
                </RadioGroup>
              )}
            />
            <Text color="red" textAlign="left">
              {(errors as FieldErrors).notificationType?.message as string}
            </Text>
          </FormControl>
          <Flex justifyContent="flex-start" mt={4}>
            <Button mr={4} isLoading={isSendingDynamicTemplateEmail} type="submit">
              {translations('send_test_notification_submit')}
            </Button>
          </Flex>
        </form>
      </Box>
    </MainPageContent>
  );
};

export default SendTestNotification;
