import React, { JSX } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, FormControl } from '@chakra-ui/react';

import { FileInput } from '../../../../common/components';
import { cutLongString } from '../../../../common/utils/helpers';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { SubmitProps } from './File';
import { CustomerFileType } from './FilesList';

interface FileFormProps {
  handleSubmit: (data: SubmitProps) => void;
  fileType: CustomerFileType;
  isLoading: boolean;
}

const MAX_FILE_SIZE = 50 * 1024 * 1024;

export const FileForm = ({ handleSubmit: handleFormSubmit, fileType, isLoading }: FileFormProps): JSX.Element => {
  const translations = useTranslations();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();

  const file = watch('file');

  const onFormSubmit = handleSubmit((data) => {
    handleFormSubmit({
      file: data.file,
    });
  });

  const acceptFileMapper = {
    [CustomerFileType.EXCEL]: '.xlsx,.xls,.csv',
    [CustomerFileType.CONTRACT]: 'image/jpeg,image/png,application/pdf',
  };

  return (
    <form onSubmit={onFormSubmit} autoComplete="off">
      <FormControl>
        <Box display="flex" flexDirection="column" alignItems="center">
          <span>{file?.length ? cutLongString(file[0].name, 20) : null}</span>
          <Box display="flex" justifyContent="center" mt={5}>
            <FileInput
              accept={acceptFileMapper[fileType]}
              register={register('file', {
                validate: () => {
                  const tooLargeFileUploaded = file[0].size > MAX_FILE_SIZE;
                  return !tooLargeFileUploaded || translations('customer_file_too_large');
                },
              })}
              aria-label="file"
            />
            <Button isDisabled={!file?.length} type="submit" isLoading={isLoading}>
              {translations('customer_files_save_button')}
            </Button>
          </Box>
          {errors?.file?.message ? (
            <Box mt={5} color="red">
              {errors.file.message as string}
            </Box>
          ) : null}
        </Box>
      </FormControl>
    </form>
  );
};
