import React, { ChangeEvent, JSX } from 'react';
import { Input } from '@chakra-ui/react';

import usePaginationWithUrl from '../Pagination/hooks/use-pagination-with-url.hook';

type SearchboxProps = {
  value: string;
  onChange: (text: string) => void;
  placeholder?: string;
};

export const Searchbox = ({ placeholder, value, onChange }: SearchboxProps): JSX.Element => {
  const { updateUrlOnSearch } = usePaginationWithUrl(undefined, onChange);

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateUrlOnSearch(event.target.value);
  };

  return <Input role="searchbox" placeholder={placeholder} w={500} value={value} onChange={handleInputOnChange} />;
};
