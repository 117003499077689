import * as yup from 'yup';

import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { DEPOSIT_AMOUNT_MAX_VALUE, DEPOSIT_AMOUNT_MIN_VALUE } from '../../utils/deposit.consts';

export const useDepositSettingsFormValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    bowlsAmount: yup
      .number()
      .min(
        DEPOSIT_AMOUNT_MIN_VALUE,
        translations('validation_min_value', {
          '{{min_value}}': DEPOSIT_AMOUNT_MIN_VALUE.toString(),
        }),
      )
      .max(
        DEPOSIT_AMOUNT_MAX_VALUE,
        translations('validation_max_value', {
          '{{max_value}}': DEPOSIT_AMOUNT_MAX_VALUE.toString(),
        }),
      )
      .typeError(translations('validation_number_type_error'))
      .required(),
    cupsAmount: yup
      .number()
      .min(
        DEPOSIT_AMOUNT_MIN_VALUE,
        translations('validation_min_value', {
          '{{min_value}}': DEPOSIT_AMOUNT_MIN_VALUE.toString(),
        }),
      )
      .max(
        DEPOSIT_AMOUNT_MAX_VALUE,
        translations('validation_max_value', {
          '{{max_value}}': DEPOSIT_AMOUNT_MAX_VALUE.toString(),
        }),
      )
      .typeError(translations('validation_number_type_error'))
      .required(),
  });
};
