import { useMemo } from 'react';

import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../../../../common/constants/common';
import { useRestaurant } from '../../../../../services/restaurants';
import { useRestaurantReturnCodesList } from '../../../../../services/return-codes';
import { getReturnCodeDefaultName } from '../../../utils/return-codes.helper';

export const useReturnCodeDefaultNameHook = (restaurantId: string, isCreatingReturnCode: boolean) => {
  const { data: restaurant, isLoading: isLoadingRestaurant } = useRestaurant(restaurantId);

  const defaultName = useMemo(() => {
    return getReturnCodeDefaultName(restaurant?.restaurantName || '');
  }, [restaurant]);

  const { data: returnCodes, isLoading: isLoadingReturnCodes } = useRestaurantReturnCodesList(
    restaurantId,
    {
      ...ALL_PAGINATED_LIST_ITEMS_PARAMS,
      searchText: defaultName,
    },
    !!restaurant && !isCreatingReturnCode,
  );

  const defaultNameValue = useMemo(() => {
    if (returnCodes?.count !== undefined && returnCodes?.count === 0) {
      return defaultName;
    } else if (returnCodes?.count && returnCodes?.count > 0) {
      return `${defaultName}(${returnCodes?.count + 1})`;
    }
  }, [returnCodes?.count, defaultName]);

  return {
    defaultNameValue,
    isLoading: isLoadingReturnCodes || isLoadingRestaurant,
  };
};
