import React, { ComponentProps, JSX, ReactNode } from 'react';
import {
  Button,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

export enum ModalType {
  CONFIRMATION = 'confirmation',
  INFO = 'info',
}

type ModalProps = {
  isModalVisible: boolean;
  customModalFooter?: ReactNode | ReactNode[] | null;
  // We probably would like to extend modal types by 'error', 'warning' etc. in the future.
  type?: ModalType;
  modalHeader?: ReactNode;
  isClosable?: boolean;
  onModalClose: () => void;
  onConfirmClick?: (...args: unknown[]) => void;
  maxWidth?: string;
  width?: string;
  isLoadingConfirmed?: boolean;
} & Omit<ComponentProps<typeof ChakraModal>, 'isOpen' | 'onClose'>;

export const Modal = ({
  isModalVisible,
  type = ModalType.CONFIRMATION,
  modalHeader,
  isClosable = true,
  onModalClose,
  onConfirmClick,
  maxWidth,
  children,
  width,
  isLoadingConfirmed,
  ...props
}: ModalProps): JSX.Element => {
  const translations = useTranslations();
  return (
    <ChakraModal isOpen={isModalVisible} onClose={onModalClose} {...props}>
      <ModalOverlay />
      <ModalContent maxW={maxWidth}>
        {modalHeader && <ModalHeader>{modalHeader}</ModalHeader>}
        {isClosable && <ModalCloseButton onClick={onModalClose} />}
        <ModalBody mt={2}>{children}</ModalBody>
        {'customModalFooter' in props ? (
          <>{props.customModalFooter}</>
        ) : (
          <ModalFooter justifyContent="flex-end">
            <Button colorScheme="orange" onClick={onConfirmClick} isLoading={isLoadingConfirmed}>
              {translations('ok')}
            </Button>
            {type === ModalType.CONFIRMATION && (
              <Button ml={3} onClick={onModalClose}>
                {translations('cancel')}
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
};
