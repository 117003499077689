import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';

import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useKeyAccountGroupRestaurantsContext } from '../../context/KeyAccountGroupRestaurantsContext/hooks/use-key-account-group-restaurants-context.hook';
import { CreateReturnCodesModal } from './CreateReturnCodesModal';

type CreateReturnCodesProps = {
  keyAccountGroupId: string;
};

export const CreateReturnCodes = ({ keyAccountGroupId }: CreateReturnCodesProps) => {
  const translations = useTranslations();
  const {
    isOpen: isCreateReturnCodesModalOpen,
    onOpen: handleOpenCreateReturnCodesModal,
    onClose: handleCloseCreateReturnCodesModal,
  } = useDisclosure();

  const { selectedRestaurantsNumber } = useKeyAccountGroupRestaurantsContext();

  return (
    <>
      <Button ml={2} mt={2} isDisabled={!selectedRestaurantsNumber} onClick={handleOpenCreateReturnCodesModal}>
        {translations('restaurant_create_return_codes', {
          '{{number}}': selectedRestaurantsNumber.toString(),
        })}
      </Button>
      <CreateReturnCodesModal
        keyAccountGroupId={keyAccountGroupId}
        isOpen={isCreateReturnCodesModalOpen}
        onClose={handleCloseCreateReturnCodesModal}
      />
    </>
  );
};
