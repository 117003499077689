import { StylesConfig } from 'react-select';

import colors from '../../../styles/colors';
import { SelectOption } from './models/select-option';

export const selectStyle: StylesConfig<SelectOption> = {
  control: (base) => ({
    ...base,
    borderColor: colors.blueGrey[200],
    '&:hover': {
      borderColor: colors.blueGrey[200],
    },
  }),
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  placeholder: (base) => ({ ...base, color: colors.blueGrey[400] }),
  menu: (base) => ({ ...base, zIndex: 1000 }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? colors.orange[300] : colors.white,
    color: colors.black,
    '&:hover': {
      cursor: 'pointer',
      background: colors.orange[100],
    },
  }),
};
