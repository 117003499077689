export enum NotificationBrand {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  NONE = 'NONE',
}

export type NotificationBrandWithoutNone = Exclude<NotificationBrand, NotificationBrand.NONE>;

export interface NotificationConfig {
  return: NotificationBrand;
  other: NotificationBrand;
}

export enum NewsletterSubscription {
  SUBSCRIBED = 'Subscribed',
  UNSUBSCRIBED = 'Unsubscribed',
}
