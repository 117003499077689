import { BaseQueryParams, Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { SegmentResponse } from '../modules/Segments/model/Segment';
import axiosInstance from './axios';

export const getSegments = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<SegmentResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get(`${apiPaths.SEGMENTS}?${searchParams}`).then(({ data }) => data);
};
