import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';

import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../../../../common/constants/common';
import { useDownloadZip } from '../../../../../common/hooks/use-download-zip.hook';
import { Paginated } from '../../../../../common/models';
import { getQRCodeSrc } from '../../../../../common/utils/qrCodes.helper';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useKeyAccountGroupReturnCodes } from '../../../../../services/key-account-groups';
import { RestaurantReturnCodeResponse } from '../../../../Restaurants/RestaurantReturnCodes/models/restaurant-return-code-response';
import { getReturnCodeFileName } from '../../../../Restaurants/utils/return-codes.helper';
import { RETURN_CODES_ZIP_FILE_NAME } from '../../../KeyAccountGroupRestaurants/components/RestaurantsReturnCodes/utils/restaurant-return-codes.const';
import { useReturnCodesFilterContext } from '../../context/HistoryFilterContext/hooks/use-return-codes-filter-context';
import { KeyAccountGroupReturnCodesQueryRequest } from '../../models/key-account-group-return-codes-query-request';

export const DownloadReturnQrCodes = ({ keyAccountGroupId }: any) => {
  const translations = useTranslations();
  const [enabled, setEnabled] = useState(false);
  const { selectedReturnCodesNumber, selectedReturnCodesIds } = useReturnCodesFilterContext();
  const { downloadZip } = useDownloadZip();
  const triggerDownload = () => setEnabled(true);

  const { offset, limit } = ALL_PAGINATED_LIST_ITEMS_PARAMS;
  const queryRequest: KeyAccountGroupReturnCodesQueryRequest = {
    offset,
    limit,
    returnCodeIds: selectedReturnCodesIds ?? undefined,
  };

  const handleDownloadReturnCodes = async (data: Paginated<RestaurantReturnCodeResponse>) => {
    const files = await Promise.all(
      data?.items.map(async (returnCode: any) => {
        const qrCodeUrl = getQRCodeSrc(returnCode.qrCode);
        const qrCodeImageResponse = await fetch(qrCodeUrl);
        return {
          name: `${returnCode.id}-${getReturnCodeFileName(returnCode.name)}`,
          blob: await qrCodeImageResponse.blob(),
        };
      }),
    );
    await downloadZip(files, RETURN_CODES_ZIP_FILE_NAME).finally(() => setEnabled(false));
  };

  const { isFetching: isFetchingReturnCodes } = useKeyAccountGroupReturnCodes(
    keyAccountGroupId,
    queryRequest,
    enabled,
    handleDownloadReturnCodes,
  );

  return (
    <Button
      isLoading={enabled && isFetchingReturnCodes}
      isDisabled={!selectedReturnCodesNumber}
      mr={2}
      onClick={triggerDownload}
    >
      {translations('return_codes_download', {
        '{{count}}': selectedReturnCodesNumber?.toString(),
      })}
    </Button>
  );
};
