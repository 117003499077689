import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { createNews, deleteNews, getNews, getNewsList, updateNews } from 'api/news';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { NewsRequest } from 'modules/News/models/news-request';
import { NewsResponse } from 'modules/News/models/news-response';

import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';

export const CK_NEWS = 'news';

export const useNewsList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<NewsResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_NEWS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getNewsList(params),
    {
      keepPreviousData: true,
    },
  );
};

export const useNews = (id: string): QueryObserverResult<NewsResponse, AxiosError<HttpException>> => {
  return useQuery([CK_NEWS, id], () => getNews(id));
};

export const useCreateNews = (): UseMutationResult<NewsResponse, AxiosError<HttpException>, NewsRequest> => {
  const queryClient = useQueryClient();
  return useMutation(createNews, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_NEWS);
    },
  });
};

export const useUpdateNews = (): UseMutationResult<
  NewsResponse,
  AxiosError<HttpException>,
  { id: string; request: NewsRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id, request }: { id: string; request: NewsRequest }) => updateNews(id, request), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_NEWS);
    },
  });
};

export const useDeleteNews = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation((id: string) => deleteNews(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_NEWS);
    },
  });
};
