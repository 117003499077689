import React, { JSX, useCallback, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button, Flex, Image, Text, useTheme } from '@chakra-ui/react';
import { pdf } from '@react-pdf/renderer';
import { AddEditItemLink, ErrorMessages, LinkButton, MainPageContent, PageHeading, Table } from 'common/components';
import { useSearchCancelQuery } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { saveAs } from 'file-saver';
import { CK_RESTAURANT_RETURN_CODES, useRestaurantReturnCodesList } from 'services/return-codes';
import invariant from 'tiny-invariant';

import qrCodeIcon from '../../../assets/icons/qr-code.png';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../../config/configConsts';
import PdfDocument from '../ReturnPosters/PdfDocument';
import { getReturnCodePosterFileName } from '../utils/return-codes.helper';
import { ReturnCodeStatusUpdate } from './components/ReturnCodeStatusUpdate/ReturnCodeStatusUpdate';
import { RestaurantReturnCodeResponse } from './models/restaurant-return-code-response';
import { getReturnCodeTypeLabel } from './utils/return-codes.helper';

const RestaurantReturnCodes = (): JSX.Element => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  invariant(restaurantId, 'restaurantId is not set within the route');
  const translations = useTranslations();
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const [page, setPage] = useState(1);
  const { colors } = useTheme();

  const {
    data: paginatedRestaurantReturnCodes,
    isFetching: isFetchingReturnCodes,
    error: loadReturnCodesListError,
  } = useRestaurantReturnCodesList(restaurantId, {
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  useSearchCancelQuery(searchText, CK_RESTAURANT_RETURN_CODES);

  const handleDownloadReturnCodePoster = useCallback((original: RestaurantReturnCodeResponse) => {
    return async () => {
      const blob = await pdf(
        <PdfDocument
          returnCode={original}
          restaurant={{
            restaurantName: original.restaurant.restaurantName || '',
            metaCategories: original.restaurant.metaCategories ?? [],
          }}
        />,
      ).toBlob();
      saveAs(blob, getReturnCodePosterFileName(original.name));
    };
  }, []);

  const columns = [
    {
      id: '1',
      Header: translations('restaurant_return_codes_table_name'),
      accessor: 'name' as const,
    },
    {
      id: '2',
      Header: translations('restaurant_return_codes_table_status'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<RestaurantReturnCodeResponse>) => (
        <ReturnCodeStatusUpdate status={original.status} id={original.id} restaurantId={restaurantId} />
      ),
    },
    {
      id: '3',
      Header: translations('restaurant_return_codes_table_id'),
      accessor: 'id' as const,
    },
    {
      id: '4',
      Header: translations('restaurant_return_codes_table_u_id'),
      accessor: 'uId' as const,
    },
    {
      id: '5',
      Header: translations('restaurant_return_codes_table_type'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<RestaurantReturnCodeResponse>) => <Text>{getReturnCodeTypeLabel(original)}</Text>,
    },
    {
      id: '6',
      Header: translations('restaurant_return_codes_table_qr_code'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<RestaurantReturnCodeResponse>) => (
        <Flex justifyContent="center">
          <LinkButton
            data-testid="qr-code"
            p={1}
            to={generatePath(Routes.DOWNLOAD_RESTAURANT_RETURN_CODES_PATH, {
              restaurantId: original.restaurant.id,
              id: original.id,
            })}
          >
            <Image src={qrCodeIcon} alt="QR code icon" width={34} />
          </LinkButton>
          <Button ml={2} color={colors.orange[500]} onClick={handleDownloadReturnCodePoster(original)}>
            {translations('return_codes_download_poster')}
          </Button>
        </Flex>
      ),
    },
    {
      id: 'actions',
      Header: translations('restaurant_return_codes_table_actions'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<RestaurantReturnCodeResponse>) => (
        <Flex>
          <AddEditItemLink
            to={generatePath(Routes.UPDATE_RESTAURANT_RETURN_CODES_PATH, {
              id: original.id,
              restaurantId: original.restaurant.id,
            })}
            m={1}
            isEdit
          />
        </Flex>
      ),
    },
  ];

  return (
    <MainPageContent>
      <PageHeading>{translations('restaurant_return_codes_header')}</PageHeading>
      <ErrorMessages errors={[loadReturnCodesListError]} />
      <Flex justifyContent="flex-end">
        <Link to={generatePath(Routes.CREATE_RESTAURANT_RETURN_CODES_PATH, { restaurantId })}>
          <Button>{`+ ${translations('restaurant_return_codes_add')}`}</Button>
        </Link>
      </Flex>
      <Table<RestaurantReturnCodeResponse>
        data={paginatedRestaurantReturnCodes?.items}
        columns={columns}
        isLoading={isFetchingReturnCodes}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedRestaurantReturnCodes?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('restaurant_return_codes_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default RestaurantReturnCodes;
