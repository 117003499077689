import React, { JSX } from 'react';
import { CellProps } from 'react-table';
import { Button, Flex, Image } from '@chakra-ui/react';
import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { useExecuteWithConfirmation } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useDeleteNews, useNewsList } from 'services/news';

import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { NewsResponse } from './models/news-response';

const News = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const [page, setPage] = React.useState(1);

  const {
    data: paginatedNews,
    isFetching: isFetchingNews,
    error: loadNewsError,
  } = useNewsList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const { mutate: deleteNews, isLoading: isDeletingNews, error: deleteError } = useDeleteNews();

  const { executeWithConfirmation: onDeleteNews } = useExecuteWithConfirmation(
    translations('news_delete_confirmation'),
    deleteNews,
  );
  const columns = React.useMemo(
    () => [
      {
        id: '1',
        Header: translations('news_table_title'),
        accessor: 'title' as const,
      },
      {
        id: '2',
        Header: translations('news_table_subtitle'),
        accessor: 'subtitle' as const,
      },
      {
        id: '3',
        Header: translations('news_table_language'),
        accessor: 'language' as const,
      },
      {
        id: '4',
        Header: translations('news_table_detailsPageUrl'),
        accessor: 'detailsPageUrl' as const,
      },
      {
        id: '5',
        Header: translations('news_table_image'),
        accessor: 'image' as const,
        Cell: ({ cell: { value } }: CellProps<NewsResponse>) => <Image width="200px" src={value} />,
      },
      {
        id: 'actions',
        Header: translations('news_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<NewsResponse>) => (
          <Flex>
            <AddEditItemLink to={`${Routes.NEWS_PATH}/${original.id}`} m={1} isEdit />
            <Button m={1} onClick={() => onDeleteNews(original.id)}>
              {translations('delete')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, onDeleteNews],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('news_header')}</PageHeading>
      <ErrorMessages errors={[loadNewsError, deleteError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_NEWS_PATH}>{translations('news_add')}</AddEditItemLink>
      </Flex>
      <Table<NewsResponse>
        isLoading={isFetchingNews || isDeletingNews}
        data={paginatedNews?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedNews?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('news_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default News;
