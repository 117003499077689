import { useToast } from '../../../../../../common/components';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { RestaurantsCreateReturnCodesResponse } from '../../../../../Restaurants/RestaurantReturnCodes/models/restaurants-create-return-codes-response';
import { KeyAccountGroupCreateReturnCodesResponse } from '../../../../models/key-account-group-create-return-codes-response';

export const useShowCreatedReturnCodesSummary = () => {
  const translations = useTranslations();
  const { displayToast } = useToast();

  const showSummary = (data: RestaurantsCreateReturnCodesResponse | KeyAccountGroupCreateReturnCodesResponse) => {
    if (data.notCreatedReturnCodes.length === 0) {
      const createdReturnCodesNumber = data.createdReturnCodes.length;
      displayToast(
        'success',
        translations('create_return_codes_success', {
          '{{created-return-codes-number}}': createdReturnCodesNumber.toString(),
        }),
        false,
        4000,
      );
    } else {
      const createdReturnCodesNumber = data.createdReturnCodes.length;
      const notCreatedReturnCodesNumber = data.notCreatedReturnCodes.length;
      displayToast(
        'warning',
        translations('create_return_codes_warning', {
          '{{created-return-codes-number}}': createdReturnCodesNumber.toString(),
          '{{all-return-codes-number}}': (createdReturnCodesNumber + notCreatedReturnCodesNumber).toString(),
        }),
        false,
        4000,
      );
    }
  };

  return { showSummary };
};
