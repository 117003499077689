import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getLocations } from '../api/locations';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { RestaurantResponse } from '../modules/Restaurants/models';

export const CK_LOCATIONS = 'locations';

export const useLocationList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<RestaurantResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_LOCATIONS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getLocations(params),
    {
      keepPreviousData: true,
    },
  );
};
