import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'config/routes';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { useTranslations } from '../../contexts/LocalizationContext';
import { usePartner, useUpdatePartner } from '../../services/partners';
import { PartnerFormValues } from './partner.model';
import { mapFormPartnerToRequest } from './Partners.utils';
import PartnersForm from './PartnersForm';

const UpdatePartner = (): JSX.Element => {
  const translations = useTranslations();
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const { isLoading: isPartnerUpdate, mutate: updatePartner } = useUpdatePartner();
  const { isLoading: isPartnerFetching, data: partner } = usePartner(id);

  const formData = partner
    ? ({
        partnerName: partner.partnerName,
        apiKey: partner.apiKey,
        isEnabled: partner.isEnabled,
        restaurants: partner.restaurants,
      } as PartnerFormValues)
    : undefined;

  return (
    <>
      <ChildPageHeading>{translations('partners_update_partner')}</ChildPageHeading>
      <PartnersForm
        isEditing
        isLoading={isPartnerFetching || isPartnerUpdate}
        formData={formData}
        onSubmit={async (values) => {
          const mappedValues = mapFormPartnerToRequest(values);

          if (partner?.id) {
            await updatePartner(
              { values: mappedValues, id: partner.id },
              {
                onSuccess: () => navigate(Routes.PARTNERS_PATH),
              },
            );
          }
        }}
      />
    </>
  );
};

export default UpdatePartner;
