import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { Corporate, CorporateRequest } from 'modules/Corporates/Corporate.model';

import { createCorporate, deleteCorporate, getCorporate, getCorporates, updateCorporate } from '../api/corporates';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';

export const CK_CORPORATES = 'corporates';

export const useCorporatesList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<Corporate>, AxiosError<HttpException>> =>
  useQuery(
    [CK_CORPORATES, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getCorporates(params),
    { keepPreviousData: true },
  );

export const useCorporate = (id: string): QueryObserverResult<Corporate, AxiosError<HttpException>> =>
  useQuery([CK_CORPORATES, id], () => getCorporate(id));

export const useCreateCorporate = (): UseMutationResult<
  Corporate,
  AxiosError<HttpException>,
  { values: CorporateRequest }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values }) => createCorporate(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CORPORATES);
    },
  });
};

export const useUpdateCorporate = (): UseMutationResult<
  Corporate,
  AxiosError<HttpException>,
  { values: CorporateRequest; id: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values, id }) => updateCorporate(values, id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CORPORATES);
    },
  });
};

export const useDeleteCorporate = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteCorporate(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CORPORATES);
    },
  });
};
