import React, { JSX, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { Menu } from './Menu';

export const MenuMobile = (): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  useEffect(() => {
    onClose();
  }, [pathname, onClose]);

  return (
    <>
      <IconButton
        aria-label={translations('menu_open_menu')}
        right={0}
        zIndex={1000}
        position="fixed"
        sx={{ '@media print': { display: 'none' } }}
        title={translations('menu_open_menu')}
        onClick={onOpen}
        bg={colors.white}
        icon={<HamburgerIcon boxSize="32px" bg={colors.white} />}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader />
            <DrawerBody>
              <Menu activeTextColor={colors.orange[500]} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
