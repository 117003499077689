import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { pdf } from '@react-pdf/renderer';

import { Modal } from '../../../../../common/components';
import { useDownloadZip } from '../../../../../common/hooks/use-download-zip.hook';
import { getQRCodeSrc } from '../../../../../common/utils/qrCodes.helper';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useCreateKeyAccountGroupReturnCodes } from '../../../../../services/key-account-groups';
import { useCreateRestaurantsReturnCodes } from '../../../../../services/return-codes';
import { ReturnCodeType } from '../../../../Restaurants/RestaurantReturnCodes/models/enums/return-code-type.enum';
import { RestaurantsCreateReturnCodesResponse } from '../../../../Restaurants/RestaurantReturnCodes/models/restaurants-create-return-codes-response';
import PdfDocument from '../../../../Restaurants/ReturnPosters/PdfDocument';
import { getReturnCodeFileName, getReturnCodePosterFileName } from '../../../../Restaurants/utils/return-codes.helper';
import { KeyAccountGroupCreateReturnCodesResponse } from '../../../models/key-account-group-create-return-codes-response';
import { useKeyAccountGroupRestaurantsContext } from '../../context/KeyAccountGroupRestaurantsContext/hooks/use-key-account-group-restaurants-context.hook';
import {
  createReturnCodesFormDefaultValues,
  CreateReturnCodesFormType,
  useCreateReturnCodesFormValidation,
} from './components/CreateReturnCodesForm/create-return-codes.form';
import { CreateReturnCodesForm } from './components/CreateReturnCodesForm/CreateReturnCodesForm';
import { useShowCreatedReturnCodesSummary } from './hooks/use-show-created-return-codes-summary.hook';
import { CreateRestaurantsReturnCodesRequest } from './models/create-restaurants-return-codes-request';
import { CreateReturnCodesRequest } from './models/create-return-codes-request';
import { CreateReturnCodesOptions } from './models/enums/create-return-codes-options.enum';
import { RETURN_CODES_ZIP_FILE_NAME, RETURN_POSTERS_ZIP_FILE_NAME } from './utils/restaurant-return-codes.const';
import { AffectedRestaurants } from './AffectedRestaurants';

type CreateReturnCodesModalProps = {
  keyAccountGroupId: string;
  isOpen: boolean;
  onClose: () => void;
};

type CreateReturnCodesResponse = RestaurantsCreateReturnCodesResponse | KeyAccountGroupCreateReturnCodesResponse;

export const CreateReturnCodesModal = ({ keyAccountGroupId, isOpen, onClose }: CreateReturnCodesModalProps) => {
  const translations = useTranslations();
  const { selectedRestaurantsNumber, isEachRestaurantSelected, selectedRestaurantsIds } =
    useKeyAccountGroupRestaurantsContext();
  const { mutate: createRestaurantsReturnCodes, isLoading: isCreatingRestaurantReturnCodes } =
    useCreateRestaurantsReturnCodes();
  const { mutate: createKeyAccountGroupReturnCodes, isLoading: isCreatingKeyAccountGroupReturnCodes } =
    useCreateKeyAccountGroupReturnCodes();
  const {
    isOpen: showAffectedRestaurants,
    onOpen: handleOpenAffectedRestaurants,
    onClose: handleCloseAffectedRestaurants,
  } = useDisclosure();
  const { downloadZip } = useDownloadZip();
  const { showSummary } = useShowCreatedReturnCodesSummary();

  const validationSchema = useCreateReturnCodesFormValidation();
  const formMethods = useForm<CreateReturnCodesFormType>({
    defaultValues: createReturnCodesFormDefaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    onClose();
    formMethods.reset();
    if (showAffectedRestaurants) {
      handleCloseAffectedRestaurants();
    }
  };

  const downloadReturnCodes = async (data: CreateReturnCodesResponse) => {
    const files = await Promise.all(
      data.createdReturnCodes.map(async (createdReturnCode) => {
        const qrCodeUrl = getQRCodeSrc(createdReturnCode.returnCode.qrCode);
        const qrCodeImageResponse = await fetch(qrCodeUrl);
        return {
          name: getReturnCodeFileName(createdReturnCode.returnCode.name),
          blob: await qrCodeImageResponse.blob(),
        };
      }),
    );
    await downloadZip(files, RETURN_CODES_ZIP_FILE_NAME);
  };

  const downloadReturnPosters = async (data: CreateReturnCodesResponse) => {
    const files = await Promise.all(
      data.createdReturnCodes.map(async (createdReturnCode) => {
        const blob = await pdf(
          <PdfDocument returnCode={createdReturnCode.returnCode} restaurant={createdReturnCode.restaurant} />,
        ).toBlob();
        return {
          name: getReturnCodePosterFileName(createdReturnCode.returnCode.name),
          blob,
        };
      }),
    );
    await downloadZip(files, RETURN_POSTERS_ZIP_FILE_NAME);
  };

  const handleSummary = (data: CreateReturnCodesResponse, downloadFn?: (data: CreateReturnCodesResponse) => void) => {
    if (downloadFn) {
      downloadFn(data);
    }
    showSummary(data);
  };

  const handleCreateMultipleReturnCodes = (
    form: CreateReturnCodesFormType,
    downloadFn?: (data: CreateReturnCodesResponse) => void,
  ) => {
    const baseRequest: CreateReturnCodesRequest = {
      type: form.type,
      gracePeriod: form.gracePeriod !== undefined && form.type === ReturnCodeType.OPEN_HOURS ? form.gracePeriod : null,
    };
    if (isEachRestaurantSelected) {
      createKeyAccountGroupReturnCodes(
        { keyAccountGroupId, request: baseRequest },
        {
          onSuccess: (data: KeyAccountGroupCreateReturnCodesResponse) => handleSummary(data, downloadFn),
        },
      );
    } else {
      const request: CreateRestaurantsReturnCodesRequest = {
        restaurantIds: selectedRestaurantsIds,
        ...baseRequest,
      };
      createRestaurantsReturnCodes(
        { request },
        {
          onSuccess: (data: RestaurantsCreateReturnCodesResponse) => handleSummary(data, downloadFn),
        },
      );
    }
  };

  const modalHeader = isEachRestaurantSelected
    ? translations('create_return_codes_for_key_account_group')
    : translations('create_return_codes_for_multiple_restaurants', {
        '{{restaurants-number}}': selectedRestaurantsNumber.toString(),
      });

  const handleConfirm = formMethods.handleSubmit((form) => {
    switch (form.action) {
      case CreateReturnCodesOptions.CREATE:
        handleCreateMultipleReturnCodes(form);
        break;
      case CreateReturnCodesOptions.CREATE_AND_DOWNLOAD_RETURN_CODES:
        handleCreateMultipleReturnCodes(form, downloadReturnCodes);
        break;
      case CreateReturnCodesOptions.CREATE_AND_DOWNLOAD_POSTERS:
        handleCreateMultipleReturnCodes(form, downloadReturnPosters);
        break;
      default: {
        const exhaustiveCheck: never = form.action;
        throw new Error(exhaustiveCheck);
      }
    }
  });

  return (
    <Modal
      size="xl"
      isModalVisible={isOpen}
      onModalClose={handleCloseModal}
      modalHeader={modalHeader}
      customModalFooter={null}
    >
      {!isEachRestaurantSelected ? (
        <AffectedRestaurants
          showAffectedRestaurants={showAffectedRestaurants}
          onShowAffectedRestaurants={handleOpenAffectedRestaurants}
          onCloseAffectedRestaurants={handleCloseAffectedRestaurants}
        />
      ) : null}
      <FormProvider {...formMethods}>
        <CreateReturnCodesForm
          onConfirm={handleConfirm}
          onCancel={handleCloseModal}
          isLoading={isCreatingRestaurantReturnCodes || isCreatingKeyAccountGroupReturnCodes}
        />
      </FormProvider>
    </Modal>
  );
};
