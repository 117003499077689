import React from 'react';
import { Navigate } from 'react-router-dom';
import { Role, useSessionContext } from 'contexts/SessionContext';

import { Routes } from '../../../config/routes';

const ProtectedRoute = ({ roles, element }: { element: React.ReactNode; roles?: Role[] | undefined }) => {
  const { role } = useSessionContext();

  if (roles && (!role || !roles.includes(role))) {
    return <Navigate to={Routes.HOME_PATH} />;
  }

  return element;
};

export default ProtectedRoute;
