import React from 'react';
import { Button } from '@chakra-ui/react';

import { exportRestaurantsByIds, exportRestaurantsByKeyAccountId } from '../../../../../api/restaurants';
import { useToast } from '../../../../../common/components';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useKeyAccountGroupRestaurantsContext } from '../../context/KeyAccountGroupRestaurantsContext/hooks/use-key-account-group-restaurants-context.hook';

type RestaurantsExportProps = {
  keyAccountGroupId: string;
};

export const ExportRestaurants = ({ keyAccountGroupId }: RestaurantsExportProps) => {
  const translations = useTranslations();
  const { displayToast } = useToast();

  const { selectedRestaurantsIds, isEachRestaurantSelected, selectedRestaurantsNumber } =
    useKeyAccountGroupRestaurantsContext();

  const processExportDownload = (response: string) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `restaurants-export-${new Date().getTime()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleExportRestaurants = () => {
    if (keyAccountGroupId && isEachRestaurantSelected) {
      exportRestaurantsByKeyAccountId(keyAccountGroupId)
        .then(processExportDownload)
        .catch(() => {
          displayToast('error', translations('restaurant_export_error'), false, 4000);
        });
    } else {
      exportRestaurantsByIds(selectedRestaurantsIds)
        .then(processExportDownload)
        .catch(() => {
          displayToast('error', translations('restaurant_export_error'), false, 4000);
        });
    }
  };

  return (
    <Button ml={2} mt={2} isDisabled={!selectedRestaurantsNumber} onClick={handleExportRestaurants}>
      {translations('restaurant_export', {
        '{{number}}': selectedRestaurantsNumber.toString(),
      })}
    </Button>
  );
};
