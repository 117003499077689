import React, { JSX } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, ModalFooter, Text, useDisclosure } from '@chakra-ui/react';
import { Modal, ModalType } from 'common/components/Modal/Modal';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { Table } from '../index';

interface FailureItem {
  uId: string;
  message?: string;
}

interface SuccessItem {
  uId: string;
}

type ItemsSummaryModalProps = {
  isVisible: boolean;
  modalHeader: string | JSX.Element | JSX.Element[];
  successText: string | JSX.Element | JSX.Element[];
  failureText: string | JSX.Element | JSX.Element[];
  onCancelClicked: () => void;
  failureItems?: FailureItem[];
  successItems?: SuccessItem[];
};
const ItemsSummaryModal = ({
  isVisible,
  modalHeader,
  successText,
  failureText,
  onCancelClicked,
  failureItems,
  successItems,
}: ItemsSummaryModalProps): JSX.Element | null => {
  const translations = useTranslations();

  const {
    isOpen: showNotAssignedItems,
    onOpen: handleShowNotAssignedItems,
    onClose: handleCloseNotAssignedItems,
  } = useDisclosure();

  const handleToggle = () => (showNotAssignedItems ? handleCloseNotAssignedItems() : handleShowNotAssignedItems());

  const columns = [
    {
      id: '1',
      Header: translations('product_u_id'),
      accessor: 'uId' as const,
    },
    {
      id: '2',
      Header: translations('error'),
      accessor: 'message' as const,
    },
  ];

  const handleCancel = () => {
    handleCloseNotAssignedItems();
    onCancelClicked();
  };

  const getSummaryTextColor = () => {
    if (successItems?.length === 0) {
      return 'red.100';
    } else if (failureItems?.length === 0) {
      return 'green.100';
    } else {
      return 'orange.100';
    }
  };

  return (
    <Modal
      modalHeader={modalHeader}
      size="xl"
      isModalVisible={isVisible}
      onModalClose={handleCancel}
      customModalFooter={
        <ModalFooter justifyContent="flex-start">
          <Button colorScheme="orange" onClick={handleCancel}>
            {translations('ok')}
          </Button>
        </ModalFooter>
      }
      type={ModalType.INFO}
    >
      <Flex alignItems="start" display="column">
        <Text p={2} bgColor={getSummaryTextColor()}>
          {successText}
        </Text>
        {failureItems?.length ? (
          <>
            <Flex onClick={handleToggle} cursor="pointer" pt={4}>
              <Center mr={2}>
                {!showNotAssignedItems ? <ChevronDownIcon h={6} w={6} /> : <ChevronUpIcon h={6} w={6} />}
              </Center>
              <Text>{failureText}</Text>
            </Flex>
            {showNotAssignedItems ? (
              <Box py={4}>
                <Table<FailureItem> data={failureItems} columns={columns} isLoading={false} />
              </Box>
            ) : null}
          </>
        ) : null}
      </Flex>
    </Modal>
  );
};

export default ItemsSummaryModal;
