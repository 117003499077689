import { Currency } from '../../../common/models/enums/currency.enum';
import { formatDate } from '../../../common/utils';

export const REUSE_CARD_DEFAULT_MONETARY_VALUE = 10.0;
export const REUSE_CARD_DEFAULT_CURRENCY = Currency.EUR;
export const REUSE_CARD_DEFAULT_BORROWINGS_LIMIT = 2;

export const REUSE_CARD_BORROWINGS_LIMIT_MIN_VALUE = 0;
export const REUSE_CARD_BORROWINGS_LIMIT_MAX_VALUE = 10;

export const REUSE_CARD_MONETARY_MIN_VALUE = 1.0;
export const REUSE_CARD_MONETARY_MAX_VALUE = 50.0;

export const REUSE_CARD_USERS_BATCH_MIN_SIZE = 1;
export const REUSE_CARD_USERS_BATCH_MAX_SIZE = 50;

export const CSV_REUSE_CARD_USERS_FILENAME = `reuse-card-users-${formatDate(new Date())}.csv`;
export const REUSE_CARD_USERS_TABLE_EMPTY_CELL_VALUE = '-';
