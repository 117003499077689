import { ReactNode, useState } from 'react';

import { ModalContext } from './context';
import { ModalState } from './hook';
interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps): JSX.Element => {
  const [modalState, setModalState] = useState<ModalState | null>(null);
  return <ModalContext.Provider value={[modalState, setModalState]}>{children}</ModalContext.Provider>;
};
