import React from 'react';
import { useParams } from 'react-router-dom';
import { HStack, Text } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { ErrorMessages, FullPageLoader, useToast } from '../../../../common/components';
import { ChildPageHeading } from '../../../../common/components/ChildPageHeading';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReuseCardUser, useUpdateReuseCardUser } from '../../../../services/reuse-cards';
import { UpdateReuseCardFormModel } from '../../models/update-reuse-card-form-model';
import { mapUpdateFormModelToReuseCardUpdateRequest } from '../../utils/reuse-cards.helper';
import { UpdateReuseCardForm } from './components/UpdateReuseCardForm/UpdateReuseCardForm';

export const UpdateReuseCard = () => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const translations = useTranslations();
  const { displayToast } = useToast();
  const { data: reuseCardUser, isLoading: isLoadingReuseCardUser, error: reuseCardUserError } = useReuseCardUser(id);
  const { mutate: updateReuseCardUser, isLoading: isUpdateInProgress } = useUpdateReuseCardUser();

  const handleUpdateReuseCardUser = (form: UpdateReuseCardFormModel) => {
    const request = mapUpdateFormModelToReuseCardUpdateRequest(form);
    updateReuseCardUser(
      { id, request },
      {
        onError: (error) => displayToast('error', error.response?.data.message || translations('something_went_wrong')),
        onSuccess: () => displayToast('success', translations('reuse_cards_update_success')),
      },
    );
  };

  return (
    <>
      <ChildPageHeading>{translations('reuse_cards_details_header')}</ChildPageHeading>
      <FullPageLoader show={isLoadingReuseCardUser || isUpdateInProgress}>
        <HStack p={2} bg="grey.10">
          <Text>{translations('reuse_cards_user_relevo_id_label')}</Text>
          <Text fontWeight={'bold'}>{reuseCardUser?.userRelevoId}</Text>
        </HStack>
        <ErrorMessages errors={reuseCardUserError} />
        <UpdateReuseCardForm model={reuseCardUser} onSubmit={handleUpdateReuseCardUser} isLoading={false} />
      </FullPageLoader>
    </>
  );
};
