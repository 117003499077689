import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormFooter } from '../../../../common/components';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useLostItemsHook } from '../../utils/hooks/use-lost-items.hook';
import { LostItemsActionType } from '../LostItemsActionType/LostItemsActionType';
import { LostItemsCsvFile } from '../LostItemsCsvFile/LostItemsCsvFIle';
import { LostSummaryModal } from '../LostSummaryModal/LostSummaryModal';
import {
  lostItemsFormDefaultValues,
  LostItemsFromCsvFormType,
  useMarkLostItemsFromCsvFormValidation,
} from './LostItems.form';

export const LostItemsForm = () => {
  const translations = useTranslations();
  const validationSchema = useMarkLostItemsFromCsvFormValidation();
  const methods = useForm<LostItemsFromCsvFormType>({
    defaultValues: lostItemsFormDefaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { items, isSubmitFormLoading, handleFormSubmit, onCloseSummaryModal, isSummaryModalOpen } =
    useLostItemsHook(methods);
  const watchLostActionType = methods.watch('action');

  const handleClearForm = () => {
    methods.reset();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleFormSubmit}>
          <LostItemsCsvFile />
          <LostItemsActionType />
          <FormFooter hasCustomContent>
            <Button mb={2} mr={2} isLoading={isSubmitFormLoading} type="submit" colorScheme="orange">
              {translations('lost_items_execute_button')}
            </Button>
            <Button onClick={handleClearForm}>{translations('lost_items_clear_button')}</Button>
          </FormFooter>
        </form>
      </FormProvider>
      <LostSummaryModal
        isOpen={isSummaryModalOpen}
        onClose={onCloseSummaryModal}
        action={watchLostActionType}
        items={items}
      />
    </>
  );
};
