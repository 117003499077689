import React, { JSX } from 'react';
import { CellProps } from 'react-table';
import { Button, Flex, Image } from '@chakra-ui/react';
import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { useExecuteWithConfirmation } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useAboutUsList, useDeleteAboutUs } from 'services/about-us';

import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { AboutUsResponse } from './models/about-us-response';

const AboutUs = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const [page, setPage] = React.useState(1);

  const {
    data: paginatedAboutUs,
    isFetching,
    error,
  } = useAboutUsList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const { mutate: deleteAboutUs, isLoading, error: deleteError } = useDeleteAboutUs();

  const { executeWithConfirmation: onDeleteAboutUs } = useExecuteWithConfirmation(
    translations('about_delete_confirmation'),
    deleteAboutUs,
  );

  const columns = React.useMemo(
    () => [
      {
        id: '1',
        Header: translations('about_table_fullname'),
        accessor: 'fullName' as const,
      },
      {
        id: '2',
        Header: translations('about_table_description'),
        accessor: 'description' as const,
      },
      {
        id: '3',
        Header: translations('about_table_language'),
        accessor: 'language' as const,
      },
      {
        id: '4',
        Header: translations('about_table_detailsPageUrl'),
        accessor: 'detailsPageUrl' as const,
      },
      {
        id: '5',
        Header: translations('about_table_image'),
        accessor: 'image' as const,
        Cell: ({ cell: { value } }: CellProps<AboutUsResponse>) => <Image width="200px" src={value} />,
      },
      {
        id: 'actions',
        Header: translations('about_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<AboutUsResponse>) => (
          <Flex>
            <AddEditItemLink to={`${Routes.ABOUT_PATH}/${original.id}`} m={1} isEdit />
            <Button m={1} onClick={() => onDeleteAboutUs(original.id)}>
              {translations('delete')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, onDeleteAboutUs],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('about_header')}</PageHeading>
      <ErrorMessages errors={[error, deleteError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_ABOUT_PATH}>{translations('about_add')}</AddEditItemLink>
      </Flex>
      <Table<AboutUsResponse>
        isLoading={isFetching || isLoading}
        data={paginatedAboutUs?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedAboutUs?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('about_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default AboutUs;
