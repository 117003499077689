import React from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { ErrorMessages } from '../../../common/components';
import { ChildPageHeading } from '../../../common/components/ChildPageHeading';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useRestaurantUserNotificationSettings } from '../../../services/restaurant-user-notification-settings';
import { NotificationSettingsForm } from './components/NotificationSettingsForm/NotificationSettingsForm';

export const NotificationSettings = () => {
  const { id: userId } = useParams<{ id: string }>();
  invariant(userId, 'id is not set within the route');
  const translations = useTranslations();

  const { data: userNotificationSettings, error: notificationSettingsError } =
    useRestaurantUserNotificationSettings(userId);

  return (
    <>
      {userNotificationSettings ? (
        <>
          <ChildPageHeading>{translations('notification_settings_header')}</ChildPageHeading>
          <ErrorMessages errors={[notificationSettingsError]} />
          <Flex mb={2} bgColor="grey.10" p={2}>
            <Text color="grey.200" mr={2}>
              {translations('restaurant_user_label')}
            </Text>
            <Text color="grey.200">{userNotificationSettings.email}</Text>
          </Flex>
          <NotificationSettingsForm userId={userId} userNotificationSettings={userNotificationSettings} />
        </>
      ) : null}
    </>
  );
};
