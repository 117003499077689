import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { FormGroup } from '../../../../common/components';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { LostItemsFormActionType } from '../../model/enums/lost-items-form-action-type.enum';

export const LostItemsActionType = () => {
  const translations = useTranslations();
  const { control } = useFormContext();

  return (
    <FormGroup label={translations('lost_items_action_label')} inputId="action">
      <Controller
        control={control}
        name="action"
        render={({ field: { value, onChange } }) => (
          <RadioGroup onChange={onChange} value={value} id="action">
            <Stack direction="row" textAlign={'left'} spacing={8}>
              <Radio value={LostItemsFormActionType.MARK_AS_LOST}>
                {translations('lost_items_mark_as_lost_option')}
              </Radio>
              <Radio value={LostItemsFormActionType.MARK_AS_ACTIVE}>
                {translations('lost_items_mark_as_active_option')}
              </Radio>
            </Stack>
          </RadioGroup>
        )}
      />
    </FormGroup>
  );
};
