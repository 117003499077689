import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getCountries } from '../api/country';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { Country } from '../modules/Country/model/Country';

export const CK_COUNTRIES = 'countries';

export const useCountryList = (
  params: BaseQueryParams = ALL_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<Country>, AxiosError<HttpException>> =>
  useQuery([CK_COUNTRIES, { text: params.searchText, page: params.offset, limit: params.limit }], () =>
    getCountries(params),
  );
