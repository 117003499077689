import React from 'react';
import DatePicker from 'react-date-picker';
import { Value } from 'react-date-picker/src/shared/types';
import { Box } from '@chakra-ui/react';

import RestaurantMultiSelect from '../../../../../common/components/RestaurantSelect/RestaurantMultiSelect';
import { DATE_PICKER_LOCALIZATION_CONFIG } from '../../../../../config/configConsts';
import { RestaurantResponse } from '../../../../Restaurants/models';
import { useReturnCodesFilterContext } from '../../context/HistoryFilterContext/hooks/use-return-codes-filter-context';
import { ReturnCodeStatusSelect } from '../ReturnCodesStatusSelect/ReturnCodesStatusSelect';

type Props = {
  keyAccountGroupRestaurants: RestaurantResponse[];
};

export const KeyAccountGroupReturnCodesFilter = ({ keyAccountGroupRestaurants }: Props) => {
  const { returnCodesFilterState, handleSelectRestaurants, handleSelectStatus, handleSelectCreationDate } =
    useReturnCodesFilterContext();

  const handleSelectDate = (value: Value) => {
    handleSelectCreationDate(value as Date | null);
  };

  return (
    <>
      <Box pt={2} mr={2} width="25%" minWidth="300px">
        <RestaurantMultiSelect
          selectedRestaurantIds={returnCodesFilterState.restaurantIds}
          restaurants={keyAccountGroupRestaurants}
          onSelectRestaurants={handleSelectRestaurants}
        />
      </Box>
      <Box pt={2} mr={2} width="25%" minWidth="300px">
        <ReturnCodeStatusSelect
          onReturnCodeStatusChange={handleSelectStatus}
          returnCodeStatus={returnCodesFilterState.status}
        />
      </Box>
      <Box pt={2} mr={2} width="25%" minWidth="300px">
        <DatePicker
          locale={DATE_PICKER_LOCALIZATION_CONFIG.locale}
          format={DATE_PICKER_LOCALIZATION_CONFIG.dateFormat}
          value={returnCodesFilterState.date || null}
          onChange={handleSelectDate}
          maxDate={new Date()}
        />
      </Box>
    </>
  );
};
