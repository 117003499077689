import React, { ComponentProps, JSX } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import colors from 'styles/colors';

import { LinkButton } from '../LinkButton';

type AddItemButtonProps = {
  to: string;
} & (
  | {
      isEdit: true;
      children?: string;
    }
  | {
      isEdit?: false;
      children: string;
    }
);

const ADD_ITEM_PREFIX = '+';

export const AddEditItemLink = ({
  to,
  children,
  isEdit = false,
  ...props
}: AddItemButtonProps & ComponentProps<typeof Button>): JSX.Element => {
  const translations = useTranslations();
  return (
    <LinkButton to={to} {...(isEdit && { style: { color: colors.orange[500] } })} {...props}>
      {isEdit ? <>{children || translations('edit')}</> : `${ADD_ITEM_PREFIX} ${children}`}
    </LinkButton>
  );
};
