import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import {
  addRestaurantImage,
  createRestaurant,
  createRestaurantCredential,
  createRestaurants,
  createRestaurantUsers,
  deleteRestaurantImage,
  deleteRestaurantUser,
  getKeyAccountGroupRestaurants,
  getRestaurant,
  getRestaurantImages,
  getRestaurantOpeningHours,
  getRestaurants,
  getRestaurantsByIds,
  getRestaurantUser,
  getRestaurantUsers,
  updateRestaurant,
  updateRestaurantImagesOrder,
  updateRestaurants,
  updateRestaurantUser,
} from 'api/restaurants';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import {
  RestaurantUserRequest,
  RestaurantUserResponse,
  RestaurantUserSettingsRequest,
} from 'modules/RestaurantCredentials/models';
import { RestaurantOpeningHoursResponse } from 'modules/Restaurants/models/restaurant-opening-hours-response.model';
import { RestaurantRequest } from 'modules/Restaurants/models/restaurant-request.model';
import { RestaurantDetailsResponse, RestaurantResponse } from 'modules/Restaurants/models/restaurant-response.model';

import { useToast } from '../common/components';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS, DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { KeyAccountGroupRestaurantsQueryRequest } from '../modules/KeyAccountGroups/models/key-account-group-restaurants-query-request';
import { CreateRestaurantUsersRequest } from '../modules/RestaurantCredentials/models/create-restaurant-users-request.model';
import { CreateRestaurantUsersResponse } from '../modules/RestaurantCredentials/models/create-restaurant-users-response.model';
import { CreateRestaurantsResponse } from '../modules/Restaurants/ImportRestaurants/model/create-restaurants.response.model';
import { CreateRestaurantsRequest } from '../modules/Restaurants/ImportRestaurants/model/create-restaurants-request.model';
import { RestaurantFilterMode } from '../modules/Restaurants/models/enums/restaurant-filter-mode.enum';
import { RestaurantImageRequest } from '../modules/Restaurants/models/restaurant-image-request.model';
import { ImageResponse, RestaurantImagesResponse } from '../modules/Restaurants/models/restaurant-image-response.model';
import { RestaurantImagesOrderRequest } from '../modules/Restaurants/models/restaurant-images-order.request';
import { RestaurantDraftsFilters } from '../modules/Restaurants/RestaurantDrafts/models/restaurant-drafts-filters.model';
import { UpdateRestaurantsRequest } from '../modules/Restaurants/UpdateMultipleRestaurants/model/update-restaurants.request.model';
import { UpdateRestaurantsResponse } from '../modules/Restaurants/UpdateMultipleRestaurants/model/update-restaurants.response.model';
import { CK_CITIES } from './cities';

export const CK_RESTAURANTS = 'restaurants';
export const CK_RESTAURANT_IMAGES = 'restaurant-images';
export const CK_RESTAURANT_USERS = 'restaurant-users';

type RestaurantListParams = Omit<BaseQueryParams, 'searchText'> & { searchText?: string } & {
  isActive?: boolean;
  q?: string;
  filterMode?: RestaurantFilterMode;
};

export type RestaurantDraftListParams = Omit<BaseQueryParams, 'searchText'> & RestaurantDraftsFilters;

type RestaurantIdsListParams = Omit<BaseQueryParams, 'searchText'> & { restaurantIds?: string[] };

export const useRestaurantsByIds = (
  params: RestaurantIdsListParams = ALL_PAGINATED_LIST_ITEMS_PARAMS,
  enabled = !!params.restaurantIds?.length,
): QueryObserverResult<Paginated<RestaurantResponse>, AxiosError<HttpException>> =>
  useQuery([CK_RESTAURANTS], () => getRestaurantsByIds(params), {
    keepPreviousData: true,
    enabled,
  });

export const useRestaurantList = (
  params: RestaurantListParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<RestaurantResponse>, AxiosError<HttpException>> =>
  useQuery(
    [
      CK_RESTAURANTS,
      { text: params.searchText, page: params.offset, limit: params.limit, filterMode: params.filterMode },
    ],
    () => getRestaurants(params),
    {
      keepPreviousData: true,
      enabled: params.isActive,
    },
  );

export const useKeyAccountGroupRestaurantList = (
  params: KeyAccountGroupRestaurantsQueryRequest,
): QueryObserverResult<Paginated<RestaurantResponse>, AxiosError<HttpException>> =>
  useQuery([CK_RESTAURANTS, { ...params }], () => getKeyAccountGroupRestaurants(params), {
    keepPreviousData: true,
    enabled: params.isActive,
  });

export const useRestaurant = (id: string): QueryObserverResult<RestaurantDetailsResponse, AxiosError<HttpException>> =>
  useQuery([CK_RESTAURANTS, id], () => getRestaurant(id), { keepPreviousData: true });

export const useRestaurantOpeningHours = (): UseMutationResult<
  Record<number, RestaurantOpeningHoursResponse>,
  AxiosError<HttpException>,
  { id: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(getRestaurantOpeningHours, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_RESTAURANTS);
    },
  });
};

export const useCreateRestaurant = (): UseMutationResult<
  RestaurantDetailsResponse,
  AxiosError<HttpException>,
  RestaurantRequest
> => {
  const queryClient = useQueryClient();
  return useMutation(createRestaurant, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_RESTAURANTS);
    },
  });
};

export const useUpdateRestaurant = (): UseMutationResult<
  RestaurantDetailsResponse,
  AxiosError<HttpException>,
  { id: string; updateRestaurantRequest: RestaurantRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, updateRestaurantRequest }: { id: string; updateRestaurantRequest: RestaurantRequest }) =>
      updateRestaurant(id, updateRestaurantRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_RESTAURANTS);
      },
    },
  );
};

export const useRestaurantUsersList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<RestaurantUserResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_RESTAURANT_USERS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getRestaurantUsers(params),
    {
      keepPreviousData: true,
    },
  );
};

export const useCreateRestaurantUsers = (
  onSuccess: (data: CreateRestaurantUsersResponse) => void,
): UseMutationResult<CreateRestaurantUsersResponse, AxiosError<HttpException>, CreateRestaurantUsersRequest> => {
  const queryClient = useQueryClient();
  return useMutation((data) => createRestaurantUsers(data), {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(CK_RESTAURANT_USERS);
      onSuccess(data);
    },
  });
};

export const useRestaurantUser = (
  id: string,
): QueryObserverResult<RestaurantUserResponse, AxiosError<HttpException>> => {
  return useQuery([CK_RESTAURANT_USERS, id], () => getRestaurantUser(id));
};

export const useCreateUpdateRestaurantUser = (): UseMutationResult<
  RestaurantUserResponse,
  AxiosError<HttpException>,
  { values: RestaurantUserSettingsRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ values }: { values: RestaurantUserSettingsRequest }) => updateRestaurantUser(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CITIES);
    },
  });
};

export interface CreateRestaurantUserMutation {
  createRestaurantCredentialRequest: RestaurantUserRequest;
}

export const useCreateRestaurantUser = (
  onSuccess: (data: RestaurantUserResponse, variable: CreateRestaurantUserMutation) => void,
): UseMutationResult<
  RestaurantUserResponse,
  AxiosError<HttpException>,
  { createRestaurantCredentialRequest: RestaurantUserRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ createRestaurantCredentialRequest: createRestaurantUserRequest }: CreateRestaurantUserMutation) =>
      createRestaurantCredential(createRestaurantUserRequest),
    {
      onSuccess: async (data, variable) => {
        await queryClient.invalidateQueries(CK_RESTAURANT_USERS);
        onSuccess(data, variable);
      },
    },
  );
};

export const useDeleteRestaurantUser = (): UseMutationResult<void, AxiosError<HttpException>, { id: string }> => {
  const queryClient = useQueryClient();
  return useMutation(({ id }: { id: string }) => deleteRestaurantUser(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_RESTAURANT_USERS);
    },
  });
};

export const useRestaurantImagesList = (
  restaurantId: string,
): QueryObserverResult<RestaurantImagesResponse, AxiosError<HttpException>> => {
  return useQuery([CK_RESTAURANT_IMAGES, { restaurantId }], () => getRestaurantImages(restaurantId), {
    keepPreviousData: true,
  });
};

export const useRestaurantWithImages = (
  id: string,
): Pick<QueryObserverResult<RestaurantDetailsResponse, AxiosError<HttpException>>, 'error' | 'data' | 'isLoading'> => {
  const restaurantQuery = useRestaurant(id);
  const imagesQuery = useRestaurantImagesList(id);

  return {
    data:
      restaurantQuery.data && imagesQuery.data
        ? {
            ...restaurantQuery.data,
            images: imagesQuery.data.restaurantImages,
          }
        : undefined,
    error: restaurantQuery.error || imagesQuery.error,
    isLoading: restaurantQuery.isLoading || imagesQuery.isLoading,
  };
};

export const useDeleteRestaurantImage = (
  restaurantId: string,
): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => {
      return deleteRestaurantImage(restaurantId, id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_RESTAURANT_IMAGES);
      },
    },
  );
};

export const useChangeOrderRestaurantImage = ({
  onSuccessfulAssign,
}: {
  onSuccessfulAssign: (data: number) => void;
}): UseMutationResult<
  number,
  AxiosError<HttpException>,
  { restaurantId: string; restaurantImages: RestaurantImagesOrderRequest[] }
> => {
  return useMutation(
    ({ restaurantId, restaurantImages }: { restaurantId: string; restaurantImages: RestaurantImagesOrderRequest[] }) =>
      updateRestaurantImagesOrder(restaurantId, restaurantImages),
    {
      onSuccess: (status) => onSuccessfulAssign(status),
    },
  );
};

export const useAddRestaurantImage = ({
  onSuccessfulAssign,
}: {
  onSuccessfulAssign: (data: ImageResponse) => void;
}): UseMutationResult<
  ImageResponse,
  AxiosError<HttpException>,
  { restaurantId: string; addRestaurantImageRequest: RestaurantImageRequest }
> => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  return useMutation(
    ({
      restaurantId,
      addRestaurantImageRequest,
    }: {
      restaurantId: string;
      addRestaurantImageRequest: RestaurantImageRequest;
    }) => addRestaurantImage(restaurantId, addRestaurantImageRequest),
    {
      onSuccess: (data) => onSuccessfulAssign(data),
      onError: (error) => {
        const toastMessage = error.response?.data.message
          ? error.response?.data.message
          : translations('image_modal_error');
        displayToast('error', toastMessage);
      },
    },
  );
};

export const useCreateRestaurants = ({
  onSuccessfulCreate,
}: {
  onSuccessfulCreate: (data: CreateRestaurantsResponse) => void;
}): UseMutationResult<CreateRestaurantsResponse, AxiosError<HttpException>, CreateRestaurantsRequest> => {
  return useMutation(createRestaurants, {
    onSuccess: (data) => onSuccessfulCreate(data),
  });
};

const getRestaurantsUpdateApiErrorToastBody = (message?: any): string | undefined => {
  if (message) {
    if (Array.isArray(message)) {
      return message.join(', ');
    } else {
      return message;
    }
  }
};

export const useUpdateRestaurants = (): UseMutationResult<
  UpdateRestaurantsResponse,
  AxiosError<HttpException>,
  { restaurantIds: string[]; data: UpdateRestaurantsRequest }
> => {
  const queryClient = useQueryClient();
  const translations = useTranslations();
  const { displayToast } = useToast();
  return useMutation(
    ({ restaurantIds, data }: { restaurantIds: string[]; data: UpdateRestaurantsRequest }) =>
      updateRestaurants(restaurantIds, data),
    {
      onSuccess: async (data) => {
        if (data.notUpdatedRestaurants.length > 0) {
          displayToast(
            'warning',
            translations('restaurants_mass_update_warning_response_message', {
              '{{updated_restaurants}}': data.updatedRestaurants.length.toString(),
              '{{not_updated_restaurants}}': (
                data.updatedRestaurants.length + data.notUpdatedRestaurants.length
              ).toString(),
              '{{not_updated_ids}}': data.notUpdatedRestaurants.join(', '),
            }),
          );
        } else {
          displayToast('success', translations('restaurants_mass_update_success_response_message'));
        }
        await queryClient.invalidateQueries(CK_RESTAURANTS);
      },
      onError: (error) =>
        displayToast(
          'error',
          getRestaurantsUpdateApiErrorToastBody(error.response?.data.message) ||
            translations('restaurants_mass_update_error'),
        ),
    },
  );
};
