import { useContext } from 'react';

import { KeyAccountGroupRestaurantsContext } from '../KeyAccountGroupRestaurantsContext';
import { KeyAccountGroupRestaurantsContextInterface } from '../models/key-account-group-restaurants-context';

export const useKeyAccountGroupRestaurantsContext = (): KeyAccountGroupRestaurantsContextInterface => {
  const ctx = useContext(KeyAccountGroupRestaurantsContext);
  if (!ctx) {
    throw new Error('No context provided for KeyAccountGroupRestaurants');
  }
  return ctx;
};
