import { BaseQueryParams, Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { RestaurantResponse } from '../modules/Restaurants/models';
import axiosInstance from './axios';

export const getLocations = ({
  searchText,
  offset,
  limit,
}: BaseQueryParams): Promise<Paginated<RestaurantResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance
    .get<Paginated<RestaurantResponse>>(`${apiPaths.LOCATIONS}?${searchParams}`)
    .then(({ data }) => data);
};
