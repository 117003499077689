import { BaseQueryParams, Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { ClosingSource, ClosingSourceRequest } from '../modules/ClosingSources/model/ClosingSource';
import axiosInstance from './axios';

export const getClosingSources = ({
  searchText,
  offset,
  limit,
}: BaseQueryParams): Promise<Paginated<ClosingSource>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get(`${apiPaths.CLOSING_SOURCES}?${searchParams}`).then(({ data }) => data);
};

export const getClosingSource = (id: string): Promise<ClosingSource> =>
  axiosInstance.get<ClosingSource>(`${apiPaths.CLOSING_SOURCES}/${id}`).then(({ data }) => data);

export const updateClosingSource = (values: ClosingSourceRequest, id: string): Promise<ClosingSource> => {
  return axiosInstance.put(`${apiPaths.CLOSING_SOURCES}/${id}`, values);
};

export const createClosingSource = (values: ClosingSourceRequest): Promise<ClosingSource> => {
  return axiosInstance.post(`${apiPaths.CLOSING_SOURCES}`, values);
};

export const deleteClosingSource = (id: string): Promise<void> =>
  axiosInstance.delete(`${apiPaths.CLOSING_SOURCES}/${id}`);
