import React, { JSX, useContext, useState } from 'react';
import { LinkButton, Modal, ModalType } from 'common/components';
import { BackgroundRequests } from 'contexts/BackgroundRequestsContext/BackgroundRequests.enum';
import { BackgroundRequestsContext } from 'contexts/BackgroundRequestsContext/BackgroundRequestsContext';
import { useTranslations } from 'contexts/LocalizationContext';
import { useTriggerGenerateProducts } from 'services/products';

import { ChildPageHeading } from '../../../common/components/ChildPageHeading';
import { Routes } from '../../../config/routes';
import { GenerateProductsFormValues } from './GenerateProducts.form';
import GenerateProductsForm from './GenerateProductsForm';

enum VisibleModal {
  SUCCESS = 'success',
  ERROR = 'error',
}

const GenerateProducts = (): JSX.Element => {
  const translations = useTranslations();
  const { mutate: triggerProductsGeneration } = useTriggerGenerateProducts();
  const [visibleModal, setVisibleModal] = useState<VisibleModal | null>(null);
  const { addRequest } = useContext(BackgroundRequestsContext);

  const onSubmit = async (values: GenerateProductsFormValues) => {
    triggerProductsGeneration(
      {
        values: {
          quantity: Number(values.quantity),
          categoryId: values.category!.id,
          hasProductNameInQrCode: values.hasProductNameInQrCode,
          hasMetaCategoryIdInQrCode: values.hasMetaCategoryIdInQrCode,
          hasCategoryGroupInQrCode: values.hasCategoryGroupInQrCode,
        },
      },
      {
        onSuccess: ({ jobId }) => {
          addRequest(BackgroundRequests.PRODUCTS_GENERATION, jobId);
          setVisibleModal(VisibleModal.SUCCESS);
        },
        onError: () => {
          setVisibleModal(VisibleModal.ERROR);
        },
      },
    );
  };

  return (
    <>
      <ChildPageHeading>{translations('products_generate_items_header')}</ChildPageHeading>
      <GenerateProductsForm onFormSubmit={onSubmit} shouldResetForm={!!visibleModal} />
      <Modal
        isModalVisible={!!visibleModal}
        isClosable={false}
        closeOnOverlayClick={false}
        onModalClose={() => setVisibleModal(null)}
        type={visibleModal === VisibleModal.ERROR ? ModalType.INFO : ModalType.CONFIRMATION}
        modalHeader={translations(
          visibleModal === VisibleModal.SUCCESS
            ? 'products_generation_trigger_success_modal_title'
            : 'products_generation_trigger_error_modal_title',
        )}
        onConfirmClick={() => setVisibleModal(null)}
        {...(visibleModal === VisibleModal.SUCCESS && {
          customModalFooter: (
            <>
              <LinkButton to={Routes.PRODUCTS}>
                {translations('products_generation_trigger_success_modal_button')}
              </LinkButton>
            </>
          ),
        })}
      >
        {translations(
          visibleModal === VisibleModal.SUCCESS
            ? 'products_generation_trigger_success_modal_text'
            : 'products_generation_trigger_error_modal_text',
        )}
      </Modal>
    </>
  );
};

export default GenerateProducts;
