import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { FormGroup, Select } from '../../../../../../../common/components';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { returnCodeStatusSelectOptions, StatusSelect } from '../../restaurant-return-code.form';

export const FormReturnCodeStatus = () => {
  const translations = useTranslations();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormGroup
      label={translations('restaurants_return_codes_form_status')}
      inputId="status"
      validationError={(errors.status as FieldError)?.message}
    >
      <Controller
        control={control}
        name="status"
        render={({ field: { value, onChange, name } }) => (
          <Select<StatusSelect>
            name={name}
            getOptionLabel={(option) => (option as StatusSelect).label}
            getOptionValue={(option) => (option as StatusSelect).value}
            value={returnCodeStatusSelectOptions.find((option) => option.value === value)}
            valueMapper={(v: StatusSelect | null) => v?.value}
            onChange={onChange}
            options={returnCodeStatusSelectOptions}
          />
        )}
      />
    </FormGroup>
  );
};
