import React, { JSX } from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import { Center, Text } from '@chakra-ui/react';

import colors from '../../../styles/colors';

type WarningTextProps = {
  text: string;
};
const WarningText = ({ text }: WarningTextProps): JSX.Element => {
  return (
    <Center p={2} bgColor={colors.orange[100]}>
      <WarningIcon />
      <Text textAlign="left" ml={2} fontSize="12px">
        {text}
      </Text>
    </Center>
  );
};

export default WarningText;
