import { BaseQueryParams, Paginated } from 'common/models';
import apiPaths from 'config/apiPaths';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import {
  GenerateProductsRequest,
  GenerateProductsResponse,
  ProductResponse,
  TriggerGenerateProductsResponse,
} from '../modules/Products/Product.model';
import axiosInstance from './axios';

export enum FilterProperties {
  PRODUCT_ID = 'productId',
  UID = 'uId',
  CATEGORY_NAME = 'categoryName',
  CREATED_AT = 'createdAt',
}

export enum DownloadFileType {
  CSV = 'csv-list',
}

export type ProductsFilterParams =
  | { [FilterProperties.PRODUCT_ID]?: string }
  | { [FilterProperties.UID]?: string }
  | { [FilterProperties.CATEGORY_NAME]?: string }
  | { [FilterProperties.CREATED_AT]?: string };

export const getProductByUid = (uId: string): Promise<ProductResponse> =>
  axiosInstance.get<ProductResponse>(`${apiPaths.PRODUCTS}/${uId}`).then(({ data }) => data);

export const getProducts = ({
  offset,
  limit,
  ...filterParams
}: Omit<BaseQueryParams, 'searchText'> & ProductsFilterParams): Promise<Paginated<ProductResponse>> => {
  const getParamsString = () => {
    const searchParams = buildBasicPaginatedSearchParams(offset, limit);

    if (Object.keys(filterParams).length) {
      const {
        0: [key, value],
      } = Object.entries(filterParams);
      searchParams.append(key, value);
      return searchParams;
    }

    return searchParams;
  };
  return axiosInstance
    .get<Paginated<ProductResponse>>(`${apiPaths.PRODUCTS}?${getParamsString()}`)
    .then(({ data }) => data);
};

export const triggerProductsGeneration = (values: GenerateProductsRequest): Promise<TriggerGenerateProductsResponse> =>
  axiosInstance.post(`${apiPaths.PRODUCTS}/batch`, { ...values }).then(({ data }) => data);

export const triggerProductsLinksGeneration = (
  type: DownloadFileType,
  paramName?: FilterProperties,
  paramValue?: string,
): Promise<TriggerGenerateProductsResponse> => {
  const param = paramName && paramValue ? `?${paramName}=${paramValue}` : '';

  return axiosInstance.get(`${apiPaths.PRODUCTS}/${type}${param}`).then(({ data }) => data);
};

export const getQueueResult = (jobId: string): Promise<GenerateProductsResponse> =>
  axiosInstance.get(`${apiPaths.PRODUCTS}/batch/${jobId}`).then(({ data }) => data);
