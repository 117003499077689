import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import { FeatureFlag } from '../modules/FeatureFlags/models/FeatureFlag';
import { FeatureFlagRequest } from '../modules/FeatureFlags/models/FeatureFlagRequest';
import axiosInstance from './axios';

export const getFeatureFlags = ({
  offset,
  limit,
}: Omit<BaseQueryParams, 'searchText'>): Promise<Paginated<FeatureFlag>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit);
  return axiosInstance
    .get<Paginated<FeatureFlag>>(`${apiPaths.FEATURE_FLAGS}?${searchParams}`)
    .then(({ data }) => data);
};

export const updateFeatureFlag = (id: string, updateFeatureFlagRequest: FeatureFlagRequest): Promise<FeatureFlag> => {
  return axiosInstance.patch(`${apiPaths.FEATURE_FLAGS}/${id}`, updateFeatureFlagRequest);
};
