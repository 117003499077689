import React, { JSX } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import { FileInput, FormFooter, FormGroup } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { ImagePreviewValidation, isFileTooLarge } from '../ImagePreviewValidation/ImagePreviewValidation';

type ImageFormProps = {
  onModalClose: () => void;
  handleFormSubmit: (data: any) => void;
  isLoading: boolean;
};

export const ImageForm = ({ onModalClose, handleFormSubmit, isLoading }: ImageFormProps): JSX.Element => {
  const translations = useTranslations();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleForm = handleSubmit(handleFormSubmit);

  const imageNamePreview: string | undefined = watch('file')?.[0]?.name;
  const imageSizePreview: number | undefined = watch('file')?.[0]?.size;

  const handleDeleteUploadedImage = () => setValue('file', undefined);

  const isDisabled = imageSizePreview ? isFileTooLarge(imageSizePreview) : true;

  return (
    <>
      <form onSubmit={handleForm}>
        <FormGroup
          label={translations('images_modal_upload_image')}
          inputId="file"
          validationError={(errors.file as FieldError)?.message}
        >
          <FileInput accept="image/*" register={register('file')} aria-label="file" />
        </FormGroup>
        {imageNamePreview && imageSizePreview ? (
          <ImagePreviewValidation
            imageNamePreview={imageNamePreview}
            imageSizePreview={imageSizePreview}
            handleDeleteUploadedImage={handleDeleteUploadedImage}
          />
        ) : null}
        <FormFooter hasCustomContent>
          <Button mb={2} mr={4} isLoading={isLoading} type="submit" colorScheme="orange" isDisabled={isDisabled}>
            {translations('save')}
          </Button>
          <Button onClick={onModalClose}>{translations('cancel')}</Button>
        </FormFooter>
      </form>
    </>
  );
};
