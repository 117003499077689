import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDomServer from 'react-dom/server';
import { CellProps } from 'react-table';
import { Box, Button, Center, Checkbox, Flex, Text, Tooltip } from '@chakra-ui/react';

import { ErrorMessages, FullPageLoader, Table } from '../../../../../common/components';
import { OrderType } from '../../../../../common/components/Table/TableHeaderWithOrder/order-type.enum';
import TableHeaderWithOrder from '../../../../../common/components/Table/TableHeaderWithOrder/TableHeaderWithOrder';
import { useDebounce } from '../../../../../common/hooks/useDebounce';
import { useSearchCancelQuery } from '../../../../../common/utils';
import { config } from '../../../../../config/config';
import { DEBOUNCE_SEARCH_TIME } from '../../../../../config/configConsts';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useHtmlTranslations } from '../../../../../contexts/LocalizationContext/hooks';
import { useLocalizationContext } from '../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import {
  useDeleteKeyAccountGroupRestaurant,
  useDeleteKeyAccountGroupWithConfirmation,
} from '../../../../../services/key-account-groups';
import { CK_RESTAURANTS, useKeyAccountGroupRestaurantList } from '../../../../../services/restaurants';
import { RestaurantResponse } from '../../../../Restaurants/models';
import { INDIVIDUAL_KEY_ACCOUNT_ID } from '../../../../Restaurants/restaurant.consts';
import { KeyAccountGroupRestaurantsOrderField } from '../../../models/enums/key-account-group-restaurants-order-field.enum';
import { useKeyAccountGroupRestaurantsContext } from '../../context/KeyAccountGroupRestaurantsContext/hooks/use-key-account-group-restaurants-context.hook';

type RestaurantsTableProps = {
  keyAccountId: string;
};

export const KeyAccountGroupRestaurantsTable = ({ keyAccountId }: RestaurantsTableProps) => {
  const translations = useTranslations();
  const htmlTranslations = useHtmlTranslations();
  const { language } = useLocalizationContext();
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const { isActiveQuery } = useSearchCancelQuery(searchText, CK_RESTAURANTS);
  const [tableFilter, setTableFilter] = useState({
    orderField: KeyAccountGroupRestaurantsOrderField.ID,
    orderType: OrderType.ASC,
  });
  const { mutate: deleteKeyAccountGroupRestaurant, error: deleteKeyAccountGroupRestaurantError } =
    useDeleteKeyAccountGroupRestaurant();

  const { deleteItem: onDeleteKeyAccountGroupRestaurant } = useDeleteKeyAccountGroupWithConfirmation(
    translations('key_account_groups_restaurant_delete_confirmation'),
    deleteKeyAccountGroupRestaurant,
  );

  const {
    data: paginatedKeyAccountGroupsRestaurants,
    isFetching: isFetchingRestaurants,
    isLoading: isLoadingRestaurants,
    error: loadRestaurantsError,
  } = useKeyAccountGroupRestaurantList({
    isActive: isActiveQuery,
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
    keyAccountId,
    ...tableFilter,
  });

  const {
    selectAllRestaurants,
    setSelectAllRestaurants,
    selectedRestaurantsIds,
    handleClearSelection,
    getIsChecked,
    getIsSelectPageChecked,
    handleSelectCheckbox,
    handleSelectPageCheckbox,
    shouldShowSelectAllRestaurants,
    setPaginatedItems,
  } = useKeyAccountGroupRestaurantsContext();

  useEffect(() => {
    if (paginatedKeyAccountGroupsRestaurants?.items && selectAllRestaurants) {
      setSelectAllRestaurants(false);
    }
    setPaginatedItems(paginatedKeyAccountGroupsRestaurants);
    // FYI: selectAllRestaurants cannot be in included in deps as it breaks `select all` implementation
    // eslint-disable-next-line
  }, [paginatedKeyAccountGroupsRestaurants]);

  const handleOrderRestaurantsBy = useCallback(
    (field: KeyAccountGroupRestaurantsOrderField) => () => {
      if (field === tableFilter.orderField) {
        setTableFilter({
          ...tableFilter,
          orderType: tableFilter.orderType === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
        });
      } else {
        setTableFilter({ orderField: field, orderType: OrderType.ASC });
      }
    },
    [tableFilter],
  );

  const handleDeleteRestaurant = useCallback(
    (original: RestaurantResponse) => () =>
      onDeleteKeyAccountGroupRestaurant({ restaurantId: original.id, keyAccountId }),
    [keyAccountId, onDeleteKeyAccountGroupRestaurant],
  );

  const columns = useMemo(
    () => [
      {
        id: '1',
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantResponse>) => (
          <Checkbox
            isDisabled={keyAccountId === INDIVIDUAL_KEY_ACCOUNT_ID}
            name={original.id}
            key={original.id}
            size="sm"
            colorScheme="orange"
            isChecked={getIsChecked(original)}
            onChange={handleSelectCheckbox(original.id)}
          />
        ),
        Header: (
          <Tooltip
            label={
              !getIsSelectPageChecked(paginatedKeyAccountGroupsRestaurants?.items)
                ? translations('table_select_page')
                : translations('table_unselect_all')
            }
          >
            <Box>
              <Checkbox
                isDisabled={
                  !paginatedKeyAccountGroupsRestaurants?.items.length || keyAccountId === INDIVIDUAL_KEY_ACCOUNT_ID
                }
                name="page"
                key="page"
                size="sm"
                colorScheme="orange"
                isChecked={getIsSelectPageChecked(paginatedKeyAccountGroupsRestaurants?.items)}
                onChange={handleSelectPageCheckbox}
              />
            </Box>
          </Tooltip>
        ),
      },
      {
        Header: (
          <TableHeaderWithOrder
            headerText={translations('restaurants_table_id')}
            showOrder={tableFilter.orderField === KeyAccountGroupRestaurantsOrderField.ID}
            orderType={tableFilter.orderType}
            onClick={handleOrderRestaurantsBy(KeyAccountGroupRestaurantsOrderField.ID)}
          />
        ),
        accessor: 'id' as const,
      },
      {
        Header: (
          <TableHeaderWithOrder
            headerText={translations('restaurants_table_name')}
            showOrder={tableFilter.orderField === KeyAccountGroupRestaurantsOrderField.RESTAURANT_NAME}
            orderType={tableFilter.orderType}
            onClick={handleOrderRestaurantsBy(KeyAccountGroupRestaurantsOrderField.RESTAURANT_NAME)}
          />
        ),
        accessor: 'restaurantName' as const,
      },
      {
        Header: (
          <TableHeaderWithOrder
            headerText={translations('restaurants_table_city')}
            showOrder={tableFilter.orderField === KeyAccountGroupRestaurantsOrderField.CITY_NAME}
            orderType={tableFilter.orderType}
            onClick={handleOrderRestaurantsBy(KeyAccountGroupRestaurantsOrderField.CITY_NAME)}
          />
        ),
        accessor: 'city' as const,
        Cell: ({ cell: { value } }: CellProps<RestaurantResponse>) => value?.translations[language] || null,
      },
      {
        Header: translations('restaurants_table_address'),
        accessor: 'restaurantAddress' as const,
      },
      {
        id: 'actions',
        Header: translations('restaurants_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantResponse>) => (
          <Flex>
            {keyAccountId !== INDIVIDUAL_KEY_ACCOUNT_ID && (
              <Button m={1} onClick={handleDeleteRestaurant(original)}>
                {translations('delete')}
              </Button>
            )}
          </Flex>
        ),
      },
    ],
    [
      getIsSelectPageChecked,
      paginatedKeyAccountGroupsRestaurants?.items,
      translations,
      keyAccountId,
      handleSelectPageCheckbox,
      tableFilter,
      handleOrderRestaurantsBy,
      getIsChecked,
      handleSelectCheckbox,
      language,
      handleDeleteRestaurant,
    ],
  );

  const handleSelectAllRestaurants = () => setSelectAllRestaurants(true);

  const showSpinner = isFetchingRestaurants || isLoadingRestaurants;

  return (
    <FullPageLoader show={showSpinner}>
      <ErrorMessages errors={[loadRestaurantsError, deleteKeyAccountGroupRestaurantError]} />
      <Table<RestaurantResponse>
        isLoading={false}
        data={paginatedKeyAccountGroupsRestaurants?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedKeyAccountGroupsRestaurants?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('restaurants_searchbox')}
        searchEnabled
        paginationEnabled
      >
        {shouldShowSelectAllRestaurants ? (
          <Center>
            <Text>
              {htmlTranslations('table_selected_restaurants', {
                '{{count}}': ReactDomServer.renderToString(
                  <Text as="b">{paginatedKeyAccountGroupsRestaurants?.items.length}</Text>,
                ),
              })}
            </Text>
            <Button ml={2} onClick={handleSelectAllRestaurants}>
              {translations('table_select_all_restaurants')}
            </Button>
          </Center>
        ) : null}
        {selectAllRestaurants &&
        paginatedKeyAccountGroupsRestaurants?.items
          .map((item) => item.id)
          .every((id) => selectedRestaurantsIds.includes(id)) ? (
          <Center>
            <Text>
              {htmlTranslations('table_all_restaurants_selected', {
                '{{count}}': ReactDomServer.renderToString(
                  <Text as="b">{paginatedKeyAccountGroupsRestaurants?.count}</Text>,
                ),
              })}
            </Text>
            <Button ml={2} onClick={handleClearSelection}>
              {translations('table_clear_selection')}
            </Button>
          </Center>
        ) : null}
      </Table>
    </FullPageLoader>
  );
};
