import React, { ComponentProps, JSX } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';

type NumberInputProps = Omit<ComponentProps<typeof ChakraNumberInput>, 'name' | 'id'> & {
  name: string;
  register: UseFormRegisterReturn;
  isStepperVisible?: boolean;
};

export const NumberInput = ({ name, register, isStepperVisible = true, ...props }: NumberInputProps): JSX.Element => (
  <ChakraNumberInput id={name} {...props}>
    <NumberInputField {...register} />
    {isStepperVisible && (
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    )}
  </ChakraNumberInput>
);
