import React, { JSX, useEffect, useMemo } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { Checkbox, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter, FormGroup, NumberInput, Select } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCategoriesList } from 'services/categories';

import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { Category } from '../../Categories/model/Category.model';
import { GenerateProductsFormValues, useGenerateProductsFormValidationSchema } from './GenerateProducts.form';

type GenerateProductsFormProps = {
  onFormSubmit: (values: GenerateProductsFormValues) => void;
  shouldResetForm: boolean;
};

const GenerateProductsForm = ({ onFormSubmit, shouldResetForm }: GenerateProductsFormProps): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const validationSchema = useGenerateProductsFormValidationSchema();
  const { isLoading: isLoadingCategories, data: paginatedCategories } = useCategoriesList();

  const formDefaultValues = useMemo(() => {
    return {
      quantity: '',
      category: null,
      hasProductNameInQrCode: true,
      hasCategoryGroupInQrCode: false,
      hasMetaCategoryIdInQrCode: false,
    };
  }, []);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    if (shouldResetForm) {
      reset(formDefaultValues);
    }
  }, [shouldResetForm, formDefaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} autoComplete="off">
      <FormGroup
        label={translations('products_generate_items_form_quantity_label')}
        inputId="quantity"
        validationError={(errors.quantity as FieldError)?.message}
      >
        <NumberInput name="quantity" value={watch('quantity')} register={register('quantity')} min={0} max={20000} />
      </FormGroup>
      <FormGroup
        label={translations('products_generate_items_form_category_label')}
        inputId="category"
        validationError={(errors.category as FieldError)?.message}
      >
        <Controller
          control={control}
          name="category"
          render={({ field: { value, onChange, name } }) => (
            <Select<Category>
              inputId="category"
              name={name}
              isLoading={isLoadingCategories}
              getOptionLabel={(option) => (option as Category).translations[language]}
              getOptionValue={(option) => (option as Category).id}
              value={value}
              onChange={onChange}
              options={paginatedCategories?.items}
            />
          )}
        />
      </FormGroup>
      <Text textAlign="left">{translations('products_generate_items_form_additional_params_label')}</Text>
      <FormGroup
        reverseLayout={true}
        isVertical={false}
        label={translations('products_generate_items_form_product_name_label')}
        inputId="hasProductNameInQrCode"
      >
        <Controller
          control={control}
          name="hasProductNameInQrCode"
          render={({ field: { value, onChange, name } }) => (
            <Checkbox
              mr={2}
              mt={1}
              id="hasProductNameInQrCode"
              isChecked={value}
              colorScheme="orange"
              defaultChecked
              name={name}
              onChange={onChange}
            />
          )}
        />
      </FormGroup>
      <FormGroup
        reverseLayout={true}
        isVertical={false}
        label={translations('products_generate_items_form_category_group_label')}
        inputId="hasCategoryGroupInQrCode"
      >
        <Controller
          control={control}
          name="hasCategoryGroupInQrCode"
          render={({ field: { value, onChange, name } }) => (
            <Checkbox
              mr={2}
              mt={1}
              id="hasCategoryGroupInQrCode"
              isChecked={value}
              colorScheme="orange"
              name={name}
              onChange={onChange}
            />
          )}
        />
      </FormGroup>
      <FormGroup
        reverseLayout={true}
        isVertical={false}
        label={translations('products_generate_items_form_meta_category_id_label')}
        inputId="hasMetaCategoryIdInQrCode"
      >
        <Controller
          control={control}
          name="hasMetaCategoryIdInQrCode"
          render={({ field: { value, onChange, name } }) => (
            <Checkbox
              mr={2}
              mt={1}
              id="hasMetaCategoryIdInQrCode"
              isChecked={value}
              colorScheme="orange"
              name={name}
              onChange={onChange}
            />
          )}
        />
      </FormGroup>
      <FormFooter />
    </form>
  );
};

export default GenerateProductsForm;
