import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { getRestaurantDepositSettings, updateRestaurantDepositSettings } from '../api/deposit';
import { useToast } from '../common/components';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { RestaurantDepositSettingsResponse } from '../modules/Restaurants/Deposit/models/restaurant-deposit-settings-response';
import { UpdateRestaurantDepositSettingsRequest } from '../modules/Restaurants/Deposit/models/update-restaurant-deposit-settings-request';

const RESTAURANT_DEPOSIT_QUERY_KEY = 'restaurantDeposit';

const getRestaurantDepositSettingsQueryKey = (restaurantId: string) => [RESTAURANT_DEPOSIT_QUERY_KEY, restaurantId];

export const useRestaurantDepositSettings = (
  restaurantId: string,
): QueryObserverResult<RestaurantDepositSettingsResponse, AxiosError<HttpException>> => {
  return useQuery(getRestaurantDepositSettingsQueryKey(restaurantId), () => getRestaurantDepositSettings(restaurantId));
};

export const useUpdateRestaurantDepositSettings = (): UseMutationResult<
  RestaurantDepositSettingsResponse,
  AxiosError<HttpException>,
  { id: string; updateRestaurantDepositSettingsRequest: UpdateRestaurantDepositSettingsRequest }
> => {
  const queryClient = useQueryClient();
  const translations = useTranslations();
  const { displayToast } = useToast();

  return useMutation(
    ({
      id,
      updateRestaurantDepositSettingsRequest,
    }: {
      id: string;
      updateRestaurantDepositSettingsRequest: UpdateRestaurantDepositSettingsRequest;
    }) => updateRestaurantDepositSettings(id, updateRestaurantDepositSettingsRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(RESTAURANT_DEPOSIT_QUERY_KEY);
      },
      onError: () => displayToast('error', translations('something_went_wrong')),
    },
  );
};
