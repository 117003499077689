import { generatePath } from 'react-router-dom';

import { BaseQueryParams, Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { CreateRestaurantsReturnCodesRequest } from '../modules/KeyAccountGroups/KeyAccountGroupRestaurants/components/RestaurantsReturnCodes/models/create-restaurants-return-codes-request';
import { RestaurantReturnCodeCreateRequest } from '../modules/Restaurants/RestaurantReturnCodes/models/restaurant-return-code-create-request';
import { RestaurantReturnCodeResponse } from '../modules/Restaurants/RestaurantReturnCodes/models/restaurant-return-code-response';
import { RestaurantReturnCodeUpdateRequest } from '../modules/Restaurants/RestaurantReturnCodes/models/restaurant-return-code-update-request';
import { RestaurantsCreateReturnCodesResponse } from '../modules/Restaurants/RestaurantReturnCodes/models/restaurants-create-return-codes-response';
import axiosInstance from './axios';

export const getRestaurantReturnCodes = (
  restaurantId: string,
  { searchText, offset, limit }: BaseQueryParams,
): Promise<Paginated<RestaurantReturnCodeResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance
    .get<Paginated<RestaurantReturnCodeResponse>>(generatePath(apiPaths.RESTAURANTS_RETURN_CODES, { restaurantId }), {
      params: searchParams,
    })
    .then(({ data }) => data);
};

export const createRestaurantReturnCode = (
  restaurantId: string,
  createRestaurantReturnCodeRequest: RestaurantReturnCodeCreateRequest,
): Promise<RestaurantReturnCodeResponse> => {
  return axiosInstance.post(
    generatePath(apiPaths.RESTAURANTS_RETURN_CODES, { restaurantId }),
    createRestaurantReturnCodeRequest,
  );
};

export const updateRestaurantReturnCode = (
  returnCodeId: string,
  restaurantId: string,
  updateRestaurantReturnCodeRequest: Partial<RestaurantReturnCodeUpdateRequest>,
): Promise<RestaurantReturnCodeResponse> => {
  return axiosInstance.patch(
    generatePath(apiPaths.RESTAURANTS_RETURN_CODES_RETURN_CODE, { restaurantId, returnCodeId }),
    updateRestaurantReturnCodeRequest,
  );
};

export const getRestaurantReturnCode = (
  returnCodeId: string,
  restaurantId: string,
): Promise<RestaurantReturnCodeResponse> =>
  axiosInstance
    .get<RestaurantReturnCodeResponse>(
      generatePath(apiPaths.RESTAURANTS_RETURN_CODES_RETURN_CODE, { restaurantId, returnCodeId }),
    )
    .then(({ data }) => data);

export const createReturnCodes = (
  request: CreateRestaurantsReturnCodesRequest,
): Promise<RestaurantsCreateReturnCodesResponse> => {
  return axiosInstance.post(apiPaths.RESTAURANTS_RETURN_CODES_BATCH, request).then(({ data }) => data);
};
