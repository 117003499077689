import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import components from './components';

const breakpoints = {
  base: '0em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
};

const theme = extendTheme({
  breakpoints,
  components,
  colors: {
    ...colors,
  },
  fonts: {
    body: `'Rubik', sans-serif`,
  },
  styles: {
    global: {
      body: {
        fontFamily: 'Rubik',
      },
    },
  },
});

export default theme;
