import { Text } from '@chakra-ui/react';

import colors from '../../../../../styles/colors';
import { Diff } from './Diff';
import { DiffProps } from './types';

export const StringDiff = ({ before = '', after = '', showDiff, isChanged }: DiffProps<string>) => {
  const hasChanged = before !== after;

  return (
    <Diff
      isChanged={isChanged}
      showDiff={showDiff}
      before={
        hasChanged ? (
          <Text textDecorationLine={isChanged ? 'line-through' : 'none'} background={colors.red[100]}>
            {before}
          </Text>
        ) : (
          <Text>{before}</Text>
        )
      }
      after={<Text background={showDiff ? colors.green[100] : 'none'}>{after}</Text>}
    />
  );
};
