import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import {
  createReuseCardUsers,
  getReuseCardUser,
  getReuseCardUserAssignedItems,
  getReuseCardUsers,
  updateReuseCardUser,
} from '../api/reuse-cards';
import { useToast } from '../common/components';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { ItemStatus } from '../modules/AssignedItems/models/enums/item-status';
import { ReuseCardUserAssignedItemResponse } from '../modules/ReuseCards/models/reuse-card-user-assigned-item-response';
import { ReuseCardUserResponse } from '../modules/ReuseCards/models/reuse-card-user-response';
import { ReuseCardUserUpdateRequest } from '../modules/ReuseCards/models/reuse-card-user-update-request';
import { ReuseCardUsersCreateRequest } from '../modules/ReuseCards/models/reuse-card-users-create-request';

const REUSE_CARD_USERS_QUERY_KEY = 'reuseCardUsers';

const getReuseCardUsersQueryKey = (offset: number, limit: number, searchText?: string) => [
  REUSE_CARD_USERS_QUERY_KEY,
  { offset, limit, searchText },
];

const getReuseCardUserAssignedItemsQueryKey = (userId: string, offset: number, limit: number, status?: ItemStatus) => [
  REUSE_CARD_USERS_QUERY_KEY,
  { userId, offset, limit, status },
];

export const useCreateReuseCardUsers = (): UseMutationResult<
  string,
  AxiosError<HttpException>,
  { request: ReuseCardUsersCreateRequest }
> => {
  return useMutation(({ request }: { request: ReuseCardUsersCreateRequest }) => createReuseCardUsers(request));
};

export const useReuseCardUsers = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<ReuseCardUserResponse>, AxiosError<HttpException>> => {
  const { offset, limit, searchText } = params;
  return useQuery(getReuseCardUsersQueryKey(offset, limit, searchText), () =>
    getReuseCardUsers({ offset, limit, searchText }),
  );
};

export const useReuseCardUser = (id: string): QueryObserverResult<ReuseCardUserResponse, AxiosError<HttpException>> => {
  return useQuery([REUSE_CARD_USERS_QUERY_KEY, id], () => getReuseCardUser(id));
};

export const useUpdateReuseCardUser = (): UseMutationResult<
  ReuseCardUserResponse,
  AxiosError<HttpException>,
  { id: string; request: ReuseCardUserUpdateRequest }
> => {
  return useMutation(({ id, request }: { id: string; request: ReuseCardUserUpdateRequest }) =>
    updateReuseCardUser(id, request),
  );
};

export const useReuseCardUserAssignedItems = (
  userId: string,
  params: Omit<BaseQueryParams, 'searchText'> & { status?: ItemStatus } = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<ReuseCardUserAssignedItemResponse>, AxiosError<HttpException>> => {
  const { offset, limit, status } = params;
  return useQuery(getReuseCardUserAssignedItemsQueryKey(userId, offset, limit, status), () =>
    getReuseCardUserAssignedItems(userId, { offset, limit, status }),
  );
};

export const useVoidReuseCard = (): UseMutationResult<
  ReuseCardUserResponse,
  AxiosError<HttpException>,
  { id: string }
> => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation(({ id }: { id: string }) => updateReuseCardUser(id, { isActive: false }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(REUSE_CARD_USERS_QUERY_KEY);
      displayToast('success', translations('reuse_cards_void_success'));
    },
    onError: (error) => displayToast('error', error.response?.data.message || translations('something_went_wrong')),
  });
};
