import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { AddEditItemLink, MainPageContent, PageHeading } from '../../common/components';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { ReuseCardsTable } from './components/ReuseCardsTable/ReuseCardsTable';

export const ReuseCards = () => {
  const translations = useTranslations();

  return (
    <MainPageContent>
      <PageHeading>{translations('reuse_cards_header')}</PageHeading>
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.REUSE_CARDS_CREATE_PATH}>{translations('reuse_cards_create')}</AddEditItemLink>
      </Flex>
      <Box w="100%" mt={2}>
        <ReuseCardsTable />
      </Box>
    </MainPageContent>
  );
};
