import React, { JSX, useCallback, useContext, useEffect, useReducer } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { DownloadFileType, FilterProperties } from 'api/products';
import { AddEditItemLink, LinkButton } from 'common/components';
import { Routes } from 'config/routes';
import { BackgroundRequests } from 'contexts/BackgroundRequestsContext/BackgroundRequests.enum';
import { BackgroundRequestsContext } from 'contexts/BackgroundRequestsContext/BackgroundRequestsContext';
import { useTranslations } from 'contexts/LocalizationContext';
import { useTriggerProductsLinksGeneration } from 'services/products';

import { GenerateProductsResponse } from '../Product.model';
import { Action, ActionTypes, ProductsManagementState } from './ProductsManagementPanel.model';
import { isEmptyData } from './ProductsManagementPanel.utils';
import { ProductsManagementPanelSection } from './ProductsManagementPanelSection';
import { ProductsManagementPanelStatus } from './ProductsManagementPanelStatus';

const initialState: ProductsManagementState = {
  isLoadingNewProducts: false,
  isLoadingProductsCSV: false,
  hasGenerationError: false,
  hasCSVCreationError: false,
  newProductsDownloadLinks: null,
  productsCSVDownloadLink: null,
};

const reducer = (state: ProductsManagementState, action: Action): ProductsManagementState => {
  switch (action.type) {
    case ActionTypes.TRIGGER_NEW_PRODUCTS_GENERATION:
      return {
        ...state,
        hasGenerationError: false,
        isLoadingNewProducts: true,
      };
    case ActionTypes.TRIGGER_FILTERED_PRODUCTS_CSV_GENERATION:
      return {
        ...state,
        hasGenerationError: false,
        isLoadingProductsCSV: true,
      };
    case ActionTypes.NEW_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingNewProducts: false,
        newProductsDownloadLinks: action.payload,
      };
    case ActionTypes.FILTERED_PRODUCTS_CSV_SUCCESS:
      return {
        ...state,
        isLoadingProductsCSV: false,
        productsCSVDownloadLink: action.payload,
      };
    case ActionTypes.GENERATION_ERROR:
      return {
        ...state,
        isLoadingNewProducts: false,
        hasGenerationError: true,
      };
    case ActionTypes.CSV_CREATION_ERROR:
      return {
        ...state,
        isLoadingProductsCSV: false,
        hasCSVCreationError: true,
      };
    case ActionTypes.CLEAR_FILTER_CSV:
      return {
        ...state,
        productsCSVDownloadLink: null,
      };
    default:
      throw new TypeError('Not supported action type');
  }
};

type ProductsManagementPanelProps = {
  tableFilters: [FilterProperties, string?] | [];
};

export const ProductsManagementPanel = ({
  tableFilters: [filterProperty, filterValue],
}: ProductsManagementPanelProps): JSX.Element => {
  const translations = useTranslations();
  const { requestResults, removeRequest, addRequest } = useContext(BackgroundRequestsContext);
  const [productsManagementState, dispatch] = useReducer(reducer, initialState);
  const {
    isLoadingNewProducts,
    isLoadingProductsCSV,
    hasGenerationError,
    hasCSVCreationError,
    newProductsDownloadLinks,
    productsCSVDownloadLink,
  } = productsManagementState;

  const { data: newProductsData, isError: hasProductsGenerationError } =
    requestResults[BackgroundRequests.PRODUCTS_GENERATION];
  const { data: csvListData, isError: hasCsvListError } = requestResults[BackgroundRequests.PRODUCTS_CSV_LIST];

  const { mutate: triggerCSVGeneration } = useTriggerProductsLinksGeneration(DownloadFileType.CSV);
  // TODO: Prompt is no longer available, will be restored in v6 in the future, add implementation
  // const hasAnyData = !isEmptyData(newProductsDownloadLinks) || !isEmptyData(productsCSVDownloadLink);

  const handleDataReceive = useCallback(
    (
      data: GenerateProductsResponse | undefined,
      hasError: boolean,
      triggerAction: ActionTypes,
      successAction: ActionTypes,
      request: BackgroundRequests,
    ) => {
      if (hasError) {
        dispatch({
          type:
            triggerAction === ActionTypes.TRIGGER_NEW_PRODUCTS_GENERATION
              ? ActionTypes.GENERATION_ERROR
              : ActionTypes.CSV_CREATION_ERROR,
        });
        removeRequest(request);
        return;
      }

      if (data) {
        if (isEmptyData(data)) {
          dispatch({ type: triggerAction } as Action);
        } else {
          dispatch({ type: successAction, payload: data });
          removeRequest(request);
        }
      }
    },
    [removeRequest],
  );

  useEffect(() => {
    dispatch({ type: ActionTypes.CLEAR_FILTER_CSV });
  }, [filterProperty, filterValue]);

  useEffect(() => {
    handleDataReceive(
      newProductsData,
      hasProductsGenerationError,
      ActionTypes.TRIGGER_NEW_PRODUCTS_GENERATION,
      ActionTypes.NEW_PRODUCTS_SUCCESS,
      BackgroundRequests.PRODUCTS_GENERATION,
    );
  }, [newProductsData, handleDataReceive, hasProductsGenerationError]);

  useEffect(() => {
    handleDataReceive(
      csvListData,
      hasCsvListError,
      ActionTypes.TRIGGER_FILTERED_PRODUCTS_CSV_GENERATION,
      ActionTypes.FILTERED_PRODUCTS_CSV_SUCCESS,
      BackgroundRequests.PRODUCTS_CSV_LIST,
    );
  }, [csvListData, handleDataReceive, hasCsvListError]);

  return (
    <Box mb={8}>
      <Flex w="100%">
        <Box flex={1}>
          <ProductsManagementPanelSection>
            <Flex flex={1} justifyContent="space-between" alignItems="center">
              <Text>{translations('products_generation_generate_list_of_filtered_products')}</Text>
              {productsCSVDownloadLink ? (
                <LinkButton isDownload size="sm" to={productsCSVDownloadLink.csvFilePresignedUrl}>
                  {translations('download')}
                </LinkButton>
              ) : (
                <Button
                  size="sm"
                  isDisabled={isLoadingProductsCSV || !filterValue}
                  onClick={() => {
                    dispatch({ type: ActionTypes.TRIGGER_FILTERED_PRODUCTS_CSV_GENERATION });
                    triggerCSVGeneration(
                      { filterProperty, filterValue },
                      {
                        onSuccess: ({ jobId }) => addRequest(BackgroundRequests.PRODUCTS_CSV_LIST, jobId),
                        onError: () => dispatch({ type: ActionTypes.CSV_CREATION_ERROR }),
                      },
                    );
                  }}
                >
                  {translations('generate')}
                </Button>
              )}
            </Flex>
            <ProductsManagementPanelStatus
              hasError={hasCSVCreationError}
              hasData={!!productsCSVDownloadLink}
              isLoading={isLoadingProductsCSV}
              mt={2}
            />
          </ProductsManagementPanelSection>
          {(isLoadingNewProducts || hasProductsGenerationError || newProductsDownloadLinks) && (
            <ProductsManagementPanelSection>
              <Flex flex={1} justifyContent="space-between" alignItems="center">
                <Text>{translations('products_generation_download_new_products_csv_list')}</Text>
                <LinkButton
                  isDownload
                  size="sm"
                  to={newProductsDownloadLinks?.csvFilePresignedUrl || ''}
                  isDisabled={!newProductsDownloadLinks?.csvFilePresignedUrl}
                >
                  {translations('download')}
                </LinkButton>
              </Flex>
              <ProductsManagementPanelStatus
                hasError={hasGenerationError}
                hasData={!!newProductsDownloadLinks}
                isLoading={isLoadingNewProducts}
                mt={2}
              />
            </ProductsManagementPanelSection>
          )}
        </Box>
        <AddEditItemLink to={Routes.GENERATE_PRODUCTS} isDisabled={isLoadingNewProducts}>
          {translations('products_generate_products_button')}
        </AddEditItemLink>
      </Flex>
      {/* // TODO: Prompt is no longer available, will be restored in v6 in the future, add implementation*/}
      {/* <Prompt when={hasAnyData} message={translations('products_generation_leave_page_prompt')} />*/}
    </Box>
  );
};
