import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { ErrorMessages } from '../../common/components';
import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCreateFaqQuestion } from '../../services/frequently-asked-questions';
import QuestionForm from './QuestionForm';

const QuestionCreate = (): JSX.Element => {
  const translations = useTranslations();
  const { sectionId } = useParams<{ sectionId: string }>();
  invariant(sectionId, 'sectionId is not set within the route');
  const navigate = useNavigate();
  const {
    mutate: createFaqQuestion,
    isLoading: isLoadingFaqQuestion,
    error: createFaqQuestionError,
  } = useCreateFaqQuestion(sectionId);

  return (
    <>
      <ChildPageHeading>{translations('faq_question_create_question')}</ChildPageHeading>
      <ErrorMessages errors={createFaqQuestionError} />
      <QuestionForm
        isLoading={isLoadingFaqQuestion}
        onSubmit={(values) =>
          createFaqQuestion(
            { values },
            {
              onSuccess: async () => navigate(Routes.FREQUENTLY_ASKED_QUESTIONS_PATH),
            },
          )
        }
      />
    </>
  );
};

export default QuestionCreate;
