import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';

import { getFeatureFlags, updateFeatureFlag } from '../api/feature-flags';
import { HttpException } from '../common/models/httpException';
import { FeatureFlag } from '../modules/FeatureFlags/models/FeatureFlag';
import { FeatureFlagRequest } from '../modules/FeatureFlags/models/FeatureFlagRequest';

export const CK_FEATURE_FLAGS = 'feature-flags';

export const useFeatureFlagsList = (
  params: Omit<BaseQueryParams, 'searchText'> = { offset: 1, limit: 0 },
): QueryObserverResult<Paginated<FeatureFlag>, AxiosError<HttpException>> =>
  useQuery([CK_FEATURE_FLAGS, { page: params.offset, limit: params.limit }], () => getFeatureFlags(params), {
    keepPreviousData: true,
  });

export const useUpdateFeatureFlag = (): UseMutationResult<
  FeatureFlag,
  AxiosError<HttpException>,
  { id: string; updateFeatureFlagRequest: FeatureFlagRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, updateFeatureFlagRequest }: { id: string; updateFeatureFlagRequest: FeatureFlagRequest }) =>
      updateFeatureFlag(id, updateFeatureFlagRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_FEATURE_FLAGS);
      },
    },
  );
};
