import { generatePath } from 'react-router-dom';

import apiPaths from '../config/apiPaths';
import { RestaurantDepositSettingsResponse } from '../modules/Restaurants/Deposit/models/restaurant-deposit-settings-response';
import { UpdateRestaurantDepositSettingsRequest } from '../modules/Restaurants/Deposit/models/update-restaurant-deposit-settings-request';
import axiosInstance from './axios';

export const getRestaurantDepositSettings = (restaurantId: string): Promise<RestaurantDepositSettingsResponse> => {
  return axiosInstance.get(generatePath(apiPaths.RESTAURANTS_DEPOSIT, { restaurantId })).then(({ data }) => data);
};

export const updateRestaurantDepositSettings = (
  restaurantId: string,
  updateRequest: UpdateRestaurantDepositSettingsRequest,
): Promise<RestaurantDepositSettingsResponse> => {
  return axiosInstance.patch(generatePath(apiPaths.RESTAURANTS_DEPOSIT, { restaurantId }), updateRequest);
};
