import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { AboutUsRequest } from 'modules/AboutUs/models/about-us-request';
import { AboutUsResponse } from 'modules/AboutUs/models/about-us-response';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export const getAboutUsList = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<AboutUsResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get<Paginated<AboutUsResponse>>(`${apiPaths.ABOUT_US}?${searchParams}`).then(({ data }) => data);
};

export const getAboutUs = (id: string): Promise<AboutUsResponse> => {
  return axiosInstance.get<AboutUsResponse>(`${apiPaths.ABOUT_US}/${id}`).then(({ data }) => data);
};

export const createAboutUs = (request: AboutUsRequest): Promise<AboutUsResponse> => {
  const data = createFormData(request);
  return axiosInstance.post(`${apiPaths.ABOUT_US}`, data);
};

export const updateAboutUs = (id: string, request: AboutUsRequest): Promise<AboutUsResponse> => {
  const data = createFormData(request);
  return axiosInstance.put(`${apiPaths.ABOUT_US}/${id}`, data);
};

export const deleteAboutUs = (id: string): Promise<void> => {
  return axiosInstance.delete(`${apiPaths.ABOUT_US}/${id}`);
};
