import { BaseQueryParams, Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { Country } from '../modules/Country/model/Country';
import axiosInstance from './axios';

export const getCountries = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<Country>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get<Paginated<Country>>(`${apiPaths.COUNTRIES}?${searchParams}`).then(({ data }) => data);
};
