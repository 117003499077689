import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorMessages } from '../../common/components';
import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCreateCategory } from '../../services/categories';
import { getParsedData } from './utils/category.helper';
import CategoriesForm, { CategoryFormModel } from './CategoriesForm';

const CreateCategory = (): JSX.Element => {
  const { mutate: createCategory, isLoading, error: createCategoryError } = useCreateCategory();
  const navigate = useNavigate();
  const translations = useTranslations();

  return (
    <>
      <ChildPageHeading>{translations('categories_create_header')}</ChildPageHeading>
      <ErrorMessages errors={createCategoryError} />
      <CategoriesForm
        isLoading={isLoading}
        onSubmit={(values: CategoryFormModel) => {
          createCategory(
            { values: getParsedData(values) },
            {
              onSuccess: async () => navigate(Routes.CATEGORIES_PATH),
            },
          );
        }}
      />
    </>
  );
};

export default CreateCategory;
