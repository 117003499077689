import apiPaths from 'config/apiPaths';

import axiosInstance from './axios';

export const makeAllPayments = (): Promise<void> => {
  return axiosInstance.post(`${apiPaths.PAYMENTS}/charge-users`);
};
export const updateAllUsersBTPaymentsInfo = (): Promise<void> => {
  return axiosInstance.post(`${apiPaths.PAYMENTS}/update-bt-payments-info`);
};
// export const updateUserBTPaymentsInfo = (userId: string): Promise<void> => {
//   return axiosInstance.post(`${apiPaths.PAYMENTS}/update-bt-payments-info/${userId}`);
// };
