import { RestaurantOpeningHoursResponse } from '../models';

export const handleGoogleOpeningHours = (value: RestaurantOpeningHoursResponse[]): RestaurantOpeningHoursResponse[] => {
  const tempOpeningHours: RestaurantOpeningHoursResponse[] = [];
  value.map((day: RestaurantOpeningHoursResponse) => {
    const dayObject: RestaurantOpeningHoursResponse = {
      day: day.day,
      periods: [],
    };
    day.periods?.map((period) => {
      const periodObject = {
        day: day.day,
        openTime: period.openTime,
        closeTime: period.closeTime,
      };
      if (dayObject.periods) {
        dayObject.periods.push(periodObject);
      }
    });
    tempOpeningHours.push(dayObject);
  });
  return tempOpeningHours;
};
