import React, { JSX, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../common/components/FormInput/FormInput';
import { TextEditor } from '../../common/components/TextEditor/TextEditor';
import { Language } from '../../common/utils';
import { FaqQuestion, FaqQuestionFormValues, FaqQuestionRequest } from './models/frequently-asked-question.model';
import { useQuestionFormValidationSchema } from './QuestionForm.form';

type QuestionFormProps = {
  isLoading: boolean;
  onSubmit(data: FaqQuestionRequest): void;
  data?: FaqQuestion | undefined;
};

const QuestionForm = ({ isLoading, onSubmit, data }: QuestionFormProps): JSX.Element => {
  const translations = useTranslations();
  const validationSchema = useQuestionFormValidationSchema();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
  } = useForm<FaqQuestionFormValues>({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const handleFormSubmit = handleSubmit(({ ...form }: FaqQuestionFormValues) => {
    onSubmit({ answerTranslations: form.answerTranslations, questionTranslations: form.questionTranslations });
  });

  return (
    <form onSubmit={handleFormSubmit}>
      {Object.values(Language).map((language) => (
        <Card key={language} mb={5}>
          <CardHeader>
            <Heading size="md" textTransform="uppercase">
              {language}
            </Heading>
          </CardHeader>
          <CardBody pt={0} pb={1}>
            <FormInput
              id={`questionTranslations.${language}`}
              label={translations(`faq_question_form_question_${language}`)}
              validationError={errors.questionTranslations ? errors.questionTranslations[language]?.message : undefined}
              useFormRegisterReturn={register(`questionTranslations.${language}`)}
              setValue={setValue}
            />
            <TextEditor<FaqQuestionFormValues>
              id={`answerTranslations.${language}`}
              label={translations(`faq_question_form_answer_${language}`)}
              validationError={errors.answerTranslations ? errors.answerTranslations[language]?.message : undefined}
              watch={watch}
              trigger={trigger}
              useFormRegisterReturn={register(`answerTranslations.${language}`)}
              setValue={setValue}
              placeholder={translations('faq_answer_placeholder')}
            />
          </CardBody>
        </Card>
      ))}
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default QuestionForm;
