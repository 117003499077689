import React, { JSX } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { FormControl } from '@chakra-ui/react';
import { FormGroup, Select } from 'common/components';
import { Role } from 'common/types/auth';
import { useTranslations } from 'contexts/LocalizationContext';
import LocationsForm from 'modules/RestaurantCredentials/LocationsForm';

import { PartnerRole } from './types';

enum AdminRoleNames {
  ADMINISTRATOR = 'Administrator',
  STANDARD_USER = 'Standard User',
  LIMITED_USER = 'Limited User',
}

type AdminRoleSelect = {
  label: AdminRoleNames;
  value: Role;
};

enum PartnerRoleNames {
  MANAGER = 'Manager',
  EMPLOYEE = 'Employee',
  RETURN_STATION = 'Return Station',
}

type PartnerRoleSelect = {
  label: PartnerRoleNames;
  value: PartnerRole;
};

export const partnerRoleSelectOptions: PartnerRoleSelect[] = [
  { label: PartnerRoleNames.MANAGER, value: PartnerRole.MANAGER },
  { label: PartnerRoleNames.EMPLOYEE, value: PartnerRole.EMPLOYEE },
  { label: PartnerRoleNames.RETURN_STATION, value: PartnerRole.RETURN_STATION },
];

export const adminRoleSelectOptions: AdminRoleSelect[] = [
  { label: AdminRoleNames.ADMINISTRATOR, value: Role.ADMINISTRATOR },
  { label: AdminRoleNames.STANDARD_USER, value: Role.STANDARD_USER },
  { label: AdminRoleNames.LIMITED_USER, value: Role.LIMITED_USER },
];

type RelevoUserRolesFormProps = {
  control: Control<any>;
};

const RelevoUserRolesForm = ({ control }: RelevoUserRolesFormProps): JSX.Element => {
  const translations = useTranslations();
  const partnerRole = useWatch({ control, name: 'partnerRole' });

  return (
    <>
      <FormControl>
        <FormGroup label={translations('relevo_users_admin_role')} inputId="adminRole">
          <>
            <Controller
              control={control}
              name="adminRole"
              render={({ field: { value, onChange, name } }) => (
                <Select<AdminRoleSelect>
                  name={name}
                  value={adminRoleSelectOptions.find((option) => option.value === value)}
                  getOptionLabel={(option) => (option as AdminRoleSelect).label}
                  getOptionValue={(option) => (option as AdminRoleSelect).value}
                  onChange={onChange}
                  valueMapper={(v) => v?.value}
                  options={adminRoleSelectOptions}
                />
              )}
            />
          </>
        </FormGroup>
      </FormControl>
      <FormGroup label={translations('relevo_users_partner_role')} inputId="partnerRole">
        <Controller
          control={control}
          name="partnerRole"
          render={({ field: { value, onChange, name } }) => (
            <Select<PartnerRoleSelect>
              name={name}
              value={partnerRoleSelectOptions.find((option) => option.value === value)}
              getOptionLabel={(option) => (option as PartnerRoleSelect).label}
              getOptionValue={(option) => (option as PartnerRoleSelect).value}
              onChange={onChange}
              valueMapper={(v) => v?.value}
              options={partnerRoleSelectOptions}
            />
          )}
        />
      </FormGroup>
      {partnerRole && <LocationsForm control={control} />}
    </>
  );
};

export default RelevoUserRolesForm;
