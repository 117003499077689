import React, { JSX } from 'react';
import { CellProps } from 'react-table';
import { ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { config } from 'config/config';
import { useTranslations } from 'contexts/LocalizationContext';

import { useFeatureFlagsList } from '../../services/feature-flags';
import { FeatureFlag } from './models/FeatureFlag';
import { FeatureFlagsIsEnabledUpdate } from './FeatureFlagsIsEnabledUpdate';

const FeatureFlagsPage = (): JSX.Element => {
  const translations = useTranslations();
  const [page, setPage] = React.useState(1);

  const {
    data,
    isFetching,
    error: loadFeatureFlagsError,
  } = useFeatureFlagsList({
    limit: config.paginationSize,
    offset: page,
  });

  const columns = React.useMemo(
    () => [
      {
        Header: translations('feature_flag_table_id'),
        accessor: 'id' as const,
      },
      {
        Header: translations('feature_flag_table_name'),
        accessor: 'name' as const,
      },
      {
        Header: translations('feature_flag_table_is_enabled'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<FeatureFlag>) => <FeatureFlagsIsEnabledUpdate isEnabled={original.isEnabled} id={original.id} />,
      },
    ],
    [translations],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('feature_flag_header')}</PageHeading>
      <ErrorMessages errors={[loadFeatureFlagsError]} />
      <Table<FeatureFlag>
        isLoading={isFetching}
        data={data?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default FeatureFlagsPage;
