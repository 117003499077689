import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ErrorMessages, FullPageLoader } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useNews, useUpdateNews } from 'services/news';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import NewsForm from './NewsForm';

const UpdateNews = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const { data: news, isLoading: isLoadingNews, error: loadError } = useNews(id);

  const navigate = useNavigate();
  const translations = useTranslations();

  const { mutate: updateNews, isLoading: isSavingNews, error: updateNewsError } = useUpdateNews();

  if (isLoadingNews) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{translations('news_update')}</ChildPageHeading>
      <ErrorMessages errors={[updateNewsError, loadError]} />
      <NewsForm
        isLoading={isSavingNews}
        onSubmit={(request) =>
          updateNews(
            { id, request },
            {
              onSuccess: () => navigate(Routes.NEWS_PATH),
            },
          )
        }
        model={news}
      />
    </>
  );
};

export default UpdateNews;
