import { QueryObserverResult, useMutation, UseMutationResult, useQuery } from 'react-query';
import {
  AssignData,
  assignProductsByUIdsToRestaurant,
  assignProductsByUIdsToRestaurantWithCategoryRewriting,
  assignProductsToRestaurant,
  getRestaurantProducts,
  MassAssignData,
  transferAllProductsBetweenRestaurants,
} from 'api/product-assignment';
import { AxiosError } from 'axios';
import { useToast } from 'common/components/Toast/useToast.hook';
import { useTranslations } from 'contexts/LocalizationContext';

import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { ItemStatus } from '../modules/AssignedItems/models/enums/item-status';
import { AssignItemsToRestaurantResponse } from '../modules/ProductAssignment/AssignProductFromCsv/model/AssignItemsToRestaurant.model';
import { ProductResponse } from '../modules/Products/Product.model';

const CK_RESTAURANT_PRODUCTS = 'restaurant_products';

type RestaurantProductsListParams = Omit<BaseQueryParams, 'searchText'> & { searchText?: string } & {
  q?: string;
  status?: ItemStatus;
};

export const useGetRestaurantProducts = (
  restaurantId: string | null,
  params: RestaurantProductsListParams = {
    offset: 1,
    limit: 100,
    searchText: '',
  },
): QueryObserverResult<Paginated<ProductResponse>, AxiosError<HttpException>> =>
  useQuery(
    [CK_RESTAURANT_PRODUCTS, restaurantId, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getRestaurantProducts(restaurantId!, params),
    {
      enabled: !!restaurantId,
    },
  );

export const useTransferAllProductsBetweenRestaurants = ({
  onSuccessfulAssign,
}: {
  onSuccessfulAssign: (data: AssignItemsToRestaurantResponse) => void;
}): UseMutationResult<
  AssignItemsToRestaurantResponse,
  AxiosError<HttpException>,
  { fromRestaurantId: string; toRestaurantId: string }
> => {
  const translations = useTranslations();
  const { displayToast } = useToast();

  return useMutation(
    ({ fromRestaurantId, toRestaurantId }) => transferAllProductsBetweenRestaurants(fromRestaurantId, toRestaurantId),
    {
      onSuccess: (data) => onSuccessfulAssign(data),
      onError: (error) => {
        const toastMessage = error.response?.data.message
          ? error.response?.data.message
          : translations('product_assignment_error');
        displayToast('error', toastMessage);
      },
    },
  );
};

export const useAssignProductsToRestaurant = ({
  onSuccessfulAssign,
}: {
  onSuccessfulAssign: (data: AssignItemsToRestaurantResponse) => void;
}): UseMutationResult<AssignItemsToRestaurantResponse, AxiosError<HttpException>, AssignData> => {
  const translations = useTranslations();
  const { displayToast } = useToast();

  return useMutation((data) => assignProductsToRestaurant(data), {
    onSuccess: (data) => onSuccessfulAssign(data),
    onError: (error) => {
      const toastMessage = error.response?.data.message
        ? error.response?.data.message
        : translations('product_assignment_error');
      displayToast('error', toastMessage);
    },
  });
};

export const useAssignProductsByUIdsToRestaurant = ({
  onSuccessfulAssign,
}: {
  onSuccessfulAssign: (data: AssignItemsToRestaurantResponse) => void;
}): UseMutationResult<AssignItemsToRestaurantResponse, AxiosError<HttpException>, AssignData> => {
  const translations = useTranslations();
  const { displayToast } = useToast();

  return useMutation((data) => assignProductsByUIdsToRestaurant(data), {
    onSuccess: (data) => onSuccessfulAssign(data),
    onError: (error) => {
      const toastMessage = error.response?.data.message
        ? error.response?.data.message
        : translations('product_assignment_error');
      displayToast('error', toastMessage);
    },
  });
};

export const useAssignProductsByUIdsToRestaurantWithCategoryRewriting = ({
  onSuccessfulAssign,
}: {
  onSuccessfulAssign: (data: AssignItemsToRestaurantResponse) => void;
}): UseMutationResult<AssignItemsToRestaurantResponse, AxiosError<HttpException>, MassAssignData> => {
  const translations = useTranslations();
  const { displayToast } = useToast();

  return useMutation((data) => assignProductsByUIdsToRestaurantWithCategoryRewriting(data), {
    onSuccess: (data) => onSuccessfulAssign(data),
    onError: (error) => {
      const toastMessage = error.response?.data.message
        ? error.response?.data.message
        : translations('product_assignment_error');
      displayToast('error', toastMessage);
    },
  });
};
