import { ApplicationUser } from '../ApplicationUsers/models/application-user';
import {
  EmailInputType,
  InternationalisedContent,
  NotificationReceiversType,
  NotificationsForm,
  SendNotificationsRequest,
} from './Notifications.model';

const mapNotificationsDataForRequest = (values: NotificationsForm): SendNotificationsRequest => {
  const {
    emailInputType,
    notificationReceiversType,
    selectedUsersList,
    inputMailList,
    notificationType,
    temporaryImage,
    otherNotificationIgnoreSettingType,
    ...internationalisedProperties
  } = values;

  const formattedValues = {
    image: temporaryImage?.[0],
    type: values.notificationType,
  } as SendNotificationsRequest;

  const internationalisedValues = Object.keys(internationalisedProperties).reduce<
    Record<
      string,
      {
        title: InternationalisedContent;
        body: InternationalisedContent;
        buttonText: InternationalisedContent;
        link: InternationalisedContent;
      }
    >
  >((acc, curr) => {
    const [label, language] = curr.split('_');
    acc[label] = {
      ...acc[label],
      [language]: internationalisedProperties[curr as keyof typeof internationalisedProperties] || null,
    };

    return acc;
  }, {});

  if (notificationReceiversType === NotificationReceiversType.SELECTED_USERS) {
    formattedValues.userEmails =
      values.emailInputType === EmailInputType.SELECT
        ? values.selectedUsersList.map((user: ApplicationUser) => user.email)
        : values.inputMailList.split(',').map((mail: string) => mail.trim());
  }

  // As sending notifications utilze form-data for sending images for in-app notifications
  // and we want to keep it consistent across different notifications type
  // we send it as multipart/form-data with nested properties flatten by JSON.stringify()
  const jsonFlatInternationalisedValues: { [key: string]: string } = {};
  for (const [key, value] of Object.entries(internationalisedValues)) {
    jsonFlatInternationalisedValues[key] = JSON.stringify(value);
  }

  return {
    ...jsonFlatInternationalisedValues,
    ...formattedValues,
  };
};

export default mapNotificationsDataForRequest;
