import { generatePath } from 'react-router-dom';

import { Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { RestaurantAssignedItemsQueryRequest } from '../modules/Restaurants/TwoFactorReturn/models/restaurant-assigned-items-query-request';
import { RestaurantAssignedItemsResponse } from '../modules/Restaurants/TwoFactorReturn/models/restaurant-assigned-items-response';
import axiosInstance from './axios';

export const getRestaurantAssignedItems = (
  restaurantId: string,
  queryParams: RestaurantAssignedItemsQueryRequest,
): Promise<Paginated<RestaurantAssignedItemsResponse>> => {
  const { limit, offset, status } = queryParams;
  const additionalParams = new Map();
  additionalParams.set('status', status);
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, undefined, additionalParams);
  return axiosInstance
    .get(generatePath(apiPaths.RESTAURANTS_ASSIGNED_ITEMS, { restaurantId }), { params: searchParams })
    .then(({ data }) => data);
};
