import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormGroup } from '../../../../../common/components';
import CurrencySelect from '../../../../../common/components/CurrencySelect/CurrencySelect';
import { useTranslations } from '../../../../../contexts/LocalizationContext';

type FormCurrencyProps = {
  isDisabled?: boolean;
  tooltip?: string;
};

export const FormCurrency = ({ isDisabled, tooltip }: FormCurrencyProps) => {
  const translations = useTranslations();
  const { control } = useFormContext();

  return (
    <FormGroup label={translations('reuse_cards_currency_label')} labelTooltipText={tooltip} inputId={'currency'}>
      <Controller
        control={control}
        name="currency"
        render={({ field: { value, name, onChange } }) => (
          <CurrencySelect
            selectedCurrency={value}
            name={name}
            onSelect={onChange}
            inputId={'currency'}
            isDisabled={isDisabled}
          />
        )}
      />
    </FormGroup>
  );
};
