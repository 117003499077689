import React from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { MainPageContent, PageHeading } from '../../../common/components';
import { OrderType } from '../../../common/components/Table/TableHeaderWithOrder/order-type.enum';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../../common/constants/common';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useKeyAccountGroupRestaurantList } from '../../../services/restaurants';
import { KeyAccountGroupRestaurantsOrderField } from '../models/enums/key-account-group-restaurants-order-field.enum';
import { DownloadReturnQrCodes } from './components/DownloadReturnQrCodes/DownloadReturnQrCodes';
import { DownloadReturnQrPosters } from './components/DownloadReturnQrPosters/DownloadReturnQrPosters';
import { KeyAccountGroupReturnCodesFilter } from './components/ReturnCodesFilter/KeyAccountGroupReturnCodesFilter';
import { KeyAccountGroupReturnCodesTable } from './components/ReturnCodesTable/KeyAccountGroupReturnCodesTable';
import { ReturnCodesFilterContextProvider } from './context/HistoryFilterContext/ReturnCodesFilterContext';

export const KeyAccountGroupReturnCodes = () => {
  const { keyAccountId } = useParams<{ keyAccountId: string }>();
  invariant(keyAccountId, 'keyAccountId is not set within the route');
  const translations = useTranslations();

  const { data: paginatedKeyAccountGroupsRestaurants } = useKeyAccountGroupRestaurantList({
    keyAccountId,
    orderField: KeyAccountGroupRestaurantsOrderField.RESTAURANT_NAME,
    orderType: OrderType.ASC,
    ...ALL_PAGINATED_LIST_ITEMS_PARAMS,
  });

  return (
    <MainPageContent>
      <PageHeading>{translations('restaurant_return_codes_header')}</PageHeading>
      {paginatedKeyAccountGroupsRestaurants?.items ? (
        <ReturnCodesFilterContextProvider keyAccountGroupRestaurants={paginatedKeyAccountGroupsRestaurants.items}>
          <Flex wrap="wrap" justifyContent="flex-end" mb={4}>
            <DownloadReturnQrCodes keyAccountGroupId={keyAccountId} />
            <DownloadReturnQrPosters keyAccountGroupId={keyAccountId} />
          </Flex>
          <Flex wrap="wrap" mb={4} ml={4}>
            <KeyAccountGroupReturnCodesFilter keyAccountGroupRestaurants={paginatedKeyAccountGroupsRestaurants.items} />
          </Flex>
          <KeyAccountGroupReturnCodesTable keyAccountGroupId={keyAccountId} />
        </ReturnCodesFilterContextProvider>
      ) : null}
    </MainPageContent>
  );
};
