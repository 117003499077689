import React, { JSX, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Flex, useTheme } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import { useSessionContext } from 'contexts/SessionContext';

import { LinkButton } from '../../LinkButton';
import { SignOut } from '../SignOut';
import MENU_ITEMS, { MenuItem } from './consts';
import { ExpandableSection } from './ExpandableSection';
import { SubSectionButton } from './SubSectionButton';

export const Menu = ({ activeTextColor }: { activeTextColor: string }): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();
  const { pathname } = useLocation();
  const { role } = useSessionContext();
  const [expandableSectionsVisibility, setExpandableSectionsVisibility] = useState<Record<string, boolean>>({
    menuSettings: false,
  });

  const defaultButtonProps = {
    width: 'full',
    justifyContent: 'flex-start',
    variant: 'ghost',
    fontSize: 18,
    mt: 2,
  };

  // eslint-disable-next-line
  const getIconPath = (iconName: string) => require(`../../../../assets/icons/${iconName}`);

  const menuItems = MENU_ITEMS.filter((item) => (item.roles ? item.roles.includes(role!) : true));

  useEffect(() => {
    if (!Object.keys(expandableSectionsVisibility).length) {
      const filteredSections = menuItems.reduce<Record<string, boolean>>((acc, curr) => {
        if ('subSections' in curr) {
          acc[curr.translationKey] = false;
        }
        return acc;
      }, {});

      setExpandableSectionsVisibility((sections) => ({
        ...sections,
        ...filteredSections,
      }));
    }
    // eslint-disable-next-line
  }, []);

  const toggleSubsectionVisibility = (translationKey: string) => {
    setExpandableSectionsVisibility((currentVisibility) => ({
      ...currentVisibility,
      [translationKey]: !currentVisibility[translationKey],
    }));
  };

  const isMenuButtonActive = (item: MenuItem) => item.subSections?.some(({ route }) => route === pathname);

  return (
    <Flex py={4} justifyContent="space-evenly" direction="column" flexGrow={1} data-testid="menu">
      {menuItems.map((item) => (
        <React.Fragment key={item.translationKey}>
          {item?.subSections ? (
            <>
              <Button
                {...defaultButtonProps}
                onClick={() => toggleSubsectionVisibility(item.translationKey)}
                color={isMenuButtonActive(item) ? activeTextColor : colors.black}
                leftIcon={<img width={30} src={getIconPath(item.iconName)} alt="Menu item icon" />}
              >
                {translations(item.translationKey)}
              </Button>
              {expandableSectionsVisibility[item.translationKey] && (
                <ExpandableSection>
                  {item.subSections
                    .filter((subSectionItem) => (subSectionItem.roles ? subSectionItem.roles.includes(role!) : true))
                    .map((subSection) => (
                      <SubSectionButton
                        type="link"
                        route={subSection.route}
                        activeTextColor={activeTextColor}
                        key={subSection.translationKey}
                      >
                        {translations(subSection.translationKey)}
                      </SubSectionButton>
                    ))}
                </ExpandableSection>
              )}
            </>
          ) : (
            <LinkButton
              {...defaultButtonProps}
              color={pathname === item.route ? activeTextColor : colors.black}
              leftIcon={<img width={30} src={getIconPath(item.iconName)} alt="Menu item icon" />}
              to={item.route!}
            >
              {translations(item.translationKey)}
            </LinkButton>
          )}
        </React.Fragment>
      ))}
      {/* TODO: why it is separated??*/}
      <Button
        {...defaultButtonProps}
        leftIcon={<img width={30} src={getIconPath('settings_icon.svg')} alt="Menu item icon" />}
        onClick={() => toggleSubsectionVisibility('menuSettings')}
      >
        {translations('menu_settings')}
      </Button>
      {expandableSectionsVisibility.menuSettings && (
        <ExpandableSection>
          <SignOut />
        </ExpandableSection>
      )}
    </Flex>
  );
};
