import React, { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button, Flex } from '@chakra-ui/react';
import { Table } from 'common/components';

import { getFormattedDate } from '../../../common/utils';
import { config } from '../../../config/config';
import { Routes } from '../../../config/routes';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useRestaurantDraftList } from '../../../services/drafts';
import { RestaurantDraftResponse } from './models/restaurant-draft-response.model';
import { RestaurantDraftStatus } from './models/restaurant-draft-status.enum';
import { RestaurantDraftsFilters } from './models/restaurant-drafts-filters.model';
import { RestaurantDraftsTableFilters } from './RestaurantDraftsTableFilters';

export const RestaurantDraftsTable = (): JSX.Element => {
  const navigate = useNavigate();
  const translations = useTranslations();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<RestaurantDraftsFilters>({
    status: RestaurantDraftStatus.ACTIVE,
  });

  const { data: drafts, isLoading } = useRestaurantDraftList({
    offset: page,
    limit: config.paginationSize,
    status: filters.status,
  });

  const handleShowDraft = useCallback(
    ({ draftId, restaurantId }: { draftId: string; restaurantId: string }) => {
      navigate(generatePath(Routes.RESTAURANT_DRAFT_DETAILS_PATH, { draftId, restaurantId }));
    },
    [navigate],
  );

  const columns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('restaurant_drafts_table_restaurant_id'),
        accessor: 'restaurantId' as const,
      },
      {
        id: '2',
        Header: translations('restaurant_drafts_table_restaurant_name'),
        accessor: 'restaurantName' as const,
      },
      {
        id: '3',
        Header: translations('restaurant_drafts_table_status'),
        accessor: 'status' as const,
      },
      {
        id: '4',
        Header: translations('restaurant_drafts_table_created_at'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantDraftResponse>) => getFormattedDate(original.recordCreatedAt),
      },
      {
        id: 'actions',
        Header: translations('partners_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantDraftResponse>) => (
          <Flex>
            <Button
              m={1}
              onClick={() =>
                handleShowDraft({
                  draftId: original.id,
                  restaurantId: original.restaurantId,
                })
              }
            >
              {original.status === RestaurantDraftStatus.ACTIVE ? translations('edit') : translations('show')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, handleShowDraft],
  );

  return (
    <Flex direction="column">
      <RestaurantDraftsTableFilters
        filters={filters}
        onChange={(newFilters) => {
          setPage(1);
          setFilters(newFilters);
        }}
      />
      <Table<RestaurantDraftResponse>
        data={drafts?.items}
        columns={columns}
        isLoading={isLoading}
        page={page}
        onPageChanged={setPage}
        totalPages={drafts?.totalPages}
        paginationEnabled
      />
    </Flex>
  );
};
