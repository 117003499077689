import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { PromotionFormValues } from 'modules/Promotions/CreateUpdatePromotionForm';
import { Promotion } from 'modules/Promotions/models/Promotion';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import axiosInstance from './axios';

export const getPromotions = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<Promotion>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance
    .get<Paginated<Promotion>>(`${apiPaths.RESTAURANTS}/promotions?${searchParams}`)
    .then(({ data }) => data);
};

export const getPromotion = (id: string): Promise<Promotion> =>
  axiosInstance.get<Promotion>(`${apiPaths.RESTAURANTS}/promotions/${id}`).then(({ data }) => data);

export const createUpdatePromotion = (data: PromotionFormValues): Promise<void> => {
  if (data.id) {
    return axiosInstance.put(`${apiPaths.RESTAURANTS}/promotions/${data.id}`, data);
  }
  return axiosInstance.post(`${apiPaths.RESTAURANTS}/promotions`, data);
};

export const deletePromotion = (id: string): Promise<void> =>
  axiosInstance.delete(`${apiPaths.RESTAURANTS}/promotions/${id}`);
