import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import sendNotifications from '../api/notifications';
import { HttpException } from '../common/models/httpException';
import { SendNotificationsRequest } from '../modules/Notifications/Notifications.model';

const useSendNotifications = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  { values: SendNotificationsRequest; ignoreSetting: boolean }
> => useMutation(({ values, ignoreSetting }) => sendNotifications(values, ignoreSetting));

export default useSendNotifications;
