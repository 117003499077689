import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { createAboutUs, deleteAboutUs, getAboutUs, getAboutUsList, updateAboutUs } from 'api/about-us';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { AboutUsRequest } from 'modules/AboutUs/models/about-us-request';
import { AboutUsResponse } from 'modules/AboutUs/models/about-us-response';

import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';

export const CK_ABOUT = 'about';

export const useAboutUsList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<AboutUsResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_ABOUT, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getAboutUsList(params),
    {
      keepPreviousData: true,
    },
  );
};

export const useAboutUs = (id: string): QueryObserverResult<AboutUsResponse, AxiosError<HttpException>> => {
  return useQuery([CK_ABOUT, id], () => getAboutUs(id));
};

export const useCreateAboutUs = (): UseMutationResult<AboutUsResponse, AxiosError<HttpException>, AboutUsRequest> => {
  const queryClient = useQueryClient();
  return useMutation(createAboutUs, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_ABOUT);
    },
  });
};

export const useUpdateAboutUs = (): UseMutationResult<
  AboutUsResponse,
  AxiosError<HttpException>,
  { id: string; request: AboutUsRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id, request }: { id: string; request: AboutUsRequest }) => updateAboutUs(id, request), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_ABOUT);
    },
  });
};

export const useDeleteAboutUs = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation((id: string) => deleteAboutUs(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_ABOUT);
    },
  });
};
