import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { FormGroup } from '../../../../../common/components';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { NotificationSettingsFrequency } from '../../models/enums/notification-settings-frequency.enum';

export const NotificationFrequency = () => {
  const translations = useTranslations();
  const { control } = useFormContext();
  return (
    <FormGroup label={translations('notification_settings_frequency_label')} inputId="frequency">
      <Controller
        control={control}
        name="frequency"
        render={({ field: { value, onChange } }) => (
          <RadioGroup onChange={onChange} value={value} id="frequency">
            <Stack direction="row">
              <Radio value={NotificationSettingsFrequency.WEEKLY}>
                {translations('notification_settings_frequency_weekly')}
              </Radio>
              <Radio value={NotificationSettingsFrequency.MONTHLY}>
                {translations('notification_settings_frequency_monthly')}
              </Radio>
            </Stack>
          </RadioGroup>
        )}
      />
    </FormGroup>
  );
};
