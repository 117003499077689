import { ReactElement } from 'react';

import { RestaurantDetailsResponse } from '../../models';
import { BooleanDiff } from '../components/Diff/BooleanDiff';
import { ImagesDiff } from '../components/Diff/ImagesDiff';
import { MetaCategoriesDiff } from '../components/Diff/MetaCategoriesDiff';
import { OpeningHoursDiff } from '../components/Diff/OpeningHoursDiff';
import { StringDiff } from '../components/Diff/StringDiff';
import { RestaurantDraftResponse } from '../models/restaurant-draft-response.model';
import { RestaurantDraftStatus } from '../models/restaurant-draft-status.enum';

interface DraftDiffAttribute {
  key: keyof RestaurantDraftResponse;
  labelTranslationKey: string;
  isChanged: (payload: { restaurant: RestaurantDetailsResponse; draft: RestaurantDraftResponse }) => boolean;
  render: (payload: {
    restaurant: RestaurantDetailsResponse;
    draft: RestaurantDraftResponse;
    isChanged: boolean;
  }) => ReactElement;
}

export const DRAFT_ATTRIBUTES: DraftDiffAttribute[] = [
  {
    key: 'sortedImages',
    labelTranslationKey: 'restaurant_drafts_label_images',
    isChanged: ({ restaurant, draft }) => {
      const numberOfImagesIsDifferent = restaurant.images?.length !== draft.sortedImages.length;
      if (numberOfImagesIsDifferent) {
        return true;
      }
      return draft.sortedImages.some((draftImage) => {
        return !restaurant.images?.find(
          (restaurantImage) =>
            restaurantImage.file?.id === draftImage.file?.id && restaurantImage.order === draftImage.order,
        );
      });
    },
    render: ({ restaurant, draft, isChanged }) => (
      <ImagesDiff
        isChanged={isChanged}
        showDiff={draft.status === RestaurantDraftStatus.ACTIVE}
        before={restaurant.images}
        after={draft.sortedImages}
      />
    ),
  },
  {
    key: 'restaurantName',
    labelTranslationKey: 'restaurant_drafts_label_name',
    isChanged: ({ restaurant, draft }) => restaurant.restaurantName !== draft.restaurantName,
    render: ({ restaurant, draft, isChanged }) => (
      <StringDiff
        isChanged={isChanged}
        showDiff={draft.status === RestaurantDraftStatus.ACTIVE}
        before={restaurant.restaurantName}
        after={draft.restaurantName}
      />
    ),
  },
  {
    key: 'restaurantDetail',
    labelTranslationKey: 'restaurant_drafts_label_description',
    isChanged: ({ restaurant, draft }) => restaurant.restaurantDetail !== draft.restaurantDetail,
    render: ({ restaurant, draft, isChanged }) => (
      <StringDiff
        isChanged={isChanged}
        showDiff={draft.status === RestaurantDraftStatus.ACTIVE}
        before={restaurant.restaurantDetail}
        after={draft.restaurantDetail}
      />
    ),
  },
  {
    key: 'restaurantAddress',
    labelTranslationKey: 'restaurant_drafts_label_address',
    isChanged: ({ restaurant, draft }) => restaurant.restaurantAddress !== draft.restaurantAddress,
    render: ({ restaurant, draft, isChanged }) => (
      <StringDiff
        isChanged={isChanged}
        showDiff={draft.status === RestaurantDraftStatus.ACTIVE}
        before={restaurant.restaurantAddress}
        after={draft.restaurantAddress}
      />
    ),
  },
  {
    key: 'phoneNumber',
    labelTranslationKey: 'restaurant_drafts_label_phone_number',
    isChanged: ({ restaurant, draft }) => restaurant.phoneNumber !== draft.phoneNumber,
    render: ({ restaurant, draft, isChanged }) => (
      <StringDiff
        isChanged={isChanged}
        showDiff={draft.status === RestaurantDraftStatus.ACTIVE}
        before={restaurant.phoneNumber}
        after={draft.phoneNumber}
      />
    ),
  },
  {
    key: 'websiteLink',
    labelTranslationKey: 'restaurant_drafts_label_website',
    isChanged: ({ restaurant, draft }) => restaurant.websiteLink !== draft.websiteLink,
    render: ({ restaurant, draft, isChanged }) => (
      <StringDiff
        isChanged={isChanged}
        showDiff={draft.status === RestaurantDraftStatus.ACTIVE}
        before={restaurant.websiteLink}
        after={draft.websiteLink}
      />
    ),
  },
  {
    key: 'metaCategoriesIds',
    labelTranslationKey: 'restaurant_drafts_label_meta_categories',
    isChanged: ({ restaurant, draft }) => {
      return restaurant.metaCategories.length !== draft.metaCategoriesIds.length;
    },
    render: ({ restaurant, draft, isChanged }) => (
      <MetaCategoriesDiff
        isChanged={isChanged}
        showDiff={draft.status === RestaurantDraftStatus.ACTIVE}
        before={restaurant.metaCategories.map((mc) => mc.id)}
        after={draft.metaCategoriesIds}
      />
    ),
  },
  {
    key: 'overrideOpeningHours',
    labelTranslationKey: 'restaurant_drafts_label_override_opening_hours',
    isChanged: ({ restaurant, draft }) => restaurant.overrideOpeningHours !== draft.overrideOpeningHours,
    render: ({ draft, restaurant, isChanged }) => (
      <BooleanDiff
        isChanged={isChanged}
        showDiff={draft.status === RestaurantDraftStatus.ACTIVE}
        before={restaurant.overrideOpeningHours}
        after={draft.overrideOpeningHours}
      />
    ),
  },
  {
    key: 'restaurantOpeningHours',
    labelTranslationKey: 'restaurant_drafts_label_opening_hours',
    isChanged: ({ restaurant, draft }) => {
      return (draft.restaurantOpeningHours || []).some(({ day, periods }) => {
        const currentPeriodsForDay = restaurant.restaurantOpeningHours?.find(
          (currentOpeningHours) => currentOpeningHours.day === day,
        )?.periods;
        if (!currentPeriodsForDay) {
          return !!periods?.length;
        }

        return (
          periods?.length !== currentPeriodsForDay.length ||
          periods.some((period) => {
            return !currentPeriodsForDay.find((currentPeriod) => {
              return currentPeriod.openTime === period.openTime && currentPeriod.closeTime === period.closeTime;
            });
          })
        );
      });
    },
    render: ({ restaurant, draft, isChanged }) =>
      draft.overrideOpeningHours ? (
        <OpeningHoursDiff
          isChanged={isChanged}
          showDiff={draft.status === RestaurantDraftStatus.ACTIVE}
          before={restaurant.restaurantOpeningHours}
          after={draft.restaurantOpeningHours}
        />
      ) : (
        <></>
      ),
  },
];
