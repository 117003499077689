import React, { JSX, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter, FormGroup, FullPageLoader } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import { RestaurantResponse } from 'modules/Restaurants/models/restaurant-response.model';
import { useRestaurantList } from 'services/restaurants';
import invariant from 'tiny-invariant';
import * as yup from 'yup';

import { useDebounce } from '../../../../../common/hooks/useDebounce';
import { config } from '../../../../../config/config';
import { DEBOUNCE_SEARCH_TIME } from '../../../../../config/configConsts';
import { RestaurantFilterMode } from '../../../../Restaurants/models/enums/restaurant-filter-mode.enum';
import {
  KeyAccountGroupsAddRestaurantFormValues,
  KeyAccountGroupsAddRestaurantRequest,
} from '../../../KeyAccountGroups.model';

type KeyAccountGroupsAddRestaurantFormProps = {
  onSubmit: (values: KeyAccountGroupsAddRestaurantRequest) => void;
  isLoading: boolean;
};

const KeyAccountGroupAddRestaurantForm = ({
  onSubmit,
  isLoading,
}: KeyAccountGroupsAddRestaurantFormProps): JSX.Element => {
  const translations = useTranslations();
  const validationSchema = yup.object().shape({
    restaurant: yup
      .object()
      .shape({
        id: yup.string().required(),
        restaurantName: yup.string().required(),
      })
      .required()
      .test('test restaurant', translations('validation_required'), (restaurant) => !!restaurant.id),
  });
  const { keyAccountId } = useParams<{ keyAccountId: string }>();
  invariant(keyAccountId, 'keyAccountId is not set within the route');
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);

  const { data: paginatedRestaurantList } = useRestaurantList({
    offset: 1,
    limit: config.paginationSize,
    searchText: debouncedSearch,
    isActive: true,
    filterMode: RestaurantFilterMode.DROP_DOWN_SELECT,
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  if (isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  const onFormSubmit = handleSubmit((form: KeyAccountGroupsAddRestaurantFormValues) => {
    onSubmit({
      restaurantId: form.restaurant.id,
      keyAccountId,
    });
  });

  return (
    <form onSubmit={onFormSubmit} autoComplete="off">
      <FormGroup
        label={translations('restaurant')}
        inputId="restaurant"
        validationError={(errors.restaurant as FieldError)?.message}
      >
        <Controller
          control={control}
          name="restaurant"
          render={({ field: { value, onChange, name } }) => (
            <Select
              inputId="restaurant"
              placeholder=""
              name={name}
              getOptionLabel={(option: RestaurantResponse) => option.restaurantName}
              getOptionValue={(option: RestaurantResponse) => option.id}
              value={value as RestaurantResponse}
              onChange={onChange}
              onInputChange={setSearchText}
              options={paginatedRestaurantList?.items}
              filterOption={null}
            />
          )}
        />
      </FormGroup>
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default KeyAccountGroupAddRestaurantForm;
