import React, { useState } from 'react';
import { EditIcon } from '@chakra-ui/icons';
import { Button, Flex, Icon, useDisclosure } from '@chakra-ui/react';

import { ErrorMessages, FullPageLoader, Modal, PageHeading } from '../../../../../common/components';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useRestaurantTwoFactorReturnSettings } from '../../../../../services/two-factor-return';
import { TwoFactorReturnHistory } from '../TwoFactorReturnHistory/TwoFactorReturnHistory';
import { UpdateTwoFactorReturnSettings } from '../UpdateTwoFactorReturnSettings/UpdateTwoFactorReturnSettings';

type TwoFactorReturnSettingsProps = {
  restaurantId: string;
};

export const TwoFactorReturnSettings = ({ restaurantId }: TwoFactorReturnSettingsProps) => {
  const translations = useTranslations();
  const [isEditMode, setIsEditMode] = useState(false);
  const { isOpen: showHistory, onOpen: handleOpenHistory, onClose: handleCloseHistory } = useDisclosure();

  const {
    data: restaurantTwoFactorReturnSettings,
    isLoading: isLoadingTwoFactorReturnSettings,
    error: twoFactorReturnSettingsError,
  } = useRestaurantTwoFactorReturnSettings(restaurantId);

  const handleEditMode = () => setIsEditMode(true);

  return (
    <>
      <PageHeading>{translations('restaurant_two_factor_return_header')}</PageHeading>
      <FullPageLoader show={isLoadingTwoFactorReturnSettings}>
        <ErrorMessages errors={twoFactorReturnSettingsError} />
        <UpdateTwoFactorReturnSettings
          restaurantId={restaurantId}
          model={restaurantTwoFactorReturnSettings}
          isEditMode={isEditMode}
          onEditModeChange={setIsEditMode}
        />
        {!isEditMode ? (
          <Flex justifyContent="flex-start">
            <Button leftIcon={<Icon as={EditIcon} />} onClick={handleEditMode} mr={2}>
              {translations('edit')}
            </Button>
            <Button onClick={handleOpenHistory}>{translations('two_factor_return_show_history')}</Button>
          </Flex>
        ) : null}

        <Modal
          size="xl"
          isModalVisible={showHistory}
          onModalClose={handleCloseHistory}
          customModalFooter={null}
          modalHeader={translations('two_factor_return_enabled_history_header')}
        >
          <TwoFactorReturnHistory enabledHistory={restaurantTwoFactorReturnSettings?.enabledHistory || []} />
        </Modal>
      </FullPageLoader>
    </>
  );
};
