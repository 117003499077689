import React, { JSX, useCallback, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button, Flex, useTheme } from '@chakra-ui/react';
import {
  AddEditItemLink,
  ErrorMessages,
  FallbackValue,
  LinkButton,
  MainPageContent,
  PageHeading,
  Table,
} from 'common/components';
import { useExecuteWithConfirmation } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { Role, useSessionContext } from 'contexts/SessionContext';
import { useDeleteKeyAccountGroup, useKeyAccountGroupsList } from 'services/key-account-groups';

import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { INDIVIDUAL_KEY_ACCOUNT_ID } from '../Restaurants/restaurant.consts';
import { KeyAccountGroupsResponse } from './models/key-account-groups-response';

const KeyAccountGroups = (): JSX.Element => {
  const translations = useTranslations();
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const { role } = useSessionContext();
  const { colors } = useTheme();

  const {
    data: paginatedKeyAccountGroups,
    isFetching,
    error: loadKeyAccountGroupError,
  } = useKeyAccountGroupsList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const { mutate: deleteKeyAccountGroup, error: deleteError } = useDeleteKeyAccountGroup();

  const { executeWithConfirmation: onDeleteKeyAccountGroup } = useExecuteWithConfirmation(
    translations('key_account_groups_delete_confirmation'),
    deleteKeyAccountGroup,
  );

  const handleDelete = useCallback(
    (original: KeyAccountGroupsResponse) => () => onDeleteKeyAccountGroup(original.id),
    [onDeleteKeyAccountGroup],
  );

  const columns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('key_account_groups_table_key_account_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('key_account_groups_table_key_account'),
        accessor: 'keyAccount' as const,
      },
      {
        id: '3',
        Header: translations('key_account_groups_table_segment'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<KeyAccountGroupsResponse>) => <FallbackValue value={original?.segment?.name} />,
      },
      {
        id: '4',
        Header: translations('key_account_groups_table_restaurants'),
        accessor: 'restaurantsCount' as const,
      },
      {
        id: 'actions',
        Header: translations('key_account_groups_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<KeyAccountGroupsResponse>) => (
          <Flex>
            <LinkButton
              isDisabled={original.id === INDIVIDUAL_KEY_ACCOUNT_ID}
              m={1}
              to={generatePath(Routes.KEY_ACCOUNT_GROUP_IMAGES_PATH, { keyAccountId: original.id })}
              color={colors.orange[500]}
            >
              {translations('images')}
            </LinkButton>
            <LinkButton
              to={generatePath(Routes.KEY_ACCOUNT_GROUP_RESTAURANTS_PATH, { keyAccountId: original.id })}
              m={1}
              color={colors.orange[500]}
            >
              {translations('key_account_groups_button_restaurants')}
            </LinkButton>
            <LinkButton
              isDisabled={original.id === INDIVIDUAL_KEY_ACCOUNT_ID}
              to={generatePath(Routes.KEY_ACCOUNT_GROUP_RETURN_CODES_PATH, { keyAccountId: original.id })}
              m={1}
              color={colors.orange[500]}
            >
              {translations('return_codes')}
            </LinkButton>
            <AddEditItemLink
              to={generatePath(Routes.EDIT_KEY_ACCOUNT_GROUP_PATH, { keyAccountId: original.id })}
              m={1}
              isEdit
            />
            {original.id !== INDIVIDUAL_KEY_ACCOUNT_ID && role !== Role.LIMITED_USER && (
              <Button m={1} onClick={handleDelete(original)}>
                {translations('delete')}
              </Button>
            )}
          </Flex>
        ),
      },
    ],
    [translations, handleDelete, role, colors],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('key_account_groups_header')}</PageHeading>
      <ErrorMessages errors={[loadKeyAccountGroupError, deleteError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_KEY_ACCOUNT_GROUP_PATH}>
          {translations('key_account_groups_add_key_account')}
        </AddEditItemLink>
      </Flex>
      <Table<KeyAccountGroupsResponse>
        isLoading={isFetching}
        data={paginatedKeyAccountGroups?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedKeyAccountGroups?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('key_account_groups_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default KeyAccountGroups;
