import { generatePath } from 'react-router-dom';
import apiPaths from 'config/apiPaths';

import {
  FaqQuestion,
  FaqQuestionRequest,
  FaqSection,
  FaqSectionRequest,
} from '../modules/FrequentlyAskedQuestions/models/frequently-asked-question.model';
import { FrequentlyAskedQuestionsReorderRequest } from '../modules/FrequentlyAskedQuestions/models/frequently-asked-questions-reorder-request.model';
import { FrequentlyAskedQuestionsResponse } from '../modules/FrequentlyAskedQuestions/models/frequently-asked-questions-response.model';
import axiosInstance from './axios';

export const getFaqList = async (): Promise<FrequentlyAskedQuestionsResponse> => {
  const { data } = await axiosInstance.get<FrequentlyAskedQuestionsResponse>(apiPaths.FREQUENTLY_ASKED_QUESTIONS);
  return data;
};

export const updateOrderFaqList = (values: FrequentlyAskedQuestionsReorderRequest): Promise<void> => {
  return axiosInstance.put(apiPaths.FREQUENTLY_ASKED_QUESTIONS, values);
};

export const getFaqSection = async (id: string): Promise<FaqSection> => {
  const { data } = await axiosInstance.get<FaqSection>(`${apiPaths.FREQUENTLY_ASKED_QUESTIONS_SECTIONS}/${id}`);
  return data;
};

export const createFaqSection = (values: FaqSectionRequest): Promise<FaqSection> =>
  axiosInstance.post(apiPaths.FREQUENTLY_ASKED_QUESTIONS_SECTIONS, values);

export const updateFaqSection = (id: string, values: FaqSectionRequest): Promise<FaqSection> =>
  axiosInstance.patch(`${apiPaths.FREQUENTLY_ASKED_QUESTIONS_SECTIONS}/${id}`, values);

export const deleteFaqSection = (sectionId: string): Promise<void> =>
  axiosInstance.delete(`${apiPaths.FREQUENTLY_ASKED_QUESTIONS_SECTIONS}/${sectionId}`);

export const getFaqQuestion = async (id: string): Promise<FaqSection> => {
  const { data } = await axiosInstance.get<FaqSection>(`${apiPaths.FREQUENTLY_ASKED_QUESTIONS_QUESTIONS}/${id}`);
  return data;
};

export const createFaqQuestion = (sectionId: string, values: FaqQuestionRequest): Promise<FaqQuestion> =>
  axiosInstance.post(`${generatePath(apiPaths.FREQUENTLY_ASKED_QUESTIONS_SECTIONS_QUESTIONS, { sectionId })}`, values);

export const updateFaqQuestion = (id: string, values: FaqQuestionRequest): Promise<FaqQuestion> =>
  axiosInstance.patch(`${apiPaths.FREQUENTLY_ASKED_QUESTIONS_QUESTIONS}/${id}`, values);

export const deleteFaqQuestion = (sectionId: string, questionId: string): Promise<void> =>
  axiosInstance.delete(
    `${generatePath(apiPaths.FREQUENTLY_ASKED_QUESTIONS_SECTIONS_QUESTIONS, { sectionId })}/${questionId}`,
  );
