import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ErrorMessages, FullPageLoader } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useAboutUs, useUpdateAboutUs } from 'services/about-us';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import AboutUsForm from './AboutUsForm';

const UpdateAboutUs = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const translations = useTranslations();
  const { data: aboutUs, isLoading: isLoadingAboutUs, error: loadAboutUsError } = useAboutUs(id);

  const { mutate: updateAboutUs, isLoading: isSavingAboutUs, error: updateAboutUsError } = useUpdateAboutUs();

  if (isLoadingAboutUs) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{translations('about_update')}</ChildPageHeading>
      <ErrorMessages errors={[updateAboutUsError, loadAboutUsError]} />
      <AboutUsForm
        isLoading={isSavingAboutUs}
        onSubmit={(request) =>
          updateAboutUs(
            { id, request },
            {
              onSuccess: () => navigate(Routes.ABOUT_PATH),
            },
          )
        }
        model={aboutUs}
      />
    </>
  );
};

export default UpdateAboutUs;
