import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Center, Heading, Image } from '@chakra-ui/react';
import { saveAs } from 'file-saver';
import invariant from 'tiny-invariant';

import { FullPageLoader } from '../../../../../common/components';
import { ChildPageHeading } from '../../../../../common/components/ChildPageHeading';
import { getQRCodeSrc } from '../../../../../common/utils/qrCodes.helper';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useRestaurantReturnCode } from '../../../../../services/return-codes';
import { getReturnCodeFileName } from '../../../utils/return-codes.helper';

export const DownloadRestaurantReturnCode = () => {
  const translations = useTranslations();
  const { restaurantId, id } = useParams<{ restaurantId: string; id: string }>();
  invariant(restaurantId, 'restaurantId is not set within the route');
  invariant(id, 'id is not set within the route');

  const { data: returnCode, isLoading: isLoadingReturnCode } = useRestaurantReturnCode(id, restaurantId);

  const getSrc = useMemo(() => getQRCodeSrc(returnCode?.qrCode || ''), [returnCode?.qrCode]);

  const handleDownloadQrCode = useCallback(
    () => saveAs(getQRCodeSrc(returnCode?.qrCode || ''), getReturnCodeFileName(returnCode?.name || '')),
    [returnCode],
  );

  return (
    <FullPageLoader show={isLoadingReturnCode}>
      <ChildPageHeading>{translations('download_return_code_header')}</ChildPageHeading>
      <Heading fontSize={16}>{returnCode?.qrCode}</Heading>
      <Center p={4}>
        <Image src={getSrc} alt="QR Code" maxWidth="500px" />
      </Center>
      <Button colorScheme="orange" onClick={handleDownloadQrCode} mr={2}>
        {translations('download_qr_code_button')}
      </Button>
    </FullPageLoader>
  );
};
