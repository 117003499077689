import apiPaths from 'config/apiPaths';

import { BaseQueryParams, Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import { MetaCategory, MetaCategoryRequest } from '../modules/MetaCategories/model/MetaCategory';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export const getMetaCategoryList = ({
  searchText,
  offset,
  limit,
}: BaseQueryParams): Promise<Paginated<MetaCategory>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get(`${apiPaths.META_CATEGORIES}?${searchParams}`).then(({ data }) => data);
};

export const getMetaCategory = (id: string): Promise<MetaCategory> =>
  axiosInstance.get<MetaCategory>(`${apiPaths.META_CATEGORIES}/${id}`).then(({ data }) => data);

export const updateMetaCategory = (values: MetaCategoryRequest, id: string): Promise<MetaCategory> => {
  const formData = createFormData(values);
  return axiosInstance.put(`${apiPaths.META_CATEGORIES}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createMetaCategory = (values: MetaCategoryRequest): Promise<MetaCategory> => {
  const formData = createFormData(values);
  return axiosInstance.post(`${apiPaths.META_CATEGORIES}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
