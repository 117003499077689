import React, { JSX } from 'react';
import { Divider, HStack, Image, useTheme } from '@chakra-ui/react';

import bowl from '../../../assets/icons/bowl.svg';
import cup from '../../../assets/icons/cup.svg';
import { getFormattedDate } from '../../../common/utils';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { ReportsStatisticsResponse } from '../model/ReportsStatisticsResponse';
import { useReportStatisticsItemState } from '../useReportStatisticsItemState';
import { TableItem } from './TableHelper';

const getRelevoIcon = (category: string) => (category?.toLowerCase() === 'cups' ? cup : bowl);

type ListItemProps = {
  data: ReportsStatisticsResponse;
};

const ListItem = ({ data }: ListItemProps): JSX.Element => {
  const { returnRate, returnTime } = useReportStatisticsItemState(data);
  const { colors } = useTheme();
  const { language } = useLocalizationContext();

  return (
    <>
      <HStack pl={5} pb={1} alignItems="center">
        <TableItem width="15%" text={data.locationName} />
        <TableItem width="7%" text={getFormattedDate(data.startDate).length ? getFormattedDate(data.startDate) : '-'} />
        <TableItem width="7%" text={getFormattedDate(data.endDate).length ? getFormattedDate(data.endDate) : '-'} />
        <TableItem width="10%" text={data.totalAccountBorrowings.toString()} />
        <TableItem
          width="5%"
          text={
            data.dishesSummary && data.dishesSummary.length > 0 ? data.dishesSummary[0].totalBorrowings.toString() : '0'
          }
        />
        <div style={{ width: '10%', textAlign: 'left' }}>
          {data.dishesSummary && data.dishesSummary.length > 0 && (
            <HStack>
              <Image
                src={getRelevoIcon(
                  data.dishesSummary && data.dishesSummary.length > 0 ? data.dishesSummary[0].categoryGroup : '0',
                )}
                maxHeight={7}
                width={7}
                alt="Relevo"
                display="inline-block"
              />
              <h2
                style={{
                  fontSize: '12px',
                  color: colors.black,
                }}
              >
                {data.dishesSummary[0].categoryGroup?.toLowerCase() === 'cups' ? 'CUP' : 'BOWL'}
              </h2>
            </HStack>
          )}
          {data.dishesSummary?.length === 0 && (
            <h2
              style={{
                fontSize: '12px',
                color: colors.black,
              }}
            >
              -
            </h2>
          )}
        </div>
        <TableItem
          width="10%"
          text={
            data.dishesSummary && data.dishesSummary.length > 0 ? data.dishesSummary[0].translations[language] : '-'
          }
        />
        <TableItem width="10%" text={data.totalAccountUsers.toString()} />
        <TableItem width="10%" text={returnRate} />
        <TableItem width="10%" text={returnTime} />
      </HStack>

      {data?.dishesSummary?.slice(1, data.dishesSummary.length).map((dishSummary) => (
        <HStack key={dishSummary.id} pl={5} pb={1} alignItems="center">
          <TableItem width="15%" />
          <TableItem width="7%" />
          <TableItem width="7%" />
          <TableItem width="10%" />
          <TableItem width="5%" text={dishSummary.totalBorrowings.toString()} />
          <div style={{ width: '10%', textAlign: 'left' }}>
            <HStack>
              <Image
                src={getRelevoIcon(dishSummary.categoryGroup)}
                maxHeight={7}
                width={7}
                alt="Relevo"
                display="inline-block"
              />
              <h2
                style={{
                  fontSize: '12px',
                  color: colors.black,
                }}
              >
                {dishSummary.categoryGroup?.toLowerCase() === 'cups' ? 'CUP' : 'BOWL'}
              </h2>
            </HStack>
          </div>
          <TableItem width="10%" text={dishSummary.translations[language]} />
          <TableItem width="10%" />
          <TableItem width="10%" />
          <TableItem width="10%" />
        </HStack>
      ))}

      <Divider color={colors.grey[200]} />
    </>
  );
};

export default ListItem;
