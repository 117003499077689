import React, { JSX } from 'react';

type Props = {
  label: string;
};

const ReportsLabel = ({ label }: Props): JSX.Element => {
  return <h2 style={{ textAlign: 'left' }}>{label}</h2>;
};

export default ReportsLabel;
