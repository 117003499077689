import { JSX, useState } from 'react';
import Select from 'react-select';
import { CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';

import { SelectOption } from '../../../../../common/components/Select/models/select-option';
import { selectStyle } from '../../../../../common/components/Select/select-styles';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useUpdateRestaurantInventoryCount } from '../../../../../services/inventory-counts';
import { InventoryCountStatus } from '../../models/enums/inventory-count-status.enum';
import { getInventoryCountStatusTranslationKey } from '../../utils/inventory-counts.helper';

interface InventoryCountsStatusUpdateProps {
  inventoryCountId: string;
  status?: InventoryCountStatus;
}

export const InventoryCountsStatusUpdate = ({
  inventoryCountId,
  status,
}: InventoryCountsStatusUpdateProps): JSX.Element => {
  const translations = useTranslations();
  const [isEditMode, setIsEditMode] = useState(false);

  const { mutate: updateRestaurantInventoryCountStatus } = useUpdateRestaurantInventoryCount();
  const handleEditMode = () => setIsEditMode(!isEditMode);

  const handleUpdateStatus = (selectedStatus: any) => {
    updateRestaurantInventoryCountStatus({
      inventoryCountId,
      values: {
        status: selectedStatus.value,
      },
    });
    handleEditMode();
  };
  const inventoryCountStatusSelectOptions: SelectOption[] = Object.values(InventoryCountStatus).map((value) => {
    return {
      value,
      label: translations(getInventoryCountStatusTranslationKey(value)),
    };
  });

  return (
    <>
      {isEditMode ? (
        <Box>
          <Box display="none">
            <label htmlFor="status">{translations('restaurant_return_codes_table_status')}</label>
          </Box>
          <HStack minW={'200px'}>
            <Select
              name="status"
              inputId="status"
              menuPortalTarget={document.body}
              styles={selectStyle}
              defaultValue={inventoryCountStatusSelectOptions.find((option) => option.value === status)}
              getOptionLabel={(option) => option.label}
              onChange={handleUpdateStatus}
              options={inventoryCountStatusSelectOptions}
            />
            <Tooltip label={translations('cancel')}>
              <CloseIcon cursor="pointer" color={'red.500'} onClick={handleEditMode} ml={2} w={3} />
            </Tooltip>
          </HStack>
        </Box>
      ) : (
        <HStack justifyContent="center">
          <Text>{status ? translations(getInventoryCountStatusTranslationKey(status)) : null}</Text>
          <Tooltip label={translations('edit')}>
            <EditIcon data-testid="edit-icon" cursor="pointer" onClick={handleEditMode} ml={2} />
          </Tooltip>
        </HStack>
      )}
    </>
  );
};
