import React, { JSX, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, useTheme } from '@chakra-ui/react';
import {
  ErrorMessages,
  FullPageLoader,
  ImageOrderBox,
  MainPageContent,
  PageHeading,
  useToast,
} from 'common/components';
import { useExecuteWithConfirmation } from 'common/utils';
import { useTranslations } from 'contexts/LocalizationContext';
import { useChangeOrderRestaurantImage, useDeleteRestaurantImage, useRestaurantImagesList } from 'services/restaurants';
import invariant from 'tiny-invariant';

import EditImages from '../../../common/components/Images/EditImages';
import { getSortedBoxesWithFiles } from '../../../common/utils/images.helper';
import { useModal } from '../../../contexts/ModalContext';
import { AddRestaurantImage } from './AddRestaurantImage';

const RestaurantImages = (): JSX.Element => {
  const { displayToast } = useToast();
  const { colors } = useTheme();
  const { restaurantId } = useParams<{ restaurantId: string }>();
  invariant(restaurantId, 'restaurantId is not set within the route');
  const translations = useTranslations();
  const { mutate: deleteImage } = useDeleteRestaurantImage(restaurantId);
  const { mutate: imagesOrderChange } = useChangeOrderRestaurantImage({
    onSuccessfulAssign: async (status) => {
      if (status === 200) {
        displayToast('success', translations('images_sort_success'), false, 5000);
      }
    },
  });
  const { executeWithConfirmation: onDeleteImage } = useExecuteWithConfirmation(
    translations('image_delete_confirmation'),
    deleteImage,
  );

  const { handleToggleModal } = useModal();

  const {
    data: images,
    isFetching: isFetchingImages,
    error: loadImagesListError,
  } = useRestaurantImagesList(restaurantId);

  const [boxes, setBoxes] = useState(images?.restaurantImages);

  const handleSaveImages = () => {
    if (boxes) {
      const boxesWithFiles = getSortedBoxesWithFiles(boxes);
      imagesOrderChange({
        restaurantId,
        restaurantImages: boxesWithFiles.map(({ file }, index) => ({ id: file!.id, order: index })) || [],
      });
    }
  };

  const handleDeleteImage = (id: string) => () => onDeleteImage(id);

  const keyAccountGroupImagesExist = images?.keyAccountGroupImages.length;

  useEffect(() => {
    setBoxes(images?.restaurantImages);
  }, [images]);

  return (
    <MainPageContent>
      <PageHeading>{translations('images_header')}</PageHeading>
      <ErrorMessages errors={[loadImagesListError]} />
      {keyAccountGroupImagesExist ? (
        <FullPageLoader show={isFetchingImages}>
          <Heading pb={8} textAlign="left" fontSize="20px">
            {translations('key_account_group_images_header')}
          </Heading>
          <Box
            border="2px"
            borderColor={colors.grey[50]}
            px={4}
            py={3}
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            gap={5}
          >
            <ImageOrderBox boxes={images?.keyAccountGroupImages} isEditOrderMode={false} />
          </Box>
        </FullPageLoader>
      ) : null}
      <Box mt={keyAccountGroupImagesExist ? 8 : 0}>
        {keyAccountGroupImagesExist ? (
          <Heading textAlign="left" fontSize="20px">
            {translations('restaurant_images_header')}
          </Heading>
        ) : null}
        <EditImages
          handleSaveImages={handleSaveImages}
          handleDeleteImage={handleDeleteImage}
          handleToggleModal={handleToggleModal}
          isFetchingImages={isFetchingImages}
          setImages={setBoxes}
          images={boxes}
        >
          <AddRestaurantImage restaurantId={restaurantId} />
        </EditImages>
      </Box>
    </MainPageContent>
  );
};

export default RestaurantImages;
