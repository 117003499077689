import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Switch } from '@chakra-ui/react';

import { FormGroup } from '../../../../../../common/components';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';

export const FormIsActive = () => {
  const translations = useTranslations();
  const { control } = useFormContext();

  return (
    <FormGroup label={translations('reuse_cards_is_active_label')} inputId="isActive">
      <Controller
        control={control}
        name="isActive"
        render={({ field: { value, onChange, ref } }) => (
          <Box>
            <Switch colorScheme="orange" id="isActive" isChecked={value} onChange={onChange} ref={ref} />
          </Box>
        )}
      />
    </FormGroup>
  );
};
