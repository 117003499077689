import React from 'react';
import { MainPageContent, PageHeading } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

const Home = () => {
  const translations = useTranslations();

  return (
    <MainPageContent>
      <PageHeading>{translations('home_header')}</PageHeading>
    </MainPageContent>
  );
};

export default Home;
