import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import {
  createClosingSource,
  deleteClosingSource,
  getClosingSource,
  getClosingSources,
  updateClosingSource,
} from '../api/closing-sources';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import {
  ClosingSource,
  ClosingSourceRequest,
  ClosingSourceResponse,
} from '../modules/ClosingSources/model/ClosingSource';

export const CK_CLOSING_SOURCE = 'closing_sources';

export const useClosingSourceList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
  enabled = true,
): QueryObserverResult<Paginated<ClosingSourceResponse>, AxiosError<HttpException>> =>
  useQuery(
    [CK_CLOSING_SOURCE, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getClosingSources(params),
    {
      keepPreviousData: true,
      enabled,
    },
  );

export const useClosingSource = (
  id: string,
  enabled = true,
): QueryObserverResult<ClosingSource, AxiosError<HttpException>> =>
  useQuery([CK_CLOSING_SOURCE, id], () => getClosingSource(id), { enabled });

export const useCreateClosingSource = (): UseMutationResult<
  ClosingSource,
  AxiosError<HttpException>,
  { values: ClosingSourceRequest }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values }) => createClosingSource(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CLOSING_SOURCE);
    },
  });
};

export const useUpdateClosingSource = (): UseMutationResult<
  ClosingSource,
  AxiosError<HttpException>,
  { values: ClosingSourceRequest; id: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values, id }) => updateClosingSource(values, id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CLOSING_SOURCE);
    },
  });
};

export const useDeleteClosingSource = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation((id: string) => deleteClosingSource(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CLOSING_SOURCE);
    },
  });
};
