/* eslint-disable import/prefer-default-export */
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { TagRequest } from 'modules/Tags/models/tag-request';
import { TagResponse } from 'modules/Tags/models/tag-response';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import axiosInstance from './axios';

export const getTags = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<TagResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance
    .get<Paginated<TagResponse>>(`${apiPaths.TAGS}?${searchParams}`)
    .then(({ data }: { data: Paginated<TagResponse> }) => data);
};

export const getTag = (id: string): Promise<TagResponse> =>
  axiosInstance.get<TagResponse>(`${apiPaths.TAGS}/${id}`).then(({ data }: { data: TagResponse }) => data);

export const createTag = (createTagRequest: TagRequest): Promise<TagResponse> =>
  axiosInstance.post(`${apiPaths.TAGS}`, createTagRequest);

export const updateTag = (id: string, updateTagRequest: TagRequest): Promise<TagResponse> =>
  axiosInstance.put(`${apiPaths.TAGS}/${id}`, updateTagRequest);

export const deleteTag = (id: string): Promise<void> => axiosInstance.delete(`${apiPaths.TAGS}/${id}`);
