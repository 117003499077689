import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import {
  createFaqQuestion,
  createFaqSection,
  deleteFaqQuestion,
  deleteFaqSection,
  getFaqList,
  getFaqQuestion,
  getFaqSection,
  updateFaqQuestion,
  updateFaqSection,
  updateOrderFaqList,
} from '../api/frequently-asked-questions';
import { HttpException } from '../common/models/httpException';
import {
  FaqQuestion,
  FaqQuestionRequest,
  FaqSection,
  FaqSectionRequest,
} from '../modules/FrequentlyAskedQuestions/models/frequently-asked-question.model';
import { FrequentlyAskedQuestionsReorderRequest } from '../modules/FrequentlyAskedQuestions/models/frequently-asked-questions-reorder-request.model';
import { FrequentlyAskedQuestionsResponse } from '../modules/FrequentlyAskedQuestions/models/frequently-asked-questions-response.model';

export const CK_FAQ = 'frequently-asked-questions';

export const useFaqList = (): QueryObserverResult<FrequentlyAskedQuestionsResponse, AxiosError<HttpException>> => {
  return useQuery([CK_FAQ], () => getFaqList(), {
    keepPreviousData: true,
  });
};

export const useUpdateOrderFaqList = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  { values: FrequentlyAskedQuestionsReorderRequest }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values }) => updateOrderFaqList(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_FAQ);
    },
  });
};

export const useDeleteFaqSection = (): UseMutationResult<void, AxiosError<HttpException>, { sectionId: string }> => {
  const queryClient = useQueryClient();
  return useMutation(({ sectionId }) => deleteFaqSection(sectionId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_FAQ);
    },
  });
};
export const useDeleteFaqQuestion = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  { sectionId: string; questionId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ sectionId, questionId }) => deleteFaqQuestion(sectionId, questionId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_FAQ);
    },
  });
};

export const useFaqSection = (
  id: string,
  enabled = true,
): QueryObserverResult<FaqSection, AxiosError<HttpException>> => {
  return useQuery([CK_FAQ, id], () => getFaqSection(id), { enabled });
};

export const useCreateFaqSection = (): UseMutationResult<FaqSection, AxiosError<HttpException>, FaqSectionRequest> => {
  const queryClient = useQueryClient();
  return useMutation(createFaqSection, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_FAQ);
    },
  });
};

export const useUpdateFaqSection = (): UseMutationResult<
  FaqSection,
  AxiosError<HttpException>,
  { id: string; values: FaqSectionRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id, values }: { id: string; values: FaqSectionRequest }) => updateFaqSection(id, values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_FAQ);
    },
  });
};

export const useFaqQuestion = (
  id: string,
  enabled = true,
): QueryObserverResult<FaqQuestion, AxiosError<HttpException>> => {
  return useQuery([CK_FAQ, id], () => getFaqQuestion(id), { enabled });
};

export const useCreateFaqQuestion = (
  sectionId: string,
): UseMutationResult<FaqQuestion, AxiosError<HttpException>, { values: FaqQuestionRequest }> => {
  const queryClient = useQueryClient();
  return useMutation(({ values }: { values: FaqQuestionRequest }) => createFaqQuestion(sectionId, values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_FAQ);
    },
  });
};

export const useUpdateFaqQuestion = (): UseMutationResult<
  FaqQuestion,
  AxiosError<HttpException>,
  { id: string; values: FaqQuestionRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id, values }: { id: string; values: FaqQuestionRequest }) => updateFaqQuestion(id, values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_FAQ);
    },
  });
};
