import { RestaurantOpeningHoursPeriodResponse } from 'modules/Restaurants/models/restaurant-opening-hours-period-response.model';

// eslint-disable-next-line import/prefer-default-export
export const formatOpeningHoursPeriods = (periods: RestaurantOpeningHoursPeriodResponse[] | null): string => {
  if (periods) {
    if (periods.length) {
      return periods.map((period) => `${period.openTime} - ${period.closeTime}`).join(' | ');
    }
    return 'closed';
  }
  return 'no data';
};
