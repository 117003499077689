import React, { JSX, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Input } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter, FormGroup, FullPageLoader, NumberInput } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import * as yup from 'yup';

import { CorporateFormValues } from './Corporate.model';

type CorporatesFormProps = {
  formData?: CorporateFormValues;
  isLoading: boolean;
  onSubmit: (values: CorporateFormValues) => void;
};

const MAX_TOTAL_EMPLOYEES = 1000000;

const CorporatesForm = ({ formData, isLoading, onSubmit }: CorporatesFormProps): JSX.Element => {
  const translations = useTranslations();
  const validationSchema = yup.object().shape({
    organisationName: yup
      .string()
      .required(translations('validation_required'))
      .max(191, translations('validation_too_long')),
    organisationAddress: yup.string().nullable().max(191, translations('validation_too_long')),
    totalEmployees: yup
      .string()
      .nullable()
      .test(
        'has-correct-value',
        translations('validation_incorrect_value'),
        (value) => !value || (Number(value) > 0 && Number(value) < MAX_TOTAL_EMPLOYEES),
      ),
  });

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  if (isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <form data-testid="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <FormGroup
        label={translations('corporates_form_name')}
        inputId="organisationName"
        validationError={errors.organisationName?.message}
      >
        <Input id="organisationName" {...register('organisationName')} />
      </FormGroup>
      <FormGroup
        label={translations('corporates_form_address')}
        inputId="organisationAddress"
        validationError={errors.organisationAddress?.message}
      >
        <Input id="organisationAddress" {...register('organisationAddress')} />
      </FormGroup>
      <FormGroup
        label={translations('corporates_form_total_employees')}
        inputId="totalEmployees"
        validationError={errors.totalEmployees?.message}
      >
        <NumberInput
          defaultValue={formData?.totalEmployees || undefined}
          name="totalEmployees"
          register={register('totalEmployees')}
          max={MAX_TOTAL_EMPLOYEES}
          min={0}
          precision={2}
        />
      </FormGroup>
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default CorporatesForm;
