import { useTranslations } from 'contexts/LocalizationContext';
import * as yup from 'yup';

import { Category } from '../../Categories/model/Category.model';
import { RestaurantResponse } from '../../Restaurants/models';

export type AssignProductsFromCsvFormType = {
  restaurant: RestaurantResponse | null;
  csvFile: Blob[] | null;
  category: Category | null;
};

export const useAssignProductsFromCsvFormValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    restaurant: yup.mixed().required(translations('validation_required')),
    csvFile: yup
      .mixed()
      .test(
        'is-correct-file',
        translations('validation_required'),
        (value) => value instanceof FileList && value?.length > 0,
      ),
  });
};

export const useMassAssignProductsFromCsvFormValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    restaurant: yup.mixed().required(translations('validation_required')),
    category: yup.mixed().required(translations('validation_required')),
    csvFile: yup
      .mixed()
      .test(
        'is-correct-file',
        translations('validation_required'),
        (value) => value instanceof FileList && value?.length > 0,
      ),
  });
};
