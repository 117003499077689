import { Routes } from 'config/routes';
import { Role } from 'contexts/SessionContext';

export type MenuItem = {
  translationKey: string;
  route?: Routes;
  iconName: string;
  subSections?: SubSection[];
  roles?: Role[];
};

export type SubSection = {
  translationKey: string;
  route: Routes;
  roles?: Role[];
};

const MENU_ITEMS: MenuItem[] = [
  {
    translationKey: 'menu_restaurants',
    iconName: 'restaurant_icon.svg',
    subSections: [
      {
        translationKey: 'menu_all_fnbs',
        route: Routes.RESTAURANTS_PATH,
      },
      {
        translationKey: 'menu_drafts',
        route: Routes.RESTAURANT_DRAFTS_PATH,
        roles: [Role.ADMINISTRATOR],
      },
      {
        translationKey: 'menu_import_restaurants',
        route: Routes.IMPORT_RESTAURANTS_PATH,
      },
      {
        translationKey: 'menu_warehouses',
        route: Routes.WAREHOUSES_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
      {
        translationKey: 'menu_tags',
        route: Routes.TAGS_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
      {
        translationKey: 'menu_cities',
        route: Routes.CITIES_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
      {
        translationKey: 'menu_closing_sources',
        route: Routes.CLOSING_SOURCES_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
      {
        translationKey: 'menu_partner',
        route: Routes.PARTNERS_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
      {
        translationKey: 'menu_key_account_groups',
        route: Routes.KEY_ACCOUNT_GROUPS_PATH,
      },
      {
        translationKey: 'menu_restaurant_users',
        route: Routes.RESTAURANT_USERS_PATH,
      },
      {
        translationKey: 'menu_customers',
        route: Routes.CUSTOMERS_PATH,
      },
    ],
  },
  {
    translationKey: 'menu_items',
    iconName: 'items_icon.svg',
    subSections: [
      {
        translationKey: 'menu_manage_items',
        route: Routes.PRODUCTS,
      },
      {
        translationKey: 'menu_assign_item',
        route: Routes.ASSIGN_ITEM,
      },
      {
        translationKey: 'menu_lost_items',
        route: Routes.LOST_ITEMS,
      },
      {
        translationKey: 'menu_categories',
        route: Routes.CATEGORIES_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
      {
        translationKey: 'menu_meta_categories',
        route: Routes.META_CATEGORIES_PATH,
        roles: [Role.ADMINISTRATOR],
      },
    ],
  },
  {
    translationKey: 'menu_user',
    iconName: 'user_icon.svg',
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    subSections: [
      {
        translationKey: 'menu_application_users',
        route: Routes.USERS_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
      {
        translationKey: 'menu_relevo_users',
        route: Routes.RELEVO_USERS_PATH,
        roles: [Role.ADMINISTRATOR],
      },
      {
        translationKey: 'menu_reuse_cards',
        route: Routes.REUSE_CARDS_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
    ],
  },
  {
    translationKey: 'menu_app',
    iconName: 'app_icon.svg',
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    subSections: [
      {
        translationKey: 'menu_push',
        route: Routes.NOTIFICATIONS_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
      {
        translationKey: 'menu_test_notifications',
        route: Routes.TEST_NOTIFICATIONS_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
      {
        translationKey: 'menu_news',
        route: Routes.NEWS_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
      {
        translationKey: 'menu_about_us',
        route: Routes.ABOUT_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
    ],
  },
  {
    translationKey: 'menu_reports',
    iconName: 'reports_icon.svg',
    route: Routes.REPORTS_PATH,
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
  },
  {
    translationKey: 'menu_rpp',
    iconName: 'rpp_icon.svg',
    roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
    subSections: [
      {
        translationKey: 'menu_rpp_faq',
        route: Routes.FREQUENTLY_ASKED_QUESTIONS_PATH,
        roles: [Role.ADMINISTRATOR, Role.STANDARD_USER],
      },
    ],
  },
];

export default MENU_ITEMS;
