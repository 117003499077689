import { CreateReuseCardUsersFormModel } from '../models/create-reuse-card-users-form-model';
import { ReuseCardUserUpdateRequest } from '../models/reuse-card-user-update-request';
import { ReuseCardUsersCreateRequest } from '../models/reuse-card-users-create-request';
import { UpdateReuseCardFormModel } from '../models/update-reuse-card-form-model';

export const mapCreateFormModelToReuseCardsCreateRequest = (
  form: CreateReuseCardUsersFormModel,
): ReuseCardUsersCreateRequest => {
  return {
    batchSize: Number(form.batchSize),
    monetaryValue: Number(form.monetaryValue),
    currency: form.currency,
    borrowingsLimit: Number(form.borrowingsLimit),
    activationRestaurantsIds: form.activationRestaurants.map((restaurant) => restaurant.id),
    activationFnbGroupsIds: form.activationFnbGroups.map((fnbGroup) => fnbGroup.id),
  };
};

export const mapUpdateFormModelToReuseCardUpdateRequest = (
  form: UpdateReuseCardFormModel,
): ReuseCardUserUpdateRequest => {
  return {
    isActive: form.isActive,
    monetaryValue: Number(form.monetaryValue),
    currency: form.currency,
    borrowingsLimit: Number(form.borrowingsLimit),
    activationRestaurantsIds: form.activationRestaurants.map((restaurant) => restaurant.id),
    activationFnbGroupsIds: form.activationFnbGroups.map((fnbGroup) => fnbGroup.id),
  };
};
