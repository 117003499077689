import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { PartnerRequest, PartnerResponse, PartnersCreateBatchRequest } from 'modules/Partners/partner.model';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import axiosInstance from './axios';

export const getPartners = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<PartnerResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get<Paginated<PartnerResponse>>(`${apiPaths.PARTNERS}?${searchParams}`).then(({ data }) => data);
};

export const getPartner = (id: string): Promise<PartnerResponse> =>
  axiosInstance.get(`${apiPaths.PARTNERS}/${id}`).then(({ data }) => data);

export const createPartner = (values: PartnerRequest): Promise<PartnerResponse> =>
  axiosInstance.post(`${apiPaths.PARTNERS}`, values);
export const createPartnersBatch = (values: PartnersCreateBatchRequest): Promise<PartnerResponse[]> =>
  axiosInstance.post(`${apiPaths.PARTNERS}/batch`, values).then(({ data }) => data);

export const updatePartner = (id: string, values: PartnerRequest): Promise<PartnerResponse> =>
  axiosInstance.put(`${apiPaths.PARTNERS}/${id}`, values);

export const deletePartner = (id: string): Promise<void> => axiosInstance.delete(`${apiPaths.PARTNERS}/${id}`);
