import React from 'react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';

import colors from '../../../../../styles/colors';
import { Diff } from './Diff';
import { DiffProps } from './types';

export const BooleanDiff = ({ before = false, after = false, showDiff, isChanged }: DiffProps<boolean>) => {
  const hasChanged = before !== after;

  const checkIcon = (lowOpacity: boolean = false) => (
    <Flex
      justifyContent="center"
      alignItems="center"
      padding={2}
      borderRadius="50%"
      opacity={lowOpacity ? 0.3 : 1}
      color={colors.white}
      background={colors.green[500]}
    >
      <CheckIcon boxSize="3" />
    </Flex>
  );
  const closeIcon = (lowOpacity: boolean = false) => (
    <Flex
      justifyContent="center"
      alignItems="center"
      padding={2}
      borderRadius="50%"
      opacity={lowOpacity ? 0.3 : 1}
      color={colors.white}
      background={colors.red[500]}
    >
      <CloseIcon boxSize="3" />
    </Flex>
  );

  return (
    <Flex mt="2">
      <Diff
        isChanged={isChanged}
        showDiff={showDiff}
        before={
          <Box position="relative">
            {before ? checkIcon(hasChanged) : closeIcon(hasChanged)}
            {hasChanged && (
              <Box
                position="absolute"
                top="50%"
                left="-2px"
                width="calc(100% + 4px)"
                height="1px"
                zIndex="1"
                background={colors.grey[200]}
              />
            )}
          </Box>
        }
        after={<Text>{after ? checkIcon() : closeIcon()}</Text>}
      />
    </Flex>
  );
};
