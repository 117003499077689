import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Flex, Switch } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormGroup, NumberInput, useToast } from '../../../../../common/components';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useUpdateRestaurantTwoFactorReturnSettings } from '../../../../../services/two-factor-return';
import { RestaurantTwoFactorReturnSettingsResponse } from '../../models/restaurant-two-factor-return-settings-response';
import { UpdateRestaurantTwoFactorReturnSettingsRequest } from '../../models/update-restaurant-two-factor-return-settings-request';
import {
  TWO_FACTOR_RETURN_COMPLETION_DAYS_AMOUNT_MAX_VALUE,
  TWO_FACTOR_RETURN_COMPLETION_DAYS_AMOUNT_MIN_VALUE,
} from '../../utils/two-factor-return.consts';
import { TwoFactorReturnModeSelect } from '../TwoFactorReturnModeSelect/TwoFactorReturnModeSelect';
import { useTwoFactorReturnSettingsFormValidation } from './update-two-factor-return-validation-schema';

type UpdateTwoFactorReturnSettingsProps = {
  restaurantId: string;
  isEditMode: boolean;
  onEditModeChange: (isEditMode: boolean) => void;
  model?: RestaurantTwoFactorReturnSettingsResponse;
};

export const UpdateTwoFactorReturnSettings = ({
  restaurantId,
  model,
  isEditMode,
  onEditModeChange,
}: UpdateTwoFactorReturnSettingsProps) => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const handleDisableEditMode = () => onEditModeChange(false);
  const validationSchema = useTwoFactorReturnSettingsFormValidation();
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<RestaurantTwoFactorReturnSettingsResponse>({
    resolver: yupResolver(validationSchema),
  });

  const { mutate: updateRestaurantTwoFactorReturnSettings, isLoading: isSavingTwoFactorReturnSettings } =
    useUpdateRestaurantTwoFactorReturnSettings();

  const isFormUpdated = (form: RestaurantTwoFactorReturnSettingsResponse) =>
    form.isTwoFactorReturnEnabled !== model?.isTwoFactorReturnEnabled ||
    form.twoFactorReturnCompletionDaysAmount !== model?.twoFactorReturnCompletionDaysAmount ||
    form.mode !== model?.mode;

  const handleFormSubmit = handleSubmit(({ ...form }: RestaurantTwoFactorReturnSettingsResponse) => {
    if (!isFormUpdated(form)) {
      displayToast('info', translations('form_no_changes_info'), false, 1500);
      handleDisableEditMode();
    } else {
      const request: UpdateRestaurantTwoFactorReturnSettingsRequest = {
        isTwoFactorReturnEnabled: form.isTwoFactorReturnEnabled,
        twoFactorReturnCompletionDaysAmount: form.twoFactorReturnCompletionDaysAmount,
        mode: form.mode,
      };
      updateRestaurantTwoFactorReturnSettings(
        {
          id: restaurantId,
          updateRestaurantTwoFactorReturnSettingsRequest: request,
        },
        {
          onSuccess: () => {
            displayToast('success', translations('two_factor_return_update_success'), false, 4000);
            handleDisableEditMode();
          },
        },
      );
    }
  });

  useEffect(() => reset(model), [reset, model]);

  const handleCancel = () => {
    reset(model);
    handleDisableEditMode();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Flex textAlign="start" wrap="wrap" width="100%">
        <Box width={['100%', '50%', '50%', '50%', '33%']} p={2}>
          <FormGroup
            label={translations('restaurant_two_factor_table_enabled_label')}
            inputId="isTwoFactorReturnEnabled"
          >
            <Controller
              control={control}
              name="isTwoFactorReturnEnabled"
              render={({ field: { value, onChange, ref } }) => (
                <Box>
                  <Switch
                    colorScheme="orange"
                    id="isTwoFactorReturnEnabled"
                    isChecked={value}
                    onChange={onChange}
                    ref={ref}
                    isDisabled={!isEditMode}
                  />
                </Box>
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['100%', '50%', '50%', '50%', '33%']} p={2}>
          <FormGroup
            label={translations('restaurant_two_factor_mode_label')}
            labelTooltipText={translations('restaurant_two_factor_mode_tooltip_label')}
            inputId={'mode'}
          >
            <Controller
              control={control}
              name="mode"
              render={({ field: { value, name, onChange } }) => (
                <TwoFactorReturnModeSelect
                  selectedMode={value}
                  name={name}
                  onSelect={onChange}
                  isDisabled={!isEditMode}
                  inputId={'mode'}
                />
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['100%', '100%', '50%', '50%', '33%']} p={2}>
          <FormGroup
            label={translations('restaurant_two_factor_table_completion_days_label')}
            inputId="twoFactorReturnCompletionDaysAmount"
            validationError={errors.twoFactorReturnCompletionDaysAmount?.message}
          >
            <Controller
              control={control}
              name="twoFactorReturnCompletionDaysAmount"
              render={({ field: { value, onChange } }) => (
                <NumberInput
                  name="twoFactorReturnCompletionDaysAmount"
                  register={register('twoFactorReturnCompletionDaysAmount')}
                  min={TWO_FACTOR_RETURN_COMPLETION_DAYS_AMOUNT_MIN_VALUE}
                  max={TWO_FACTOR_RETURN_COMPLETION_DAYS_AMOUNT_MAX_VALUE}
                  precision={0}
                  isDisabled={!isEditMode}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </FormGroup>
        </Box>
      </Flex>
      <Flex justifyContent="flex-start">
        {isEditMode ? (
          <>
            <Button mr={2} type="submit" isLoading={isSavingTwoFactorReturnSettings}>
              {translations('save')}
            </Button>
            <Button onClick={handleCancel}>{translations('cancel')}</Button>
          </>
        ) : null}
      </Flex>
    </form>
  );
};
