import { QRCodeCanvas } from '@cheprasov/qrcode';

import { RELEVO_CHARACTERS } from '../constants/common';
import { PRODUCT_U_ID_LENGTH, RETURN_U_ID_LENGTH } from '../constants/uId.consts';
import { UIdType } from '../models/enums/uIdType';

export const getQRCodeSrc = (qrCode: string): string => new QRCodeCanvas(qrCode, { level: 'Q' }).toDataURL();

export const isEnhancedUId = (uId: string, uIdType: UIdType): boolean => {
  const extractedNumbers = uId.replace(/[^0-9]/g, '');
  const sumOfExtractedNumbers = Array.from(extractedNumbers)
    .map((el) => Number(el))
    .reduce((previousSumValue, currentNumber) => {
      return previousSumValue + currentNumber;
    }, 0);
  return (
    uId.length === (uIdType === UIdType.PRODUCT ? PRODUCT_U_ID_LENGTH : RETURN_U_ID_LENGTH) &&
    sumOfExtractedNumbers % 3 === 1 &&
    Number.isInteger(Number(uId.substring(2, 3))) &&
    Array.from(RELEVO_CHARACTERS).includes(uId.substring(uId.length - 3, uId.length - 2))
  );
};

const isValidUrl = (url: string): boolean => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const extractUId = (qrCode: string): string | null => {
  if (isValidUrl(qrCode)) {
    return new URL(qrCode).searchParams.get('uid');
  }
  return qrCode;
};
