import React, { JSX, useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertStatus, AlertTitle, CloseButton } from '@chakra-ui/react';

type BannerProps = {
  status: AlertStatus | (() => AlertStatus);
  title?: React.ReactNode;
  customIcon?: React.ReactNode;
  isClosable?: boolean;
  children: React.ReactNode | React.ReactNode[];
};

export const Banner = ({ status, title, customIcon, isClosable, children }: BannerProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => setIsVisible(true), [status]);

  return (
    <>
      {isVisible && (
        <Alert status={typeof status === 'string' ? status : status()}>
          {customIcon ? <>{customIcon}</> : <AlertIcon />}
          {title && <AlertTitle mr={2}>{title}</AlertTitle>}
          <AlertDescription>{children}</AlertDescription>
          {isClosable && (
            <CloseButton
              position="absolute"
              right="8px"
              top="50%"
              transform="translateY(-50%)"
              onClick={() => setIsVisible(false)}
            />
          )}
        </Alert>
      )}
    </>
  );
};
