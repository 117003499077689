/* eslint-disable import/prefer-default-export */
import React, { JSX } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from '@rollbar/react';
import { AppLayout } from 'common/components';
import { initSuperTokens } from 'common/utils';
import { LocalizationContextProvider } from 'contexts/LocalizationContext';
import { SessionContextProvider } from 'contexts/SessionContext';
import { Configuration } from 'rollbar';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react';
import { EmailPasswordAuth } from 'supertokens-auth-react/lib/build/recipe/emailpassword';

import './App.css';

import ProtectedRoute from './common/components/ProtectedRoute';
import ScanditSDKContextProvider from './common/components/Scanner/ScanditSDKContext';
import { ROUTER_ITEMS } from './config/router-items';
import { BackgroundRequestsContextProvider } from './contexts/BackgroundRequestsContext/BackgroundRequestsContext';
import { ModalProvider } from './contexts/ModalContext/';
import Home from './modules/Home';
import Fonts from './styles/fonts';
import theme from './styles/theme';

initSuperTokens();
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const rollbarConfig: Configuration | (() => Configuration) = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_ENV,
  enabled: !!process.env.REACT_APP_ENV && ['development', 'production'].includes(process.env.REACT_APP_ENV),
  payload: {
    client: {
      javascript: {
        code_version: process.env.REACT_APP_VERSION, // This variable is provided by CI/CD with current hash commit
        source_map_enabled: true,
      },
    },
  },
};

export const App = (): JSX.Element => (
  <Provider config={rollbarConfig}>
    <ModalProvider>
      <ChakraProvider theme={theme}>
        <Fonts />
        <LocalizationContextProvider>
          <QueryClientProvider client={queryClient}>
            <BackgroundRequestsContextProvider>
              <ScanditSDKContextProvider>
                <Router>
                  <Routes>
                    {/* eslint-disable-next-line @typescript-eslint/no-var-requires,global-require */}
                    {getSuperTokensRoutesForReactRouterDom(require('react-router-dom'))}
                    {ROUTER_ITEMS.map(({ path, element, roles }) => (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <EmailPasswordAuth>
                            <SessionContextProvider>
                              <AppLayout>
                                <ProtectedRoute roles={roles} element={element} />
                              </AppLayout>
                            </SessionContextProvider>
                          </EmailPasswordAuth>
                        }
                      />
                    ))}
                    <Route
                      path="*"
                      element={
                        <EmailPasswordAuth>
                          <SessionContextProvider>
                            <AppLayout>
                              <Home />
                            </AppLayout>
                          </SessionContextProvider>
                        </EmailPasswordAuth>
                      }
                    />
                  </Routes>
                </Router>
              </ScanditSDKContextProvider>
            </BackgroundRequestsContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </LocalizationContextProvider>
      </ChakraProvider>
    </ModalProvider>
  </Provider>
);
