import React, { JSX } from 'react';
import { Box } from '@chakra-ui/react';

type ProductsManagementPanelSectionProps = {
  children: React.ReactNode;
};

export const ProductsManagementPanelSection = ({ children }: ProductsManagementPanelSectionProps): JSX.Element => (
  <Box w="50%" minWidth="500px" borderWidth="2px" borderRadius="4px" p={3} mb={1}>
    {children}
  </Box>
);
