import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { makeAllPayments, updateAllUsersBTPaymentsInfo } from '../api/payments';
import { HttpException } from '../common/models/httpException';

export const useMakeAllPayments = (): UseMutationResult<void, AxiosError<HttpException>> => {
  return useMutation(() => {
    return makeAllPayments();
  });
};

export const useUpdateAllUsersBTPaymentsInfo = (): UseMutationResult<void, AxiosError<HttpException>> => {
  return useMutation(() => {
    return updateAllUsersBTPaymentsInfo();
  });
};
