import React, { ChangeEvent } from 'react';
import { ArrowUpIcon } from '@chakra-ui/icons';
import { Button, Icon, Image, InputGroup } from '@chakra-ui/react';

import { LinkButton } from '../../../../../common/components';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useUpdateRestaurantInventoryCount } from '../../../../../services/inventory-counts';

const getIconPath = (iconName: string) => require(`../../../../../assets/icons/${iconName}`);

type InventoryCountsInvoiceProps = {
  inventoryCountId: string;
  invoiceFileUrl: string | null;
};
export const InventoryCountsInvoice = ({ inventoryCountId, invoiceFileUrl }: InventoryCountsInvoiceProps) => {
  const translations = useTranslations();
  const { mutate: updateRestaurantInventoryCountStatus, isLoading: isUploadingInvoice } =
    useUpdateRestaurantInventoryCount();

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      updateRestaurantInventoryCountStatus({
        values: { invoice: event.target.files[0] },
        inventoryCountId,
      });
    }
  };

  const getRef = (element: HTMLInputElement) => {
    inputRef.current = element;
  };

  const handleClick = () => inputRef.current?.click();

  if (invoiceFileUrl) {
    return (
      <LinkButton data-testid={'invoice'} isDownload to={invoiceFileUrl} variant={'transparent'}>
        <Image cursor="pointer" src={getIconPath('pdf_icon.svg')} />
      </LinkButton>
    );
  } else {
    return (
      <InputGroup onClick={handleClick}>
        <input onChange={handleUpload} type="file" hidden accept={'application/pdf'} ref={getRef} />
        <Button isLoading={isUploadingInvoice} leftIcon={<Icon as={ArrowUpIcon} />}>
          {translations('upload')}
        </Button>
      </InputGroup>
    );
  }
};
