import { ReactElement } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';

import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { DiffProps } from './types';

export const Diff = ({ before, after, showDiff, isChanged }: DiffProps<ReactElement>) => {
  const translations = useTranslations();

  if (showDiff && !isChanged) {
    return (
      <Flex width="100%" justifyContent="center">
        {translations('unchanged')}
      </Flex>
    );
  }

  return (
    <>
      {showDiff ? (
        <Flex width="100%" justifyContent="center" alignItems="center">
          <Flex width="50%" pr={2} justifyContent="flex-end">
            {before}
          </Flex>
          <ArrowForwardIcon m={[0, 2]} />
          <Flex width="50%" pl={2} justifyContent="flex-start">
            {after}
          </Flex>
        </Flex>
      ) : (
        <Flex width="100%" justifyContent="center">
          {after}
        </Flex>
      )}
    </>
  );
};
