import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'config/routes';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCreateCorporate } from '../../services/corporates';
import { CorporateFormValues } from './Corporate.model';
import parseFormValuesForRequest from './Corporates.utils';
import CorporatesForm from './CorporatesForm';

const CreateCorporate = (): JSX.Element => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { mutate: createCorporate, isLoading } = useCreateCorporate();
  return (
    <>
      <ChildPageHeading>{translations('corporates_create_header')}</ChildPageHeading>
      <CorporatesForm
        isLoading={isLoading}
        onSubmit={(values: CorporateFormValues) => {
          const parsedData = parseFormValuesForRequest(values);
          createCorporate(
            { values: parsedData },
            {
              onSuccess: () => navigate(Routes.CORPORATES_PATH),
            },
          );
        }}
      />
    </>
  );
};

export default CreateCorporate;
