import React, { JSX } from 'react';
import { generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button, Flex, useTheme } from '@chakra-ui/react';
import { AddEditItemLink, ErrorMessages, LinkButton, MainPageContent, PageHeading, Table } from 'common/components';
import { useSearchCancelQuery } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { CK_RELEVO_USERS, useDeleteRelevoUser, useRelevoUsersList } from 'services/relevo-users';

import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { RelevoUserResponse } from './models';

const RelevoUsers = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const { colors } = useTheme();

  const {
    data,
    isFetching,
    error: loadRelevoUsersListError,
  } = useRelevoUsersList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  useSearchCancelQuery(searchText, CK_RELEVO_USERS);

  const { mutate: deleteRelevoUser, isLoading, error: deleteRelevoUserError } = useDeleteRelevoUser();

  const onDeleteClicked = React.useCallback(
    (id: string, email: string) => {
      const confirmed = window.confirm(translations('relevo_user_delete_confirmation', { '{{user-email}}': email }));
      if (confirmed) {
        deleteRelevoUser({ id });
      }
    },
    [translations, deleteRelevoUser],
  );

  const columns = React.useMemo(
    () => [
      {
        id: '1',
        Header: translations('relevo_users_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('relevo_users_email'),
        accessor: 'email' as const,
      },
      {
        id: '3',
        Header: translations('relevo_users_admin_role'),
        accessor: 'adminRole' as const,
      },
      {
        id: '4',
        Header: translations('relevo_users_partner_role'),
        accessor: 'partnerRole' as const,
      },
      {
        id: 'actions',
        Header: translations('relevo_users_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RelevoUserResponse>) => (
          <Flex>
            <LinkButton
              m={1}
              to={generatePath(Routes.UPDATE_RELEVO_USER_PATH, { id: original.id })}
              color={colors.orange[500]}
            >
              {translations('relevo_users_edit_button')}
            </LinkButton>
            <Button m={1} onClick={() => onDeleteClicked(original.id, original.email)}>
              {translations('delete')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, onDeleteClicked, colors],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('relevo_users_header')}</PageHeading>
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_RELEVO_USER_PATH}>{translations('relevo_users_add_button')}</AddEditItemLink>
      </Flex>
      <ErrorMessages errors={[loadRelevoUsersListError, deleteRelevoUserError]} />
      <Table<RelevoUserResponse>
        isLoading={isFetching || isLoading}
        data={data?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('relevo_users_search_box')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default RelevoUsers;
