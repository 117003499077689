import React, { JSX, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, FormControl, FormLabel, Image } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileInput, FormFooter, FormGroup, FullPageLoader, Select } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../common/components/FormInput/FormInput';
import {
  ImagePreviewValidation,
  isFileTooLarge,
} from '../../common/components/ImagePreviewValidation/ImagePreviewValidation';
import { Language } from '../../common/utils';
import { MetaCategory, MetaCategoryFormValues, MetaCategoryType } from './model/MetaCategory';
import { useMetaCategoryFormValidationSchema } from './MetaCategoriesForm.form';

type MetaCategoriesFormProps = {
  formData?: MetaCategory;
  isLoading: boolean;
  onSubmit: (values: MetaCategoryFormValues) => void;
};

type MetaCategoryTypeSelect = {
  label: MetaCategoryType | string;
  value: MetaCategoryType | string;
};

const selectOptions: MetaCategoryTypeSelect[] = [
  { value: MetaCategoryType.STANDARD, label: MetaCategoryType.STANDARD },
  { value: MetaCategoryType.HIDDEN, label: MetaCategoryType.HIDDEN },
  { value: MetaCategoryType.SPECIAL, label: MetaCategoryType.SPECIAL },
];

const MetaCategoriesForm = ({ formData, isLoading, onSubmit }: MetaCategoriesFormProps): JSX.Element => {
  const translations = useTranslations();
  const validationSchema = useMetaCategoryFormValidationSchema();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    // TODO: According to react-hook-form release note, you don't have to provide the type when using the useForm function
    //  because it automatically infers the types from the Yup schema
    //  https://github.com/react-hook-form/resolvers/releases/tag/v3.1.1
  } = useForm<MetaCategoryFormValues>({
    resolver: yupResolver(validationSchema),
  });

  const imageNamePreview: string | undefined = watch('imageTemporary')?.[0]?.name;
  const imageSizePreview: number | undefined = watch('imageTemporary')?.[0]?.size;

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  if (isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  const handleDeleteUploadedImage = () => setValue('imageTemporary', undefined);

  return (
    <form data-testid="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {Object.values(Language).map((language) => (
        <Box key={language}>
          <FormInput
            id={`translations.${language}`}
            label={translations(`meta_categories_form_name_${language}`)}
            validationError={errors.translations ? errors.translations[language]?.message : undefined}
            useFormRegisterReturn={register(`translations.${language}`)}
            setValue={setValue}
          />
        </Box>
      ))}
      <FormInput
        id={'color'}
        label={translations('meta_categories_form_color')}
        validationError={errors.color?.message}
        useFormRegisterReturn={register('color')}
        setValue={setValue}
      />
      <FormGroup
        label={translations('meta_categories_form_type')}
        inputId="type"
        validationError={errors.type?.message}
      >
        <Controller
          control={control}
          name="type"
          render={({ field: { value, onChange, name } }) => (
            <Select<MetaCategoryTypeSelect>
              name={name}
              defaultValue={selectOptions[0]}
              value={selectOptions.find((option) => option.value === value)}
              getOptionLabel={(option) => (option as MetaCategoryTypeSelect).label}
              getOptionValue={(option) => (option as MetaCategoryTypeSelect).value}
              onChange={onChange}
              valueMapper={(v) => v?.value}
              options={selectOptions}
            />
          )}
        />
      </FormGroup>
      <FormControl>
        <FormLabel htmlFor="image">{translations('meta_categories_form_image')}</FormLabel>
        <Image id="image" src={getValues('image') ?? undefined} />
        <FileInput accept="image/*" register={register('imageTemporary')} aria-label="imageTemporary" />
        {imageNamePreview && imageSizePreview ? (
          <ImagePreviewValidation
            imageNamePreview={imageNamePreview}
            imageSizePreview={imageSizePreview}
            handleDeleteUploadedImage={handleDeleteUploadedImage}
          />
        ) : null}
      </FormControl>
      <FormFooter
        isLoadingSubmitResult={isLoading}
        isSubmitDisabled={imageSizePreview ? isFileTooLarge(imageSizePreview) : false}
      />
    </form>
  );
};

export default MetaCategoriesForm;
