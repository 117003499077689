import { GenerateProductsResponse } from '../Product.model';

export enum ActionTypes {
  TRIGGER_NEW_PRODUCTS_GENERATION,
  TRIGGER_FILTERED_PRODUCTS_CSV_GENERATION,
  NEW_PRODUCTS_SUCCESS,
  FILTERED_PRODUCTS_CSV_SUCCESS,
  GENERATION_ERROR,
  CSV_CREATION_ERROR,
  CLEAR_FILTER_CSV,
}

export type Action =
  | { type: ActionTypes.TRIGGER_NEW_PRODUCTS_GENERATION }
  | { type: ActionTypes.TRIGGER_FILTERED_PRODUCTS_CSV_GENERATION }
  | { type: ActionTypes.NEW_PRODUCTS_SUCCESS; payload: GenerateProductsResponse }
  | { type: ActionTypes.FILTERED_PRODUCTS_CSV_SUCCESS; payload: GenerateProductsResponse }
  | { type: ActionTypes.GENERATION_ERROR }
  | { type: ActionTypes.CSV_CREATION_ERROR }
  | { type: ActionTypes.CLEAR_FILTER_CSV };

export type ProductsManagementState = {
  isLoadingNewProducts: boolean;
  isLoadingProductsCSV: boolean;
  hasGenerationError: boolean;
  hasCSVCreationError: boolean;
  newProductsDownloadLinks: GenerateProductsResponse | null;
  productsCSVDownloadLink: GenerateProductsResponse | null;
};
