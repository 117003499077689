import React, { JSX, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormFooter, FullPageLoader } from '../../common/components';
import FormInput from '../../common/components/FormInput/FormInput';
import { isValidStringUrl } from '../../common/utils/url.helper';
import { useTranslations } from '../../contexts/LocalizationContext';
import { MAX_COMMON_LENGTH } from '../Restaurants/restaurant.consts';
import { ClosingSourceFormValues, ClosingSourceRequest } from './model/ClosingSource';

type ClosingSourceFormProps = {
  formData?: ClosingSourceFormValues;
  isLoading: boolean;
  onSubmit: (values: ClosingSourceRequest) => void;
};

const ClosingSourcesForm = ({ formData, isLoading, onSubmit }: ClosingSourceFormProps): JSX.Element => {
  const translations = useTranslations();
  const validationSchema = yup.object().shape({
    name: yup.string().required(translations('validation_required')).max(191, translations('validation_too_long')),
    googleDriveLink: yup
      .string()
      .nullable()
      .test('test url', translations('validation_incorrect_url_format'), (text) => {
        if (text?.trim().length === 0 || !text) {
          return true;
        }
        return text.trim().length > 0 && isValidStringUrl(text.trim());
      })
      .max(MAX_COMMON_LENGTH, translations('validation_too_long')),
  });

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  if (isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  const handleFormSubmit = (data: ClosingSourceFormValues) => {
    onSubmit({ ...data, googleDriveLink: data.googleDriveLink ?? null });
  };

  return (
    <form data-testid="form" onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
      <FormInput
        id={'name'}
        label={translations('closing_sources_form_name')}
        validationError={errors.name?.message}
        useFormRegisterReturn={register('name')}
        setValue={setValue}
      />
      {/* <FormInput */}
      {/*   id={'googleDriveLink'} */}
      {/*   label={translations('google_drive_link')} */}
      {/*   validationError={errors.googleDriveLink?.message} */}
      {/*   useFormRegisterReturn={register('googleDriveLink')} */}
      {/*   setValue={setValue} */}
      {/* /> */}
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default ClosingSourcesForm;
