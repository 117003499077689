import React, { JSX, useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { Button, Flex } from '@chakra-ui/react';
import {
  AddEditItemLink,
  BooleanValueIcon,
  ErrorMessages,
  MainPageContent,
  PageHeading,
  Table,
} from 'common/components';
import { useExecuteWithConfirmation } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useDeletePartner, usePartnersList } from 'services/partners';

import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { PartnerResponse } from './partner.model';
import { mappedRestaurantsColumnElement } from './Partners.utils';

const HTTP_FORBIDDEN_STATUS = 403;

const Partners = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const [page, setPage] = useState(1);
  const {
    data,
    isFetching,
    error: loadPartnersListError,
  } = usePartnersList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });
  const { mutate: deletePartner, error: deletePartnerError } = useDeletePartner();
  const { executeWithConfirmation: handlePartnerDelete } = useExecuteWithConfirmation(
    translations('partners_delete_confirmation'),
    deletePartner,
  );

  useEffect(() => {
    if (deletePartnerError?.response?.status === HTTP_FORBIDDEN_STATUS) {
      window.alert(translations('partners_delete_error'));
    }
  }, [deletePartnerError, translations]);

  const columns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('partners_table_partner_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('partners_table_partner_name'),
        accessor: 'partnerName' as const,
      },
      {
        id: '3',
        Header: translations('partners_table_api_key'),
        accessor: 'apiKey' as const,
      },
      {
        id: '4',
        Header: translations('partners_table_enabled'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<PartnerResponse>) => <BooleanValueIcon value={original.isEnabled} />,
      },
      {
        id: '5',
        Header: translations('partners_table_restaurants'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<PartnerResponse>) => <>{mappedRestaurantsColumnElement(original.restaurants)}</>,
      },
      {
        id: 'actions',
        Header: translations('partners_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<PartnerResponse>) => (
          <Flex>
            <AddEditItemLink to={`${Routes.PARTNERS_PATH}/${original.id}`} m={1} isEdit />
            <Button m={1} onClick={() => handlePartnerDelete(original.id)}>
              {translations('delete')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, handlePartnerDelete],
  );
  return (
    <MainPageContent>
      <PageHeading>{translations('partners_header')}</PageHeading>
      <ErrorMessages errors={[loadPartnersListError, deletePartnerError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_PARTNERS_BATCH_PATH} mr={2}>
          {translations('partners_add_batch')}
        </AddEditItemLink>
        <AddEditItemLink to={Routes.CREATE_PARTNER_PATH}>{translations('partners_add')}</AddEditItemLink>
      </Flex>
      <Table<PartnerResponse>
        data={data?.items}
        columns={columns}
        isLoading={isFetching}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('partners_searchbox_placeholder')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default Partners;
