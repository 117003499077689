import { Language } from 'common/utils';
import { useTranslations } from 'contexts/LocalizationContext';
import * as yup from 'yup';

import { EmailInputType, NotificationReceiversType, NotificationType } from './Notifications.model';

// const isEmail = (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

const useSendNotificationValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();
  const dynamicLanguageValidation = Object.values(Language).reduce<
    Record<string, yup.StringSchema<string | undefined | null>>
  >((acc, curr) => {
    acc[`title_${curr}`] = yup
      .string()
      .nullable()
      .max(300)
      .required(translations('validation_required'))
      .when('notificationType', {
        is: (value: NotificationType) => value === NotificationType.IN_APP,
        then: (schema) => schema.max(60, translations('validation_too_long')),
      });
    acc[`body_${curr}`] = yup
      .string()
      .nullable()
      .max(5000)
      .required(translations('validation_required'))
      .when('notificationType', {
        is: (value: NotificationType) => value === NotificationType.IN_APP,
        then: (schema) => schema.max(500, translations('validation_too_long')),
      });

    acc[`buttonText_${curr}`] = yup
      .string()
      .nullable()
      .when('notificationType', {
        is: (value: NotificationType) => value === NotificationType.IN_APP,
        then: (schema) =>
          schema.required(translations('validation_required')).max(25, translations('validation_too_long')),
      });

    acc[`link_${curr}`] = yup.string().nullable().max(200);

    return acc;
  }, {});

  return yup.object().shape({
    notificationType: yup
      .string()
      .oneOf([...Object.values(NotificationType)])
      .default(NotificationType.DEFAULT),
    notificationReceiversType: yup.string().oneOf([...Object.values(NotificationReceiversType)]),
    emailInputType: yup
      .string()
      .nullable()
      .when('notificationReceiversType', {
        is: (value: NotificationReceiversType) => value === NotificationReceiversType.SELECTED_USERS,
        then: (schema) => schema.required(translations('validation_required')).oneOf(Object.values(EmailInputType)),
      }),
    selectedUsersList: yup
      .array()
      .nullable()
      .when(['notificationReceiversType', 'emailInputType'], {
        is: (receiversType: NotificationReceiversType, emailInputType: EmailInputType) =>
          receiversType === NotificationReceiversType.SELECTED_USERS && emailInputType === EmailInputType.SELECT,
        then: (schema) => schema.required(translations('validation_required')),
      }),
    inputMailList: yup
      .string()
      .nullable()
      .max(100000)
      .when(['notificationReceiversType', 'emailInputType'], {
        is: (receiversType: NotificationReceiversType, emailInputType: EmailInputType) =>
          receiversType === NotificationReceiversType.SELECTED_USERS && emailInputType === EmailInputType.TEXT,
        then: (schema) =>
          schema
            .test('is-not-empty', translations('validation_required'), (value) => !!value)
            .test(
              'is-each-item-email',
              translations('validation_only_email'),
              () => true,
              // !!value
              //   ?.replace(/,\s*$/, '')
              //   .split(',')
              //   .map((mail: string) => mail.trim()),
              // .every(isEmail),
            ),
      }),
    ...dynamicLanguageValidation,
  });
};

export default useSendNotificationValidation;
