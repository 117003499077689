import React from 'react';

import { useToast } from '../../../../common/components';
import { ChildPageHeading } from '../../../../common/components/ChildPageHeading';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useCreateReuseCardUsers } from '../../../../services/reuse-cards';
import { CreateReuseCardUsersFormModel } from '../../models/create-reuse-card-users-form-model';
import { useCreateReuseCardsResult } from '../../utils/hooks/use-create-reuse-cards-result.hook';
import { mapCreateFormModelToReuseCardsCreateRequest } from '../../utils/reuse-cards.helper';
import { CreateReuseCardsForm } from './components/CreateReuseCardsForm/CreateReuseCardsForm';

export const CreateReuseCards = () => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const { mutate: createReuseCardUsers, isLoading: isCreatingReuseCardUsers } = useCreateReuseCardUsers();
  const { handleSuccess, handleError } = useCreateReuseCardsResult();

  const handleCreateReuseCards = (form: CreateReuseCardUsersFormModel) => {
    if (!form.activationRestaurants.length && !form.activationFnbGroups.length) {
      displayToast('error', translations('reuse_cards_activation_location_missing_error'));
    } else {
      const request = mapCreateFormModelToReuseCardsCreateRequest(form);
      createReuseCardUsers(
        { request },
        {
          onError: (error) => handleError(error),
          onSuccess: (data) => handleSuccess(data, form.batchSize),
        },
      );
    }
  };

  return (
    <>
      <ChildPageHeading>{translations('reuse_cards_create_header')}</ChildPageHeading>
      <CreateReuseCardsForm onSubmit={handleCreateReuseCards} isLoading={isCreatingReuseCardUsers} />
    </>
  );
};
