import React, { ChangeEvent, JSX, useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Box, Flex, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { FilterProperties, ProductsFilterParams } from 'api/products';
import { config } from 'config/config';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCategoriesList } from 'services/categories';

import '../../styles/DatePicker.css';

import usePaginationWithUrl from '../../common/components/Table/Pagination/hooks/use-pagination-with-url.hook';
import queryParamsToString from '../../common/components/Table/Table.utils';
import { getFormattedDate } from '../../common/utils';
import { DATE_PICKER_LOCALIZATION_CONFIG } from '../../config/configConsts';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { Category } from '../Categories/model/Category.model';
import { UnionKeys } from './Product.model';

type ProductsFilterProps = {
  onChange: (propertyName: UnionKeys<ProductsFilterParams>, propertyValue?: string) => void;
};

const ProductsFilter = ({ onChange }: ProductsFilterProps): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const radioConfig: { value: FilterProperties; label: string }[] = [
    { value: FilterProperties.PRODUCT_ID, label: translations('products_filter_product_id_label') },
    { value: FilterProperties.UID, label: translations('products_filter_product_uid_label') },
    { value: FilterProperties.CATEGORY_NAME, label: translations('products_filter_product_category_name_label') },
    { value: FilterProperties.CREATED_AT, label: translations('products_filter_product_created_at_label') },
  ];
  const [radioValue, setRadioValue] = useState<FilterProperties>(radioConfig[0].value);
  const [selectSearchText, setSelectSearchText] = useState('');
  const { data: categories, isLoading } = useCategoriesList({
    searchText: selectSearchText,
    limit: config.paginationSize,
    offset: 1,
  });
  const [createdAt, setCreatedAt] = useState(null);
  const { updateUrlOnPaginationChange } = usePaginationWithUrl();

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();
  // FYI: RELEVO-944
  const { uId, page } = Object.fromEntries(new URLSearchParams(search).entries());

  useEffect(() => {
    if (uId) {
      setRadioValue(FilterProperties.UID);
      onChange(FilterProperties.UID, uId);
    }
    // eslint-disable-next-line
  }, [uId]);

  const updateUrlOnSearch = (name: string, searchQuery = '') => {
    navigate({
      pathname: location.pathname,
      search: queryParamsToString({ paramValue: searchQuery, paramName: name, search }),
    });
  };

  const handleValueChange = (value?: string) => {
    updateUrlOnSearch(radioValue, value);
    if (page && page !== '1') {
      updateUrlOnPaginationChange(1);
    }
    onChange(radioValue, value);
  };

  const handleRadioValueChange = (value: FilterProperties) => {
    updateUrlOnSearch(radioValue, '');
    if (page && page !== '1') {
      updateUrlOnPaginationChange(1);
    }
    if (radioValue === FilterProperties.CREATED_AT) {
      setCreatedAt(null);
    }
    setRadioValue(value);
    onChange(value, '');
  };

  const handleInputValueChange = (e: ChangeEvent<HTMLInputElement>) => handleValueChange(e.target.value);

  const handleDateValueChange = (v: any) => {
    setCreatedAt(v);
    if (!v) {
      handleValueChange(v);
      return;
    }
    handleValueChange(getFormattedDate(v?.toISOString()));
  };

  const handleSelectValueChange = (value: Category | null) => {
    handleValueChange(value?.translations[language]);
  };

  return (
    <>
      <Flex direction="column" w="50%" minWidth="500px">
        <Box mb={2}>
          <RadioGroup onChange={handleRadioValueChange} value={radioValue}>
            <Stack direction="row">
              {radioConfig.map(({ label, value }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Box>
        <Box p={1} h={12} w="50%" minWidth="500px">
          {radioValue === FilterProperties.PRODUCT_ID && (
            <Input
              onChange={handleInputValueChange}
              placeholder={translations('products_filter_product_id_placeholder')}
            />
          )}
          {radioValue === FilterProperties.UID && (
            <Input
              value={uId}
              onChange={handleInputValueChange}
              placeholder={translations('products_filter_product_uid_placeholder')}
            />
          )}
          {radioValue === FilterProperties.CATEGORY_NAME && (
            <Select
              id="category-name-select"
              placeholder={translations('products_filter_select_placeholder')}
              getOptionLabel={(option: Category) => option.translations[language]}
              getOptionValue={(option: Category) => option.id}
              onChange={handleSelectValueChange}
              onInputChange={setSelectSearchText}
              options={categories?.items}
              isLoading={isLoading}
            />
          )}
          {radioValue === FilterProperties.CREATED_AT && (
            <DatePicker
              locale={DATE_PICKER_LOCALIZATION_CONFIG.locale}
              format={DATE_PICKER_LOCALIZATION_CONFIG.dateFormat}
              value={createdAt}
              onChange={handleDateValueChange}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};

export default ProductsFilter;
