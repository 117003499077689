import React, { JSX } from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, HStack, Input } from '@chakra-ui/react';
import { FormFooter } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import { PartnerRole } from 'modules/RelevoUsers/types';

import FormInput from '../../common/components/FormInput/FormInput';
import { generatePassword } from '../../common/utils/helpers';
import LocationsForm from './LocationsForm';
import { RestaurantUserRequest, RestaurantUserResponse } from './models';
import RestaurantUserRoleForm from './RestaurantUserRoleForm';

type FormModel = RestaurantUserResponse & { password: string };

type RestaurantCredentialFormProps = {
  data?: RestaurantUserResponse;
  isLoading: boolean;
  onSubmit: (request: RestaurantUserRequest) => void;
  model?: RestaurantUserResponse;
};

const RestaurantCredentialForm = ({ isLoading, onSubmit, data }: RestaurantCredentialFormProps): JSX.Element => {
  const translations = useTranslations();

  const { register, handleSubmit, reset, control, setValue } = useForm<FormModel>({
    defaultValues: {
      role: PartnerRole.MANAGER,
    },
  });

  React.useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onFormSubmit = handleSubmit(({ ...form }: FormModel) => {
    onSubmit({
      ...form,
      locations: {
        restaurantIds: form.locations.restaurantIds?.map((r) => r.id) ?? [],
        keyAccountsGroupIds: form.locations.keyAccountsGroupIds?.map((k) => k.id) ?? [],
      },
    });
  });

  return (
    <form data-testid="form" onSubmit={onFormSubmit}>
      <FormInput
        id={'email'}
        label={translations('restaurants_credential_form_email')}
        useFormRegisterReturn={register('email')}
        setValue={setValue}
      />
      <HStack>
        <FormInput
          id={'firstName'}
          label={translations('restaurants_credential_form_first_name')}
          useFormRegisterReturn={register('firstName')}
          setValue={setValue}
        />
        <FormInput
          id={'lastName'}
          label={translations('restaurants_credential_form_last_name')}
          useFormRegisterReturn={register('lastName')}
          setValue={setValue}
        />
      </HStack>
      <FormControl>
        <Input hidden id="password" {...register('password')} value={generatePassword()} />
      </FormControl>
      <LocationsForm control={control} />
      <RestaurantUserRoleForm control={control} />
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default RestaurantCredentialForm;
