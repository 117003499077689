import React, { JSX } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { MainPageContent, PageHeading } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import invariant from 'tiny-invariant';

import { INDIVIDUAL_KEY_ACCOUNT_ID } from '../../Restaurants/restaurant.consts';
import { AddRestaurant } from './components/AddRestaurant/AddRestaurant';
import { ExportRestaurants } from './components/RestaurantsExport/ExportRestaurants';
import { MassEditRestaurants } from './components/RestaurantsMassEdit/MassEditRestaurants';
import { CreateReturnCodes } from './components/RestaurantsReturnCodes/CreateReturnCodes';
import { KeyAccountGroupRestaurantsTable } from './components/RestaurantsTable/KeyAccountGroupRestaurantsTable';
import { KeyAccountGroupRestaurantsContextProvider } from './context/KeyAccountGroupRestaurantsContext/KeyAccountGroupRestaurantsContext';

const KeyAccountGroupRestaurants = (): JSX.Element => {
  const translations = useTranslations();
  const { keyAccountId } = useParams<{ keyAccountId: string }>();
  invariant(keyAccountId, 'keyAccountId is not set within the route');

  return (
    <MainPageContent>
      <PageHeading>{translations('key_account_groups_restaurants_header')}</PageHeading>
      <KeyAccountGroupRestaurantsContextProvider>
        {keyAccountId !== INDIVIDUAL_KEY_ACCOUNT_ID ? (
          <Flex justifyContent="flex-end" wrap="wrap">
            <AddRestaurant keyAccountGroupId={keyAccountId} />
            <CreateReturnCodes keyAccountGroupId={keyAccountId} />
            <ExportRestaurants keyAccountGroupId={keyAccountId} />
            <MassEditRestaurants keyAccountGroupId={keyAccountId} />
          </Flex>
        ) : null}
        <KeyAccountGroupRestaurantsTable keyAccountId={keyAccountId} />
      </KeyAccountGroupRestaurantsContextProvider>
    </MainPageContent>
  );
};

export default KeyAccountGroupRestaurants;
