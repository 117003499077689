import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { getTestMessageTypeList, sendTestNotification } from '../api/test-notifications';
import { HttpException } from '../common/models/httpException';
import {
  SendTestNotificationRequest,
  TestMessageTypeResponse,
} from '../modules/Notifications/TestNotifications/model/TestNotification.model';

export const CK_TEST_NOTIFICATIONS = 'test-notifications';

export const useGetTestMessageTypeList = (): QueryObserverResult<
  TestMessageTypeResponse[],
  AxiosError<HttpException>
> => useQuery([CK_TEST_NOTIFICATIONS, {}], () => getTestMessageTypeList(), {});

export const useSendTestNotification = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  { values: SendTestNotificationRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ values }) => sendTestNotification(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_TEST_NOTIFICATIONS);
    },
  });
};
