import React, { JSX, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Box, Switch } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormFooter, FormGroup, FullPageLoader } from '../../common/components';
import { config } from '../../config/config';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useKeyAccountGroupsList } from '../../services/key-account-groups';
import { KeyAccountGroup } from '../KeyAccountGroups/models/key-account-groups-response';
import { INDIVIDUAL_KEY_ACCOUNT_ID } from '../Restaurants/restaurant.consts';
import { PartnersCreateBatchValues } from './partner.model';

type PartnersBatchFormProps = {
  isLoading?: boolean;
  onSubmit: (values: PartnersCreateBatchValues) => void;
};

const CreatePartnersBatchForm = ({ isLoading, onSubmit }: PartnersBatchFormProps): JSX.Element => {
  const translations = useTranslations();

  const [keyAccountGroupsSearchText, setKeyAccountGroupsSearchText] = useState('');
  const validationSchema = yup.object().shape({
    isEnabled: yup.bool().required(),
    keyAccountGroups: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().required(),
          keyAccount: yup.string().required(),
        }),
      )
      .required()
      .test(
        'test keyAccountGroups',
        translations('validation_required'),
        (keyAccountGroups) => !!keyAccountGroups.length,
      ),
  });

  const { data: keyAccountGroups, isFetching: isFetchingKayAccounts } = useKeyAccountGroupsList({
    searchText: keyAccountGroupsSearchText,
    limit: config.paginationSize,
    offset: 1,
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { isEnabled: true, keyAccountGroups: [] },
  });

  if (isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <FormGroup label={translations('partners_form_enabled')} inputId="isEnabled">
        <Switch id="isEnabled" {...register('isEnabled')} />
      </FormGroup>
      <FormGroup
        label={translations('partners_form_key_account_groups')}
        inputId="KeyAccountGroups"
        validationError={(errors.keyAccountGroups as FieldError)?.message}
      >
        <Controller
          control={control}
          name="keyAccountGroups"
          render={({ field: { onChange, name } }) => (
            <Select
              inputId="KeyAccountGroups"
              placeholder={translations('partners_form_key_account_groups_placeholder')}
              name={name}
              isMulti
              getOptionLabel={(option: KeyAccountGroup) => option.keyAccount}
              getOptionValue={(option: KeyAccountGroup) => option.id}
              onChange={onChange}
              onInputChange={setKeyAccountGroupsSearchText}
              options={keyAccountGroups?.items.filter((k) => k.id !== INDIVIDUAL_KEY_ACCOUNT_ID)}
              isLoading={isFetchingKayAccounts}
            />
          )}
        />
      </FormGroup>
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default CreatePartnersBatchForm;
