import React from 'react';
import { Box } from '@chakra-ui/react';

import { Sidebar } from '../Navbar';
import { MenuMobile } from '../Navbar/Menu/MenuMobile';

export const SIDEBAR_WIDTH = '222px';
const LAYOUT_WIDTH = '75%';

export const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box display="flex" minHeight="100%">
      <Box minWidth={SIDEBAR_WIDTH} display={{ base: 'none', lg: 'block' }}>
        <Sidebar />
      </Box>
      <Box display={{ lg: 'none' }}>
        <MenuMobile />
      </Box>
      <Box p={4} minWidth={{ base: '100%', lg: LAYOUT_WIDTH }} width="100%">
        {children}
      </Box>
    </Box>
  );
};
