export const cutLongString = (str: string, range: number): string => {
  if (str.length >= range) {
    return `${str.slice(0, range)}...`;
  }
  return str;
};

export const generatePassword = (): string =>
  Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);

// FYI: object: any
// eslint-disable-next-line
export const isEmpty = (object: any): boolean => {
  for (const key in object) {
    if (object[key] instanceof Object) {
      if (!isEmpty(object[key])) {
        return false;
      }
    } else {
      if (object[key]) {
        return false;
      }
    }
  }
  return true;
};
