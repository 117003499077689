import React, { JSX, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { Flex } from '@chakra-ui/react';
import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { config } from 'config/config';
import { Routes } from 'config/routes';

import { useDebounce } from '../../common/hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { Role, useSessionContext } from '../../contexts/SessionContext';
import { useCategoriesList } from '../../services/categories';
import { Category } from './model/Category.model';
import { mapCategoryGroupToTranslation } from './utils/category.helper';

const Categories = (): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const { role } = useSessionContext();

  const {
    data,
    isFetching,
    error: loadCategoriesError,
  } = useCategoriesList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const columns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('categories_table_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('categories_table_name'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<Category>) => original.translations[language],
      },
      {
        id: '3',
        Header: translations('categories_table_category_group'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<Category>) => translations(mapCategoryGroupToTranslation(original.categoryGroup)),
      },
      ...(role === Role.ADMINISTRATOR
        ? [
            {
              id: 'actions',
              Header: translations('categories_table_actions'),
              Cell: ({
                cell: {
                  row: { original },
                },
              }: CellProps<Category>) => (
                <Flex>
                  <AddEditItemLink to={`${Routes.CATEGORIES_PATH}/${original.id}`} m={1} isEdit />
                </Flex>
              ),
            },
          ]
        : []),
    ],
    [translations, role, language],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('categories_header')}</PageHeading>
      <ErrorMessages errors={[loadCategoriesError]} />
      <Flex justifyContent="flex-end">
        {role === Role.ADMINISTRATOR ? (
          <AddEditItemLink to={Routes.CREATE_CATEGORY_PATH}>{translations('categories_add')}</AddEditItemLink>
        ) : null}
      </Flex>
      <Table<Category>
        data={data?.items}
        columns={columns}
        isLoading={isFetching}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('categories_searchbox_placeholder')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default Categories;
