import React, { JSX } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCreateRestaurant } from 'services/restaurants';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import RestaurantForm, { RestaurantFormMode } from './RestaurantForm/RestaurantForm';
import { restaurantDefaultValues, useRestaurantFormValidationSchema } from './RestaurantForm/RestaurantForm.form';

const CreateRestaurant = (): JSX.Element => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { mutate: createRestaurant, isLoading, error } = useCreateRestaurant();
  const schema = useRestaurantFormValidationSchema();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: restaurantDefaultValues,
  });

  return (
    <>
      <ChildPageHeading>{translations('restaurants_create_header')}</ChildPageHeading>
      <ErrorMessages errors={error} />
      <FormProvider {...methods}>
        <RestaurantForm
          methods={methods}
          mode={RestaurantFormMode.CREATE}
          isLoading={isLoading}
          onSubmit={(values) =>
            createRestaurant(values, {
              onSuccess: async () => navigate(Routes.RESTAURANTS_PATH),
            })
          }
        />
      </FormProvider>
    </>
  );
};

export default CreateRestaurant;
