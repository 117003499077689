import React, { JSX, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, Link } from 'react-router-dom';
import { Button, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages, FormFooter } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { CreateRelevoUserMutation, useCreateRelevoUser } from 'services/relevo-users';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { RelevoUserRequest, RelevoUserResponse } from './models';
import { useRelevoUserFormValidation } from './RelevoUser.form';
import RelevoUserCredentialsForm, { RelevoUserCredentialsFormModel } from './RelevoUserCredentialsForm';
import RelevoUserRolesForm from './RelevoUserRolesForm';

const CreateRelevoUser = (): JSX.Element => {
  const [isCreated, setIsCreated] = useState(false);
  const [credentials, setCredentials] = useState<RelevoUserRequest>();
  const [relevoUser, setRelevoUser] = useState<RelevoUserResponse>();

  const translations = useTranslations();

  const onSuccess = (data: RelevoUserResponse, variable: CreateRelevoUserMutation) => {
    setCredentials(variable.createRelevoUserRequest);
    setRelevoUser(data);
    setIsCreated(true);
  };
  const { mutate: createRelevoUser, isLoading, error: createRelevoUserError } = useCreateRelevoUser(onSuccess);
  const validationSchema = useRelevoUserFormValidation();

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    control,
  } = formMethods;

  const onSubmit = handleSubmit(({ closingSource, ...form }: RelevoUserCredentialsFormModel) => {
    const createRelevoUserRequest = {
      ...form,
      closingSourceId: closingSource?.id,
      locations: {
        restaurantIds: form.locations?.restaurantIds?.map((r) => r.id),
        keyAccountsGroupIds: form.locations?.keyAccountsGroupIds?.map((k) => k.id),
      },
    };
    createRelevoUser({ createRelevoUserRequest });
  });

  return (
    <>
      <ChildPageHeading>{translations('relevo_users_create_header')}</ChildPageHeading>
      <ErrorMessages errors={createRelevoUserError} />
      {!isCreated ? (
        <form data-testid="form" onSubmit={onSubmit}>
          <RelevoUserCredentialsForm register={register} errors={errors} setValue={setValue} />
          <RelevoUserRolesForm control={control} />
          {/* <RelevoUserMetadataForm control={control} /> */}
          <FormFooter isLoadingSubmitResult={isLoading} />
        </form>
      ) : (
        <>
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Td>User</Td>
                <Td>{credentials?.email}</Td>
              </Tr>
              <Tr>
                <Td>Password</Td>
                <Td>{credentials?.password}</Td>
              </Tr>
              <Tr>
                <Td>Admin Role</Td>
                <Td>{credentials?.adminRole}</Td>
              </Tr>
              <Tr>
                <Td>Partner Role</Td>
                <Td>{credentials?.partnerRole}</Td>
              </Tr>
              {credentials?.partnerRole ? (
                <>
                  {relevoUser?.locations?.restaurantIds ? (
                    <Tr>
                      <Td>{translations('relevo_user_restaurants')}</Td>
                      <Td>
                        <ul>{relevoUser?.locations?.restaurantIds.map((r) => <li>{r.restaurantName}</li>)}</ul>
                      </Td>
                    </Tr>
                  ) : null}
                  {relevoUser?.locations?.keyAccountsGroupIds ? (
                    <Tr>
                      <Td>{translations('relevo_user_key_account_groups')}</Td>
                      <Td>
                        <ul>{relevoUser?.locations?.keyAccountsGroupIds.map((k) => <li>{k.keyAccount}</li>)}</ul>
                      </Td>
                    </Tr>
                  ) : null}
                  {relevoUser?.closingSource ? (
                    <Tr>
                      <Td>{translations('restaurants_form_closing_source')}</Td>
                      <Td>{relevoUser.closingSource.name}</Td>
                    </Tr>
                  ) : null}
                </>
              ) : null}
            </Tbody>
          </Table>
          <Link to={generatePath(Routes.RELEVO_USERS_PATH)}>
            <Button mt={4} ml={4}>
              {translations('back')}
            </Button>
          </Link>
        </>
      )}
    </>
  );
};

export default CreateRelevoUser;
