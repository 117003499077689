import { ChangeEvent } from 'react';

export const useHandleTablePageCheckbox = (
  selectedIds: string[],
  setSelectedIds: (data: string[]) => void,
  pageRowsIds?: string[],
): { handlePageCheckbox: (e: ChangeEvent<HTMLInputElement>) => void } => {
  const getIdsToSelect = (isChecked: boolean, rowId: string, idsEntry: string[]) => {
    let idsToSelect = idsEntry;
    if (isChecked && !selectedIds.some((id) => id === rowId)) {
      idsToSelect = idsToSelect.concat([rowId]);
    }
    if (!isChecked && selectedIds.some((id) => id === rowId)) {
      idsToSelect = idsToSelect.filter((id) => id !== rowId);
    }
    return idsToSelect;
  };
  const setSelectedIdsBasedOnEvent = (e: ChangeEvent<HTMLInputElement>) => {
    if (pageRowsIds) {
      let idsToSelect = selectedIds;
      for (const id of pageRowsIds) {
        idsToSelect = getIdsToSelect(e.target.checked, id, idsToSelect);
      }
      setSelectedIds(idsToSelect);
    }
  };
  const handlePageCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      setSelectedIds([]);
    } else {
      setSelectedIdsBasedOnEvent(e);
    }
  };
  return { handlePageCheckbox };
};
