import React, { ComponentProps, useCallback, useMemo } from 'react';

import { Select } from '../../../../../common/components';
import { SelectOption } from '../../../../../common/components/Select/models/select-option';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { TwoFactorReturnMode } from '../../models/enums/two-factor-return-mode.enum';

type TwoFactorReturnModeSelectProps = Omit<
  ComponentProps<typeof Select>,
  'options' | 'isLoading' | 'getOptionLabel' | 'getOptionValue' | 'placeholder' | 'onChange'
> & {
  selectedMode: TwoFactorReturnMode;
  onSelect: (value: unknown) => void;
};

export const TwoFactorReturnModeSelect = ({
  isDisabled,
  name,
  selectedMode,
  onSelect,
  inputId,
}: TwoFactorReturnModeSelectProps) => {
  const translations = useTranslations();
  const getModeLabel = useCallback(
    (mode: TwoFactorReturnMode) => {
      switch (mode) {
        case TwoFactorReturnMode.STRICT:
          return translations('two_factor_return_mode_strict');
        case TwoFactorReturnMode.NON_STRICT:
          return translations('two_factor_return_mode_non_strict');
        default: {
          const exhaustiveCheck: never = mode;
          throw new Error(exhaustiveCheck);
        }
      }
    },
    [translations],
  );

  const modeSelectOptions = useMemo(
    () =>
      Object.values(TwoFactorReturnMode).map((mode) => ({
        value: mode,
        label: getModeLabel(mode),
      })),
    [getModeLabel],
  );

  return (
    <Select<SelectOption>
      inputId={inputId}
      name={name}
      value={modeSelectOptions.find((option) => option.value === selectedMode)}
      getOptionLabel={(option) => (option as SelectOption).label}
      getOptionValue={(option) => (option as SelectOption).value}
      onChange={onSelect}
      options={modeSelectOptions}
      isDisabled={isDisabled}
      valueMapper={(v) => v?.value}
    />
  );
};
