import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { MainPageContent } from '../../../common/components';
import { DepositItemsTable } from './components/DepositItemsTable/DepositItemsTable';
import { DepositSettings } from './components/DepositSettings/DepositSettings';

export const Deposit = () => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  invariant(restaurantId, 'restaurantId is not set within the route');

  return (
    <MainPageContent>
      <Box pb={6}>
        <DepositSettings restaurantId={restaurantId} />
      </Box>
      <Divider />
      <Box pt={6}>
        <DepositItemsTable restaurantId={restaurantId} />
      </Box>
    </MainPageContent>
  );
};
