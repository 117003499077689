import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { runScript } from '../api/scripts';
import { HttpException } from '../common/models/httpException';

export const useRunScript = (): UseMutationResult<string, AxiosError<HttpException>, string> => {
  return useMutation((scriptName: string) => {
    return runScript(scriptName);
  });
};
