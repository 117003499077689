import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { getNotificationSettings, updateNotificationSettings } from '../api/restaurant-user-notification-settings';
import { HttpException } from '../common/models/httpException';
import { NotificationSettingsResponse } from '../modules/RestaurantCredentials/NotificationSettings/models/notification-settings-response';
import { UpdateNotificationSettingsRequest } from '../modules/RestaurantCredentials/NotificationSettings/models/update-notification-settings-request';
import { UserNotificationSettingsResponse } from '../modules/RestaurantCredentials/NotificationSettings/models/user-notification-settings-response';

const CK_RESTAURANT_USERS_NOTIFICATION_SETTINGS = 'restaurantUsersNotificationSettings';

export const useRestaurantUserNotificationSettings = (
  id: string,
): QueryObserverResult<UserNotificationSettingsResponse, AxiosError<HttpException>> => {
  return useQuery([CK_RESTAURANT_USERS_NOTIFICATION_SETTINGS, id], () => getNotificationSettings(id));
};

export const useUpdateRestaurantUserNotificationSettings = (): UseMutationResult<
  NotificationSettingsResponse,
  AxiosError,
  { id: string; request: UpdateNotificationSettingsRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, request }: { id: string; request: UpdateNotificationSettingsRequest }) =>
      updateNotificationSettings(id, request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_RESTAURANT_USERS_NOTIFICATION_SETTINGS);
      },
    },
  );
};
