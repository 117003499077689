import React, { JSX, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { Button, Flex } from '@chakra-ui/react';
import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { useExecuteWithConfirmation, useSearchCancelQuery } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { CK_CORPORATES, useCorporatesList, useDeleteCorporate } from 'services/corporates';

import { Corporate } from './Corporate.model';

const Corporates = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const {
    data,
    isFetching,
    error: loadCorporatesError,
  } = useCorporatesList({
    searchText,
    limit: config.paginationSize,
    offset: page,
  });
  const { mutate: deleteCorporate, error: deleteError } = useDeleteCorporate();
  const { executeWithConfirmation: handleCorporateDelete } = useExecuteWithConfirmation(
    translations('corporates_delete_confirmation'),
    deleteCorporate,
  );
  useSearchCancelQuery(searchText, CK_CORPORATES);

  const columns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('corporates_table_corporate_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('corporates_table_corporate_unique_id'),
        accessor: 'organisationUniqueId' as const,
      },
      {
        id: '3',
        Header: translations('corporates_table_corporate_name'),
        accessor: 'organisationName' as const,
      },
      {
        id: '4',
        Header: translations('corporates_table_corporate_address'),
        accessor: 'organisationAddress' as const,
      },
      {
        id: '5',
        Header: translations('corporates_table_total_employees'),
        accessor: 'totalEmployees' as const,
      },
      {
        id: 'actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<Corporate>) => (
          <Flex>
            <AddEditItemLink to={`${Routes.CORPORATES_PATH}/${original.id}`} m={1} isEdit />
            <Button m={1} onClick={() => handleCorporateDelete(original.id)}>
              {translations('delete')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, handleCorporateDelete],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('corporates_header')}</PageHeading>
      <ErrorMessages errors={[loadCorporatesError, deleteError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_CORPORATE_PATH}>{translations('corporates_add')}</AddEditItemLink>
      </Flex>
      <Table<Corporate>
        data={data?.items}
        columns={columns}
        isLoading={isFetching}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={(text) => {
          setSearchText(text);
          setPage(1);
        }}
        searchboxPlaceholder={translations('corporates_searchbox_placeholder')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default Corporates;
