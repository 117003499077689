import React, { JSX, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages, LinkButton, Modal, useToast } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { ChildPageHeading } from '../../../common/components/ChildPageHeading';
import { isEmpty } from '../../../common/utils/helpers';
import { Routes } from '../../../config/routes';
import { useModal } from '../../../contexts/ModalContext';
import { ModalVariant } from '../../../contexts/ModalContext/hook';
import { useKeyAccountGroup } from '../../../services/key-account-groups';
import { useUpdateRestaurants } from '../../../services/restaurants';
import { RestaurantDetailsResponse, RestaurantRequest } from '../models';
import RestaurantForm, { RestaurantFormMode } from '../RestaurantForm/RestaurantForm';
import { useRestaurantsUpdateFormValidationSchema } from './hooks/useRestaurantsUpdateFormValidationSchema';
import { useUpdateMultipleRestaurantsCharacteristics } from './hooks/useUpdateMultipleRestaurantsCharacteristics';
import { UpdateRestaurantsRequest } from './model/update-restaurants.request.model';
import {
  buildRestaurantChangesSummaryObject,
  buildUpdateRestaurantsRequest,
} from './utils/update-ultiple-restaurants.helper';
import RestaurantsChangesSummary from './RestaurantsChangesSummary';

const UpdateMultipleRestaurants = (): JSX.Element => {
  const navigate = useNavigate();
  const translations = useTranslations();
  const { displayToast } = useToast();
  const schema = useRestaurantsUpdateFormValidationSchema();
  const { modalState, handleToggleModal } = useModal();
  const [updateRestaurantsRequest, setUpdateRestaurantsRequest] = useState<UpdateRestaurantsRequest>({});
  const { keyAccountId } = useParams<{ keyAccountId: string }>();
  const {
    data: keyAccountGroup,
    isLoading: isLoadingKeyAccountGroup,
    error: keyAccountGroupError,
  } = useKeyAccountGroup(keyAccountId!, !!keyAccountId);
  const { state: selectedRestaurants } = useLocation();

  const {
    city,
    isLoadingCity,
    keyAccount,
    isLoadingKeyAccount,
    closingSource,
    isLoadingClosingSource,
    restaurants,
    isLoadingRestaurants,
  } = useUpdateMultipleRestaurantsCharacteristics(updateRestaurantsRequest, selectedRestaurants, keyAccountId);

  const formDefaultValues: RestaurantDetailsResponse = {
    id: '',
    restaurantName: '',
    metaCategories: [],
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
  });

  const {
    mutate: updateRestaurants,
    isLoading: isUpdatingRestaurants,
    error: updateRestaurantsError,
  } = useUpdateRestaurants();

  const handleToggleConfirmationModal = handleToggleModal({ modalType: ModalVariant.DEFAULT });
  const handleCloseConfirmationModal = handleToggleModal(null);
  const backRoute = keyAccountId
    ? generatePath(Routes.KEY_ACCOUNT_GROUP_RESTAURANTS_PATH, { keyAccountId })
    : Routes.RESTAURANTS_PATH;

  const handleSubmit = (formData: RestaurantRequest) => {
    const requestData = buildUpdateRestaurantsRequest(formData);
    setUpdateRestaurantsRequest(requestData);
    if (isEmpty(requestData)) {
      displayToast('warning', translations('restaurants_mass_update_no_changes_provided'));
    } else {
      handleToggleConfirmationModal();
    }
  };

  const handleUpdateRestaurants = () => {
    if (restaurants) {
      updateRestaurants(
        { restaurantIds: restaurants.items.map((restaurant) => restaurant.id), data: updateRestaurantsRequest },
        {
          onSuccess: async () => {
            handleCloseConfirmationModal();
            navigate(backRoute);
          },
        },
      );
    }
  };

  const isLoadingData = isLoadingCity || isLoadingKeyAccount || isLoadingClosingSource || isLoadingRestaurants;

  const restaurantChangesSummary = useMemo(() => {
    return buildRestaurantChangesSummaryObject(updateRestaurantsRequest, city, keyAccount, closingSource);
  }, [updateRestaurantsRequest, city, keyAccount, closingSource]);

  const restaurantsNumber = useMemo(() => {
    if (selectedRestaurants.length) {
      return selectedRestaurants.length;
    }
    if (keyAccountGroup) {
      return keyAccountGroup.restaurantsCount;
    }
    return 0;
  }, [keyAccountGroup, selectedRestaurants.length]);

  if (isLoadingKeyAccountGroup) {
    return <Spinner />;
  }

  return (
    <>
      <ChildPageHeading>
        {translations('restaurants_mass_update_header', {
          '{{number}}': restaurantsNumber.toString(),
        })}
      </ChildPageHeading>
      <ErrorMessages errors={[updateRestaurantsError, keyAccountGroupError]} />
      <FormProvider {...methods}>
        <RestaurantForm
          methods={methods}
          mode={RestaurantFormMode.MASS_UPDATE}
          isLoading={isUpdatingRestaurants}
          onSubmit={handleSubmit}
          hasFormFooterCustomContent={true}
          customFormFooter={
            <>
              <Button mr={4} type="submit" isDisabled={!!Object.keys(methods.formState.errors).length}>
                {translations('restaurants_mass_update_save_button', {
                  '{{number}}': restaurantsNumber.toString(),
                })}
              </Button>
              <LinkButton to={backRoute}>{translations('cancel')}</LinkButton>
            </>
          }
        />
      </FormProvider>
      <Modal
        size="xl"
        isModalVisible={modalState?.modalType === ModalVariant.DEFAULT}
        onModalClose={handleToggleModal(null)}
        modalHeader={translations('restaurants_mass_update_confirmation_dialog_header')}
        onConfirmClick={handleUpdateRestaurants}
        isLoadingConfirmed={isUpdatingRestaurants}
      >
        {isLoadingData ? (
          <Spinner />
        ) : (
          <RestaurantsChangesSummary changes={restaurantChangesSummary} restaurantsData={restaurants?.items} />
        )}
      </Modal>
    </>
  );
};

export default UpdateMultipleRestaurants;
