export enum SelectType {
  CITIES = 'CITIES',
  TAGS = 'TAGS',
  PROMOTIONS = 'PROMOTIONS',
  META_CATEGORIES = 'META_CATEGORIES',
  KEY_ACCOUNT_GROUPS = 'KEY_ACCOUNT_GROUPS',
  CLOSING_SOURCES = 'CLOSING_SOURCES',
  STARTER_SET_SIZES = 'STARTER_SET_SIZES',
  STATUSES = 'STATUSES',
  SEGMENTS = 'SEGMENTS',
  ACCOUNT_PRIORITIES = 'ACCOUNT_PRIORITIES',
}
