import React, { JSX, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages, FullPageLoader, Modal, useToast } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCreateRestaurant, useRestaurant, useUpdateRestaurant } from 'services/restaurants';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import RestaurantForm, { RestaurantFormMode } from './RestaurantForm/RestaurantForm';
import { useRestaurantFormValidationSchema } from './RestaurantForm/RestaurantForm.form';
import DuplicateRestaurant from './DuplicateRestaurant';
import { RestaurantRequest } from './models';

const UpdateRestaurant = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const translations = useTranslations();
  const { data: restaurant, isLoading: isLoadingRestaurant, error: restaurantLoadError } = useRestaurant(id);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
  const { displayToast } = useToast();
  const schema = useRestaurantFormValidationSchema();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: restaurant,
  });

  const {
    mutate: updateRestaurant,
    isLoading: isSavingRestaurant,
    error: updateRestaurantError,
  } = useUpdateRestaurant();

  const {
    mutate: createRestaurant,
    isLoading: isCreatingRestaurant,
    error: createRestaurantError,
  } = useCreateRestaurant();

  if (isLoadingRestaurant) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  const handleDuplicateRestaurant = () => setIsDuplicateModalVisible(true);

  const handleModalClose = () => setIsDuplicateModalVisible(false);

  const handleUpdateRestaurant = (updateRestaurantRequest: RestaurantRequest) => {
    updateRestaurant(
      { id, updateRestaurantRequest },
      {
        onSuccess: async () => navigate(Routes.RESTAURANTS_PATH),
      },
    );
  };

  const handleCreateRestaurant = (createRestaurantRequest: RestaurantRequest) => {
    createRestaurant(createRestaurantRequest, {
      onSuccess: (response) => {
        handleModalClose();
        displayToast('success', translations('restaurants_duplicate_success'));
        navigate(`${Routes.RESTAURANTS_PATH}/${response.id}`);
      },
    });
  };

  return (
    <>
      <ChildPageHeading>{translations('restaurants_update_header')}</ChildPageHeading>
      <ErrorMessages errors={[updateRestaurantError, restaurantLoadError]} />
      <FormProvider {...methods}>
        <RestaurantForm
          methods={methods}
          mode={RestaurantFormMode.UPDATE}
          isLoading={isSavingRestaurant}
          onSubmit={handleUpdateRestaurant}
          model={restaurant}
          onDuplicateRestaurant={handleDuplicateRestaurant}
        />
      </FormProvider>
      <Modal
        isModalVisible={isDuplicateModalVisible}
        onModalClose={handleModalClose}
        customModalFooter={null}
        maxWidth="50%"
      >
        <DuplicateRestaurant
          isLoading={isCreatingRestaurant}
          data={restaurant}
          errors={[createRestaurantError] || null}
          onSubmit={handleCreateRestaurant}
          onCancel={handleModalClose}
        />
      </Modal>
    </>
  );
};

export default UpdateRestaurant;
