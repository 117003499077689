import { generatePath } from 'react-router-dom';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { AssignedItems } from 'modules/AssignedItems/models/assigned-items';
import { AssignedItemsExtendRequest } from 'modules/AssignedItems/models/assigned-items-extend-request';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import axiosInstance from './axios';

export const getAssignedItemsForUserList = (
  userId: string,
  isReturn: boolean,
  { searchText, offset, limit }: BaseQueryParams,
): Promise<Paginated<AssignedItems>> => {
  const additionalParams = new Map();
  additionalParams.set('isReturn', isReturn.toString());
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText, additionalParams);
  return axiosInstance
    .get<Paginated<AssignedItems>>(generatePath(apiPaths.USERS_ASSIGNED_ITEMS, { userId }), { params: searchParams })
    .then(({ data }) => data);
};

export const extendAssignedItems = (
  userId: string,
  assignedItemsId: string,
  assignedItemsExtendRequest: AssignedItemsExtendRequest,
): Promise<void> => {
  return axiosInstance.post(
    `${generatePath(apiPaths.USERS_ASSIGNED_ITEMS, { userId })}/${assignedItemsId}/extend`,
    assignedItemsExtendRequest,
  );
};

export const removeAssignedItem = ({
  userId,
  assignedItemsId,
}: {
  userId: string;
  assignedItemsId: string;
}): Promise<void> => {
  return axiosInstance.delete(`${generatePath(apiPaths.USERS_ASSIGNED_ITEMS, { userId })}/${assignedItemsId}`);
};
