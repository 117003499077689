import React, { JSX } from 'react';

export const DEFAULT_SEPARATOR = '-';
type FallbackValueProps = {
  fallback?: string;
  isFallbackVisible?: boolean;
  value: React.ReactNode;
};

export const FallbackValue = ({
  fallback = DEFAULT_SEPARATOR,
  isFallbackVisible,
  value,
}: FallbackValueProps): JSX.Element => {
  const hasValue = typeof isFallbackVisible === 'boolean' ? !isFallbackVisible : !!value;
  return <>{hasValue ? value : fallback}</>;
};
