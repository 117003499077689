import colors from 'styles/colors';

export default {
  baseStyle: {
    fontWeight: 'bold',
  },
  variants: {
    outlineNegative: {
      color: colors.white,
      background: 'none',
      border: '1px solid',
      borderColor: colors.white,
      _active: {
        background: colors.white,
        color: `${colors.orange[500]}`,
      },
      _hover: {
        background: colors.white,
        color: `${colors.orange[500]}`,
      },
    },
    outline: {
      color: `${colors.orange[500]}`,
      background: 'none',
      borderColor: `${colors.orange[500]}`,
      _active: {
        background: `${colors.orange[500]}`,
        color: colors.white,
      },
      _disabled: {
        color: `${colors.orange[500]}`,
      },
      _hover: {
        background: `${colors.orange[500]}`,
        color: colors.white,
      },
    },
  },
};
