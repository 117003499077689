import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { PromotionFormValues } from 'modules/Promotions/CreateUpdatePromotionForm';
import { Promotion } from 'modules/Promotions/models/Promotion';

import { createUpdatePromotion, deletePromotion, getPromotion, getPromotions } from '../api/promotions';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';

export const CK_PROMOTIONS = 'promotions';

export const usePromotionsList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<Promotion>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_PROMOTIONS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getPromotions(params),
    {
      keepPreviousData: true,
    },
  );
};

export const usePromotion = (id: string): QueryObserverResult<Promotion, AxiosError<HttpException>> => {
  return useQuery([CK_PROMOTIONS, id], () => getPromotion(id));
};

export const useCreateUpdatePromotion = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  { values: PromotionFormValues }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ values }: { values: PromotionFormValues }) => createUpdatePromotion(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_PROMOTIONS);
    },
  });
};

export const useDeletePromotion = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation((id: string) => deletePromotion(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_PROMOTIONS);
    },
  });
};
