import { Box, Heading, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { RestaurantOpeningHoursResponse } from '../../../models';
import { weekdayToString } from '../../../RestaurantForm/restaurantFormHelper';
import { Diff } from './Diff';
import { DiffProps } from './types';

const DAYS = Array.from({ length: 7 }, (_, i) => weekdayToString(i));

export const OpeningHoursDiff = ({
  before = [],
  after = [],
  showDiff,
  isChanged,
}: DiffProps<RestaurantOpeningHoursResponse[]>) => {
  const translation = useTranslations();

  if (!isChanged && showDiff) {
    return <Text>{translation('unchanged')}</Text>;
  }

  return (
    <Box mt="2">
      {DAYS.map((day, dayIndex) => {
        const beforeDay = (before ?? []).find((openingHours) => openingHours.day === dayIndex);
        const afterDay = (after ?? []).find((openingHours) => openingHours.day === dayIndex);
        return (
          <Box>
            <Heading as="h4" size="xs">
              {translation(day)}
            </Heading>
            <Box mt="1" mb="2">
              <Diff
                key={dayIndex}
                isChanged={isChanged}
                showDiff={showDiff}
                before={
                  <Box>
                    {(beforeDay?.periods ?? []).map((period, index) => {
                      const periodHasBeenRemoved = !afterDay?.periods?.find(
                        (afterPeriod) =>
                          afterPeriod.openTime === period.openTime && afterPeriod.closeTime === period.closeTime,
                      );
                      return (
                        <Text
                          key={index}
                          textDecorationLine={periodHasBeenRemoved ? 'line-through' : 'none'}
                          background={periodHasBeenRemoved ? 'red.100' : 'none'}
                        >
                          {period.openTime} - {period.closeTime}
                        </Text>
                      );
                    })}
                  </Box>
                }
                after={
                  <Box>
                    {(afterDay?.periods ?? []).map((period, index) => {
                      const periodHasBeenAdded = !beforeDay?.periods?.find(
                        (beforePeriod) =>
                          beforePeriod.openTime === period.openTime && beforePeriod.closeTime === period.closeTime,
                      );
                      return (
                        <Text key={index} background={periodHasBeenAdded && showDiff ? 'green.100' : 'none'}>
                          {period.openTime} - {period.closeTime}
                        </Text>
                      );
                    })}
                  </Box>
                }
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
