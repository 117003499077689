import React, { JSX, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';

import { DayOfWeek } from '../../../common/models';
import { formatOpeningHoursPeriods } from '../../../common/utils';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { RestaurantOpeningHoursResponse } from '../models';
import { RestaurantChangesSummary } from './model/restaurant-changes-summary.model';
import { toSentenceCase } from './utils/update-ultiple-restaurants.helper';

const SEPARATOR = '-';

interface RestaurantData {
  id: string;
  restaurantName: string;
}

type RestaurantChangesSummaryProps = {
  changes: RestaurantChangesSummary;
  restaurantsData?: RestaurantData[];
};

const RestaurantsChangesSummary = ({ changes, restaurantsData }: RestaurantChangesSummaryProps): JSX.Element => {
  const translations = useTranslations();
  const [showAffectedRestaurants, setShowAffectedRestaurants] = useState(false);
  const handleToggle = () => setShowAffectedRestaurants(!showAffectedRestaurants);

  const getOpeningHours = (value: RestaurantOpeningHoursResponse[]) => {
    const tooltipLabel = value
      ?.map((data) => `${DayOfWeek[data.day]}: ${formatOpeningHoursPeriods(data.periods)}`)
      .join('\n');

    return tooltipLabel ? (
      <Tooltip label={tooltipLabel} fontSize="md">
        <Center ml={2}>
          <InfoOutlineIcon />
        </Center>
      </Tooltip>
    ) : null;
  };

  return (
    <Box>
      <Text pb={8}>
        {translations('restaurants_mass_update_confirmation_dialog_message_1')}
        <b>{restaurantsData?.length.toString()}</b>
        {translations('restaurants_mass_update_confirmation_dialog_message_2')}
      </Text>
      <Box pb={8}>
        {Object.entries(changes).map(([key, value]) => (
          <Flex key={key}>
            <Text w="40%">
              <b>{toSentenceCase(key)}:</b>
            </Text>
            {key === 'restaurantOpeningHours' ? getOpeningHours(value) : <Text ml={2}>{value}</Text>}
          </Flex>
        ))}
      </Box>
      <Box>
        <Flex pb={4} onClick={handleToggle} cursor="pointer" width="200px">
          <Center>{!showAffectedRestaurants ? <ChevronDownIcon h={6} w={6} /> : <ChevronUpIcon h={6} w={6} />}</Center>
          <Text>{translations('restaurants_mass_update_confirmation_dialog_affected_restaurants_label')}</Text>
        </Flex>
        {showAffectedRestaurants ? (
          <Box>
            {restaurantsData?.map((restaurant) => (
              <HStack spacing={2} key={restaurant.id}>
                <Text>{restaurant.id}</Text>
                <Text>{SEPARATOR}</Text>
                <Text>{restaurant.restaurantName}</Text>
              </HStack>
            ))}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default RestaurantsChangesSummary;
