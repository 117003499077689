import React, { JSX } from 'react';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, Link, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { FullPageLoader } from '../../../common/components';
import { ChildPageHeading } from '../../../common/components/ChildPageHeading';
import { Routes } from '../../../config/routes';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useRelevoUser } from '../../../services/relevo-users';

export const CustomerSummary = (): JSX.Element => {
  const translations = useTranslations();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const { data: user, isLoading: isUserLoading } = useRelevoUser(id);

  const userHasRestaurants = !!user?.locations?.restaurantIds?.length;
  const userHasKeyAccountGroups = !!user?.locations?.keyAccountsGroupIds?.length;

  if (isUserLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{translations('customers_details_header')}</ChildPageHeading>

      <Table variant="simple">
        <Tbody>
          <Tr>
            <Td width={350}>{translations('customer_summary_id')}:</Td>
            <Td>{user?.id}</Td>
          </Tr>
          <Tr>
            <Td width={350}>{translations('customer_summary_email')}:</Td>
            <Td>{user?.email}</Td>
          </Tr>
          <Tr>
            <Td width={350}>{translations('customer_summary_password')}:</Td>
            <Td>{location.state}</Td>
          </Tr>
        </Tbody>
      </Table>

      <Link href={`${Routes.RELEVO_USERS_PATH}/${user?.id}`} target="_blank">
        <Button mt={4} rightIcon={<EditIcon />}>
          {translations('customer_summary_edit_user_account')}
        </Button>
      </Link>

      {userHasRestaurants ? (
        <>
          <Heading mt={5} mb={5} as="h2">
            {translations('customer_summary_restaurants')}
          </Heading>
          <Table variant="simple">
            <Tbody>
              {user?.locations?.restaurantIds?.map(({ id: restaurantId, restaurantName }) => (
                <>
                  <Tr>
                    <Td width={350}>{translations('customer_summary_id')}:</Td>
                    <Td>{restaurantId}</Td>
                  </Tr>
                  <Tr>
                    <Td width={350}>{translations('customer_summary_restaurant_name')}:</Td>
                    <Td>{restaurantName}</Td>
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
          <Link
            href={`${Routes.RESTAURANTS_PATH}/${
              !!user?.locations?.restaurantIds?.length && user?.locations?.restaurantIds[0]?.id
            }`}
            target="_blank"
          >
            <Button mt={4} rightIcon={<EditIcon />}>
              {translations('customer_summary_edit_restaurant')}
            </Button>
          </Link>
        </>
      ) : null}

      {userHasKeyAccountGroups ? (
        <>
          <Heading mt={5} mb={5} as="h2">
            {translations('customer_summary_key_account_groups')}
          </Heading>
          <Table variant="simple">
            <Tbody>
              {user?.locations?.keyAccountsGroupIds?.map(({ id: keyAccountId, keyAccount, segment }) => (
                <>
                  <Tr>
                    <Td width={200}>{translations('customer_summary_id')}:</Td>
                    <Td>{keyAccountId}</Td>
                  </Tr>
                  <Tr>
                    <Td width={350}>{translations('customer_summary_key_account_group_name')}:</Td>
                    <Td>{keyAccount}</Td>
                  </Tr>
                  <Tr>
                    <Td width={350}>{translations('customer_summary_segment')}:</Td>
                    <Td>{segment?.name}</Td>
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
          <Link
            href={`${Routes.KEY_ACCOUNT_GROUPS_PATH}/${
              !!user?.locations?.keyAccountsGroupIds?.length && user?.locations?.keyAccountsGroupIds[0]?.id
            }/edit`}
            target="_blank"
          >
            <Button mt={4} rightIcon={<EditIcon />}>
              {translations('customer_summary_edit_key_account_group')}
            </Button>
          </Link>
        </>
      ) : null}

      <Box width="100%" mt={10}>
        <Link href={generatePath(Routes.CUSTOMERS_PATH)}>
          <Button mt={4}>{translations('customer_summary_back')}</Button>
        </Link>
      </Box>
    </>
  );
};
