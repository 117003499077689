import React, { JSX, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';
import { signOut } from 'supertokens-auth-react/recipe/emailpassword';

import colors from '../../../styles/colors';
import { SubSectionButton } from './Menu/SubSectionButton';

export const SignOut = (): JSX.Element => {
  const translations = useTranslations();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);

  const handleSignOutButtonClick = () => {
    setErrorMessage(null);
    setIsOpen(true);
  };

  const handleSignOut = async () => {
    await signOut();
    window.location.href = '/';
  };

  return (
    <>
      <SubSectionButton type="button" onClick={handleSignOutButtonClick}>
        {translations('sign_out')}
      </SubSectionButton>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" />
            <AlertDialogBody>
              {errorMessage ? (
                <Stack spacing={1} fontWeight="bold" color={colors.red[500]}>
                  <Text>{translations(errorMessage)}</Text>
                  <Text>{translations('please_try_again')}</Text>
                </Stack>
              ) : (
                <Text>{translations('sign_out_are_you_sure')}</Text>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {translations('cancel')}
              </Button>
              <Button colorScheme="orange" onClick={handleSignOut} ml={3}>
                {translations('sign_out')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
