export const useExecuteWithConfirmation = <T>(
  confirmationText: string,
  cb: (data: T) => void,
): { executeWithConfirmation: (data: T) => void } => {
  const executeWithConfirmation = (data: T) => {
    const isConfirmed = window.confirm(confirmationText);
    if (isConfirmed) {
      cb(data);
    }
  };

  return { executeWithConfirmation };
};
