import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  addKeyAccountGroupImage,
  addRestaurantToKeyAccountGroup,
  createKeyAccountGroup,
  createReturnCodes,
  deleteKeyAccountGroup,
  deleteKeyAccountGroupImage,
  deleteKeyAccountGroupRestaurant,
  editKeyAccountGroup,
  getKeyAccountGroup,
  getKeyAccountGroupImages,
  getKeyAccountGroupReturnCodes,
  getKeyAccountGroupsList,
  updateKeyAccountGroupImagesOrder,
} from 'api/key-account-groups';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { Routes } from 'config/routes';
import { KeyAccountGroupsAddRestaurantRequest } from 'modules/KeyAccountGroups/KeyAccountGroups.model';
import { FormSubmitData } from 'modules/KeyAccountGroups/models/form-submit-data';
import { KeyAccountGroupsResponse } from 'modules/KeyAccountGroups/models/key-account-groups-response';
import { CK_RESTAURANTS } from 'services/restaurants';

import { useToast } from '../common/components';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { CreateReturnCodesRequest } from '../modules/KeyAccountGroups/KeyAccountGroupRestaurants/components/RestaurantsReturnCodes/models/create-return-codes-request';
import { KeyAccountGroupReturnCodesQueryRequest } from '../modules/KeyAccountGroups/KeyAccountGroupReturnCodes/models/key-account-group-return-codes-query-request';
import { KeyAccountGroupCreateReturnCodesResponse } from '../modules/KeyAccountGroups/models/key-account-group-create-return-codes-response';
import { KeyAccountGroupImageRequest } from '../modules/KeyAccountGroups/models/key-account-group-image-request';
import { KeyAccountGroupImageResponse } from '../modules/KeyAccountGroups/models/key-account-group-image-response';
import { KeyAccountGroupImagesOrderRequest } from '../modules/KeyAccountGroups/models/key-account-group-images-order-request';
import { RestaurantReturnCodeResponse } from '../modules/Restaurants/RestaurantReturnCodes/models/restaurant-return-code-response';

export const CK_KEY_ACCOUNT_GROUPS = 'key-account-groups';
export const CK_KEY_ACCOUNT_GROUP_IMAGES = 'key-account-group-images';
export const CK_KEY_ACCOUNT_GROUP_RETURN_CODES = 'key-account-group-return-codes';

export const useKeyAccountGroupsList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<KeyAccountGroupsResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_KEY_ACCOUNT_GROUPS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getKeyAccountGroupsList(params),
    {
      keepPreviousData: true,
    },
  );
};

export const useCreateKeyAccountGroup = (
  redirect?: Routes,
): UseMutationResult<KeyAccountGroupsResponse, AxiosError<HttpException>, { values: FormSubmitData }> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(({ values }) => createKeyAccountGroup(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_KEY_ACCOUNT_GROUPS);
      if (redirect) {
        navigate(redirect);
      }
    },
  });
};

export const useEditKeyAccountGroup = (
  redirect?: Routes,
): UseMutationResult<KeyAccountGroupsResponse, AxiosError<HttpException>, { id: string; values: FormSubmitData }> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(({ id, values }) => editKeyAccountGroup(id, values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_KEY_ACCOUNT_GROUPS);
      if (redirect) {
        navigate(redirect);
      }
    },
  });
};

export const useKeyAccountGroup = (
  id: string,
  enabled = true,
): QueryObserverResult<KeyAccountGroupsResponse, AxiosError<HttpException>> => {
  return useQuery([CK_KEY_ACCOUNT_GROUPS, id], () => getKeyAccountGroup(id || ''), { enabled, keepPreviousData: true });
};

export const useAddRestaurantToKeyAccountGroup = (
  redirect?: Routes,
): UseMutationResult<void, AxiosError<HttpException>, { values: KeyAccountGroupsAddRestaurantRequest }> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(({ values }) => addRestaurantToKeyAccountGroup(values.keyAccountId, values.restaurantId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_KEY_ACCOUNT_GROUPS);
      if (redirect) {
        navigate(redirect);
      }
    },
  });
};

export const useDeleteKeyAccountGroup = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteKeyAccountGroup(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_KEY_ACCOUNT_GROUPS);
    },
  });
};

export const useDeleteKeyAccountGroupWithConfirmation = (
  confirmationText: string,
  deleteFn: (keyAccountGroupRestaurant: { keyAccountId: string; restaurantId: string }) => void,
): { deleteItem: (keyAccountGroupRestaurant: { keyAccountId: string; restaurantId: string }) => void } => {
  const deleteItem = (keyAccountGroupRestaurant: { keyAccountId: string; restaurantId: string }) => {
    const isConfirmed = window.confirm(confirmationText);
    if (isConfirmed) {
      deleteFn(keyAccountGroupRestaurant);
    }
  };

  return { deleteItem };
};

export const useDeleteKeyAccountGroupRestaurant = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  { keyAccountId: string; restaurantId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ keyAccountId, restaurantId }) => deleteKeyAccountGroupRestaurant(keyAccountId, restaurantId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_RESTAURANTS);
    },
  });
};

export const useKeyAccountGroupImages = (
  keyAccountGroupId: string,
): QueryObserverResult<KeyAccountGroupImageResponse[], AxiosError<HttpException>> => {
  return useQuery(
    [CK_KEY_ACCOUNT_GROUP_IMAGES, { keyAccountGroupId }],
    () => getKeyAccountGroupImages(keyAccountGroupId),
    {
      keepPreviousData: true,
    },
  );
};

export const useDeleteKeyAccountGroupImage = (
  keyAccountGroupId: string,
): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => {
      return deleteKeyAccountGroupImage(keyAccountGroupId, id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_KEY_ACCOUNT_GROUP_IMAGES);
      },
    },
  );
};

export const useChangeOrderKeyAccountGroupImage = ({
  onSuccessfulAssign,
}: {
  onSuccessfulAssign: (data: number) => void;
}): UseMutationResult<
  number,
  AxiosError<HttpException>,
  { keyAccountGroupId: string; keyAccountGroupImages: KeyAccountGroupImagesOrderRequest[] }
> => {
  return useMutation(
    ({
      keyAccountGroupId,
      keyAccountGroupImages,
    }: {
      keyAccountGroupId: string;
      keyAccountGroupImages: KeyAccountGroupImagesOrderRequest[];
    }) => updateKeyAccountGroupImagesOrder(keyAccountGroupId, keyAccountGroupImages),
    {
      onSuccess: (status) => onSuccessfulAssign(status),
    },
  );
};

export const useAddKeyAccountGroupImage = ({
  onSuccessfulAssign,
}: {
  onSuccessfulAssign: (data: KeyAccountGroupImageResponse) => void;
}): UseMutationResult<
  KeyAccountGroupImageResponse,
  AxiosError<HttpException>,
  { keyAccountId: string; addKeyAccountGroupImageRequest: KeyAccountGroupImageRequest }
> => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  return useMutation(
    ({
      keyAccountId,
      addKeyAccountGroupImageRequest,
    }: {
      keyAccountId: string;
      addKeyAccountGroupImageRequest: KeyAccountGroupImageRequest;
    }) => addKeyAccountGroupImage(keyAccountId, addKeyAccountGroupImageRequest),
    {
      onSuccess: (data) => onSuccessfulAssign(data),
      onError: (error) => {
        const toastMessage = error.response?.data.message
          ? error.response?.data.message
          : translations('image_modal_error');
        displayToast('error', toastMessage);
      },
    },
  );
};

export const useCreateKeyAccountGroupReturnCodes = (): UseMutationResult<
  KeyAccountGroupCreateReturnCodesResponse,
  AxiosError<HttpException>,
  { keyAccountGroupId: string; request: CreateReturnCodesRequest }
> => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  return useMutation(
    ({ keyAccountGroupId, request }: { keyAccountGroupId: string; request: CreateReturnCodesRequest }) =>
      createReturnCodes(keyAccountGroupId, request),
    {
      onError: () => displayToast('error', translations('something_went_wrong')),
    },
  );
};

const getKeyAccountGroupReturnCodesKey = (id: string, queryRequest: KeyAccountGroupReturnCodesQueryRequest) => [
  CK_KEY_ACCOUNT_GROUP_RETURN_CODES,
  id,
  { ...queryRequest },
];

export const useKeyAccountGroupReturnCodes = (
  id: string,
  queryRequest: KeyAccountGroupReturnCodesQueryRequest,
  enabled: boolean,
  onSuccess?: (data: Paginated<RestaurantReturnCodeResponse>) => void,
): QueryObserverResult<Paginated<RestaurantReturnCodeResponse>, AxiosError<HttpException>> => {
  return useQuery(
    getKeyAccountGroupReturnCodesKey(id, queryRequest),
    () => getKeyAccountGroupReturnCodes(id, queryRequest),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled,
      onSuccess: enabled && onSuccess ? onSuccess : undefined,
    },
  );
};
