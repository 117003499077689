import React, { ComponentProps, useMemo } from 'react';
import { Select } from 'common/components';

import { Currency } from '../../models/enums/currency.enum';
import { SelectOption } from '../Select/models/select-option';

type CurrencySelectProps = Omit<
  ComponentProps<typeof Select>,
  'options' | 'isLoading' | 'getOptionLabel' | 'getOptionValue' | 'placeholder' | 'onChange'
> & {
  selectedCurrency: Currency;
  onSelect: (value: unknown) => void;
};

const CurrencySelect = ({ isDisabled, name, selectedCurrency, onSelect, inputId }: CurrencySelectProps) => {
  const currencySelectOptions = useMemo(
    () =>
      Object.values(Currency).map((currency) => ({
        value: currency,
        label: currency,
      })),
    [],
  );

  return (
    <Select<SelectOption>
      inputId={inputId}
      name={name}
      value={currencySelectOptions.find((option) => option.value === selectedCurrency)}
      getOptionLabel={(option) => (option as SelectOption).label}
      getOptionValue={(option) => (option as SelectOption).value}
      onChange={onSelect}
      options={currencySelectOptions}
      isDisabled={isDisabled}
      valueMapper={(v) => v?.value}
    />
  );
};

export default CurrencySelect;
