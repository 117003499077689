import { Box, Flex } from '@chakra-ui/react';

import { PublicFileWithOrder } from '../../../../../common/models/file';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import colors from '../../../../../styles/colors';
import { Diff } from './Diff';
import { DiffProps } from './types';

export const ImagesDiff = ({ before = [], after = [], showDiff, isChanged }: DiffProps<PublicFileWithOrder[]>) => {
  const translations = useTranslations();

  return (
    <Box mt={2}>
      <Diff
        isChanged={isChanged}
        showDiff={showDiff}
        before={
          <Flex gap={2} mr={4}>
            {(before ?? []).map((image, index) => {
              const hasBeenRemoved = !(after ?? []).find((img) => img.file?.id === image.file?.id);
              return (
                <Box position="relative">
                  <Box
                    backgroundSize="cover"
                    aspectRatio="1 / 1"
                    h="100px"
                    border={hasBeenRemoved ? `1px solid ${colors.red[500]}` : 'none'}
                    opacity={hasBeenRemoved ? 0.3 : 1}
                    key={index}
                    backgroundImage={image.file?.url}
                  />
                  {hasBeenRemoved && (
                    <Box
                      position="absolute"
                      top="-1.5"
                      right="-1.5"
                      fontWeight="600"
                      fontSize="10"
                      p={[1, 1]}
                      borderRadius="2"
                      textTransform="uppercase"
                      color={colors.white}
                      background={colors.red[500]}
                    >
                      {translations('removed')}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Flex>
        }
        after={
          <Flex gap={2}>
            {(after ?? []).map((image, index) => {
              const hasBeenAdded = !(before ?? []).find((img) => img.file?.id === image.file?.id);
              return (
                <Box
                  backgroundSize="cover"
                  aspectRatio="1 / 1"
                  h="100px"
                  key={index}
                  position="relative"
                  border={hasBeenAdded ? `1px solid ${colors.orange[500]}` : 'none'}
                  backgroundImage={image.file?.url}
                >
                  {hasBeenAdded && (
                    <Box
                      position="absolute"
                      top="-1.5"
                      right="-1.5"
                      fontWeight="600"
                      fontSize="10"
                      p={[1, 1]}
                      borderRadius="2"
                      textTransform="uppercase"
                      color={colors.white}
                      background={colors.orange[500]}
                    >
                      {translations('new')}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Flex>
        }
      />
    </Box>
  );
};
