import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import queryParamsToString from '../../Table.utils';

const usePaginationWithUrl = (
  onChangeFn?: (value: number) => void,
  onSearchChangeFn?: (value: string) => void,
): { updateUrlOnPaginationChange: (page: number) => void; updateUrlOnSearch: (search: string) => void } => {
  const navigate = useNavigate();
  const { search, state } = useLocation();

  useEffect(() => {
    const { page, q } = Object.fromEntries(new URLSearchParams(search).entries());
    if (onChangeFn) {
      if (page) {
        onChangeFn(Number(page));
      } else {
        onChangeFn(1);
      }
    }
    if (onSearchChangeFn) {
      if (q) {
        onSearchChangeFn(q);
      } else {
        onSearchChangeFn('');
      }
    }
  }, [search, onChangeFn, onSearchChangeFn]);

  const updateUrlOnPaginationChange = (pageNumber: number) => {
    navigate({
      pathname: state?.location.pathname,
      search: queryParamsToString({ paramValue: String(pageNumber), paramName: 'page', search }),
    });
  };

  const updateUrlOnSearch = (searchQuery = '') => {
    const queryParamsWithPageReset = queryParamsToString({
      paramValue: String(1),
      paramName: 'page',
      search,
    });
    navigate({
      pathname: state?.location.pathname,
      search: queryParamsToString({ paramValue: searchQuery, paramName: 'q', search: queryParamsWithPageReset }),
    });
  };

  return {
    updateUrlOnPaginationChange,
    updateUrlOnSearch,
  };
};

export default usePaginationWithUrl;
