import React, { JSX } from 'react';
import { useForm } from 'react-hook-form';
import { HStack } from '@chakra-ui/react';
import { FormFooter } from 'common/components';

import FormInput from '../../common/components/FormInput/FormInput';
import { useTranslations } from '../../contexts/LocalizationContext';
import LocationsForm from './LocationsForm';
import { RestaurantUserResponse, RestaurantUserSettingsRequest } from './models';
import RestaurantUserRoleForm from './RestaurantUserRoleForm';

type Props = {
  data?: RestaurantUserResponse;
  isLoading: boolean;
  onSubmit(data: RestaurantUserSettingsRequest): void;
};

const UpdateRestaurantUserForm = ({ isLoading, onSubmit, data }: Props): JSX.Element => {
  const { handleSubmit, reset, control, setValue, register } = useForm<RestaurantUserResponse>();
  const translations = useTranslations();

  React.useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onFormSubmit = handleSubmit(({ ...form }: RestaurantUserResponse) => {
    onSubmit({
      ...form,
      locations: {
        restaurantIds: form.locations.restaurantIds?.map((r) => r.id),
        keyAccountsGroupIds: form.locations.keyAccountsGroupIds?.map((k) => k.id),
      },
    });
  });

  return (
    <form onSubmit={onFormSubmit}>
      {data?.email ? `User: ${data?.email}` : null}
      <HStack>
        <FormInput
          id={'firstName'}
          label={translations('restaurants_credential_form_first_name')}
          useFormRegisterReturn={register('firstName')}
          setValue={setValue}
        />
        <FormInput
          id={'lastName'}
          label={translations('restaurants_credential_form_last_name')}
          useFormRegisterReturn={register('lastName')}
          setValue={setValue}
        />
      </HStack>
      <RestaurantUserRoleForm control={control} />
      <LocationsForm control={control} />
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default UpdateRestaurantUserForm;
