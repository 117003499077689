import { useTranslations } from 'contexts/LocalizationContext';
import * as yup from 'yup';

import { Category } from '../../Categories/model/Category.model';

export type GenerateProductsFormValues = {
  quantity: string | null;
  category: Category | null;
  hasProductNameInQrCode: boolean;
  hasMetaCategoryIdInQrCode: boolean;
  hasCategoryGroupInQrCode: boolean;
};

export const useGenerateProductsFormValidationSchema = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    quantity: yup.string().required(translations('validation_required')),
    category: yup.object().nullable().required(translations('validation_required')),
    hasProductNameInQrCode: yup.boolean(),
    hasMetaCategoryIdInQrCode: yup.boolean(),
    hasCategoryGroupInQrCode: yup.boolean(),
  });
};
