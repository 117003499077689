import React, { JSX } from 'react';
import { Heading } from '@chakra-ui/react';

type FormHeaderProps = {
  children: React.ReactNode;
};

export const FormHeader = ({ children }: FormHeaderProps): JSX.Element => {
  return (
    <Heading mb={5} as="h1">
      {children}
    </Heading>
  );
};
