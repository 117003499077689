import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { Corporate, CorporateRequest } from 'modules/Corporates/Corporate.model';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import axiosInstance from './axios';

export const getCorporates = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<Corporate>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get<Paginated<Corporate>>(`${apiPaths.CORPORATES}?${searchParams}`).then(({ data }) => data);
};

export const getCorporate = (id: string): Promise<Corporate> =>
  axiosInstance.get<Corporate>(`${apiPaths.CORPORATES}/${id}`).then(({ data }) => data);

export const updateCorporate = (values: CorporateRequest, id: string): Promise<Corporate> =>
  axiosInstance.put(`${apiPaths.CORPORATES}/${id}`, values);

export const createCorporate = (values: CorporateRequest): Promise<Corporate> =>
  axiosInstance.post(`${apiPaths.CORPORATES}`, values);

export const deleteCorporate = (id: string): Promise<void> => axiosInstance.delete(`${apiPaths.CORPORATES}/${id}`);
