import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { PartnerRequest, PartnerResponse, PartnersCreateBatchRequest } from 'modules/Partners/partner.model';

import {
  createPartner,
  createPartnersBatch,
  deletePartner,
  getPartner,
  getPartners,
  updatePartner,
} from '../api/partners';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';

export const CK_PARTNERS = 'partners';

export const usePartnersList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<PartnerResponse>, AxiosError<HttpException>> =>
  useQuery(
    [CK_PARTNERS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getPartners(params),
    { keepPreviousData: true },
  );

export const usePartner = (id: string): QueryObserverResult<PartnerResponse, AxiosError<HttpException>> =>
  useQuery([CK_PARTNERS, id], () => getPartner(id));

export const useCreatePartner = (): UseMutationResult<
  PartnerResponse,
  AxiosError<HttpException>,
  { values: PartnerRequest }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values }) => createPartner(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_PARTNERS);
    },
  });
};

export const useCreatePartnersBatch = (
  onSuccess: (data: PartnerResponse[]) => void,
): UseMutationResult<PartnerResponse[], AxiosError<HttpException>, { values: PartnersCreateBatchRequest }> => {
  const queryClient = useQueryClient();

  return useMutation(({ values }) => createPartnersBatch(values), {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(CK_PARTNERS);
      onSuccess(data);
    },
  });
};

export const useUpdatePartner = (): UseMutationResult<
  PartnerResponse,
  AxiosError<HttpException>,
  { values: PartnerRequest; id: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values, id }) => updatePartner(id, values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_PARTNERS);
    },
  });
};

export const useDeletePartner = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation((id) => deletePartner(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_PARTNERS);
    },
  });
};
