import React, { createContext, useMemo } from 'react';
import Session from 'supertokens-auth-react/recipe/session';

export enum Role {
  ADMINISTRATOR = 'ADMINISTRATOR',
  STANDARD_USER = 'STANDARD_USER',
  LIMITED_USER = 'LIMITED_USER',
}

export interface SessionContextInterface {
  doesSessionExist: boolean;
  role: Role | null;
}

const defaultSessionContext = {
  doesSessionExist: false,
  role: null,
};

export const SessionContext = createContext<SessionContextInterface>(defaultSessionContext);

export const SessionContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const sessionContext = Session.useSessionContext();

  const value = useMemo(() => {
    if (sessionContext.loading || !sessionContext.doesSessionExist) {
      return defaultSessionContext;
    }

    const { role } = sessionContext.accessTokenPayload;

    return {
      doesSessionExist: true,
      role,
    };
  }, [sessionContext]);

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};
