import React, { JSX, useMemo } from 'react';
import { Button, Flex, Image } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { getQRCodeSrc } from '../../utils/qrCodes.helper';
import { Modal, ModalType } from '../Modal';

type Props = {
  isModalVisible: boolean;
  qrCode: string;
  onModalClose: () => void;
  onDownloadQRCode: () => void;
};

const QRCodeDownloadModal = ({ isModalVisible, qrCode, onModalClose, onDownloadQRCode }: Props): JSX.Element => {
  const translations = useTranslations();

  const getSrc = useMemo(() => getQRCodeSrc(qrCode), [qrCode]);

  return (
    <Modal
      isModalVisible={isModalVisible}
      onModalClose={onModalClose}
      type={ModalType.INFO}
      modalHeader={qrCode}
      customModalFooter={
        <Flex justifyContent="flex-end" pb={3} px={3}>
          <Button colorScheme="orange" onClick={onDownloadQRCode}>
            {translations('download_qr_code_button')}
          </Button>
          <Button ml={3} onClick={onModalClose}>
            {translations('close')}
          </Button>
        </Flex>
      }
    >
      <Flex justifyContent="center" p={4}>
        <Image src={getSrc} alt="QR Code" />
      </Flex>
    </Modal>
  );
};

export default QRCodeDownloadModal;
