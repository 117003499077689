import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'config/routes';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCorporate, useUpdateCorporate } from '../../services/corporates';
import { CorporateFormValues } from './Corporate.model';
import parseFormValuesForRequest from './Corporates.utils';
import CorporatesForm from './CorporatesForm';

const UpdateCorporate = (): JSX.Element => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const { isLoading: isCorporateFetching, data: corporate } = useCorporate(id);
  const { isLoading: isCorporateUpdate, mutate: updateCorporate } = useUpdateCorporate();

  const formData = corporate
    ? ({
        organisationName: corporate?.organisationName,
        organisationAddress: corporate?.organisationAddress,
        totalEmployees: corporate?.totalEmployees.toString(),
      } as CorporateFormValues)
    : undefined;

  return (
    <>
      <ChildPageHeading>{translations('corporates_update_header')}</ChildPageHeading>
      <CorporatesForm
        formData={formData}
        isLoading={isCorporateFetching || isCorporateUpdate}
        onSubmit={(values: CorporateFormValues) => {
          const parsedData = parseFormValuesForRequest(values);
          if (corporate?.id) {
            updateCorporate(
              { values: parsedData, id: corporate.id },
              {
                onSuccess: () => navigate(Routes.CORPORATES_PATH),
              },
            );
          }
        }}
      />
    </>
  );
};

export default UpdateCorporate;
