import React, { JSX } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorMessages } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import { useExtendAssignedItems } from 'services/assigned-items';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { AssignedItemsExtendRequest } from './models/assigned-items-extend-request';
import ExtendAssignedItemForm from './ExtendAssignedItemForm';

const ExtendAssignedItem = (): JSX.Element => {
  const { userId, assignedItemsId } = useParams<{ userId: string; assignedItemsId: string }>();
  invariant(userId, 'userId is not set within the route');
  invariant(assignedItemsId, 'assignedItemsId is not set within the route');

  const translations = useTranslations();

  const { mutate: extendAssignedItems, isLoading, error } = useExtendAssignedItems();

  const onSubmit = (assignedItemsExtendRequest: AssignedItemsExtendRequest) =>
    extendAssignedItems({ userId, assignedItemsId, assignedItemsExtendRequest });

  return (
    <>
      <ChildPageHeading>{translations('assigned_items_extend_header')}</ChildPageHeading>
      <ErrorMessages errors={error} />
      <ExtendAssignedItemForm isLoading={isLoading} onSubmit={onSubmit} userId={userId} />
    </>
  );
};

export default ExtendAssignedItem;
