import { MetaCategoryFormValues, MetaCategoryRequest } from '../model/MetaCategory';

export const getParsedData = (values: MetaCategoryFormValues): MetaCategoryRequest => {
  const jsonFlatTranslationsValues: { [key: string]: string } = {};
  jsonFlatTranslationsValues.translations = JSON.stringify(values.translations);
  const formattedValues = {
    color: values.color ? values.color : null,
    type: values.type,
    image: values.imageTemporary?.[0],
  } as MetaCategoryRequest;
  return {
    ...formattedValues,
    ...jsonFlatTranslationsValues,
  };
};
