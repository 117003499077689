import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorMessages } from '../../common/components';
import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCreateFaqSection } from '../../services/frequently-asked-questions';
import SectionForm from './SectionForm';

const SectionCreate = (): JSX.Element => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { mutate: createFaqSection, isLoading, error } = useCreateFaqSection();

  return (
    <>
      <ChildPageHeading>{translations('faq_section_create_section')}</ChildPageHeading>
      <ErrorMessages errors={error} />
      <SectionForm
        isLoading={isLoading}
        onSubmit={(createSectionRequest) =>
          createFaqSection(createSectionRequest, {
            onSuccess: async () => navigate(Routes.FREQUENTLY_ASKED_QUESTIONS_PATH),
          })
        }
      />
    </>
  );
};

export default SectionCreate;
