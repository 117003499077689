import { RestaurantDetailsResponse, RestaurantRequest } from '../../models';
import { CLASSIC_META_CATEGORY_ID, INDIVIDUAL_KEY_ACCOUNT_ID, RELEVO_CLOSING_SOURCE_ID } from '../../restaurant.consts';

export const restaurantRequestToRestaurantResponseMapper = (
  id: string,
  restaurant: RestaurantRequest,
): RestaurantDetailsResponse => {
  return {
    ...restaurant,
    id: id,
    restaurantName: restaurant.restaurantName || '',
    city: restaurant.cityId
      ? {
          id: restaurant.cityId,
          translations: { en: '', de: '', fr: '' },
          country: { id: '', countryCode: '', translations: { en: '', de: '', fr: '' } },
        }
      : undefined,
    tags: restaurant.tags?.map((tagId) => {
      return { id: tagId, translations: { en: '', de: '', fr: '' } };
    }),
    promotions: restaurant.promotions?.map((promotionId) => {
      return { id: promotionId, translations: { en: '', de: '', fr: '' } };
    }),
    metaCategories: restaurant.metaCategories?.map((metaCategoryId) => {
      return { id: metaCategoryId, translations: { en: '', de: '', fr: '' }, image: null, type: null, color: null };
    }) || [{ id: '1', translations: { en: '', de: '', fr: '' }, image: null, type: null, color: null }],
  };
};

export const restaurantResponseToRestaurantRequestMapper = (
  restaurant: RestaurantDetailsResponse,
): RestaurantRequest => {
  const { city, id, closingSource, ...data } = restaurant;
  return {
    ...data,
    starterSetStartDate: restaurant.starterSetStartDate?.length ? restaurant.starterSetStartDate : undefined,
    starterSetSize: restaurant.starterSetSize?.length ? restaurant.starterSetSize : undefined,
    restaurantName: restaurant.restaurantName,
    ...(city?.id ? { cityId: city?.id } : { cityId: undefined }),
    ...(closingSource?.id ? { closingSourceId: closingSource?.id } : { closingSourceId: RELEVO_CLOSING_SOURCE_ID }),
    tags: restaurant.tags?.map((tag) => tag.id),
    promotions: restaurant.promotions?.map((promotion) => promotion.id),
    metaCategories: restaurant.metaCategories?.map((metaCategory) => metaCategory.id) || [CLASSIC_META_CATEGORY_ID],
    keyAccountId: restaurant.keyAccountId || INDIVIDUAL_KEY_ACCOUNT_ID,
    restaurantOpeningHours: restaurant.restaurantOpeningHours,
    overrideOpeningHours: restaurant.overrideOpeningHours,
    latitude: restaurant.latitude || undefined,
    longitude: restaurant.longitude || undefined,
  };
};
