import React, { JSX, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorMessages, MainPageContent, PageHeading, useToast } from 'common/components';
import { useExecuteWithConfirmation } from 'common/utils';
import { useTranslations } from 'contexts/LocalizationContext';
import invariant from 'tiny-invariant';

import EditImages from '../../../common/components/Images/EditImages';
import { getSortedBoxesWithFiles } from '../../../common/utils/images.helper';
import { useModal } from '../../../contexts/ModalContext';
import {
  useChangeOrderKeyAccountGroupImage,
  useDeleteKeyAccountGroupImage,
  useKeyAccountGroupImages,
} from '../../../services/key-account-groups';
import { AddKeyAccountGroupImage } from './AddKeyAccountGroupImage';

const KeyAccountGroupImages = (): JSX.Element => {
  const { displayToast } = useToast();
  const { keyAccountId } = useParams<{ keyAccountId: string }>();
  invariant(keyAccountId, 'keyAccountId is not set within the route');
  const translations = useTranslations();
  const { mutate: deleteImage } = useDeleteKeyAccountGroupImage(keyAccountId);
  const { mutate: changeOrder } = useChangeOrderKeyAccountGroupImage({
    onSuccessfulAssign: async (status) => {
      if (status === 200) {
        displayToast('success', translations('images_sort_success'), false, 5000);
      }
    },
  });
  const { executeWithConfirmation: onDeleteImage } = useExecuteWithConfirmation(
    translations('image_delete_confirmation'),
    deleteImage,
  );

  const { handleToggleModal } = useModal();

  const { data: images, isFetching: isFetchingImages, error: loadImagesError } = useKeyAccountGroupImages(keyAccountId);

  const [boxes, setBoxes] = useState(images);

  const handleSaveImages = () => {
    if (boxes) {
      const boxesWithFiles = getSortedBoxesWithFiles(boxes);
      changeOrder({
        keyAccountGroupId: keyAccountId,
        keyAccountGroupImages: boxesWithFiles.map(({ file }, index) => ({ id: file!.id, order: index })) || [],
      });
    }
  };

  const handleDeleteImage = (id: string) => () => onDeleteImage(id);

  useEffect(() => {
    setBoxes(images);
  }, [images]);

  return (
    <MainPageContent>
      <PageHeading>{translations('images_header')}</PageHeading>
      <ErrorMessages errors={[loadImagesError]} />
      <EditImages
        handleSaveImages={handleSaveImages}
        handleDeleteImage={handleDeleteImage}
        handleToggleModal={handleToggleModal}
        isFetchingImages={isFetchingImages}
        setImages={setBoxes}
        images={boxes}
      >
        <AddKeyAccountGroupImage keyAccountId={keyAccountId} />
      </EditImages>
    </MainPageContent>
  );
};

export default KeyAccountGroupImages;
