import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Button, Flex } from '@chakra-ui/react';
import { useExecuteWithConfirmation } from 'common/utils';

import { ErrorMessages, useToast } from '../../common/components';
import { Drag } from '../../common/components/DragAndDrop/Drag';
import { DragItem } from '../../common/components/DragAndDrop/DragItem';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useDeleteFaqQuestion } from '../../services/frequently-asked-questions';
import { FaqQuestion } from './models/frequently-asked-question.model';
import { FrequentlyAskedQuestion } from './models/frequently-asked-questions-response.model';

type FaqDragAndDropQuestionProps = {
  question: FaqQuestion;
  index: number;
  faq: FrequentlyAskedQuestion;
};

export const FaqDragAndDropQuestion = ({ faq, question, index }: FaqDragAndDropQuestionProps) => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const { language } = useLocalizationContext();
  const { mutate: deleteFaqQuestion, error: deleteQuestionError } = useDeleteFaqQuestion();

  const handleQuestionDelete = (values: { sectionId: string; questionId: string }) => {
    deleteFaqQuestion(values, {
      onSuccess: () => displayToast('success', translations('faq_question_delete_success')),
      onError: () => displayToast('error', translations('faq_question_delete_error')),
    });
  };
  const { executeWithConfirmation: onDeleteFaqQuestion } = useExecuteWithConfirmation(
    translations('faq_question_delete_confirmation'),
    handleQuestionDelete,
  );
  const handleDeleteFaqQuestion = () => onDeleteFaqQuestion({ sectionId: faq.section.id, questionId: question.id });
  return (
    <>
      <ErrorMessages errors={deleteQuestionError} />
      <Drag id={'q' + question.id} index={index}>
        <Flex gap={3}>
          <DragItem w="100%">{question.questionTranslations[language]}</DragItem>
          <DragItem
            as={Link}
            h="auto"
            to={generatePath(Routes.UPDATE_FAQ_QUESTION_PATH, {
              sectionId: faq.section.id,
              id: question.id,
            })}
          >
            <EditIcon />
          </DragItem>
          <DragItem as={Button} h="auto" onClick={handleDeleteFaqQuestion}>
            <DeleteIcon />
          </DragItem>
        </Flex>
      </Drag>
    </>
  );
};
