import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { ErrorMessages, FullPageLoader } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useRelevoUser, useUpdateRelevoUser } from 'services/relevo-users';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import UpdateRelevoUserForm from './UpdateRelevoUser';

const RelevoUserPage = (): JSX.Element => {
  const { id: userId } = useParams<{ id: string }>();
  invariant(userId, 'userId is not set within the route');
  const translations = useTranslations();
  const navigate = useNavigate();
  const { mutate: createUpdateRelevoUser, isLoading, error: modifyRestaurantUserError } = useUpdateRelevoUser();
  const { data: relevoUser, isFetching, error: loadError } = useRelevoUser(userId);

  if (isFetching || isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{relevoUser && translations('relevo_user_update_relevo_user')}</ChildPageHeading>
      <ErrorMessages errors={[loadError, modifyRestaurantUserError]} />
      <UpdateRelevoUserForm
        isLoading={isLoading}
        onSubmit={(values) =>
          createUpdateRelevoUser(
            { values },
            {
              onSuccess: () => navigate(Routes.RELEVO_USERS_PATH),
            },
          )
        }
        data={relevoUser}
      />
    </>
  );
};

export default RelevoUserPage;
