import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import { FormGroup } from '../../../../../common/components';
import { useLocationsSetState } from '../../../../../common/hooks/use-locations-set-state.hook';
import { config } from '../../../../../config/config';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useKeyAccountGroupsList } from '../../../../../services/key-account-groups';
import { KeyAccountGroupsResponse } from '../../../../KeyAccountGroups/models/key-account-groups-response';
import { INDIVIDUAL_KEY_ACCOUNT_ID } from '../../../../Restaurants/restaurant.consts';

type FormActivationFnbGroupsProps = {
  isDisabled?: boolean;
  tooltip?: string;
};

export const FormActivationFnbGroups = ({ isDisabled, tooltip }: FormActivationFnbGroupsProps) => {
  const translations = useTranslations();
  const { control } = useFormContext();

  const { setKeyAccountGroupSearchText, debouncedKeyAccountGroupSearch } = useLocationsSetState();

  const { data: keyAccountGroups, isFetching: isFetchingKeyAccountGroups } = useKeyAccountGroupsList({
    limit: config.paginationSize,
    searchText: debouncedKeyAccountGroupSearch,
    offset: 1,
  });

  const filteredKeyAccountGroups = keyAccountGroups?.items.filter(
    (keyAccountGroup) => keyAccountGroup.id !== INDIVIDUAL_KEY_ACCOUNT_ID,
  );

  return (
    <FormGroup
      label={translations('reuse_cards_activation_fnb_groups_label')}
      labelTooltipText={tooltip}
      inputId={'activationFnbGroups'}
    >
      <Controller
        control={control}
        name="activationFnbGroups"
        render={({ field: { value, name, onChange } }) => (
          <Select
            inputId="activationFnbGroups"
            name={name}
            placeholder={translations('key_account_groups_placeholder')}
            isMulti
            getOptionLabel={(option: KeyAccountGroupsResponse) => option.keyAccount}
            getOptionValue={(option: KeyAccountGroupsResponse) => option.id}
            value={value}
            onChange={onChange}
            onInputChange={setKeyAccountGroupSearchText}
            options={filteredKeyAccountGroups}
            isLoading={isFetchingKeyAccountGroups}
            filterOption={null}
            isDisabled={isDisabled}
          />
        )}
      />
    </FormGroup>
  );
};
