import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

export const useSearchCancelQuery = (searchText: string, queryKey: string): { isActiveQuery: boolean } => {
  const queryClient = useQueryClient();
  const [isActiveQuery, setIsActiveQuery] = useState(true);
  React.useEffect(() => {
    setIsActiveQuery(false);
    const timer = setTimeout(async () => {
      await queryClient.cancelQueries();
      queryClient.removeQueries(queryKey);
      setIsActiveQuery(true);
    }, 300);
    return () => clearTimeout(timer);
  }, [searchText, queryClient, queryKey]);

  return {
    isActiveQuery,
  };
};
