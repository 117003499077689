import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { Category, CategoryRequest } from 'modules/Categories/model/Category.model';

import { createCategory, getCategories, getCategory, updateCategory } from '../api/categories';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';

export const CK_CATEGORIES = 'categories';

export const useCategoriesList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<Category>, AxiosError<HttpException>> =>
  useQuery(
    [CK_CATEGORIES, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getCategories(params),
    { keepPreviousData: true },
  );

export const useCategory = (id: string): QueryObserverResult<Category, AxiosError<HttpException>> =>
  useQuery([CK_CATEGORIES, id], () => getCategory(id));

export const useCreateCategory = (): UseMutationResult<
  Category,
  AxiosError<HttpException>,
  { values: CategoryRequest }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values }) => createCategory(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CATEGORIES);
    },
  });
};

export const useUpdateCategory = (): UseMutationResult<
  Category,
  AxiosError<HttpException>,
  { values: CategoryRequest; id: string }
> => {
  const queryClient = useQueryClient();

  return useMutation(({ values, id }) => updateCategory(values, id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CATEGORIES);
    },
  });
};
