import React, { JSX } from 'react';
import DatePicker from 'react-date-picker';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Switch } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import invariant from 'tiny-invariant';

import { CustomerStatus } from '../../../../api/customers';
import { ErrorMessages, FormFooter, FormGroup, Select } from '../../../../common/components';
import FormInput from '../../../../common/components/FormInput/FormInput';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../../../common/constants/common';
import { DATE_PICKER_LOCALIZATION_CONFIG } from '../../../../config/configConsts';
import { Routes } from '../../../../config/routes';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useUpdateCustomer } from '../../../../services/customers';
import { useKeyAccountGroupsList } from '../../../../services/key-account-groups';
import { useSegmentList } from '../../../../services/segments';
import { KeyAccountGroupsResponse } from '../../../KeyAccountGroups/models/key-account-groups-response';
import { SegmentResponse } from '../../../Segments/model/Segment';
import {
  StarterSetSize,
  StarterSetSizeSelect,
  starterSetSizeSelectOptions,
} from '../../RestaurantForm/RestaurantForm.form';
import { CustomerRequest } from '../models/customer-request.model';
import { AccountTypeEnum, CustomerResponse, StarterSet } from '../models/customer-response.model';
import {
  AccountTypesList,
  accountTypesList,
  CustomerUpdateFormData,
  useCustomerFormValidationSchema,
} from './CustomerForm.form';

interface CustomerFormProps {
  isLoading: boolean;
  model?: CustomerResponse;
}

export interface StatusListProps {
  label: CustomerStatus;
  value: CustomerStatus;
}

export const statusList: StatusListProps[] = [
  {
    label: CustomerStatus.NEW,
    value: CustomerStatus.NEW,
  },
  {
    label: CustomerStatus.APPROVED,
    value: CustomerStatus.APPROVED,
  },
  {
    label: CustomerStatus.READY,
    value: CustomerStatus.READY,
  },
];

export const CustomerForm = ({ model }: CustomerFormProps): JSX.Element => {
  const translations = useTranslations();
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const updateCustomerSchema = useCustomerFormValidationSchema();

  const { mutate: updateCustomer, isLoading: isSavingCustomer, error: updateCustomerError } = useUpdateCustomer();

  const { data: paginatedKeyAccountGroups } = useKeyAccountGroupsList(ALL_PAGINATED_LIST_ITEMS_PARAMS);
  const { data: paginatedSegments } = useSegmentList(ALL_PAGINATED_LIST_ITEMS_PARAMS);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateCustomerSchema),
    defaultValues: model,
  });

  const isHubspotDeal = watch('isHubspotDeal');
  const isPartOfExistingKeyAccountGroup = watch('accountType') === AccountTypeEnum.PART_OF_EXISTING_KEY_ACCOUNT_GROUP;
  const keyAccountId = watch('keyAccountId');

  const onFormSubmit = handleSubmit((data: CustomerUpdateFormData) => {
    const starterSetSize = data.starterSet?.value ?? StarterSetSize.S;

    const starterSet: StarterSet = {
      S: Number(starterSetSize === StarterSetSize.S),
      M: Number(starterSetSize === StarterSetSize.M),
      L: Number(starterSetSize === StarterSetSize.L),
      XL: Number(starterSetSize === StarterSetSize.XL),
      XXL: Number(starterSetSize === StarterSetSize.XXL),
    };

    const customerData: CustomerRequest = {
      ...data,
      hubspotUrl: isHubspotDeal ? data.hubspotUrl : '',
      keyAccountId: isPartOfExistingKeyAccountGroup ? keyAccountId : null,
      starterSet,
    };

    updateCustomer(
      { id, updateCustomerRequest: customerData },
      {
        onSuccess: async () => navigate(Routes.CUSTOMERS_PATH),
      },
    );
  });

  const isFormDisabled = model?.status === CustomerStatus.READY;

  return (
    <>
      <ErrorMessages errors={[updateCustomerError]} />
      <form data-testid="form" onSubmit={onFormSubmit} autoComplete="off">
        <FormGroup label={translations('customer_form_customer_is_hubspot_deal')} inputId="isHubspotDeal">
          <Switch id="isHubspotDeal" {...register('isHubspotDeal')} isDisabled={isFormDisabled} />
        </FormGroup>
        {isHubspotDeal ? (
          <FormInput
            label={translations('customer_form_customer_hubspot_url')}
            validationError={(errors?.hubspotUrl as FieldError)?.message}
            id={'hubspotUrl'}
            disabled={isFormDisabled}
            placeholder={translations('customer_form_customer_hubspot_url_input_placeholder')}
            useFormRegisterReturn={register('hubspotUrl')}
            setValue={setValue}
          />
        ) : null}
        <FormGroup
          label={translations('customer_form_customer_account_type_label')}
          inputId="accountType"
          validationError={(errors.status as FieldError)?.message}
        >
          <Controller
            control={control}
            name="accountType"
            render={({ field: { onChange, name, value } }) => (
              <Select<AccountTypesList>
                name={name}
                placeholder={translations('customer_form_customer_account_type_placeholder')}
                getOptionLabel={(option) => (option as AccountTypesList).label}
                getOptionValue={(option) => (option as AccountTypesList).value}
                value={accountTypesList.find((item) => item?.value === value)}
                valueMapper={(v: AccountTypesList | null) => v?.value}
                onChange={onChange}
                options={accountTypesList}
                isDisabled={isFormDisabled}
              />
            )}
          />
        </FormGroup>

        {isPartOfExistingKeyAccountGroup ? (
          <FormGroup
            label={translations('key_account_group')}
            inputId="keyAccountId"
            validationError={(errors.keyAccountId as FieldError)?.message}
          >
            <Controller
              control={control}
              name="keyAccountId"
              render={({ field: { value, onChange, name } }) => (
                <Select<KeyAccountGroupsResponse>
                  defaultValue={paginatedKeyAccountGroups?.items[0]}
                  name={name}
                  getOptionLabel={(option) =>
                    `${(option as KeyAccountGroupsResponse).keyAccount} - ${(option as KeyAccountGroupsResponse).segment
                      ?.name}`
                  }
                  getOptionValue={(option) =>
                    `${(option as KeyAccountGroupsResponse).keyAccount} - ${(option as KeyAccountGroupsResponse).segment
                      ?.name}`
                  }
                  onChange={onChange}
                  value={paginatedKeyAccountGroups?.items?.find((group) => group.id === value)}
                  valueMapper={(v: KeyAccountGroupsResponse | null) => v?.id}
                  options={paginatedKeyAccountGroups?.items}
                  isDisabled={isFormDisabled}
                />
              )}
            />
          </FormGroup>
        ) : null}

        <FormGroup
          label={translations('segment')}
          inputId="segmentId"
          validationError={(errors.segmentId as FieldError)?.message}
        >
          <Controller
            control={control}
            name="segmentId"
            render={({ field: { value, onChange, name } }) => (
              <Select<SegmentResponse>
                name={name}
                getOptionLabel={(option) => (option as SegmentResponse).name}
                getOptionValue={(option) => (option as SegmentResponse).id}
                defaultValue={!model?.segmentId ? paginatedSegments?.items[0] : null}
                value={paginatedSegments?.items.find((item) => item.id === value)}
                onChange={onChange}
                options={paginatedSegments?.items}
              />
            )}
          />
        </FormGroup>
        <FormInput
          label={translations('customer_form_sales_organisation')}
          validationError={(errors?.salesOrganisation as FieldError)?.message}
          id={'salesOrganisation'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('salesOrganisation')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_sales_person_name')}
          validationError={(errors?.salesPersonName as FieldError)?.message}
          id={'salesPersonName'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('salesPersonName')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_sales_person_email')}
          validationError={(errors?.salesPersonEmail as FieldError)?.message}
          id={'salesPersonEmail'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('salesPersonEmail')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_sales_person_phone')}
          validationError={(errors?.salesPersonPhone as FieldError)?.message}
          id={'salesPersonPhone'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('salesPersonPhone')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_whosale_customer_id')}
          validationError={(errors?.wholesaleCustomerId as FieldError)?.message}
          id={'wholesaleCustomerId'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('wholesaleCustomerId')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_name')}
          validationError={(errors?.customerName as FieldError)?.message}
          id={'customerName'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('customerName')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_city')}
          validationError={(errors?.billingAddressCity as FieldError)?.message}
          id={'billingAddressCity'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('billingAddressCity')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_fist_name')}
          validationError={(errors?.contactPersonFirstName as FieldError)?.message}
          id={'contactPersonFirstName'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('contactPersonFirstName')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_last_name')}
          validationError={(errors?.contactPersonLastName as FieldError)?.message}
          id={'contactPersonLastName'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('contactPersonLastName')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_email')}
          validationError={(errors?.contactPersonEmail as FieldError)?.message}
          id={'contactPersonEmail'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('contactPersonEmail')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_phone')}
          validationError={(errors?.contactPersonPhone as FieldError)?.message}
          id={'contactPersonPhone'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('contactPersonPhone')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_address_street_number')}
          validationError={(errors?.billingAddressStreetNumber as FieldError)?.message}
          id={'billingAddressStreetNumber'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('billingAddressStreetNumber')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_additional_info')}
          validationError={(errors?.billingAddressAddition as FieldError)?.message}
          id={'billingAddressAddition'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('billingAddressAddition')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_zip_code')}
          validationError={(errors?.billingAddressZipCode as FieldError)?.message}
          id={'billingAddressZipCode'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('billingAddressZipCode')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_country')}
          validationError={(errors?.billingAddressCountry as FieldError)?.message}
          id={'billingAddressCountry'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('billingAddressCountry')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_billing_person_title')}
          validationError={(errors?.billingPersonTitle as FieldError)?.message}
          id={'billingPersonTitle'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('billingPersonTitle')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_billing_name')}
          validationError={(errors?.billingName as FieldError)?.message}
          id={'billingName'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('billingName')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_billing_email')}
          validationError={(errors?.billingEmail as FieldError)?.message}
          id={'billingEmail'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('billingEmail')}
          setValue={setValue}
        />
        <FormInput
          label={translations('customer_form_customer_special_requests')}
          validationError={(errors?.specialRequests as FieldError)?.message}
          id={'specialRequests'}
          disabled={isFormDisabled}
          useFormRegisterReturn={register('specialRequests')}
          setValue={setValue}
        />

        <FormGroup label={translations('customer_form_customer_desired_start_date')} inputId="desiredStartDate">
          <Controller
            control={control}
            name="desiredStartDate"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                locale={DATE_PICKER_LOCALIZATION_CONFIG.locale}
                format={DATE_PICKER_LOCALIZATION_CONFIG.dateFormat}
                value={value ? new Date(value) : null}
                disabled={isFormDisabled}
                onChange={(v) => {
                  onChange(v);
                }}
              />
            )}
          />
        </FormGroup>

        <FormGroup
          label={translations('customer_form_customer_status')}
          inputId="status"
          validationError={(errors.status as FieldError)?.message}
        >
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, name } }) => (
              <Select<StatusListProps>
                name={name}
                placeholder={translations('customer_form_customer_status')}
                getOptionLabel={(option) => (option as StatusListProps).label}
                getOptionValue={(option) => (option as StatusListProps).value}
                defaultValue={statusList.find((item) => item.value === model?.status)}
                onChange={onChange}
                valueMapper={(v: StatusListProps | null) => v?.value}
                options={statusList}
              />
            )}
          />
        </FormGroup>

        <FormGroup label={translations('customer_form_customer_starterset')} inputId={'starterSet'}>
          <Controller
            control={control}
            name="starterSet"
            render={({ field: { onChange, name } }) => (
              <Select<StarterSetSizeSelect>
                defaultValue={starterSetSizeSelectOptions.find(
                  (item) => model?.starterSet && model.starterSet[item.value],
                )}
                name={name}
                getOptionLabel={(option) => (option as StarterSetSizeSelect).label}
                getOptionValue={(option) => (option as StarterSetSizeSelect).value}
                onChange={onChange}
                isDisabled={isFormDisabled}
                options={starterSetSizeSelectOptions}
              />
            )}
          />
        </FormGroup>

        <FormGroup label={translations('customer_form_customer_is_sepa')} inputId="isSepaDesired">
          <Switch id="isSepaDesired" {...register('isSepaDesired')} isDisabled={isFormDisabled} />
        </FormGroup>

        <FormGroup
          label={translations('customer_form_customer_is_social_press_permitted')}
          inputId="isSocialMediaPressPermitted"
        >
          <Switch
            id="isSocialMediaPressPermitted"
            {...register('isSocialMediaPressPermitted')}
            isDisabled={isFormDisabled}
          />
        </FormGroup>

        <FormFooter isLoadingSubmitResult={isSavingCustomer} hasCustomContent={false} />
      </form>
    </>
  );
};
