import React, { JSX } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../common/components/FormInput/FormInput';
import { Language } from '../../common/utils';
import { TagRequest } from './models/tag-request';
import { TagResponse } from './models/tag-response';
import { useTagFormValidationSchema } from './TagForm.form';

type TagFormProps = {
  isLoading: boolean;
  onSubmit: (request: TagRequest) => void;
  model?: TagResponse;
};

const TagForm = ({ isLoading, onSubmit, model }: TagFormProps): JSX.Element => {
  const translations = useTranslations();

  const validationSchema = useTagFormValidationSchema();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<TagRequest>({ resolver: yupResolver(validationSchema) });

  React.useEffect(() => {
    reset(model);
  }, [reset, model]);

  const onFormSubmit = handleSubmit(onSubmit);

  return (
    <form onSubmit={onFormSubmit}>
      {Object.values(Language).map((language) => (
        <Box key={language}>
          <FormInput
            id={`translations.${language}`}
            label={translations(`tags_${language}_translations`)}
            validationError={errors.translations ? errors.translations[language]?.message : undefined}
            useFormRegisterReturn={register(`translations.${language}`)}
            setValue={setValue}
          />
        </Box>
      ))}
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default TagForm;
