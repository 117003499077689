import { getStringUrl } from '../../../common/utils/url.helper';
import { Deliverers } from '../models';

export const getDeliverersData = (
  customDeliveryUrl: string | null,
  customDeliveryImageUrl: string | null,
  deliverers?: Deliverers,
): Deliverers => {
  return {
    wolt: {
      url: getStringUrl(deliverers?.wolt?.url?.trim()),
    },
    uberEats: {
      url: getStringUrl(deliverers?.uberEats?.url?.trim()),
    },
    lieferando: {
      url: getStringUrl(deliverers?.lieferando?.url?.trim()),
    },
    customDelivery: {
      url: customDeliveryUrl,
      imageUrl: customDeliveryImageUrl,
    },
  };
};
