import React, { JSX } from 'react';
import DatePicker from 'react-date-picker';
import { Box, HStack } from '@chakra-ui/react';

import '../../../styles/DatePicker.css';

import { getFormattedDate } from '../../../common/utils';
import { DATE_PICKER_LOCALIZATION_CONFIG } from '../../../config/configConsts';
import { useTranslations } from '../../../contexts/LocalizationContext';
import ReportsLabel from './ReportsLabel';

type DateRangeProps = {
  startDate: string;
  endDate: string;
  onStartDateChange: (value: string) => void;
  onEndDateChange: (value: string) => void;
};

const DateRange = ({ startDate, endDate, onStartDateChange, onEndDateChange }: DateRangeProps): JSX.Element => {
  const translations = useTranslations();

  return (
    <HStack w="100%" spacing="10px">
      <Box w="50%">
        <ReportsLabel label={translations('reports_start_date_label')} />
        <DatePicker
          locale={DATE_PICKER_LOCALIZATION_CONFIG.locale}
          format={DATE_PICKER_LOCALIZATION_CONFIG.dateFormat}
          maxDate={new Date()}
          value={startDate.length > 0 ? new Date(startDate) : null}
          onChange={(v: any) => {
            onStartDateChange(getFormattedDate(v));
          }}
        />
      </Box>
      <Box w="50%">
        <ReportsLabel label={translations('reports_end_date_label')} />
        <DatePicker
          locale={DATE_PICKER_LOCALIZATION_CONFIG.locale}
          format={DATE_PICKER_LOCALIZATION_CONFIG.dateFormat}
          maxDate={new Date()}
          value={endDate.length > 0 ? new Date(endDate) : null}
          onChange={(v: any) => {
            onEndDateChange(getFormattedDate(v));
          }}
        />
      </Box>
    </HStack>
  );
};

export default DateRange;
