import { generatePath } from 'react-router-dom';

import { BaseQueryParams, Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { ItemStatus } from '../modules/AssignedItems/models/enums/item-status';
import { ReuseCardUserAssignedItemResponse } from '../modules/ReuseCards/models/reuse-card-user-assigned-item-response';
import { ReuseCardUserResponse } from '../modules/ReuseCards/models/reuse-card-user-response';
import { ReuseCardUserUpdateRequest } from '../modules/ReuseCards/models/reuse-card-user-update-request';
import { ReuseCardUsersCreateRequest } from '../modules/ReuseCards/models/reuse-card-users-create-request';
import axiosInstance from './axios';

export const createReuseCardUsers = (reuseCardUsersCreateRequest: ReuseCardUsersCreateRequest): Promise<string> => {
  return axiosInstance
    .post<string>(apiPaths.REUSE_CARDS_CREATE_BATCH, reuseCardUsersCreateRequest)
    .then((response) => response.data);
};

export const getReuseCardUsers = ({
  searchText,
  offset,
  limit,
}: BaseQueryParams): Promise<Paginated<ReuseCardUserResponse>> => {
  const params = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance
    .get<Paginated<ReuseCardUserResponse>>(apiPaths.REUSE_CARDS, {
      params,
    })
    .then((response) => response.data);
};

export const getReuseCardUser = (id: string): Promise<ReuseCardUserResponse> => {
  return axiosInstance
    .get<ReuseCardUserResponse>(generatePath(apiPaths.REUSE_CARDS_REUSE_CARD, { reuseCardId: id }))
    .then((response) => response.data);
};

export const updateReuseCardUser = (
  id: string,
  reuseCardUserUpdateRequest: ReuseCardUserUpdateRequest,
): Promise<ReuseCardUserResponse> => {
  return axiosInstance
    .patch<ReuseCardUserResponse>(
      generatePath(apiPaths.REUSE_CARDS_REUSE_CARD, { reuseCardId: id }),
      reuseCardUserUpdateRequest,
    )
    .then((response) => response.data);
};

export const getReuseCardUserAssignedItems = (
  id: string,
  {
    offset,
    limit,
    status,
  }: Omit<BaseQueryParams, 'searchText'> & {
    status?: ItemStatus;
  },
): Promise<Paginated<ReuseCardUserAssignedItemResponse>> => {
  const additionalParams = new Map();
  additionalParams.set('status', status);
  const params = buildBasicPaginatedSearchParams(offset, limit, undefined, additionalParams);
  return axiosInstance
    .get<Paginated<ReuseCardUserAssignedItemResponse>>(
      generatePath(apiPaths.REUSE_CARDS_REUSE_CARD_ITEMS, { reuseCardId: id }),
      {
        params,
      },
    )
    .then((response) => response.data);
};
