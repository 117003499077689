import React, { createContext, JSX, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { GenerateProductsResponse } from 'modules/Products/Product.model';
import { useGetQueueResult } from 'services/products';

import { BackgroundRequests } from './BackgroundRequests.enum';

// When more background requests will be introduced, this type should be changed to union
// and each new response type should be added here.
type PossibleResults = GenerateProductsResponse;

type BackgroundActionsContextProviderProps = {
  children: React.ReactNode;
};

type BackgroundActionsContextType = {
  addRequest: (requestKey: BackgroundRequests, mutationArg: string) => void;
  removeRequest: (requestKey: BackgroundRequests) => void;
  requestResults: Record<BackgroundRequests, UseQueryResult<PossibleResults>>;
};

export const BackgroundRequestsContext = createContext<BackgroundActionsContextType>({
  addRequest: () => {},
  removeRequest: () => {},
  requestResults: {} as Record<BackgroundRequests, UseQueryResult<PossibleResults>>,
});

export const BackgroundRequestsContextProvider = ({ children }: BackgroundActionsContextProviderProps): JSX.Element => {
  const [activeRequests, setActiveRequests] = useState<Record<BackgroundRequests, string> | null>(null);

  const requestResults = {
    [BackgroundRequests.PRODUCTS_GENERATION]: useGetQueueResult(
      activeRequests?.[BackgroundRequests.PRODUCTS_GENERATION],
    ),
    [BackgroundRequests.PRODUCTS_CSV_LIST]: useGetQueueResult(activeRequests?.[BackgroundRequests.PRODUCTS_CSV_LIST]),
  };

  const handleAddRequest = (actionName: BackgroundRequests, mutationArg: string) => {
    if (Object.keys(activeRequests || {}).includes(actionName)) {
      return;
    }

    setActiveRequests((actions) => ({ ...actions!, [actionName]: mutationArg }));
  };

  const handleRemoveRequest = (actionName: BackgroundRequests) => {
    if (!activeRequests || !Object.keys(activeRequests).length || !Object.keys(activeRequests).includes(actionName)) {
      return;
    }

    const { [actionName]: currentName, ...restOfActions } = activeRequests;

    if (!restOfActions || !Object.keys(restOfActions).length) {
      setActiveRequests(null);
      return;
    }

    setActiveRequests(restOfActions as Record<BackgroundRequests, string>);
  };

  return (
    <BackgroundRequestsContext.Provider
      value={{
        addRequest: handleAddRequest,
        removeRequest: handleRemoveRequest,
        requestResults,
      }}
    >
      {children}
    </BackgroundRequestsContext.Provider>
  );
};
