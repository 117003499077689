import React, { JSX } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter, FormGroup, Select } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../common/components/FormInput/FormInput';
import { Language } from '../../common/utils';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useCountryList } from '../../services/countries';
import { Country } from '../Country/model/Country';
import { City, CityFormValues, CityRequest } from './models/City';
import { useCityFormValidationSchema } from './CityForm.form';

type Props = {
  data?: City | undefined;
  isLoading: boolean;
  onSubmit(data: CityRequest): void;
};

const CityForm = ({ isLoading, onSubmit, data }: Props): JSX.Element => {
  const translations = useTranslations();
  const { language: languageFromContext } = useLocalizationContext();
  const { data: paginatedCountries } = useCountryList();
  const validationSchema = useCityFormValidationSchema();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<CityFormValues>({ resolver: yupResolver(validationSchema) });

  React.useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onFormSubmit = handleSubmit(({ ...form }: CityFormValues) => {
    onSubmit({ translations: form.translations, countryId: form.country.id });
  });

  return (
    <form onSubmit={onFormSubmit}>
      {Object.values(Language).map((language) => (
        <Box key={language}>
          <FormInput
            id={`translations.${language}`}
            label={translations(`cities_form_name_${language}`)}
            validationError={errors.translations ? errors.translations[language]?.message : undefined}
            useFormRegisterReturn={register(`translations.${language}`)}
            setValue={setValue}
          />
        </Box>
      ))}
      <FormGroup
        label={translations('cities_form_country')}
        inputId="country"
        validationError={(errors.country as FieldError)?.message}
      >
        <Controller
          control={control}
          name="country"
          render={({ field: { value, onChange, name } }) => (
            <Select<Country>
              name={name}
              getOptionLabel={(option) => (option as Country).translations[languageFromContext]}
              getOptionValue={(option) => (option as Country).id}
              onChange={onChange}
              value={value}
              options={paginatedCountries?.items}
            />
          )}
        />
      </FormGroup>
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default CityForm;
