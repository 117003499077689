import React, { JSX } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '@chakra-ui/react';
import { FormFooter, FormGroup } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { Promotion } from './models/Promotion';

type CreateUpdatePromotionFormProps = {
  data?: Promotion;
  isLoading: boolean;
  onSubmit(data: PromotionFormValues): void;
};

export type PromotionFormValues = Omit<Promotion, 'name'> & { id?: string };

export const CreateUpdatePromotionForm = ({
  isLoading,
  onSubmit,
  data,
}: CreateUpdatePromotionFormProps): JSX.Element => {
  const translations = useTranslations();
  const { register, handleSubmit, reset } = useForm<PromotionFormValues>();

  React.useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onFormSubmit = handleSubmit(onSubmit);

  return (
    <form onSubmit={onFormSubmit}>
      <FormGroup label={translations('promotions_form_name')} inputId="translations.en">
        <Input {...register('translations.en')} />
      </FormGroup>
      <FormGroup label={translations('promotions_form_name_de')} inputId="translations.de">
        <Input {...register('translations.de')} />
      </FormGroup>
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};
