import React, { ComponentProps, JSX } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

type LinkButtonProps = {
  to: string;
  isDownload?: boolean;
  state?: any;
};

export const LinkButton = ({
  to,
  children,
  isDownload,
  state,
  ...props
}: ComponentProps<typeof Button> & LinkButtonProps): JSX.Element => {
  if (isDownload) {
    return (
      <a href={to} download={true}>
        <Button {...props}>{children}</Button>
      </a>
    );
  }
  return (
    <Link to={to} state={state}>
      <Button {...props}>{children}</Button>
    </Link>
  );
};
