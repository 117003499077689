import React, { useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { PageHeading, Table } from '../../../../../common/components';
import { DEFAULT_PAGINATED_TABLE_LIMIT } from '../../../../../common/constants/common';
import { formatDateTime } from '../../../../../common/utils';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useRestaurantAssignedItems } from '../../../../../services/restaurant-assigned-items';
import { ItemStatus } from '../../../../AssignedItems/models/enums/item-status';
import { RestaurantAssignedItemsResponse } from '../../../TwoFactorReturn/models/restaurant-assigned-items-response';

type DepositItemsTableProps = {
  restaurantId: string;
};

export const DepositItemsTable = ({ restaurantId }: DepositItemsTableProps) => {
  const translations = useTranslations();
  const [page, setPage] = useState(1);
  const params = {
    offset: page,
    limit: DEFAULT_PAGINATED_TABLE_LIMIT,
    status: ItemStatus.DEPOSIT,
  };

  const { data: paginatedDepositItems, isFetching: isFetchingAssignedItems } = useRestaurantAssignedItems(
    restaurantId,
    params,
  );

  const columns = useMemo(
    () => [
      {
        Header: translations('restaurant_deposit_table_product_name'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantAssignedItemsResponse>) => original.product?.productName,
      },
      {
        Header: translations('restaurant_deposit_table_product_u_id'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantAssignedItemsResponse>) => original.product?.uId,
      },
      {
        Header: translations('restaurant_deposit_table_category_name'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantAssignedItemsResponse>) => original.product?.categoryName,
      },
      {
        Header: translations('restaurant_deposit_table_borrowed_at'),
        accessor: 'borrowedAt' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantAssignedItemsResponse>) => (
          <>{original.borrowedAt ? formatDateTime(new Date(original.borrowedAt)) : null}</>
        ),
      },
    ],
    [translations],
  );
  return (
    <>
      <PageHeading>{translations('restaurant_deposit_items_header')}</PageHeading>
      <Table<RestaurantAssignedItemsResponse>
        data={paginatedDepositItems?.items}
        columns={columns}
        isLoading={isFetchingAssignedItems}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedDepositItems?.totalPages}
        paginationEnabled
      />
    </>
  );
};
