import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios/index';

import {
  createRestaurantReturnCode,
  createReturnCodes,
  getRestaurantReturnCode,
  getRestaurantReturnCodes,
  updateRestaurantReturnCode,
} from '../api/return-codes';
import { useToast } from '../common/components';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { CreateRestaurantsReturnCodesRequest } from '../modules/KeyAccountGroups/KeyAccountGroupRestaurants/components/RestaurantsReturnCodes/models/create-restaurants-return-codes-request';
import { RestaurantReturnCodeCreateRequest } from '../modules/Restaurants/RestaurantReturnCodes/models/restaurant-return-code-create-request';
import { RestaurantReturnCodeResponse } from '../modules/Restaurants/RestaurantReturnCodes/models/restaurant-return-code-response';
import { RestaurantReturnCodeUpdateRequest } from '../modules/Restaurants/RestaurantReturnCodes/models/restaurant-return-code-update-request';
import { RestaurantsCreateReturnCodesResponse } from '../modules/Restaurants/RestaurantReturnCodes/models/restaurants-create-return-codes-response';
import { CK_KEY_ACCOUNT_GROUP_RETURN_CODES } from './key-account-groups';

export const CK_RESTAURANT_RETURN_CODES = 'restaurant-return-codes';

export interface UpdateRestaurantReturnCodeMutation {
  id: string;
  restaurantId: string;
  updateRestaurantReturnCodeRequest: Partial<RestaurantReturnCodeUpdateRequest>;
}

export const useRestaurantReturnCodesList = (
  restaurantId: string,
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
  enabled = true,
): QueryObserverResult<Paginated<RestaurantReturnCodeResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_RESTAURANT_RETURN_CODES, { text: params.searchText, page: params.offset, limit: params.limit, restaurantId }],
    () => getRestaurantReturnCodes(restaurantId, params),
    {
      keepPreviousData: true,
      enabled,
    },
  );
};

export const useCreateRestaurantReturnCode = (
  redirect?: string,
): UseMutationResult<
  RestaurantReturnCodeResponse,
  AxiosError<HttpException>,
  { restaurantId: string; createRestaurantReturnCodeRequest: RestaurantReturnCodeCreateRequest }
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { displayToast } = useToast();
  const translations = useTranslations();
  return useMutation(
    ({
      restaurantId,
      createRestaurantReturnCodeRequest,
    }: {
      restaurantId: string;
      createRestaurantReturnCodeRequest: RestaurantReturnCodeCreateRequest;
    }) => createRestaurantReturnCode(restaurantId, createRestaurantReturnCodeRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_RESTAURANT_RETURN_CODES);
        if (redirect) {
          navigate(redirect);
        }
      },
      onError: (error) => {
        const toastMessage = error.response?.data.message
          ? error.response?.data.message
          : translations('return_codes_create_error');
        displayToast('error', toastMessage);
      },
    },
  );
};

export const useUpdateRestaurantReturnCode = (
  redirect?: string,
): UseMutationResult<
  RestaurantReturnCodeResponse,
  AxiosError<HttpException>,
  { id: string; restaurantId: string; updateRestaurantReturnCodeRequest: Partial<RestaurantReturnCodeUpdateRequest> }
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, restaurantId, updateRestaurantReturnCodeRequest }: UpdateRestaurantReturnCodeMutation) =>
      updateRestaurantReturnCode(id, restaurantId, updateRestaurantReturnCodeRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_RESTAURANT_RETURN_CODES);
        await queryClient.invalidateQueries(CK_KEY_ACCOUNT_GROUP_RETURN_CODES);
        if (redirect) {
          navigate(redirect);
        }
      },
    },
  );
};

export const useRestaurantReturnCode = (
  id: string,
  restaurantId: string,
): QueryObserverResult<RestaurantReturnCodeResponse, AxiosError<HttpException>> =>
  useQuery([CK_RESTAURANT_RETURN_CODES, id], () => getRestaurantReturnCode(id, restaurantId));

export const useCreateRestaurantsReturnCodes = (): UseMutationResult<
  RestaurantsCreateReturnCodesResponse,
  AxiosError<HttpException>,
  { request: CreateRestaurantsReturnCodesRequest }
> => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  return useMutation(({ request }: { request: CreateRestaurantsReturnCodesRequest }) => createReturnCodes(request), {
    onError: () => displayToast('error', translations('something_went_wrong')),
  });
};
