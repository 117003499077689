import React, { JSX } from 'react';
import { Badge } from '@chakra-ui/react';
import { AxiosError } from 'axios';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { HttpException } from '../../models/httpException';

type ErrorMessagesProps = {
  errors: (AxiosError<HttpException> | null)[] | AxiosError<HttpException> | null;
};

export const ErrorMessages = ({ errors }: ErrorMessagesProps): JSX.Element | null => {
  const translations = useTranslations();
  const errorsArray = Array.isArray(errors) ? errors : [errors];
  const nonNullErrors = errorsArray.filter(
    (possibleError): possibleError is AxiosError<HttpException> => !!possibleError,
  );

  if (!nonNullErrors.length) {
    return null;
  }

  if (!nonNullErrors.some((error) => error?.response?.data?.message)) {
    return <Badge colorScheme="red">{translations('something_went_wrong')}</Badge>;
  }

  return (
    <>
      {nonNullErrors.map((error, index) => (
        <React.Fragment key={index}>
          {Array.isArray(error?.response?.data?.message) ? (
            <>
              {error?.response?.data.message.map((message: string) => (
                <div key={message}>
                  <Badge colorScheme="red">{message}</Badge>
                </div>
              ))}
            </>
          ) : (
            <Badge colorScheme="red">{error?.response?.data?.message}</Badge>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
