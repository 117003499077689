import React, { createContext, useEffect, useState } from 'react';
import enTranslations from 'translations/en.json';

import { Language } from '../../common/models/enums/language';
import { LocalizationContextInterface, SetLanguage, TranslationKeys } from './types';
import getTranslations from './utils';

// TODO: consider changing the implementation so that translations in deps does not cause re-rendering or putting it in deps is not necessary
// check https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
export const LocalizationContext = createContext<LocalizationContextInterface>({
  language: Language.En,
  translations: {},
  setLanguage: () => {},
});

const setTranslationKeys = async (setTranslations: SetLanguage, language: Language) => {
  const translations = await getTranslations(language);
  setTranslations(translations);
};

export const LocalizationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [translations, setTranslations] = useState<TranslationKeys>(enTranslations);
  const [language, setLanguage] = useState(Language.En);

  useEffect(() => {
    setTranslationKeys(setTranslations, language);
  }, [language]);

  return (
    <LocalizationContext.Provider
      value={{
        language,
        translations,
        setLanguage,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
