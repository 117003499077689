import { BaseQueryParams, Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { RestaurantUserResponse } from '../modules/RestaurantCredentials/models';
import { CustomerRequest } from '../modules/Restaurants/Customers/models/customer-request.model';
import { CustomerResponse } from '../modules/Restaurants/Customers/models/customer-response.model';
import { CustomersResponse } from '../modules/Restaurants/Customers/models/Customers.model';
import { FileResponse } from '../modules/Restaurants/Customers/models/file.response.model';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export enum CustomerStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  READY = 'READY',
}

export enum CustomersFilterProperties {
  STATUS = 'status',
  SALES_ORGANISATION = 'salesOrganisation',
  SALES_PERSON_NAME = 'salesPersonName',
  CUSTOMER_NAME = 'customerName',
  BILLING_ADDRESS_CITY = 'billingAddressCity',
  DESIRED_START_DATE = 'desiredStartDate',
}

export type CustomersFilterParams = { [key: string]: string };

export const getCustomers = (
  paginationParams: Omit<BaseQueryParams, 'searchText'>,
  additionalParams?: CustomersFilterParams,
): Promise<Paginated<CustomersResponse>> => {
  const getParamsString = () => {
    const defaultParams = buildBasicPaginatedSearchParams(paginationParams.offset, paginationParams.limit);

    if (additionalParams && Object.keys(additionalParams).length) {
      for (const key of Object.keys(additionalParams)) {
        defaultParams.append(key, additionalParams[key]);
      }

      return `${defaultParams.toString()}`;
    }

    if (additionalParams?.startDate) {
      defaultParams.append('startDate', additionalParams?.startDate);
    }

    if (additionalParams?.endDate) {
      defaultParams.append('endDate', additionalParams?.endDate);
    }

    return defaultParams;
  };

  return axiosInstance
    .get<Paginated<CustomersResponse>>(`${apiPaths.CUSTOMERS}?${getParamsString()}`)
    .then(({ data }) => data);
};

export const getCustomer = (id: string): Promise<CustomerResponse> =>
  axiosInstance.get<CustomerResponse>(`${apiPaths.CUSTOMERS}/${id}`).then(({ data }) => data);

export const createCustomerAccount = (id: string, password: string): Promise<RestaurantUserResponse> => {
  return axiosInstance.post(`${apiPaths.CUSTOMERS}/${id}`, { password }).then(({ data }) => data);
};

export const updateCustomer = (id: string, updateCustomerRequest: CustomerRequest): Promise<CustomerResponse> => {
  return axiosInstance.patch(`${apiPaths.CUSTOMERS}/${id}`, updateCustomerRequest);
};

export const updateContractFile = (customerId: string, fileId: string, file: File): Promise<FileResponse> => {
  const formData = createFormData({ contractFile: file });
  return axiosInstance
    .put(`${apiPaths.CUSTOMERS}/${customerId}/contract-files/${fileId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data);
};

export const updateExcelFIle = (customerId: string, fileId: string, file: File): Promise<FileResponse> => {
  const formData = createFormData({ relevoCalculatorFile: file });
  return axiosInstance
    .put(`${apiPaths.CUSTOMERS}/${customerId}/calculator-files/${fileId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data);
};

export const exportCustomers = async (customerIds: string[]): Promise<string> => {
  return axiosInstance
    .get<string>(`${apiPaths.CUSTOMERS}/hubspot/export`, {
      params: { customerIds },
      responseType: 'blob',
    })
    .then((response) => response.data);
};
