import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import {
  createRelevoUser,
  deleteRelevoUser,
  getRelevoUser,
  getRelevoUsersList,
  updateRelevoUser,
} from 'api/relevo-users';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { RelevoUserFormModel, RelevoUserRequest, RelevoUserResponse } from 'modules/RelevoUsers/models';

import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';

export const CK_RELEVO_USERS = 'relevoUsers';

export const useRelevoUsersList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<RelevoUserResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_RELEVO_USERS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getRelevoUsersList(params),
    {
      keepPreviousData: true,
    },
  );
};

export interface CreateRelevoUserMutation {
  createRelevoUserRequest: RelevoUserRequest;
}

export const useCreateRelevoUser = (
  onSuccess: (data: RelevoUserResponse, variable: CreateRelevoUserMutation) => void,
): UseMutationResult<RelevoUserResponse, AxiosError<HttpException>, { createRelevoUserRequest: RelevoUserRequest }> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ createRelevoUserRequest }: CreateRelevoUserMutation) => createRelevoUser(createRelevoUserRequest),
    {
      onSuccess: async (data, variable) => {
        await queryClient.invalidateQueries(CK_RELEVO_USERS);
        onSuccess(data, variable);
      },
    },
  );
};

export const useRelevoUser = (id: string): QueryObserverResult<RelevoUserFormModel, AxiosError<HttpException>> => {
  return useQuery([CK_RELEVO_USERS, id], () => getRelevoUser(id));
};

export const useUpdateRelevoUser = (): UseMutationResult<
  RelevoUserResponse,
  AxiosError<HttpException>,
  { values: RelevoUserRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ values }: { values: RelevoUserRequest }) => updateRelevoUser(values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_RELEVO_USERS);
    },
  });
};

export const useDeleteRelevoUser = (): UseMutationResult<void, AxiosError<HttpException>, { id: string }> => {
  const queryClient = useQueryClient();
  return useMutation(({ id }: { id: string }) => deleteRelevoUser(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_RELEVO_USERS);
    },
  });
};
