import React, { JSX, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { Button, Flex, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileInput, FormFooter, FormGroup, Select } from 'common/components';
import { readBlob } from 'common/utils/readBlob.helper';
import { useTranslations } from 'contexts/LocalizationContext';
import {
  useAssignProductsByUIdsToRestaurant,
  useAssignProductsByUIdsToRestaurantWithCategoryRewriting,
} from 'services/product-assignment';

import { extractUId } from '../../../common/utils/qrCodes.helper';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useCategoriesList } from '../../../services/categories';
import { Category } from '../../Categories/model/Category.model';
import { RestaurantSelect } from '../../RestaurantSelect/RestaurantSelect';
import { AssignItemsToRestaurantResponse } from './model/AssignItemsToRestaurant.model';
import {
  AssignProductsFromCsvFormType,
  useAssignProductsFromCsvFormValidation,
  useMassAssignProductsFromCsvFormValidation,
} from './AssignProductsFromCsv.form';

type AssignProductsFromCsvProps = {
  onModalClose: () => void;
  isMassAssign: boolean;
  onAssign: (data: AssignItemsToRestaurantResponse) => void;
};

export const AssignProductsFromCsv = ({
  isMassAssign,
  onModalClose,
  onAssign,
}: AssignProductsFromCsvProps): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [categorySearchText, setCategorySearchText] = useState('');
  const validationSchema = useAssignProductsFromCsvFormValidation();
  const massAssignValidationSchema = useMassAssignProductsFromCsvFormValidation();
  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(isMassAssign ? massAssignValidationSchema : validationSchema),
  });

  const { mutate: assignProductsToRestaurant, isLoading: isAssignProductsInProgress } =
    useAssignProductsByUIdsToRestaurant({
      onSuccessfulAssign: (data) => onAssign(data),
    });

  const { mutate: massAssignProductsToRestaurant, isLoading: isMassAssignProductsInProgress } =
    useAssignProductsByUIdsToRestaurantWithCategoryRewriting({
      onSuccessfulAssign: (data) => onAssign(data),
    });

  const { data: searchedCategories, isLoading: isLoadingCategories } = useCategoriesList({
    limit: 100,
    offset: 1,
    searchText: categorySearchText,
  });

  const handleFormSubmit = handleSubmit(async (formValues: AssignProductsFromCsvFormType) => {
    let productUIds = (await readBlob(formValues.csvFile![0]))?.split(/[\r\n]+/);
    productUIds = productUIds?.map((qrCode) => extractUId(qrCode) || '');

    if (!productUIds || !formValues?.restaurant?.id) {
      throw new Error('ProductIds and restaurantId should not be empty, check form validation');
    }
    productUIds = productUIds.filter((uId) => uId !== '' && uId.length >= 20);

    if (isMassAssign) {
      if (!formValues?.category?.id) {
        throw new Error('Category should not be empty, check form validation');
      }
      await massAssignProductsToRestaurant({
        productIds: productUIds,
        restaurantId: formValues.restaurant.id,
        categoryId: formValues.category.id,
      });
    } else {
      await assignProductsToRestaurant({
        productIds: productUIds,
        restaurantId: formValues.restaurant.id,
      });
    }
  });

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <FormGroup
          inputId="restaurant"
          label={translations('products_assignment_from_csv_modal_restaurant_label')}
          validationError={(errors.restaurant as FieldError)?.message}
        >
          <Controller
            control={control}
            name="restaurant"
            render={({ field: { value, onChange, name } }) => (
              <RestaurantSelect
                name={name}
                value={value}
                onChange={onChange}
                valueMapper={(restaurant) => restaurant?.id}
              />
            )}
          />
        </FormGroup>
        {isMassAssign && (
          <FormGroup
            inputId="category"
            label={translations('products_assignment_from_csv_modal_category_label')}
            validationError={(errors.category as FieldError)?.message}
          >
            <Controller
              control={control}
              name="category"
              render={({ field: { value, onChange, name } }) => (
                <Select<Category>
                  placeholder={translations('product_assignment_category_placeholder')}
                  name={name}
                  getOptionLabel={(option) => (option as Category).translations[language]}
                  getOptionValue={(option) => (option as Category).id}
                  value={value}
                  onChange={(inputValue) => {
                    onChange(inputValue);
                  }}
                  onInputChange={(searchText) => setCategorySearchText(searchText)}
                  options={searchedCategories?.items}
                  isLoading={isLoadingCategories}
                />
              )}
            />
          </FormGroup>
        )}
        <FormGroup
          label={translations('products_assignment_from_csv_modal_list_label')}
          labelTooltipText={translations('products_assignment_from_csv_modal_list_tooltip')}
          inputId="csvFile"
          validationError={(errors.csvFile as FieldError)?.message}
        >
          <FileInput accept="text/csv" register={register('csvFile')} aria-label="csvFile" />
        </FormGroup>
        {watch('csvFile')?.length ? (
          <Flex alignItems="center">
            <Text>{watch('csvFile')[0].name}</Text>
            <Button onClick={() => setValue('csvFile', undefined)} size="sm" ml={2}>
              X
            </Button>
          </Flex>
        ) : null}
        <FormFooter hasCustomContent>
          <Button
            mb={2}
            mr={4}
            isLoading={isMassAssign ? isMassAssignProductsInProgress : isAssignProductsInProgress}
            type="submit"
            colorScheme="orange"
          >
            {translations('save')}
          </Button>
          <Button onClick={onModalClose}>{translations('cancel')}</Button>
        </FormFooter>
      </form>
    </>
  );
};
