import React, { JSX } from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import { Button, Flex, Text, useTheme, VStack } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

const IMAGE_FILE_MAX_SIZE_IN_BYTES = 1100000; // ~1MB (value from the backend validation)

export const isFileTooLarge = (fileSize: number): boolean => {
  return fileSize > IMAGE_FILE_MAX_SIZE_IN_BYTES;
};

type ImageSizeValidationProps = {
  handleDeleteUploadedImage: () => void;
  imageSizePreview: number;
  imageNamePreview: string;
};

export const ImagePreviewValidation = ({
  handleDeleteUploadedImage,
  imageSizePreview,
  imageNamePreview,
}: ImageSizeValidationProps): JSX.Element => {
  const translations = useTranslations();
  const { colors } = useTheme();

  return (
    <VStack alignItems="start">
      {isFileTooLarge(imageSizePreview) && (
        <Flex alignItems="center" bgColor={colors.red[100]} my={2} p={2}>
          <WarningIcon color={colors.red[500]} w={10} h={6} />
          <Text ml={2}>
            <b>{translations('image_upload_error_message')}</b>
            {translations('image_upload_error_message_explanation')}
          </Text>
        </Flex>
      )}
      <Flex alignItems="center">
        {isFileTooLarge(imageSizePreview) && <WarningIcon color={colors.red[500]} w={10} h={6} />}
        <Text m={2}>{imageNamePreview}</Text>
        <Button onClick={handleDeleteUploadedImage}>{translations('image_upload_remove_button')}</Button>
      </Flex>
    </VStack>
  );
};
