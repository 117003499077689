const queryParamsToString = ({
  paramValue,
  paramName,
  search,
}: {
  paramValue: string;
  paramName: string;
  search: string;
}): string => {
  const { [paramName]: mainParam, ...otherQueryParams } = Object.fromEntries(new URLSearchParams(search).entries());
  const parsedOtherParams = Object.keys(otherQueryParams).reduce((acc, curr) => {
    // eslint-disable-next-line
    acc += `${curr}=${encodeURIComponent(otherQueryParams[curr])}`;
    return acc;
  }, '');
  const querySearchParam = paramValue ? `${paramName}=${encodeURIComponent(paramValue)}` : '';
  const hasOtherQueryParams = !!Object.keys(otherQueryParams).length;
  return `?${querySearchParam}${querySearchParam && hasOtherQueryParams ? '&' : ''}${parsedOtherParams}`;
};

export default queryParamsToString;
