export const INDIVIDUAL_KEY_ACCOUNT_ID = '1';
export const RELEVO_CLOSING_SOURCE_ID = '1';
export const CLASSIC_META_CATEGORY_ID = '1';

export const MAX_RESTAURANT_NAME_LENGTH = 100;
export const MAX_RESTAURANT_DETAIL_LENGTH = 80;
export const MAX_RESTAURANT_ADDRESS_LENGTH = 200;
export const MAX_RESTAURANT_STREET_NAME_LENGTH = 150;
export const MAX_RESTAURANT_PHONE_LENGTH = 20;
export const MAX_COMMON_LENGTH = 255;
export const MAX_RESTAURANT_GOOGLE_PLACE_ID_LENGTH = 200;
export const MAX_RESTAURANT_ANDROID_LINK_LENGTH = 200;
export const MAX_RESTAURANT_WHOLESALE_CUSTOMER_ID_LENGTH = 200;
export const MAX_CATEGORY_GROUP_PRICE_VALUE = 0.999;
export const MIN_CATEGORY_GROUP_PRICE_VALUE = 0.01;
export const MAX_LATITUDE = 90;
export const MIN_LATITUDE = -90;
export const MAX_LONGITUDE = 180;
export const MIN_LONGITUDE = -180;
