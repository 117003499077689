import { ChangeEvent } from 'react';

export const useHandleTableRowCheckbox = (
  selectedIds: string[],
  setSelectedIds: (data: string[]) => void,
): {
  handleCheckbox: (id: string, e: ChangeEvent<HTMLInputElement>) => void;
} => {
  const getIdsToSelect = (isChecked: boolean, rowId: string, idsEntry: string[]) => {
    let idsToSelect = idsEntry;
    if (isChecked && !selectedIds.some((id) => id === rowId)) {
      idsToSelect = idsToSelect.concat([rowId]);
    }
    if (!isChecked && selectedIds.some((id) => id === rowId)) {
      idsToSelect = idsToSelect.filter((id) => id !== rowId);
    }
    return idsToSelect;
  };
  const handleCheckbox = (id: string, e: ChangeEvent<HTMLInputElement>) => {
    setSelectedIds(getIdsToSelect(e.target.checked, id, selectedIds));
  };

  return { handleCheckbox };
};
