import {
  ReturnCodesFormType,
  returnCodeTypeSelectOptions,
} from '../components/RestaurantReturnCodeForm/restaurant-return-code.form';
import { ReturnCodeType } from '../models/enums/return-code-type.enum';
import { RestaurantReturnCodeCreateRequest } from '../models/restaurant-return-code-create-request';
import { RestaurantReturnCodeResponse } from '../models/restaurant-return-code-response';
import { GRACE_PERIOD_UNIT } from './return-codes.consts';

export const mapReturnCodeFormTypeToCreateRequest = (
  formValues: ReturnCodesFormType,
): RestaurantReturnCodeCreateRequest => {
  return {
    name: formValues.name,
    status: formValues.status,
    type: formValues.type,
    gracePeriod:
      formValues.gracePeriod !== undefined && formValues.type === ReturnCodeType.OPEN_HOURS
        ? formValues.gracePeriod
        : null,
  };
};

const getOpenHoursTypeLabel = (original: RestaurantReturnCodeResponse) => {
  const returnCodeType = returnCodeTypeSelectOptions.find((option) => option.value === original.type)?.label;
  const gracePeriodSuffix = original.gracePeriod ? ` (+${original.gracePeriod}${GRACE_PERIOD_UNIT})` : '';
  return `${returnCodeType}${gracePeriodSuffix}`;
};

export const getReturnCodeTypeLabel = (original: RestaurantReturnCodeResponse) => {
  switch (original.type) {
    case ReturnCodeType.REGULAR:
      return returnCodeTypeSelectOptions.find((option) => option.value === original.type)?.label;
    case ReturnCodeType.OPEN_HOURS:
      return getOpenHoursTypeLabel(original);
    default: {
      const exhaustiveCheck: never = original.type;
      throw new Error(exhaustiveCheck);
    }
  }
};
