import { NotificationSettingsEnabled } from '../models/enums/notification-settings-enabled.enum';
import { NotificationSettingsFrequency } from '../models/enums/notification-settings-frequency.enum';
import { NotificationSettingsLocations } from '../models/enums/notification-settings-locations.enum';
import { NotificationSettingsFormData } from '../models/notification-settings-form-data';
import { UpdateNotificationSettingsRequest } from '../models/update-notification-settings-request';
import { UserNotificationSettingsResponse } from '../models/user-notification-settings-response';

export const buildNotificationSettingsFormData = (
  userNotificationSettings: UserNotificationSettingsResponse,
): NotificationSettingsFormData => {
  const { notificationSettings, userRestaurants } = userNotificationSettings;
  return {
    enabled: notificationSettings.isNotificationEnabled
      ? NotificationSettingsEnabled.ON
      : NotificationSettingsEnabled.OFF,
    frequency:
      notificationSettings.notificationFrequency === NotificationSettingsFrequency.WEEKLY
        ? NotificationSettingsFrequency.WEEKLY
        : NotificationSettingsFrequency.MONTHLY,
    locationsSetting: notificationSettings.selectedLocations?.length
      ? NotificationSettingsLocations.SELECTED
      : NotificationSettingsLocations.ALL,
    selectedLocations: notificationSettings.selectedLocations || [],
    userLocations: userRestaurants,
  };
};

export const buildNotificationSettingsUpdateRequest = (
  data: NotificationSettingsFormData,
): UpdateNotificationSettingsRequest => {
  return {
    isNotificationEnabled: data.enabled === NotificationSettingsEnabled.ON,
    notificationFrequency: data.frequency,
    selectedLocations:
      data.locationsSetting === NotificationSettingsLocations.ALL || !data.selectedLocations.length
        ? null
        : data.selectedLocations,
  };
};
