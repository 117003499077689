import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/react';

import { FormFooter } from '../../../../../../common/components';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import {
  createReuseCardUsersFormDefaultValues,
  CreateReuseCardUsersFormModel,
} from '../../../../models/create-reuse-card-users-form-model';
import { FormActivationFnbGroups } from '../../../common/FormActivationFnbGroups/FormActivationFnbGroups';
import { FormActivationLocations } from '../../../common/FormActivationLocations/FormActivationLocations';
import { FormBorrowingsLimit } from '../../../common/FormBorrowingsLimit/FormBorrowingsLimit';
import { FormCurrency } from '../../../common/FormCurrency/FormCurrency';
import { FormMonetaryValue } from '../../../common/FormMonetaryValue/FormMonetaryValue';
import { FormBatchSize } from '../FormBatchSize/FormBatchSize';

type ReuseCardsFormProps = {
  onSubmit: (request: CreateReuseCardUsersFormModel) => void;
  isLoading: boolean;
};

export const CreateReuseCardsForm = ({ onSubmit, isLoading }: ReuseCardsFormProps) => {
  const translations = useTranslations();
  const formMethods = useForm<CreateReuseCardUsersFormModel>({
    defaultValues: createReuseCardUsersFormDefaultValues,
  });
  const handleFormSubmit = formMethods.handleSubmit(onSubmit);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleFormSubmit}>
        <Flex textAlign="start" wrap="wrap" width="100%">
          <Box width={['50%', '50%', '25%']} p={2}>
            <FormBatchSize />
          </Box>
          <Box width={['50%', '50%', '25%']} p={2}>
            <FormMonetaryValue />
          </Box>
          <Box width={['50%', '50%', '25%']} p={2}>
            <FormCurrency />
          </Box>
          <Box width={['50%', '50%', '25%']} p={2}>
            <FormBorrowingsLimit />
          </Box>
        </Flex>
        <Flex textAlign="start" wrap="wrap" width="100%">
          <Box width={['100%', '100%', '50%']} p={2}>
            <FormActivationLocations tooltip={translations('reuse_cards_activation_restaurants_create_tooltip_text')} />
          </Box>
          <Box width={['100%', '100%', '50%']} p={2}>
            <FormActivationFnbGroups tooltip={translations('reuse_cards_activation_fnb_groups_create_tooltip_text')} />
          </Box>
        </Flex>
        <FormFooter isLoadingSubmitResult={isLoading} />
      </form>
    </FormProvider>
  );
};
