import React, { JSX } from 'react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useTheme } from '@chakra-ui/react';

export const BooleanValueIcon = ({ value = false }: { value?: boolean | null }): JSX.Element => {
  const { colors } = useTheme();
  return (
    <>
      {value ? (
        <CheckIcon data-testid="check-icon" color={colors.green[500]} />
      ) : (
        <CloseIcon data-testid="close-icon" color={colors.red[500]} />
      )}
    </>
  );
};
