import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'config/routes';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCreatePartner } from '../../services/partners';
import { mapFormPartnerToRequest } from './Partners.utils';
import PartnersForm from './PartnersForm';

const CreatePartner = (): JSX.Element => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { mutate: createPartner, isLoading } = useCreatePartner();

  return (
    <>
      <ChildPageHeading>{translations('partners_create_partner')}</ChildPageHeading>
      <PartnersForm
        isLoading={isLoading}
        onSubmit={async (values) => {
          const mappedValues = mapFormPartnerToRequest(values);

          await createPartner(
            { values: mappedValues },
            {
              onSuccess: () => navigate(Routes.PARTNERS_PATH),
            },
          );
        }}
      />
    </>
  );
};

export default CreatePartner;
