import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { createCity, deleteCity, getCities, getCity, updateCity } from 'api/city';
import { AxiosError } from 'axios';
import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import { City, CityRequest } from 'modules/City/models/City';

import { HttpException } from '../common/models/httpException';

export const CK_CITIES = 'cities';

export const useCitiesList = (
  params: BaseQueryParams = { offset: 1, limit: 0, searchText: '' },
): QueryObserverResult<Paginated<City>, AxiosError<HttpException>> =>
  useQuery(
    [CK_CITIES, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getCities(params),
    {
      keepPreviousData: true,
    },
  );

export const useCity = (id: string, enabled = true): QueryObserverResult<City, AxiosError<HttpException>> => {
  return useQuery([CK_CITIES, id], () => getCity(id), { enabled });
};

export const useCreateCity = (): UseMutationResult<City, AxiosError<HttpException>, CityRequest> => {
  const queryClient = useQueryClient();
  return useMutation(createCity, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CITIES);
    },
  });
};

export const useUpdateCity = (): UseMutationResult<
  City,
  AxiosError<HttpException>,
  { id: string; values: CityRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id, values }: { id: string; values: CityRequest }) => updateCity(id, values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CITIES);
    },
  });
};

export const useDeleteCity = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation((id: string) => deleteCity(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_CITIES);
    },
  });
};
