import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessages } from 'common/components';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCreateAboutUs } from 'services/about-us';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import AboutUsForm from './AboutUsForm';

const CreateAboutUs = (): JSX.Element => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { mutate: createAboutUs, isLoading, error } = useCreateAboutUs();

  return (
    <>
      <ChildPageHeading>{translations('about_create')}</ChildPageHeading>
      <ErrorMessages errors={error} />
      <AboutUsForm
        isLoading={isLoading}
        onSubmit={(createAboutUsRequest) =>
          createAboutUs(createAboutUsRequest, {
            onSuccess: () => navigate(Routes.ABOUT_PATH),
          })
        }
      />
    </>
  );
};

export default CreateAboutUs;
