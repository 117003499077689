import React, { ChangeEvent, JSX, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Box, Button, Checkbox, Flex, Tooltip, useTheme } from '@chakra-ui/react';
import { LinkButton, Table, Time, useToast } from 'common/components';
import { config } from 'config/config';

import { CustomersFilterParams, CustomerStatus, exportCustomers } from '../../../api/customers';
import { OrderType } from '../../../common/components/Table/TableHeaderWithOrder/order-type.enum';
import TableHeaderWithOrder from '../../../common/components/Table/TableHeaderWithOrder/TableHeaderWithOrder';
import { useHandleTablePageCheckbox } from '../../../common/hooks/useHandleTablePageCheckbox';
import { useHandleTableRowCheckbox } from '../../../common/hooks/useHandleTableRowCheckbox';
import { useExecuteWithConfirmation } from '../../../common/utils';
import { generatePassword } from '../../../common/utils/helpers';
import { Routes } from '../../../config/routes';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useCreateCustomerAccount, useCustomersList } from '../../../services/customers';
import { UnionKeys } from '../../Products/Product.model';
import { CustomersResponse } from './models/Customers.model';
import { CustomerFilterStatus, CustomersFilter } from './CustomersFilter';
import { CustomStatusTableField } from './CustomStatusTableField';

export type AvailableOrderFields =
  | 'status'
  | 'salesOrganisation'
  | 'salesPersonName'
  | 'customerName'
  | 'billingAddressCity'
  | 'desiredStartDate';

export interface TableFilter {
  property: UnionKeys<CustomersFilterParams>;
  value: string | undefined;
  orderType?: OrderType;
  orderField?: AvailableOrderFields;
  startDate?: string;
  endDate?: string;
}

const emptyTableFilters = {
  property: '',
  value: '',
  startDate: '',
  endDate: '',
};
export const CustomersTable = (): JSX.Element => {
  const translations = useTranslations();
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [tableFilter, setTableFilter] = useState<TableFilter>({
    property: 'status',
    value: CustomerStatus.NEW,
    startDate: '',
    endDate: '',
  });
  const [selectedCustomersIds, setSelectedCustomersIds] = useState<string[]>([]);
  const { displayToast } = useToast();
  const { colors } = useTheme();
  const password = useMemo(() => generatePassword(), []);

  const { mutate: createCustomerAccount, isLoading: isCreatingCustomerAccount } = useCreateCustomerAccount(password);

  const { executeWithConfirmation: onCreateCustomerAccount } = useExecuteWithConfirmation(
    translations('customers_create_account_confirmation'),
    createCustomerAccount,
  );

  const handleOrder = (param?: AvailableOrderFields) => () => {
    if (param === tableFilter.orderField) {
      setTableFilter({
        ...tableFilter,
        orderType: tableFilter.orderType === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
      });
    } else {
      setTableFilter({
        ...tableFilter,
        orderField: param,
        orderType: OrderType.ASC,
      });
    }
  };

  const { data, isFetching: isFetchingCustomersList } = useCustomersList(
    {
      offset: page,
      limit: config.paginationSize,
    },
    {
      ...(tableFilter.value && { [tableFilter.property]: tableFilter.value }),
      ...(tableFilter.orderField && { orderField: tableFilter.orderField }),
      ...(tableFilter.orderType && { orderType: tableFilter.orderType }),
      ...(tableFilter.startDate && { startDate: tableFilter.startDate }),
      ...(tableFilter.endDate && { endDate: tableFilter.endDate }),
    },
  );
  const { handlePageCheckbox } = useHandleTablePageCheckbox(
    selectedCustomersIds,
    setSelectedCustomersIds,
    data?.items.map((item) => item.id),
  );

  const { handleCheckbox } = useHandleTableRowCheckbox(selectedCustomersIds, setSelectedCustomersIds);

  const getIsChecked = (original: CustomersResponse) => selectedCustomersIds.some((id) => id === original.id);

  const getIsSelectPageChecked = (pageItems: CustomersResponse[] | undefined) =>
    pageItems?.every((item) => selectedCustomersIds.includes(item.id));

  const handleSelectCheckbox = (original: CustomersResponse) => (e: ChangeEvent<HTMLInputElement>) =>
    handleCheckbox(original.id, e);

  const columns = [
    {
      id: '1',
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<CustomersResponse>) => (
        <Checkbox
          name={original.id}
          key={original.id}
          size="sm"
          colorScheme="orange"
          isChecked={getIsChecked(original)}
          onChange={handleSelectCheckbox(original)}
        />
      ),
      Header: (
        <Tooltip
          label={
            !getIsSelectPageChecked(data?.items)
              ? translations('table_select_page')
              : translations('table_unselect_all')
          }
        >
          <Box>
            <Checkbox
              name="page"
              key="page"
              size="sm"
              colorScheme="orange"
              isChecked={getIsSelectPageChecked(data?.items)}
              onChange={handlePageCheckbox}
            />
          </Box>
        </Tooltip>
      ),
    },
    {
      id: '2',
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<CustomersResponse>) => <CustomStatusTableField status={original.status} id={original.id} />,
      Header: (
        <>
          <TableHeaderWithOrder
            headerText={translations('customers_status')}
            orderType={tableFilter.orderType}
            showOrder={'status' === tableFilter.orderField}
            onClick={handleOrder('status')}
          />
        </>
      ),
    },
    {
      id: '3',
      accessor: 'salesOrganisation' as const,
      Header: (
        <TableHeaderWithOrder
          headerText={translations('customers_sales_organization')}
          orderType={tableFilter.orderType}
          showOrder={'salesOrganisation' === tableFilter.orderField}
          onClick={handleOrder('salesOrganisation')}
        />
      ),
    },
    {
      id: '4',
      accessor: 'salesPersonName' as const,
      Header: (
        <TableHeaderWithOrder
          headerText={translations('customers_sales_person_name')}
          orderType={tableFilter.orderType}
          showOrder={'salesPersonName' === tableFilter.orderField}
          onClick={handleOrder('salesPersonName')}
        />
      ),
    },
    {
      id: '5',
      accessor: 'customerName' as const,
      Header: (
        <TableHeaderWithOrder
          headerText={translations('customers_customer_name')}
          orderType={tableFilter.orderType}
          showOrder={'customerName' === tableFilter.orderField}
          onClick={handleOrder('customerName')}
        />
      ),
    },
    {
      id: '6',
      accessor: 'billingAddressCity' as const,
      Header: (
        <TableHeaderWithOrder
          headerText={translations('customers_city')}
          orderType={tableFilter.orderType}
          showOrder={'billingAddressCity' === tableFilter.orderField}
          onClick={handleOrder('billingAddressCity')}
        />
      ),
    },
    {
      id: '7',
      accessor: 'desiredStartDate' as const,
      Header: (
        <TableHeaderWithOrder
          headerText={translations('customers_desired_start_date')}
          orderType={tableFilter.orderType}
          showOrder={'desiredStartDate' === tableFilter.orderField}
          onClick={handleOrder('desiredStartDate')}
        />
      ),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<CustomersResponse>) => <Time time={original.desiredStartDate || ''} />,
    },
    {
      id: 'actions',
      Header: translations('customers_actions'),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<CustomersResponse>) => (
        <Flex wrap="wrap">
          <LinkButton
            m={1}
            to={generatePath(Routes.UPDATE_CUSTOMER_PATH, { id: original.id })}
            color={colors.orange[500]}
          >
            {translations('customers_more_details_button')}
          </LinkButton>
          <Button
            m={1}
            isDisabled={original.status !== CustomerStatus.APPROVED}
            onClick={() => onCreateCustomerAccount(original.id)}
            color={colors.orange[500]}
          >
            {translations('customers_create_account_button')}
          </Button>
        </Flex>
      ),
    },
  ];

  const handleExportCustomers = () => {
    exportCustomers(selectedCustomersIds)
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), response]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `customers-${new Date().getTime()}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        displayToast('error', translations('customer_export_error'), false, 4000);
      });
  };

  return (
    <>
      <Flex justifyContent="flex-end">
        <Button isDisabled={!selectedCustomersIds.length} onClick={handleExportCustomers}>
          {translations('customer_export', { '{{number}}': selectedCustomersIds.length.toString() })}
        </Button>
      </Flex>
      <Table<CustomersResponse>
        data={data?.items}
        columns={columns}
        isLoading={isCreatingCustomerAccount || isFetchingCustomersList}
        page={page}
        onPageChanged={setPage}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        totalPages={data?.totalPages}
        customFilter={
          <CustomersFilter
            onChange={(property, value) => {
              setPage(1);
              setSelectedCustomersIds([]);
              if (property === 'status' && value === CustomerFilterStatus.ALL) {
                setTableFilter(emptyTableFilters);
              } else {
                setTableFilter({ property, value });
              }
            }}
            onDatePickerChange={(property, value) => {
              setPage(1);
              setTableFilter({
                ...tableFilter,
                ...(property === 'startDate' && { startDate: value }),
                ...(property === 'endDate' && { endDate: value }),
              });
            }}
          />
        }
        searchEnabled
        paginationEnabled
      />
    </>
  );
};
