import { generatePath } from 'react-router-dom';

import apiPaths from '../config/apiPaths';
import { NotificationSettingsResponse } from '../modules/RestaurantCredentials/NotificationSettings/models/notification-settings-response';
import { UpdateNotificationSettingsRequest } from '../modules/RestaurantCredentials/NotificationSettings/models/update-notification-settings-request';
import { UserNotificationSettingsResponse } from '../modules/RestaurantCredentials/NotificationSettings/models/user-notification-settings-response';
import axiosInstance from './axios';

export const getNotificationSettings = (userId: string): Promise<UserNotificationSettingsResponse> =>
  axiosInstance
    .get<UserNotificationSettingsResponse>(generatePath(apiPaths.RESTAURANT_USERS_NOTIFICATION_SETTINGS, { userId }))
    .then(({ data }) => data);

export const updateNotificationSettings = (
  userId: string,
  updateRequest: UpdateNotificationSettingsRequest,
): Promise<NotificationSettingsResponse> =>
  axiosInstance.patch(generatePath(apiPaths.RESTAURANT_USERS_NOTIFICATION_SETTINGS, { userId }), updateRequest);
