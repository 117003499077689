import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { ErrorMessages, FullPageLoader } from '../../common/components';
import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCity, useUpdateCity } from '../../services/cities';
import CityForm from './CityForm';

const UpdateCity = (): JSX.Element => {
  const translations = useTranslations();
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const { mutate: updateCity, isLoading, error: modifyCityError } = useUpdateCity();
  const { data: city, isFetching, error: loadError } = useCity(id);

  if (isFetching || isLoading) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{translations('city_update_city')}</ChildPageHeading>
      <ErrorMessages errors={[loadError, modifyCityError]} />
      <CityForm
        isLoading={isLoading}
        onSubmit={(values) =>
          updateCity(
            { id, values },
            {
              onSuccess: () => navigate(Routes.CITIES_PATH),
            },
          )
        }
        data={city}
      />
    </>
  );
};

export default UpdateCity;
