import React, { JSX, useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import { ErrorMessages } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCreateRestaurantReturnCode } from 'services/return-codes';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../../../../common/components/ChildPageHeading';
import { Routes } from '../../../../../config/routes';
import { useReturnCodeDefaultNameHook } from '../../utils/hooks/use-return-code-default-name.hook';
import { mapReturnCodeFormTypeToCreateRequest } from '../../utils/return-codes.helper';
import {
  returnCodeFormDefaultValues,
  ReturnCodeFormMode,
  ReturnCodesFormType,
} from '../RestaurantReturnCodeForm/restaurant-return-code.form';
import RestaurantReturnCodeForm from '../RestaurantReturnCodeForm/RestaurantReturnCodeForm';

const CreateRestaurantReturnCode = (): JSX.Element => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  invariant(restaurantId, 'restaurantId is not set within the route');
  const translations = useTranslations();

  const {
    mutate: createRestaurantReturnCode,
    isLoading: isCreatingReturnCode,
    error: createRestaurantReturnCodeError,
  } = useCreateRestaurantReturnCode(generatePath(Routes.RESTAURANT_RETURN_CODES_PATH, { restaurantId }));

  const { defaultNameValue, isLoading } = useReturnCodeDefaultNameHook(restaurantId, isCreatingReturnCode);

  const defaultValues: ReturnCodesFormType = useMemo(() => {
    return {
      ...returnCodeFormDefaultValues,
      name: defaultNameValue || '',
    };
  }, [defaultNameValue]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <ChildPageHeading>{translations('restaurant_return_codes_create_header')}</ChildPageHeading>
      <ErrorMessages errors={createRestaurantReturnCodeError} />
      <RestaurantReturnCodeForm
        isLoading={isCreatingReturnCode}
        onSubmit={(formValues) =>
          createRestaurantReturnCode({
            restaurantId,
            createRestaurantReturnCodeRequest: mapReturnCodeFormTypeToCreateRequest(formValues),
          })
        }
        mode={ReturnCodeFormMode.CREATE}
        model={defaultValues}
      />
    </>
  );
};

export default CreateRestaurantReturnCode;
