import colors from 'styles/colors';

export default {
  baseStyle: {
    table: {
      color: `${colors.orange[500]}`,
      borderCollapse: 'separate',
      borderSpacing: '10px 0',
    },
    th: {
      borderRadius: 'md',
      border: `1px solid ${colors.orange[500]}`,
      textAlign: 'center',
      fontSize: '16px',
      padding: '8px!important',
    },
    td: {
      textAlign: 'center',
      fontSize: '16px',
      padding: '8px!important',
      wordBreak: 'break-word',
      minWidth: '100px',
      _first: {
        minWidth: '50px',
      },
    },
  },
};
