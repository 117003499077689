import React, { JSX, useCallback, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { Button, Flex } from '@chakra-ui/react';

import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from '../../common/components';
import { useDebounce } from '../../common/hooks/useDebounce';
import { useExecuteWithConfirmation } from '../../common/utils';
import { config } from '../../config/config';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { Role, useSessionContext } from '../../contexts/SessionContext';
import { useClosingSourceList, useDeleteClosingSource } from '../../services/closing-sources';
import { ClosingSource, ClosingSourceResponse } from './model/ClosingSource';

const ClosingSources = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const {
    data,
    isFetching: isFetchingList,
    error: loadClosingSourcesError,
  } = useClosingSourceList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });
  const { mutate: deleteClosingSource, isLoading: isDeleting, error: deleteError } = useDeleteClosingSource();
  const { executeWithConfirmation: onDeleteClosingSource } = useExecuteWithConfirmation(
    translations('closing_source_delete_confirmation'),
    deleteClosingSource,
  );
  const { role } = useSessionContext();

  const handleDeleteClosingSource = useCallback(
    (original: ClosingSource) => {
      return () => onDeleteClosingSource(original.id);
    },
    [onDeleteClosingSource],
  );

  const columns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('closing_sources_table_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('closing_sources_table_name'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ClosingSource>) => original.name,
      },
      {
        id: 'actions',
        Header: translations('closing_sources_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ClosingSource>) => (
          <Flex>
            <AddEditItemLink to={`${Routes.CLOSING_SOURCES_PATH}/${original.id}`} m={1} isEdit />
            {role === Role.ADMINISTRATOR ? (
              <Button onClick={handleDeleteClosingSource(original)} m={1}>
                {translations('delete')}
              </Button>
            ) : null}
          </Flex>
        ),
      },
    ],
    [translations, handleDeleteClosingSource, role],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('closing_sources_header')}</PageHeading>
      <ErrorMessages errors={[loadClosingSourcesError, deleteError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_CLOSING_SOURCE_PATH}>{translations('closing_sources_add')}</AddEditItemLink>
      </Flex>
      <Table<ClosingSourceResponse>
        data={data?.items}
        columns={columns}
        isLoading={isFetchingList || isDeleting}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('closing_sources_searchbox_placeholder')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default ClosingSources;
