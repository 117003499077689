import { generatePath } from 'react-router-dom';

import { Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { RestaurantDraftResponse } from '../modules/Restaurants/RestaurantDrafts/models/restaurant-draft-response.model';
import { RestaurantDraftStatus } from '../modules/Restaurants/RestaurantDrafts/models/restaurant-draft-status.enum';
import { RestaurantDraftListParams } from '../services/restaurants';
import axiosInstance from './axios';

export const updateRestaurantDraftStatus = async ({
  status,
  draftId,
  restaurantId,
}: {
  restaurantId: string;
  draftId: string;
  status: RestaurantDraftStatus;
}): Promise<{ status: RestaurantDraftStatus }> => {
  return axiosInstance
    .put(generatePath(apiPaths.RESTAURANTS_DRAFTS_STATUS, { draftId, restaurantId }), { status })
    .then(({ data }) => data);
};
export const getRestaurantDrafts = async ({
  offset,
  limit,
  status,
}: RestaurantDraftListParams): Promise<Paginated<RestaurantDraftResponse>> => {
  const queryParams = buildBasicPaginatedSearchParams(
    offset,
    limit,
    undefined,
    new Map().set('status', status === 'ALL' ? undefined : status),
  );
  return axiosInstance
    .get<Paginated<RestaurantDraftResponse>>(generatePath(apiPaths.RESTAURANTS_DRAFTS), { params: queryParams })
    .then(({ data }) => data);
};
export const getRestaurantDraftById = async ({
  draftId,
  restaurantId,
}: {
  draftId: string;
  restaurantId: string;
}): Promise<RestaurantDraftResponse> => {
  return axiosInstance
    .get<RestaurantDraftResponse>(generatePath(apiPaths.RESTAURANT_DRAFTS_BY_ID, { draftId, restaurantId }))
    .then(({ data }) => data);
};
