import { useNavigate } from 'react-router-dom';

import { Routes } from '../../../../config/routes';
import { useAcceptRestaurantDraft, useRejectRestaurantDraft, useRestaurantDraft } from '../../../../services/drafts';
import { useRestaurantWithImages } from '../../../../services/restaurants';
import { RestaurantDraftStatus } from '../models/restaurant-draft-status.enum';

type UseDraftProps = {
  draftId: string;
  restaurantId: string;
};

export const useDraft = ({ draftId, restaurantId }: UseDraftProps) => {
  const navigate = useNavigate();

  const { data: draft, isLoading: isLoadingDraft } = useRestaurantDraft({
    draftId,
    restaurantId,
  });
  const { data: restaurant, isLoading: isLoadingRestaurant } = useRestaurantWithImages(restaurantId);

  const { mutate: acceptDraft } = useAcceptRestaurantDraft({
    onSuccess: () => {
      navigate(Routes.RESTAURANT_DRAFTS_PATH);
    },
  });
  const { mutate: rejectDraft } = useRejectRestaurantDraft({
    onSuccess: () => {
      navigate(Routes.RESTAURANT_DRAFTS_PATH);
    },
  });

  const isLoading = isLoadingDraft || isLoadingRestaurant;
  const isEditable = draft?.status === RestaurantDraftStatus.ACTIVE;

  return {
    draft,
    restaurant,
    acceptDraft,
    rejectDraft,
    isLoading,
    isEditable,
  };
};
