import React, { JSX, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { Box, Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter, FormGroup, Select, Switch } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import {
  useAssignProductsToRestaurant,
  useGetRestaurantProducts,
  useTransferAllProductsBetweenRestaurants,
} from 'services/product-assignment';

import { config } from '../../../config/config';
import { ProductResponse } from '../../Products/Product.model';
import { RestaurantResponse } from '../../Restaurants/models';
import { RestaurantSelect } from '../../RestaurantSelect/RestaurantSelect';
import { AssignItemsToRestaurantResponse } from '../AssignProductFromCsv/model/AssignItemsToRestaurant.model';
import {
  ReassignBetweenRestaurantsForm,
  useReassignBetweenRestaurantsFormSchema,
} from './ReassignBetweenRestaurants.form';

type ReassignBetweenRestaurantsProps = {
  onModalClose: () => void;
  onAssign: (data: AssignItemsToRestaurantResponse) => void;
};

export const ReassignBetweenRestaurants = ({
  onModalClose,
  onAssign,
}: ReassignBetweenRestaurantsProps): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = useState('');
  const validationSchema = useReassignBetweenRestaurantsFormSchema();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const watchSourceRestaurant: RestaurantResponse | undefined = watch('sourceRestaurant');

  const { data: restaurantProducts, isLoading: isLoadingRestaurantProducts } = useGetRestaurantProducts(
    watchSourceRestaurant?.id || null,
    {
      searchText,
      limit: config.paginationSize,
      offset: 1,
    },
  );

  const { mutate: transferProductsBetweenRestaurants, isLoading: isTransferProductsInProgress } =
    useTransferAllProductsBetweenRestaurants({
      onSuccessfulAssign: (data) => onAssign(data),
    });

  const { mutate: assignProductsToRestaurant, isLoading: isAssignProductsInProgress } = useAssignProductsToRestaurant({
    onSuccessfulAssign: (data) => onAssign(data),
  });

  const handleFormSubmit = handleSubmit(
    ({
      sourceRestaurant,
      destinationRestaurant,
      shouldTransferAllProducts,
      selectedRestaurantProducts,
    }: ReassignBetweenRestaurantsForm) => {
      const getTruthyIds = (products: ProductResponse[] | undefined = []) =>
        products?.map((product) => product.id).filter((id): id is string => !!id);

      if (shouldTransferAllProducts) {
        transferProductsBetweenRestaurants({
          fromRestaurantId: sourceRestaurant.id,
          toRestaurantId: destinationRestaurant.id,
        });
      } else {
        assignProductsToRestaurant({
          restaurantId: destinationRestaurant.id,
          productIds: getTruthyIds(selectedRestaurantProducts),
        });
      }
    },
  );

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <FormGroup
          label={translations('product_assignment_source_restaurant')}
          inputId="sourceRestaurant"
          validationError={(errors.sourceRestaurant as FieldError)?.message}
        >
          <Controller
            control={control}
            name="sourceRestaurant"
            render={({ field: { value, onChange, name } }) => (
              <RestaurantSelect name={name} onChange={onChange} value={value} />
            )}
          />
        </FormGroup>
        <FormGroup label={translations('product_assignment_transfer_all_products')} inputId="shouldTransferAllProducts">
          <Controller
            control={control}
            name="shouldTransferAllProducts"
            render={({ field: { value, onChange, name } }) => (
              <Switch id="shouldTransferAllProducts" value={value} onChange={onChange} name={name} />
            )}
          />
        </FormGroup>
        {!watch('shouldTransferAllProducts') && (
          <FormGroup
            label={translations('product_assignment_selected_restaurant_products')}
            inputId="selectedRestaurantProducts"
            validationError={(errors.selectedRestaurantProducts as FieldError)?.message}
          >
            <Controller
              control={control}
              name="selectedRestaurantProducts"
              render={({ field: { value, onChange, name } }) => (
                <Select<ProductResponse>
                  name={name}
                  onChange={onChange}
                  onInputChange={setSearchText}
                  value={value}
                  getOptionLabel={(option) =>
                    `${(option as ProductResponse).id} - ${(option as ProductResponse).productName}`
                  }
                  getOptionValue={(option) => (option as ProductResponse).id}
                  isDisabled={!watchSourceRestaurant?.id}
                  isLoading={isLoadingRestaurantProducts}
                  options={restaurantProducts?.items}
                  isMulti
                />
              )}
            />
          </FormGroup>
        )}
        <FormGroup
          label={translations('product_assignment_destination_restaurant')}
          inputId="destinationRestaurant"
          validationError={(errors.destinationRestaurant as FieldError)?.message}
        >
          <Controller
            control={control}
            name="destinationRestaurant"
            render={({ field: { value, onChange, name } }) => (
              <RestaurantSelect name={name} onChange={onChange} value={value} />
            )}
          />
        </FormGroup>
        <Box pt={4}>
          <FormFooter hasCustomContent>
            <Button
              mb={2}
              mr={4}
              isLoading={isAssignProductsInProgress || isTransferProductsInProgress}
              isDisabled={isAssignProductsInProgress || isTransferProductsInProgress}
              type="submit"
              colorScheme="orange"
            >
              {translations('save')}
            </Button>
            <Button onClick={onModalClose}>{translations('cancel')}</Button>
          </FormFooter>
        </Box>
      </form>
    </>
  );
};
