import * as React from 'react';
import { ComponentProps, JSX } from 'react';
import { Box, useTheme } from '@chakra-ui/react';

export const DragItem = ({
  children,
  ...props
}: { children: React.ReactNode } & ComponentProps<typeof Box>): JSX.Element => {
  const { colors } = useTheme();
  return (
    <Box
      bgColor={colors.gray[200]}
      p={3}
      borderRadius="lg"
      border="2px solid"
      borderColor={colors.gray[300]}
      {...props}
    >
      {children}
    </Box>
  );
};
