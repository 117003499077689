import React, { JSX } from 'react';
import { HStack, Image, Stack } from '@chakra-ui/react';

import logo from '../../assets/icons/relevo_logo.svg';
import { MainPageContent, PageHeading } from '../../common/components';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../common/constants/common';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCategoriesList } from '../../services/categories';
import FilteredResults from './Filters/FilteredResults';

const Reports = (): JSX.Element => {
  const translations = useTranslations();
  const { data: paginatedCategories } = useCategoriesList(ALL_PAGINATED_LIST_ITEMS_PARAMS);

  if (!paginatedCategories?.items) {
    return <></>;
  }

  return (
    <MainPageContent>
      <HStack spacing="auto" className="reportsHeader">
        <Image src={logo} maxWidth={20} alt="Relevo" display="inline-block" />
        <h2>{new Date().toLocaleDateString()}</h2>
      </HStack>
      <PageHeading>{translations('reports_heading')}</PageHeading>
      <Stack width="100%">
        <FilteredResults productCategories={paginatedCategories?.items} />
      </Stack>
    </MainPageContent>
  );
};

export default Reports;
