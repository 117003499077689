import { AccountPriority, AccountPriorityNames } from '../../models/enums/accountPriority';

export type AccountPrioritySelect = {
  label: AccountPriorityNames;
  value: AccountPriority;
};

export const accountPriorityOptions: AccountPrioritySelect[] = [
  { label: AccountPriorityNames.KEY_ACCOUNT, value: AccountPriority.KEY_ACCOUNT },
  { label: AccountPriorityNames.POTENTIAL, value: AccountPriority.POTENTIAL },
  { label: AccountPriorityNames.STANDARD, value: AccountPriority.STANDARD },
];
