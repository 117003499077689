import React, { ComponentProps, JSX } from 'react';
// eslint-disable-next-line
import { default as ReactSelect } from 'react-select';

import colors from '../../../styles/colors';

type SelectProps<T> = {
  name: string;
  getOptionLabel: (value: T) => string;
  getOptionValue: (value: T) => string;
  onChange: (value: unknown) => void;
  onInputChange?: (value: string) => void;
  valueMapper?: (value: T | null) => unknown;
} & Omit<ComponentProps<typeof ReactSelect>, 'onChange' | 'onInputChange'>;

export const Select = <T extends { [P in keyof T]: unknown }>({
  name,
  getOptionLabel,
  getOptionValue,
  onChange,
  ...props
}: SelectProps<T>): JSX.Element => {
  const { value, ...restProps } = props;

  return (
    <ReactSelect
      inputId={name}
      name={name}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={value}
      onChange={(v: unknown) => {
        onChange(props.valueMapper ? props.valueMapper(v as T) : v);
      }}
      styles={{
        control: (base) => ({
          ...base,
          borderColor: colors.blueGrey[200],
          '&:hover': {
            borderColor: colors.blueGrey[200],
          },
        }),
        placeholder: (base) => ({ ...base, color: colors.blueGrey[400] }),
        menu: (base) => ({ ...base, zIndex: 1000 }),
        option: (base, state) => ({
          ...base,
          background: state.isSelected ? colors.orange[300] : colors.white,
          color: colors.black,
          '&:hover': {
            background: colors.orange[100],
          },
        }),
      }}
      {...restProps}
    />
  );
};

export default Select;
