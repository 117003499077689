import React, { AriaAttributes, JSX } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { ArrowUpIcon } from '@chakra-ui/icons';
import { Button, Icon, InputGroup } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

type FileInputProps = {
  register: UseFormRegisterReturn;
  accept?: string;
  children?: React.ReactNode;
} & AriaAttributes;

export const FileInput = ({ register, accept, children, ...props }: FileInputProps): JSX.Element => {
  const translations = useTranslations();
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register as { ref: (instance: HTMLInputElement | null) => void };

  return (
    <InputGroup onClick={() => inputRef.current?.click()}>
      <input
        type="file"
        hidden
        accept={accept}
        {...rest}
        {...props}
        ref={(e) => {
          ref(e);
          inputRef.current = e;
        }}
      />
      <Button leftIcon={<Icon as={ArrowUpIcon} />}>{translations('upload')}</Button>
    </InputGroup>
  );
};
