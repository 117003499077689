import React, { JSX } from 'react';
import { Box, Checkbox, Divider, Flex, HStack, Image, useTheme, VStack } from '@chakra-ui/react';

import bowl from '../../../assets/icons/bowl.svg';
import cup from '../../../assets/icons/cup.svg';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { Category } from '../../Categories/model/Category.model';

type ProductCategoriesProps = {
  categories: Category[];
  onChange: (e: boolean, categoryId: string) => void;
  categoryIds: string[];
};

const headingDefaultStyle = {
  fontSize: '14px',
};

const ProductCategories = ({ categories, onChange, categoryIds }: ProductCategoriesProps): JSX.Element => {
  const { colors } = useTheme();
  const { language } = useLocalizationContext();
  return (
    <Box bg={colors.beige[50]} p={2}>
      <HStack>
        <VStack width="10%">
          <h2 style={headingDefaultStyle}>Bowls:</h2>
          <Image src={bowl} maxHeight={7} maxWidth={7} alt="Relevo" display="inline-block" />
        </VStack>
        <Flex wrap="wrap" width="90%" textAlign="left">
          {categories
            .filter((value) => value.categoryGroup === 'BOWLS')
            .map((value) => (
              <Checkbox
                name={value.id}
                key={value.id}
                width={['33%', '33%', '33%', '25%', '20%', '20%']}
                size="sm"
                colorScheme="orange"
                defaultChecked
                isChecked={categoryIds.some((id) => id === value.id)}
                onChange={(e) => onChange(e.target.checked, value.id)}
              >
                {value.translations[language]}
              </Checkbox>
            ))}
        </Flex>
      </HStack>

      <Divider borderColor={colors.white} border={1} my={2} />

      <HStack>
        <VStack width="10%">
          <h2 style={headingDefaultStyle}>Cups:</h2>
          <Image src={cup} maxHeight={7} maxWidth={7} alt="Relevo" display="inline-block" />
        </VStack>
        <Flex wrap="wrap" width="90%" textAlign="left">
          {categories
            .filter((value) => value.categoryGroup === 'CUPS')
            .map((value) => (
              <Checkbox
                key={value.id}
                width={['33%', '33%', '33%', '25%', '20%', '20%']}
                size="sm"
                colorScheme="orange"
                defaultChecked
                isChecked={categoryIds.some((id) => id === value.id)}
                onChange={(e) => onChange(e.target.checked, value.id)}
              >
                {value.translations[language]}
              </Checkbox>
            ))}
        </Flex>
      </HStack>
    </Box>
  );
};

export default ProductCategories;
