import React, { MouseEventHandler } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';

import { OrderType } from './order-type.enum';

type TableHeaderWithOrderProps = {
  headerText: string;
  showOrder: boolean;
  onClick?: MouseEventHandler;
  orderType?: OrderType;
};

const TableHeaderWithOrder = ({ onClick, headerText, showOrder, orderType }: TableHeaderWithOrderProps) => {
  return (
    <Box onClick={onClick} cursor="pointer">
      {headerText}
      {showOrder && orderType === OrderType.ASC ? <ArrowUpIcon ml={2} /> : null}
      {showOrder && orderType === OrderType.DESC ? <ArrowDownIcon ml={2} /> : null}
    </Box>
  );
};

export default TableHeaderWithOrder;
