import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormGroup, NumberInput } from '../../../../../../common/components';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { REUSE_CARD_USERS_BATCH_MAX_SIZE, REUSE_CARD_USERS_BATCH_MIN_SIZE } from '../../../../utils/reuse-cards.consts';

export const FormBatchSize = () => {
  const translations = useTranslations();
  const { control, register } = useFormContext();

  return (
    <FormGroup label={translations('reuse_cards_batch_size_label')} inputId="batchSize">
      <Controller
        control={control}
        name="batchSize"
        render={({ field: { value, onChange } }) => (
          <NumberInput
            name="batchSize"
            register={register('batchSize')}
            min={REUSE_CARD_USERS_BATCH_MIN_SIZE}
            max={REUSE_CARD_USERS_BATCH_MAX_SIZE}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </FormGroup>
  );
};
