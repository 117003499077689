import React, { JSX } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { AddEditItemLink, MainPageContent, PageHeading } from '../../common/components';
import { Routes } from '../../config/routes';
import { Role, useSessionContext } from '../../contexts/SessionContext';
import FrequentlyAskedQuestionsList from './FrequentlyAskedQuestionsList';

const FrequentlyAskedQuestionsPage = (): JSX.Element => {
  const translations = useTranslations();
  const { role } = useSessionContext();

  return (
    <MainPageContent>
      <PageHeading>{translations('faq_header')}</PageHeading>
      <Flex justifyContent="flex-end">
        {role === Role.ADMINISTRATOR ? (
          <AddEditItemLink to={Routes.CREATE_FAQ_SECTION_PATH}>{translations('faq_section_add')}</AddEditItemLink>
        ) : null}
      </Flex>
      <FrequentlyAskedQuestionsList />
    </MainPageContent>
  );
};

export default FrequentlyAskedQuestionsPage;
