import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/react';

import { FormFooter } from '../../../../../../common/components';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { ReuseCardUserResponse } from '../../../../models/reuse-card-user-response';
import { UpdateReuseCardFormModel } from '../../../../models/update-reuse-card-form-model';
import { useUpdateReuseCard } from '../../../../utils/hooks/use-update-reuse-card.hook';
import { FormActivationFnbGroups } from '../../../common/FormActivationFnbGroups/FormActivationFnbGroups';
import { FormActivationLocations } from '../../../common/FormActivationLocations/FormActivationLocations';
import { FormBorrowingsLimit } from '../../../common/FormBorrowingsLimit/FormBorrowingsLimit';
import { FormCurrency } from '../../../common/FormCurrency/FormCurrency';
import { FormMonetaryValue } from '../../../common/FormMonetaryValue/FormMonetaryValue';
import { FormIsActive } from '../FormIsActive/FormIsActive';

type UpdateReuseCardFormProps = {
  onSubmit: (request: UpdateReuseCardFormModel) => void;
  isLoading: boolean;
  model?: ReuseCardUserResponse;
};

export const UpdateReuseCardForm = ({ model, onSubmit, isLoading }: UpdateReuseCardFormProps) => {
  const translations = useTranslations();
  const { formDefaultValues } = useUpdateReuseCard(model);
  const formMethods = useForm<UpdateReuseCardFormModel>({
    defaultValues: formDefaultValues,
  });
  const handleFormSubmit = formMethods.handleSubmit(onSubmit);

  useEffect(() => {
    formMethods.reset(formDefaultValues);
  }, [formDefaultValues, formMethods]);

  const watchIsActive = formMethods.watch('isActive');

  const isReuseCardActivated = watchIsActive || !!model?.totalBorrowings;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleFormSubmit}>
        <Flex textAlign="start" wrap="wrap" width="100%">
          <Box width={['50%', '50%', '25%']} p={2}>
            <FormIsActive />
          </Box>
          <Box width={['50%', '50%', '25%']} p={2}>
            <FormMonetaryValue
              isDisabled={isReuseCardActivated}
              tooltip={translations('reuse_cards_form_activated_card_update_tooltip_text')}
            />
          </Box>
          <Box width={['50%', '50%', '25%']} p={2}>
            <FormCurrency
              isDisabled={isReuseCardActivated}
              tooltip={translations('reuse_cards_form_activated_card_update_tooltip_text')}
            />
          </Box>
          <Box width={['50%', '50%', '25%']} p={2}>
            <FormBorrowingsLimit />
          </Box>
        </Flex>
        <Flex textAlign="start" wrap="wrap" width="100%">
          <Box width={['100%', '100%', '50%']} p={2}>
            <FormActivationLocations
              isDisabled={isReuseCardActivated}
              tooltip={translations('reuse_cards_activation_restaurants_update_tooltip_text')}
            />
          </Box>
          <Box width={['100%', '100%', '50%']} p={2}>
            <FormActivationFnbGroups
              isDisabled={isReuseCardActivated}
              tooltip={translations('reuse_cards_activation_fnb_groups_update_tooltip_text')}
            />
          </Box>
        </Flex>
        <FormFooter isLoadingSubmitResult={isLoading} />
      </form>
    </FormProvider>
  );
};
