import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormGroup, NumberInput } from '../../../../../common/components';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import {
  REUSE_CARD_BORROWINGS_LIMIT_MAX_VALUE,
  REUSE_CARD_BORROWINGS_LIMIT_MIN_VALUE,
} from '../../../utils/reuse-cards.consts';

export const FormBorrowingsLimit = () => {
  const translations = useTranslations();
  const { control, register } = useFormContext();

  return (
    <FormGroup label={translations('reuse_cards_limit_label')} inputId="borrowingsLimit">
      <Controller
        control={control}
        name="borrowingsLimit"
        render={({ field: { value, onChange } }) => (
          <NumberInput
            name="borrowingsLimit"
            register={register('borrowingsLimit')}
            min={REUSE_CARD_BORROWINGS_LIMIT_MIN_VALUE}
            max={REUSE_CARD_BORROWINGS_LIMIT_MAX_VALUE}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </FormGroup>
  );
};
