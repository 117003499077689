import { useMemo } from 'react';

import { useTranslations } from '../../contexts/LocalizationContext';
import { ReportsStatisticsResponse } from './model/ReportsStatisticsResponse';
import { handleReturnRate, handleReturnTime } from './utils/statistics.helper';

// eslint-disable-next-line
export const useReportStatisticsItemState = (data: ReportsStatisticsResponse) => {
  const translations = useTranslations();
  const returnRate = useMemo(() => {
    return handleReturnRate(data.returnRate);
  }, [data]);

  const returnTime = useMemo(() => {
    if (data.totalAccountBorrowings && data.totalAccountBorrowings > 0) {
      const { returnTimeDays, returnTimeHours } = handleReturnTime(data.returnTime);
      return `${returnTimeDays}${translations('days_shortcut')} ${returnTimeHours}${translations('hours_shortcut')}`;
    }
    return `0${translations('days_shortcut')} 0${translations('hours_shortcut')}`;
  }, [data, translations]);

  return { translations, returnRate, returnTime };
};
