import apiPaths from '../config/apiPaths';
import { SendNotificationsRequest } from '../modules/Notifications/Notifications.model';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

const sendNotifications = (values: SendNotificationsRequest, ignoreSetting?: boolean): Promise<void> => {
  const data = createFormData(values);
  const config = { params: { ignoreSetting } };
  return 'userEmails' in values
    ? axiosInstance.post(`${apiPaths.NOTIFICATIONS}/selected`, data, config)
    : axiosInstance.post(`${apiPaths.NOTIFICATIONS}/all`, data, config);
};

export default sendNotifications;
