import React, { JSX } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, useTheme } from '@chakra-ui/react';

import { LinkButton } from '../../LinkButton';

type SubSectionButtonProps = {
  children: React.ReactNode;
} & ({ type: 'link'; route: string; activeTextColor: string } | { type: 'button'; onClick: () => void });

export const SubSectionButton = ({ children, ...props }: SubSectionButtonProps): JSX.Element => {
  const { pathname } = useLocation();
  const { colors } = useTheme();

  const defaultProps = {
    width: 'full',
    variant: 'ghost',
    justifyContent: 'flex-start',
    fontSize: 16,
    fontWeight: 500,
    color: 'route' in props && pathname === props.route ? props.activeTextColor : colors.black,
  };

  return (
    <>
      {props.type === 'link' && (
        <LinkButton {...defaultProps} to={props.route}>
          {children}
        </LinkButton>
      )}
      {props.type === 'button' && (
        <Button {...defaultProps} onClick={props.onClick}>
          {children}
        </Button>
      )}
    </>
  );
};
