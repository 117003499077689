import React, { ChangeEvent, JSX } from 'react';
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { RestaurantOpeningHoursResponse } from '../models';
import { parseOpeningHourPeriodId, weekdayToString } from './restaurantFormHelper';

type RestaurantOpeningHoursDayProps = {
  day: RestaurantOpeningHoursResponse;
  openingHours: RestaurantOpeningHoursResponse[];
  setOpeningHours: React.Dispatch<React.SetStateAction<RestaurantOpeningHoursResponse[]>>;
  isOverrideEnabled: boolean;
  isLoadingGoogle: boolean;
};

const hourSeparatorSign = '-';
const removeSign = 'x';
const addSign = '+';

export const RestaurantOpeningHoursDay = ({
  day,
  openingHours,
  setOpeningHours,
  isOverrideEnabled,
  isLoadingGoogle,
}: RestaurantOpeningHoursDayProps): JSX.Element => {
  const translations = useTranslations();

  const generateRandomKey = (): string => {
    return Math.random().toString(36).slice(2, 9);
  };

  const handleAddPeriod = (event: React.MouseEvent<HTMLButtonElement>) => {
    const periodMetadata = parseOpeningHourPeriodId(event.currentTarget.id);
    if (openingHours && periodMetadata) {
      const openingHoursTemp = cloneDeep(openingHours);
      openingHoursTemp
        .find((dayElement) => dayElement.day === periodMetadata.day)
        ?.periods?.splice(periodMetadata.periodIndex + 1, 0, {
          day: periodMetadata.day,
          openTime: '',
          closeTime: null,
        });
      setOpeningHours(openingHoursTemp);
    }
  };

  const handleRemovePeriod = (event: React.MouseEvent<HTMLButtonElement>) => {
    const periodMetadata = parseOpeningHourPeriodId(event.currentTarget.id);
    if (openingHours && periodMetadata) {
      const openingHoursTemp = cloneDeep(openingHours);
      openingHoursTemp
        .find((dayElement) => dayElement.day === periodMetadata.day)
        ?.periods?.splice(periodMetadata.periodIndex, 1);
      setOpeningHours(openingHoursTemp);
    }
  };

  const handleOnBlurPeriodHour = (event: ChangeEvent<HTMLInputElement>) => {
    const periodMetadata = parseOpeningHourPeriodId(event.target.id);
    if (openingHours && periodMetadata) {
      const openingHoursTemp = cloneDeep(openingHours);
      const targetPeriods = openingHoursTemp.find((dayElement) => dayElement.day === periodMetadata.day)?.periods;
      if (!targetPeriods) {
        return;
      }
      const targetPeriod = targetPeriods[periodMetadata.periodIndex];
      if (periodMetadata.type === 'open') {
        targetPeriod.openTime = event.target.value;
      }
      if (periodMetadata.type === 'close') {
        targetPeriod.closeTime = event.target.value === '' ? null : event.target.value;
      }
      setOpeningHours(openingHoursTemp);
    }
  };
  return (
    <div>
      {day.periods && day.periods.length > 0 ? (
        day.periods.map((period, periodIndex) => (
          <Flex pt={2} pb={2} key={`override-input-${period.day}-${periodIndex}-period-${generateRandomKey()}`}>
            <Text pt={2} width="80%">
              {periodIndex === 0 ? translations(weekdayToString(period.day)) : ''}
            </Text>
            <Input
              defaultValue={period.openTime || ''}
              type="time"
              id={`override-input-${period.day}-${periodIndex}-open`}
              key={`override-input-${period.day}-${periodIndex}-open`}
              disabled={!isOverrideEnabled || isLoadingGoogle}
              onBlur={handleOnBlurPeriodHour}
            />
            <Box pr={2} pl={2} pt={2}>
              {hourSeparatorSign}
            </Box>
            <Input
              defaultValue={period.closeTime || ''}
              type="time"
              id={`override-input-${period.day}-${periodIndex}-close`}
              key={`override-input-${period.day}-${periodIndex}-close`}
              disabled={!isOverrideEnabled}
              onBlur={handleOnBlurPeriodHour}
            />
            <Button
              ml={2}
              isDisabled={!isOverrideEnabled || isLoadingGoogle}
              id={`override-input-${period.day}-${periodIndex}-add`}
              onClick={handleAddPeriod}
            >
              {addSign}
            </Button>
            <Button
              ml={2}
              isDisabled={!isOverrideEnabled || isLoadingGoogle}
              id={`override-input-${period.day}-${periodIndex}-remove`}
              onClick={handleRemovePeriod}
            >
              {removeSign}
            </Button>
          </Flex>
        ))
      ) : (
        <Flex pt={2} pb={2} key={`override-input-${day.day}-0-empty-${generateRandomKey()}`}>
          <Text mt={2} mr={2} width="35%">
            {translations(weekdayToString(day.day))}
          </Text>
          <Input
            ml={-6}
            variant="filled"
            placeholder={translations('restaurants_form_closed_restaurant_placeholder')}
            _placeholder={{ opacity: 1, color: 'black', textAlign: 'center' }}
            disabled={true}
          />
          <Button
            ml={2}
            isDisabled={!isOverrideEnabled || isLoadingGoogle}
            id={`override-input-${day.day}-0-add`}
            onClick={handleAddPeriod}
          >
            {addSign}
          </Button>
        </Flex>
      )}
    </div>
  );
};
