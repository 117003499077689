import { CustomerStatus } from '../../../../api/customers';
import { PrivateFile } from '../../../../common/models/file';
import { StarterSetSize } from '../../RestaurantForm/RestaurantForm.form';

export interface CustomerResponse {
  id: string;
  status: CustomerStatus;
  salesOrganisation: string;
  salesPersonName: string;
  customerName: string;
  city: string | null;
  desiredStartDate: string | null;
  salesPersonEmail: string;
  salesPersonPhone: string;
  wholesaleCustomerId: string | null;
  contactPersonFirstName: string | null;
  contactPersonLastName: string | null;
  contactPersonEmail: string;
  contactPersonPhone: string | null;
  billingAddressStreetNumber: string | null;
  billingAddressAddition: string | null;
  billingAddressZipCode: string | null;
  billingAddressCity: string | null;
  billingAddressCountry: string | null;
  billingPersonTitle: string | null;
  billingName: string | null;
  billingEmail: string | null;
  isSepaDesired: boolean | null;
  specialRequests: string | null;
  contractFile: PrivateFile | null;
  isSocialMediaPressPermitted: boolean | null;
  starterSet: StarterSet | null;
  relevoCalculatorFilesUrls: PrivateFile[] | null;
  hubspotUrl: string | null;
  isHubspotDeal: boolean;
  accountType: AccountTypeEnum | null;
  keyAccountId: string | null;
  segmentId: string | null;
}

export enum AccountTypeEnum {
  INDIVIDUAL_RESTAURANT = 'INDIVIDUAL_RESTAURANT',
  NEW_KEY_ACCOUNT_GROUP = 'NEW_KEY_ACCOUNT_GROUP',
  PART_OF_EXISTING_KEY_ACCOUNT_GROUP = 'PART_OF_EXISTING_KEY_ACCOUNT_GROUP',
}

export type StarterSet = {
  [key in StarterSetSize]: number;
};
