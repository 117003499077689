import React, { JSX } from 'react';
import { CellProps } from 'react-table';
import { Button, Flex } from '@chakra-ui/react';
import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from 'common/components';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useCitiesList, useDeleteCity } from 'services/cities';

import { useDebounce } from '../../common/hooks/useDebounce';
import { Language, useExecuteWithConfirmation } from '../../common/utils';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { Country } from '../Country/model/Country';
import { City } from './models/City';

const CitiesPage = (): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);

  const {
    data,
    isFetching,
    error: loadCitiesError,
  } = useCitiesList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const { mutate: deleteCity, isLoading, error: deleteError } = useDeleteCity();
  const { executeWithConfirmation: onDeleteCity } = useExecuteWithConfirmation(
    translations('city_delete_confirmation'),
    deleteCity,
  );

  const columns = React.useMemo(
    () => [
      {
        id: '1',
        Header: translations('city_table_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('city_table_name'),
        accessor: 'translations' as const,
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell }: CellProps<City>) => cell.value[Language.En] ?? null,
      },
      {
        id: '3',
        Header: translations('city_de_translations'),
        accessor: 'translations' as const,
        Cell: ({ cell }: CellProps<City>) => cell.value[Language.De] ?? null,
      },
      {
        id: '4',
        Header: translations('city_country'),
        accessor: 'country' as const,
        Cell: ({ cell }: CellProps<City>) => (cell.value as Country).translations[language],
      },
      {
        id: 'actions',
        Header: translations('city_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<City>) => (
          <Flex>
            <AddEditItemLink to={`${Routes.CITIES_PATH}/${original.id}`} m={1} isEdit />
            <Button m={1} onClick={() => onDeleteCity(original.id)}>
              {translations('delete')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, onDeleteCity, language],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('city_header')}</PageHeading>
      <ErrorMessages errors={[loadCitiesError, deleteError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_CITY_PATH}>{translations('city_add')}</AddEditItemLink>
      </Flex>
      <Table<City>
        isLoading={isFetching || isLoading}
        data={data?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('city_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default CitiesPage;
