import * as yup from 'yup';

import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { ReturnCodeType } from '../../../../../../Restaurants/RestaurantReturnCodes/models/enums/return-code-type.enum';
import { RETURN_CODE_TYPE_DEFAULT_VALUE } from '../../../../../../Restaurants/RestaurantReturnCodes/utils/return-codes.consts';
import { CreateReturnCodesOptions } from '../../models/enums/create-return-codes-options.enum';

export type CreateReturnCodesFormType = {
  type: ReturnCodeType;
  action: CreateReturnCodesOptions;
  gracePeriod?: number | null;
};

export const createReturnCodesFormDefaultValues = {
  type: RETURN_CODE_TYPE_DEFAULT_VALUE,
  action: CreateReturnCodesOptions.CREATE,
  gracePeriod: 0,
};

export const useCreateReturnCodesFormValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    type: yup.string().oneOf(Object.values(ReturnCodeType), translations('validation_incorrect_value')),
    gracePeriod: yup.number().nullable().typeError(translations('validation_number_type_error')),
  });
};
