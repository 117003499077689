import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Text } from '@chakra-ui/react';

import { Table } from '../../../../../common/components';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../../../../common/constants/common';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useRestaurantsByIds } from '../../../../../services/restaurants';
import { RestaurantResponse } from '../../../../Restaurants/models';
import { useKeyAccountGroupRestaurantsContext } from '../../context/KeyAccountGroupRestaurantsContext/hooks/use-key-account-group-restaurants-context.hook';

type AffectedRestaurantsProps = {
  showAffectedRestaurants: boolean;
  onShowAffectedRestaurants: () => void;
  onCloseAffectedRestaurants: () => void;
};

export const AffectedRestaurants = ({
  showAffectedRestaurants,
  onShowAffectedRestaurants,
  onCloseAffectedRestaurants,
}: AffectedRestaurantsProps) => {
  const translations = useTranslations();
  const { selectedRestaurantsIds } = useKeyAccountGroupRestaurantsContext();

  const handleToggle = () => (showAffectedRestaurants ? onCloseAffectedRestaurants() : onShowAffectedRestaurants());

  const { data: restaurants, isLoading: isLoadingRestaurants } = useRestaurantsByIds(
    {
      restaurantIds: selectedRestaurantsIds,
      ...ALL_PAGINATED_LIST_ITEMS_PARAMS,
    },
    !!selectedRestaurantsIds.length,
  );

  const columns = [
    {
      id: '1',
      Header: translations('restaurants_table_id'),
      accessor: 'id' as const,
    },
    {
      id: '2',
      Header: translations('restaurants_table_name'),
      accessor: 'restaurantName' as const,
    },
  ];
  return (
    <>
      <Flex onClick={handleToggle} cursor="pointer">
        <Center mr={2}>
          {!showAffectedRestaurants ? <ChevronDownIcon h={6} w={6} /> : <ChevronUpIcon h={6} w={6} />}
        </Center>
        <Text>{translations('create_return_codes_affected_restaurants')}</Text>
      </Flex>
      {showAffectedRestaurants ? (
        <Box pb={4}>
          <Table<RestaurantResponse> data={restaurants?.items} columns={columns} isLoading={isLoadingRestaurants} />
        </Box>
      ) : null}
    </>
  );
};
