import React, { JSX } from 'react';
import { Box } from '@chakra-ui/react';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useAddRestaurantToKeyAccountGroup } from 'services/key-account-groups';

import { ChildPageHeading } from '../../../../../common/components/ChildPageHeading';
import { KeyAccountGroupsAddRestaurantRequest } from '../../../KeyAccountGroups.model';
import KeyAccountGroupsAddRestaurantForm from './KeyAccountGroupAddRestaurantForm';

const KeyAccountGroupAddRestaurant = (): JSX.Element => {
  const translations = useTranslations();
  const { mutate: addRestaurantToKeyAccountGroup, isLoading } = useAddRestaurantToKeyAccountGroup(
    Routes.KEY_ACCOUNT_GROUPS_PATH,
  );

  return (
    <Box mb={10}>
      <ChildPageHeading>{translations('key_account_groups_add_restaurant_header')}</ChildPageHeading>
      <KeyAccountGroupsAddRestaurantForm
        isLoading={isLoading}
        onSubmit={(values: KeyAccountGroupsAddRestaurantRequest) => {
          addRestaurantToKeyAccountGroup({ values });
        }}
      />
    </Box>
  );
};

export default KeyAccountGroupAddRestaurant;
