import { QueryObserverResult, useMutation, UseMutationResult, useQuery } from 'react-query';
import {
  DownloadFileType,
  FilterProperties,
  getProductByUid,
  getProducts,
  getQueueResult,
  ProductsFilterParams,
  triggerProductsGeneration,
  triggerProductsLinksGeneration,
} from 'api/products';
import { AxiosError } from 'axios';
import { BaseQueryParams, Paginated } from 'common/models';

import { HttpException } from '../common/models/httpException';
import {
  GenerateProductsRequest,
  GenerateProductsResponse,
  ProductResponse,
  TriggerGenerateProductsResponse,
} from '../modules/Products/Product.model';

export const CK_PRODUCTS = 'products';
export const QUEUE_RESULT_REFETCH_INTERVAL = 10000;

export const useProductByUid = (): UseMutationResult<ProductResponse, AxiosError<HttpException>, { uId: string }> => {
  return useMutation(({ uId }: { uId: string }) => getProductByUid(uId));
};

export const useProductList = (
  paginationParams: Omit<BaseQueryParams, 'searchText'> = { offset: 1, limit: 100 },
  filterParam?: ProductsFilterParams,
): QueryObserverResult<Paginated<ProductResponse>, AxiosError<HttpException>> => {
  const { offset, limit } = paginationParams;
  return useQuery(
    [CK_PRODUCTS, { page: offset, limit, ...filterParam }],
    () => getProducts({ ...paginationParams, ...filterParam }),
    { keepPreviousData: true },
  );
};

export const useTriggerGenerateProducts = (): UseMutationResult<
  TriggerGenerateProductsResponse,
  AxiosError<HttpException>,
  { values: GenerateProductsRequest }
> => useMutation(({ values }) => triggerProductsGeneration(values));

export const useGetQueueResult = (
  jobId: string | undefined,
): QueryObserverResult<GenerateProductsResponse, AxiosError<HttpException>> =>
  useQuery([CK_PRODUCTS, jobId], () => getQueueResult(jobId || ''), {
    enabled: !!jobId,
    refetchInterval: QUEUE_RESULT_REFETCH_INTERVAL,
  });

export const useTriggerProductsLinksGeneration = (
  type: DownloadFileType,
): UseMutationResult<
  TriggerGenerateProductsResponse,
  AxiosError<HttpException>,
  { filterProperty?: FilterProperties; filterValue?: string }
> =>
  useMutation(({ filterProperty, filterValue }) => triggerProductsLinksGeneration(type, filterProperty, filterValue));
