import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { createTag, deleteTag, getTag, getTags, updateTag } from 'api/tags';
import { AxiosError } from 'axios';
import { BaseQueryParams, Paginated } from 'common/models';
import { TagRequest } from 'modules/Tags/models/tag-request';
import { TagResponse } from 'modules/Tags/models/tag-response';

import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { HttpException } from '../common/models/httpException';

export const CK_TAGS = 'tags';

export const useTagsList = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<TagResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_TAGS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getTags(params),
    {
      keepPreviousData: true,
    },
  );
};

export const useTag = (id?: string): QueryObserverResult<TagResponse, AxiosError<HttpException>> => {
  return useQuery([CK_TAGS, id], () => getTag(id || ''), {
    enabled: !!id,
  });
};

export const useCreateTag = (): UseMutationResult<TagResponse, AxiosError<HttpException>, TagRequest> => {
  const queryClient = useQueryClient();
  return useMutation(createTag, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_TAGS);
    },
  });
};

export const useUpdateTag = (): UseMutationResult<
  TagResponse,
  AxiosError<HttpException>,
  { id: string; updateTagRequest: TagRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, updateTagRequest }: { id: string; updateTagRequest: TagRequest }) => updateTag(id, updateTagRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_TAGS);
      },
    },
  );
};

export const useDeleteTag = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation((id: string) => deleteTag(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_TAGS);
    },
  });
};
