import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { Category, CategoryRequest } from 'modules/Categories/model/Category.model';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export const getCategories = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<Category>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get<Paginated<Category>>(`${apiPaths.CATEGORIES}?${searchParams}`).then(({ data }) => data);
};

export const getCategory = (id: string): Promise<Category> =>
  axiosInstance.get<Category>(`${apiPaths.CATEGORIES}/${id}`).then(({ data }) => data);

export const updateCategory = (values: CategoryRequest, id: string): Promise<Category> => {
  const formData = createFormData(values);
  return axiosInstance.put(`${apiPaths.CATEGORIES}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createCategory = (values: CategoryRequest): Promise<Category> => {
  const formData = createFormData(values);
  return axiosInstance.post(`${apiPaths.CATEGORIES}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
