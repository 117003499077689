import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, VStack } from '@chakra-ui/react';

import { FormGroup } from '../../../../../../../../../common/components';
import { useTranslations } from '../../../../../../../../../contexts/LocalizationContext';
import { CreateReturnCodesOptions } from '../../../../models/enums/create-return-codes-options.enum';

export const FormCreateReturnCodesAction = () => {
  const translations = useTranslations();
  const { control } = useFormContext();

  const radioConfig: { optionValue: string; label: string }[] = [
    {
      optionValue: CreateReturnCodesOptions.CREATE,
      label: translations('create_return_codes_for_multiple_restaurants_confirm'),
    },
    {
      optionValue: CreateReturnCodesOptions.CREATE_AND_DOWNLOAD_RETURN_CODES,
      label: translations('create_return_codes_for_multiple_restaurants_confirm_and_download'),
    },
    {
      optionValue: CreateReturnCodesOptions.CREATE_AND_DOWNLOAD_POSTERS,
      label: translations('create_return_codes_for_multiple_restaurants_confirm_and_download_posters'),
    },
  ];

  return (
    <FormGroup label={translations('lost_items_action_label')} inputId="action">
      <Controller
        control={control}
        name="action"
        render={({ field: { value, onChange } }) => (
          <RadioGroup onChange={onChange} value={value} pt={4}>
            <VStack alignItems="start">
              {radioConfig.map(({ label, optionValue }) => (
                <Radio key={optionValue} value={optionValue}>
                  {label}
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
        )}
      />
    </FormGroup>
  );
};
