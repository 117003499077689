import React, { JSX } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FormGroup, Select } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import { PartnerRole } from 'modules/RelevoUsers/types';

export enum RestaurantUserRoleNames {
  MANAGER = 'Manager',
  EMPLOYEE = 'Employee',
  RETURN_STATION = 'Return station',
}

type RestaurantUserRoleSelect = {
  label: RestaurantUserRoleNames;
  value: PartnerRole;
};

const roleSelectOptions: RestaurantUserRoleSelect[] = [
  { label: RestaurantUserRoleNames.MANAGER, value: PartnerRole.MANAGER },
  { label: RestaurantUserRoleNames.EMPLOYEE, value: PartnerRole.EMPLOYEE },
  { label: RestaurantUserRoleNames.RETURN_STATION, value: PartnerRole.RETURN_STATION },
];

const RestaurantUserRoleForm = ({ control }: { control: Control<any> }): JSX.Element => {
  const translations = useTranslations();
  return (
    <>
      <FormGroup label={translations('restaurant_users_form_role')} inputId="role">
        <Controller
          control={control}
          name="role"
          render={({ field: { value, onChange, name } }) => (
            <Select<RestaurantUserRoleSelect>
              name={name}
              value={roleSelectOptions.find((option) => option.value === value)}
              getOptionLabel={(option) => (option as RestaurantUserRoleSelect).label}
              getOptionValue={(option) => (option as RestaurantUserRoleSelect).value}
              onChange={onChange}
              valueMapper={(v) => v?.value}
              options={roleSelectOptions}
            />
          )}
        />
      </FormGroup>
    </>
  );
};

export default RestaurantUserRoleForm;
