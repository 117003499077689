import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { getReportsStatistics } from '../api/reports';
import { HttpException } from '../common/models/httpException';
import { ReportsStatisticsResponse } from '../modules/Reports/model/ReportsStatisticsResponse';

export const useReportsStatistics = (): UseMutationResult<
  ReportsStatisticsResponse[],
  AxiosError<HttpException>,
  { restaurantIds: string[]; keyAccountGroupIds: string[]; categoryIds: string[]; startDate: string; endDate: string }
> =>
  useMutation(
    ({
      restaurantIds,
      keyAccountGroupIds,
      categoryIds,
      startDate,
      endDate,
    }: {
      restaurantIds: string[];
      keyAccountGroupIds: string[];
      categoryIds: string[];
      startDate: string;
      endDate: string;
    }) => getReportsStatistics(restaurantIds, keyAccountGroupIds, categoryIds, startDate, endDate),
  );
