import { BaseQueryParams } from 'common/models';
import { Paginated } from 'common/utils';
import apiPaths from 'config/apiPaths';
import { City, CityRequest } from 'modules/City/models/City';

import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import axiosInstance from './axios';

export const getCities = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<City>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance.get<Paginated<City>>(`${apiPaths.CITY}?${searchParams}`).then(({ data }) => data);
};

export const getCity = (id: string): Promise<City> => {
  return axiosInstance.get<City>(`${apiPaths.CITY}/${id}`).then(({ data }) => data);
};

export const createCity = (values: CityRequest): Promise<City> => axiosInstance.post(`${apiPaths.CITY}`, values);

export const updateCity = (id: string, values: CityRequest): Promise<City> =>
  axiosInstance.put(`${apiPaths.CITY}/${id}`, values);

export const deleteCity = (id: string): Promise<void> => axiosInstance.delete(`${apiPaths.CITY}/${id}`);
