import React, { JSX } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Text } from '@chakra-ui/react';

import usePaginationWithUrl from './hooks/use-pagination-with-url.hook';

type PaginationProps = {
  page: number;
  onPageChanged: (newPage: number) => void;
  totalPages: number;
};

export const Pagination = ({ page, onPageChanged, totalPages }: PaginationProps): JSX.Element => {
  const { updateUrlOnPaginationChange } = usePaginationWithUrl(onPageChanged);

  return (
    <Flex justifyContent="flex-end" m={4} alignItems="center">
      <Flex>
        <IconButton
          aria-label="first-page"
          onClick={() => updateUrlOnPaginationChange(1)}
          isDisabled={page === 1}
          icon={<ArrowLeftIcon h={3} w={3} />}
          mr={4}
        />
        <IconButton
          aria-label="previous-page"
          onClick={() => updateUrlOnPaginationChange(page - 1)}
          isDisabled={page === 1}
          icon={<ChevronLeftIcon h={6} w={6} />}
        />
      </Flex>

      <Flex alignItems="center">
        <Text fontWeight="bold" m={4} as="span">
          {`${page} / ${totalPages}`}
        </Text>
      </Flex>

      <Flex>
        <IconButton
          aria-label="next-page"
          onClick={() => updateUrlOnPaginationChange(page + 1)}
          isDisabled={page === totalPages}
          icon={<ChevronRightIcon h={6} w={6} />}
        />
        <IconButton
          aria-label="last-page"
          onClick={() => updateUrlOnPaginationChange(totalPages)}
          isDisabled={page === totalPages}
          icon={<ArrowRightIcon h={3} w={3} />}
          ml={4}
        />
      </Flex>
    </Flex>
  );
};
