import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createStandaloneToast } from '@chakra-ui/react';

import './index.css';

import theme from './styles/theme';
import { App } from './App';

const container = document.getElementById('root');

const { ToastContainer } = createStandaloneToast({ theme });
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
    <ToastContainer />
  </React.StrictMode>,
);
