/* eslint-disable import/prefer-default-export */
import { LOCALIZATION_CONFIG } from '../../config/configConsts';

export const formatDate = (date: Date): string => {
  const {
    de: { locale },
  } = LOCALIZATION_CONFIG;
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return date.toLocaleDateString(locale, options).replaceAll('.', '/');
};

export const formatTime = (date: Date): string => {
  const {
    de: { locale },
  } = LOCALIZATION_CONFIG;
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };
  return date.toLocaleTimeString(locale, options);
};

export const formatDateTime = (date: Date): string => {
  const {
    de: { locale },
  } = LOCALIZATION_CONFIG;
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  return date.toLocaleString(locale, options).replaceAll('.', '/');
};

export const getFormattedDate = (dateString?: string | null): string => {
  if (!dateString) {
    return '';
  }
  const dateValue = new Date(dateString);
  const msTimezoneOffset = dateValue.getTimezoneOffset() * 60 * 1000;
  const globalTimeValue = new Date(dateValue.getTime() - msTimezoneOffset);

  return globalTimeValue.toISOString().split('T')[0];
};
