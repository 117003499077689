import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useClosingSource, useUpdateClosingSource } from '../../services/closing-sources';
import { ClosingSource, ClosingSourceRequest } from './model/ClosingSource';
import ClosingSourcesForm from './ClosingSourcesForm';

const UpdateClosingSource = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const translations = useTranslations();
  const { isLoading: isClosingSourceFetching, data: closingSource } = useClosingSource(id);
  const { isLoading: isClosingSourceUpdating, mutate: updateClosingSource } = useUpdateClosingSource();

  const formData = closingSource
    ? ({
        name: closingSource?.name,
        googleDriveLink: closingSource.googleDriveLink,
      } as ClosingSource)
    : undefined;

  const onClosingSourceSubmit = (values: ClosingSourceRequest) => {
    if (!closingSource?.id) {
      return;
    }
    updateClosingSource(
      { values: values, id: closingSource.id },
      {
        onSuccess: () => navigate(Routes.CLOSING_SOURCES_PATH),
      },
    );
  };

  return (
    <>
      <ChildPageHeading>{translations('closing_sources_update_header')}</ChildPageHeading>
      <ClosingSourcesForm
        formData={formData}
        isLoading={isClosingSourceFetching || isClosingSourceUpdating}
        onSubmit={onClosingSourceSubmit}
      />
    </>
  );
};

export default UpdateClosingSource;
