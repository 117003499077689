import { useTranslations } from 'contexts/LocalizationContext';
import * as yup from 'yup';

export const useRelevoUserFormValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    email: yup.string().required(translations('validation_required')),
  });
};
