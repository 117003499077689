import { QueryObserverResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getRestaurantAssignedItems } from '../api/restaurant-assigned-items';
import { Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { ItemStatus } from '../modules/AssignedItems/models/enums/item-status';
import { RestaurantAssignedItemsQueryRequest } from '../modules/Restaurants/TwoFactorReturn/models/restaurant-assigned-items-query-request';
import { RestaurantAssignedItemsResponse } from '../modules/Restaurants/TwoFactorReturn/models/restaurant-assigned-items-response';

const RESTAURANT_ASSIGNED_ITEMS_QUERY_KEY = 'restaurantAssignedItems';

const getRestaurantAssignedItemsQueryKey = (
  restaurantId: string,
  offset: number,
  limit: number,
  status: ItemStatus,
) => [RESTAURANT_ASSIGNED_ITEMS_QUERY_KEY, restaurantId, { page: offset, limit, status }];

export const useRestaurantAssignedItems = (
  restaurantId: string,
  queryParams: RestaurantAssignedItemsQueryRequest,
): QueryObserverResult<Paginated<RestaurantAssignedItemsResponse>, AxiosError<HttpException>> => {
  const { limit, offset, status } = queryParams;
  return useQuery(getRestaurantAssignedItemsQueryKey(restaurantId, offset, limit, status), () =>
    getRestaurantAssignedItems(restaurantId, queryParams),
  );
};
