import React, { JSX, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { FilterProperties } from 'api/products';
import { MainPageContent, PageHeading } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { ProductsManagementPanel } from './ProductsManagementPanel/ProductsManagementPanel';
import ProductsTable from './ProductsTable';

const ProductList = (): JSX.Element => {
  const translations = useTranslations();
  const [tableFilters, setTableFilters] = useState<[FilterProperties, string?] | []>([]);

  return (
    <MainPageContent>
      <PageHeading>{translations('products_header')}</PageHeading>
      <Box w="100%">
        <ProductsManagementPanel tableFilters={tableFilters} />
      </Box>
      <ProductsTable onTableFilterChange={(property, value) => setTableFilters([property, value])} />
    </MainPageContent>
  );
};

export default ProductList;
