/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line import/prefer-default-export
export const createFormData = (data: object): FormData => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value != null) {
      if (Array.isArray(value)) {
        const arrayKey = `${key}[]`;
        value.forEach((v) => {
          formData.append(arrayKey, v);
        });
      } else {
        formData.append(key, value);
      }
    }
  });
  return formData;
};
