import React, { JSX } from 'react';
import { Message, UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { CheckIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { RestaurantDetailsResponse } from '../models';
import { RestaurantFormMode } from './RestaurantForm';

type RestaurantGoogleInputProps = {
  label: string;
  updatedFields: string[];
  useFormRegisterReturn: UseFormRegisterReturn;
  setUpdatedFields: React.Dispatch<React.SetStateAction<string[]>>;
  onClick: () => Promise<void>;
  buttonDisabled: boolean;
  fieldName: keyof RestaurantDetailsResponse;
  setValue: UseFormSetValue<any>;
  formMode: RestaurantFormMode;
  fieldErrors?: string | Message | undefined;
};

const RestaurantGoogleInput = ({
  label,
  updatedFields,
  useFormRegisterReturn,
  setUpdatedFields,
  onClick,
  buttonDisabled,
  fieldName,
  setValue,
  formMode,
  fieldErrors,
}: RestaurantGoogleInputProps): JSX.Element => {
  const translations = useTranslations();

  const trimValue = (event: React.FocusEvent<HTMLInputElement>) => setValue(fieldName, event.target.value.trim());

  return (
    <FormControl isInvalid={!!fieldErrors} mt={1}>
      <FormLabel htmlFor={fieldName}>{label}</FormLabel>
      <InputGroup size="md">
        {updatedFields.includes(fieldName) ? <InputLeftElement children={<CheckIcon color="green.500" />} /> : null}
        <Input
          {...useFormRegisterReturn}
          id={fieldName}
          onFocus={() => setUpdatedFields((prevState) => prevState.filter((item) => item !== fieldName))}
          onBlur={trimValue}
          disabled={formMode === RestaurantFormMode.MASS_UPDATE && fieldName === 'googlePlaceId'}
          placeholder={
            formMode === RestaurantFormMode.MASS_UPDATE
              ? translations('restaurants_mass_update_input_placeholder')
              : undefined
          }
        />
        {formMode !== RestaurantFormMode.MASS_UPDATE ? (
          <InputRightElement width="auto">
            <Button right="0.5rem" h="1.75rem" size="sm" onClick={onClick} isDisabled={buttonDisabled}>
              {fieldName === 'googlePlaceId'
                ? translations('update_all_fields_from_google')
                : translations('update_field_from_google')}
            </Button>
          </InputRightElement>
        ) : null}
      </InputGroup>
      {fieldErrors ? <FormErrorMessage>{fieldErrors}</FormErrorMessage> : null}
    </FormControl>
  );
};

export default RestaurantGoogleInput;
