import React, { ComponentProps } from 'react';
import { Box } from '@chakra-ui/react';
import { Droppable } from '@hello-pangea/dnd';

export const Drop = ({
  id,
  type,
  children,
  ...props
}: { id: string; type?: string } & ComponentProps<typeof Box>): JSX.Element => {
  return (
    <Droppable droppableId={id} type={type}>
      {(provided) => {
        return (
          <Box ref={provided.innerRef} {...provided.droppableProps} {...props}>
            {children}
            {provided.placeholder}
          </Box>
        );
      }}
    </Droppable>
  );
};
